<template>
  <div class="storyblok-text-richtext">
    <UtilWrapperLayout class="storyblok-text-richtext__wrapper">
      <UtilGrid>
        <AtomRichtext class="storyblok-text-richtext__text" :html="parsedHtml" />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: Object,
        required: true,
    },
});

const storyblokApi = useStoryblokApi();
const parsedHtml = computed(() => storyblokApi.richTextResolver.render(props.text));
</script>

<style lang="scss">
.storyblok-text-richtext {
    @include fluid('margin-bottom', 20px, 40px);
    @include fluid('--negative-margin', 10px, 20px);

    & + .storyblok-heading {
        margin-top: calc(var(--negative-margin) * -1);
    }

    & + .storyblok-text-richtext {
        margin-top: calc(var(--negative-margin) * -1);
    }

    &:has(+ .storyblok-link__wrapper) {
        margin-bottom: 20px;
    }
}

.storyblok-text-richtext__text {
    @include default-content-columns;
    li {
        margin-bottom: 15px;
    }
}
</style>
