<template>
  <div class="block-refrigerator-langing-page">
    <component
      :is="availableComponents[item.component]"
      v-for="item in items"
      :key="item._uid"
      :data="item"
      :html="item.text ? storyblokApi.richTextResolver.render(item?.text) : ''"
      :color-scheme="colorScheme"
      :label="item.label"
      :link="item.link"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    colorScheme: {
        type: String,
        default: '',
    },
});

const {
    data,
    colorScheme,
} = toRefs(props);

const items = ref([]);
const calculatorStore = useCalculatorStore();

items.value = data.value.content;
calculatorStore.setHeaderInfo(data.value.info);

const availableComponents = {
    StoryblokTextRichtext: resolveComponent('AtomRichtext'),
    BlockItemCalculationinput: resolveComponent('BlockCalculationItems'),
    BlockItemCalculationResult: resolveComponent('BlockCalculationResults'),
    StoryblokLink: resolveComponent('StoryblokLink'),
};

const storyblokApi = useStoryblokApi();
</script>

<style lang="scss">
.block-refrigerator-langing-page {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 60px 100px 0 100px;
    row-gap: 60px;

    .storyblok-link__wrapper {
        path {
            stroke: v-bind(colorScheme);
        }
    }

    @include mobile {
        padding: 20px 20px 20px 20px;
        row-gap: 10px;
    }
}
</style>
