<template>
  <div class="storyblok-info-programm" :class="[`is-${variant}`]">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-info-programm__wrapper is-narrow">
          <div class="storyblok-info-programm__section">
            <div class="storyblok-info-programm__section-icon__wrapper">
              <IonIcon
                :icon-name="iconName"
                class="storyblok-info-programm__section-icon"
              />
            </div>

            <AtomHeading
              v-if="titleIcon1"
              tag="h3"
              font-size="h3"
              :text="titleIcon1"
              class="storyblok-info-programm__section-title1"
              :color-schema="color"
            />
            <AtomHeading
              v-if="titleIcon2"
              tag="h3"
              font-size="h3"
              :text="titleIcon2"
              class="storyblok-info-programm__section-title2"
              :color-schema="color"
            />
          </div>

          <div class="storyblok-info-programm__content">
            <div class="storyblok-info-programm__column1">
              <div>
                <div class="storyblok-info-programm__content-title">
                  {{ title }}
                </div>
                <div class="storyblok-info-programm__content-icons">
                  <IonIcon
                    v-if="showPersonIcon"
                    icon-name="login"
                    class="storyblok-info-programm__person-icon"
                  />
                  <IonIcon
                    v-if="showCompanyIcon"
                    icon-name="unternehmen"
                    class="storyblok-info-programm__company-icon"
                  />
                </div>
              </div>

              <BlockItemInfoProgramm
                v-for=" item in column1"
                :key="item._uid"
                v-editable="item"
                :title="item?.title"
                :text="storyblokApi?.richTextResolver.render(item?.text)"
                :image="item?.image"
                image-provider="storyblok"
                :pdf="item?.pdf"
                :color-schema="color"
                :link="item?.link"
                :link-label="item?.linkLabel"
              />
            </div>
            <div class="storyblok-info-programm__column2">
              <BlockItemInfoProgramm
                v-for=" item in column2"
                :key="item._uid"
                v-editable="item"
                :title="item?.title"
                :text="storyblokApi?.richTextResolver.render(item?.text)"
                :image="item?.image"
                image-provider="storyblok"
                :pdf="item?.pdf"
                :color-schema="color"
                :link="item?.link"
                :link-label="item?.linkLabel"
              />
            </div>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    titleIcon1: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    titleIcon2: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    variant: {
        type: String,
        default: 'internal',
        validator: (value) => value.length > 0,
    },
    showPersonIcon: {
        type: Boolean,
        default: false,
    },
    showCompanyIcon: {
        type: Boolean,
        default: false,
    },

    column1: {
        type: Array,
        default: () => [],
    },

    column2: {
        type: Array,
        default: () => [],
    },
    icon: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
    color: {
        type: String,
        default: 'petrol',
    },

});

const storyblokApi = useStoryblokApi();

const colorText = computed(() => (props.color ? `var(--c-${props.color}-1)` : 'var(--c-orange-1)'));
const iconName = computed(() => (props.variant && props.icon ? `${props.variant}-${props.icon}` : 'internal-house'));
const colorIcon = computed(() => (props.color ? `var(--c-${props.color}-8)` : 'var(--c-orange-8)'));
const colorBg = computed(() => (props.color ? `var(--c-${props.color}-11)` : 'var(--c-orange-11)'));

</script>
<style lang="scss">
.storyblok-info-programm {
    background:  var(--c-white);

    &.is-internal {
        background: v-bind(colorBg);
    }

    @include fluid('margin-bottom', 50px, 80px);
}

.storyblok-info-programm__wrapper {
    @include default-content-columns;

    display: grid;
    padding: 40px 0;
    color: v-bind(colorText);
    column-gap: var(--grid-gutter);
    grid-template-columns: repeat(12, 1fr);

    @include mobile {
        padding-bottom: 30px;
        grid-column-end: span 3;
        grid-template-columns: repeat(3, 1fr);
    }
}

.storyblok-info-programm__section {
    grid-column-end: span 3;

    @include mobile {
        padding-bottom: 40px;
        grid-column-end: span 3;
    }
}

.storyblok-info-programm__section-icon__wrapper {
    position: relative;
    margin-bottom: 10px;
    aspect-ratio: 25/21;

    @include mobile {
        width: 174px;
        min-height: 147px;
        margin-bottom: 20px;
    }
}

.storyblok-info-programm__section-icon {
    .light-icon-color {
        fill: v-bind(colorBg);
    }

    .dark-icon-color {
        fill: v-bind(colorIcon);
    }

}

.storyblok-info-programm__section-title1 {
    font-family: var(--f-family--black) !important;
}

.storyblok-info-programm__section-title2 {
    font-family: var(--f-family--regular);
}

.storyblok-info-programm__section-title1,
.storyblok-info-programm__section-title2 {
    .is-internal & {
        color: var(--c-white);
    }

    .is-external & {
        color: v-bind(colorBg);
    }

    @include mobile {
        font-size: 30px !important;
        line-height: 34px !important;
    }
}

.storyblok-info-programm__content {
    @include grid-layout(9);

    display: grid;
    gap: var(--grid-gutter);
    grid-column-end: span 9;

    @include mobile {
        flex-wrap: wrap;
        column-gap: var(--grid-gutter);
        grid-column-end: span 3;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 20px;
    }
}

.storyblok-info-programm__content-title {
    padding-bottom: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);

    @include tablet {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
    }
}

.storyblok-info-programm__content-icons {
    display: flex;
    gap: 20px;
}

.storyblok-info-programm__person-icon,
.storyblok-info-programm__company-icon {

    path, circle {
        stroke: v-bind(colorText);
    }
}

.storyblok-info-programm__column1 {
    grid-column-end: span 4;
}

.storyblok-info-programm__column2 {
    grid-column-end: span 5;
}

.storyblok-info-programm__column1,
.storyblok-info-programm__column2  {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mobile {
        flex-wrap: wrap;
        column-gap: var(--grid-gutter);
        gap: 20px;
        grid-column-end: span 3;
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
