<template>
  <div class="storyblok-slider-hero">
    <BlockSliderHero
      v-if="isArrayNotEmpty(mappedItems)"
      :items="mappedItems"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const { locale: currentLocale } = useI18n();

/*
    Mapping of items
*/
const mappedItems = props.items.map((item) => ({
    title_dark: item.title_dark,
    title_white: item.title_white,
    lead: item.lead,
    article: {
        cached_url: item.article.default_full_slug,
        story: {
            alternates: item.article.translated_slugs,
        },
        date: item.article.first_published_at,
        type: item.article.content.type.name,
        department: {
            name: item.article.content.department.translated_slugs?.find(
                (slug) => slug.lang === currentLocale.value,
            )?.name || item.article.content.department.name,
            color_1: item.article.content.department.content.color_1,
            color_8: item.article.content.department.content.color_8,
        },
        image: {
            ...item.article.content.image_video,
            provider: 'storyblok',
        },
    },
    image: item.image,
}));
</script>

<style lang="scss" scoped>
.storyblok-slider-hero {
    @include default-content-columns;
    @include fluid('margin-bottom', 20px, 80px);

    min-height: 520px;

    &:has(+ .block-breadcrumbs) {
        margin-bottom: 0;
    }

    @include mobile {
        min-height: unset;
    }
}
</style>
