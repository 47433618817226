<template>
  <div
    v-if="!isFiltered"
    class="block-teaser-curated"
  >
    <BlockItemTeaser
      v-for="item in items"
      :key="item._uid"
      :class="{
        'block-teaser-curated--is-all-items-mini': isAllItemsMini,
      }"
      :item="item"
    />
    <Swiper
      v-if="isAllItemsMini"
      :modules="modules"
      slides-per-view="auto"
      :space-between="20"
      :grab-cursor="true"
      class="block-teaser-curated__swiper"
      @swiper="onSwiper"
    >
      <SwiperSlide
        v-for="item in items"
        :key="item._uid"
        class="block-teaser-curated__swiper-card"
      >
        <BlockItemTeaser
          :item="{ ...item }
          "
          news-section
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
const { Swiper, SwiperSlide } = useSwiperjs();

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
        validator: (value) => typeof value === 'object',
    },
});
/*
    Init Swiper
*/

const swiperController = ref(null);

const onSwiper = (e) => {
    swiperController.value = e;
};

const filterStore = useFilterStore();
const isFiltered = computed(() => filterStore.isFiltered);
const isAllItemsMini = computed(() => props.items.every((item) => item.size === 'mini'));

</script>

<style lang="scss" scoped>
.block-teaser-curated {
    @include grid-layout(12);
}
.block-teaser-curated--is-all-items-mini {
    @include tablet {
        display: none;
    }
}
.block-teaser-curated__swiper {
    display: none;

    @include tablet {
        display: flex;
        width: 100%;
        flex-direction: row;
        grid-column-end: span 12;

        .block-teaser-curated__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;

        }

        .swiper-slide {
            width: 270px !important;
            @include mobile {
                width: 290px !important;
            }
        }
    }

}
</style>
