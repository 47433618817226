<template>
  <div class="block-item-key-figures-international">
    <div v-if="title" class="block-item-key-figures-international__item-title">
      {{ title }}
    </div>
    <AtomRichtext
      v-if="text?.type"
      class="block-item-key-figures-international__item-text"
      :html="richTextResolver.render(text)"
    />
    <p v-else class="block-item-key-figures-international__item-text">
      {{ text }}
    </p>
    <AtomLink
      v-if="link?.id
        || link?.url"
      class="block-item-key-figures-international__link"
      v-bind="{ ...getLinkData(link, linkLabel) }"
    />
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    text: {
        type: [String, Object],
        default: '',
    },

    linkLabel: {
        type: String,
        default: '',
    },

    link: {
        type: Object,
        default: () => {},
    },

});

const getLinkData = (link, label) => useStoryblokLink(link, label);
const { richTextResolver } = useStoryblokApi();
</script>

<style lang="scss" scoped>
.block-item-key-figures-international {
    font-size: var(--f-size--richtext);
    grid-column-end: span 1;
    line-height: var(--l-height--richtext);

    @include mobile {
        grid-column-end: span 2;
    }
}

.block-item-key-figures-international__item-title {
    font-family: var(--f-family--thin);
    hyphens: auto;
    word-break: break-word;
}

.block-item-key-figures-international__item-text {
    font-family: var(--f-family--bold);
}
</style>
