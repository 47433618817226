<template>
  <div class="atom-input-number-calc atom-input-number-calc__wrapper">
    <div
      class="atom-input-number-calc-text"
      :class="{
        'has-input': hasInput,
        'is-disabled': isDisabled || isDisabledRefrigerator,
        'has-error': showErrors,
      }"
    >
      <label class="atom-input-number-calc-text__label" :for="name">
        {{ label + (isRequiredVal ? ' *' : '') }}
      </label>

      <input
        :id="name"
        v-model="inputValue"
        :name="name"
        :required="isRequiredVal"
        type="number"
        class="atom-input-number-calc-text__input"
        :tabindex="isDisabledRefrigerator ? -1 : null"
        @focus="setFocus(true)"
        @focusout="setFocus(false), resetValue(false)"
        @mouseenter="setHover(true)"
        @mouseleave="setHover(false)"
        @click="resetValue(true)"
      >
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides an input field with label, icon, and validation support.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} [props.initialValue=''] - The initial value of the input field.
 * @param {string} [props.inputType='text'] - The type of input field.
 * @param {array} [props.validations=[]] - An array of validation rules.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    parentObject: {
        type: String,
        default: '',
    },

    executionType: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    initialValue: {
        type: String,
        default: '',
        validator: (value) => typeof value === 'string',
    },

    inputType: {
        type: String,
        default: 'text',
        validator: (value) => [
            'text',
            'email',
            'password',
        ].includes(value),
    },

    /*
        Validations
    */
    validations: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    /*
        Variants
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    isRequiredVal: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    sendIfZero: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },
});

const {
    executionType,
    name,
    initialValue,
    sendIfZero,
} = toRefs(props);

/*
    Intialze formfield
*/
const errors = ref([]);
const inputValue = ref('');

/*
    Handle input
*/
const emit = defineEmits(['set-input', 'set-error']);

/* Watch input */
const handleData = (value) => {
    if (props.isDisabled) return;

    emit('set-input', {
        name: name.value,
        value,
        parentObject: props.parentObject,
    });
};

watch(() => inputValue.value, (newValue) => {
    if (inputValue.value === 0 && sendIfZero.value) {
        handleData(newValue);
        return;
    }

    if (inputValue.value === 0 && !sendIfZero.value) {
        return;
    }

    handleData(newValue);
});

/*
    States
*/
const {
    setHover,
    setFocus,
    hasInput,
    showErrors,
} = useFormFieldStates(inputValue, errors, props);

const calculatorStore = useCalculatorStore();
const projectType = computed(() => calculatorStore.getProjectType);

const isDisabledRefrigerator = ref(false);

if (executionType.value && executionType.value?.length > 0) {
    isDisabledRefrigerator.value = true;
}

watch(projectType, () => {
    isDisabledRefrigerator.value = true;
    inputValue.value = '';

    executionType.value.forEach((type) => {
        if (type === projectType.value) {
            isDisabledRefrigerator.value = false;
        }
    });
});

executionType.value.forEach((type) => {
    if (type === projectType.value) {
        isDisabledRefrigerator.value = false;
    }
});

// set initial value
const requestBody = computed(() => calculatorStore.getRequestBody);
inputValue.value = parseInt(
    requestBody.value[name.value] || requestBody.value[name.value] === 0
        ? requestBody.value[name.value]
        : initialValue.value,
    10,
);

// if click on input, reset value
const isFocus = ref(false);
const resetValue = (focus) => {
    isFocus.value = focus;

    if (inputValue.value === 0 && isFocus.value) {
        inputValue.value = '';
        return;
    }

    if (inputValue.value === '' && !isFocus.value) {
        inputValue.value = 0;
    }
};

const colorScheme = computed(() => calculatorStore.getColorScheme);
const colorText = `var(--c-${colorScheme.value}-1)`;
</script>

<style lang="scss">
.atom-input-number-calc__wrapper {
    @include formFieldWrapper;

    input {
        //hide number input arrows
        -moz-appearance: textfield;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
    }
}

.atom-input-number-calc-text {
    @include formFieldBasicsText;

    justify-content: space-between;

    &.is-disabled {
        pointer-events: none;
    }
}

.atom-input-number-calc-text__label {
    @include formFieldLabelText;

    margin-bottom: 0;
    color: v-bind(colorText);

    .block-download-hub__search & {
        display: none;
    }

    @include mobile {
        white-space: normal;
    }
}

.atom-input-number-calc-text__input {
    @include formFieldInputText;

    height: 40px;
    border-bottom: solid 2px v-bind(colorText);
    margin-bottom: 0;
    color: v-bind(colorText);

    .block-download-hub__search-input &{
        margin-bottom: 0
    }
}

.atom-input-number-calc-text__icon {
    @include formFieldInputIcon;
}
</style>
