<template>
  <div class="block-item-info-programm">
    <div v-if="image?.filename" class="block-item-info-programm__image">
      <AtomImage
        v-if="image?.filename"
        :image="image"
        :provider="imageProvider"
      />
    </div>

    <div class="block-item-info-programm__content">
      <div v-if="title" class="block-item-info-programm__item-title">
        {{ title }}
      </div>
      <AtomLink
        v-if="link?.id
          || link?.url"
        class="block-item-info-programm__link"
        v-bind="{ ...getLinkData(link, linkLabel) }"
        :color-schema="colorSchema"
      />

      <AtomRichtext
        v-if="text"
        class="block-item-info-programm__item-text"
        :html="text"
      />
      <div
        v-if="pdf?.filename"
        class="block-item-info-programm__download-section"
        @click.prevent="downloadItem(pdf)"
        @keypress="downloadItem(pdf)"
      >
        <p class="block-item-info-programm__title">
          {{ getStoryblokTranslation('general', 'download') }}
        </p>
        <IonIcon
          icon-name="arrow-download"
          class="block-item-info-programm__icon"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    text: {
        type: String,
        default: null,
    },

    image: {
        type: Object,
        default: null,
    },

    imageProvider: {
        type: String,
        default: 'storyblok',
        validator: (value) => ['storyblok'].includes(value),
    },

    pdf: {
        type: Object,
        default: () => ({}),
    },

    colorSchema: {
        type: String,
        default: 'orange',
    },

    linkLabel: {
        type: String,
        default: '',
    },

    link: {
        type: Object,
        default: () => {},
    },

});

const {
    createBlob,
    downloadImages,
} = useDownloadFile();

const getLinkData = (link, label) => useStoryblokLink(link, label);

const downloadItem = async ({ filename }, index) => {
    const blobImage = await createBlob(filename, index);
    const pdfName = filename.split('/').pop();

    downloadImages(blobImage, index, pdfName);
};

const colorText = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-1)` : 'var(--c-orange-1)'));

const colorTextHover = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-4)` : 'var(--c-orange-4)'));

</script>
<style lang="scss">
.block-item-info-programm {
    @include fluid('gap', 10px, 20px);

    display: flex;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include mq(1033px) {
        flex-direction: column;
    }
}

.block-item-info-programm__item-title {
    @include fluid('padding-bottom', 2px, 10px);

    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);

    @include tablet {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
    }
}

.block-item-info-programm__item-text {
    color: v-bind(colorText) !important;
    font-family: var(--f-family--bold);

    a {
        color: v-bind(colorText) !important;
    }

    .thin {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--tag);
    }
}

.block-item-info-programm__image {
    width: 160px;
    height: 160px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.block-item-info-programm__download-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--f-family--bold);
    gap: 10px;
}

.block-item-info-programm__icon {
    path {
        stroke: v-bind(colorText);
    }
}

.block-item-info-programm__link {
    path {
        stroke: v-bind(colorText);
    }

    color: v-bind(colorText);

    &:hover {
        color: v-bind(colorTextHover);

        path {
            stroke: v-bind(colorTextHover);
        }
    }
}
</style>
