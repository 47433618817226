<template>
  <div
    class="block-item-sublinks-links-card-scalable"
    :class="[`is-columns-amount-${sizeProportion}`]"
  >
    <div class="block-item-sublinks-links-card-scalable__content">
      <UtilRouteLink
        v-if="link?.id || link?.url"
        :key="link?._uid"
        :link="link"
      >
        <div class="block-item-sublinks-links-card-scalable__heading">
          <h4 v-if="title" class="block-item-sublinks-links-card-scalable__title">
            {{ title }}
          </h4>
          <IonIcon
            icon-name="chevron-right"
            class="block-item-sublinks-links-card-scalable__icon"
          />
        </div>
      </UtilRouteLink>
      <div v-if="!link?.id && !link?.url" class="block-item-sublinks-links-card-scalable__heading">
        <h4 v-if="title" class="block-item-sublinks-links-card-scalable__title">
          {{ title }}
        </h4>
        <IonIcon
          icon-name="chevron-right"
          class="block-item-sublinks-links-card-scalable__icon"
        />
      </div>

      <div v-if="Array.isArray(columns) && columns.length > 0" class="block-item-sublinks-links-card-scalable__nav">
        <div
          v-for="column in columns"
          :key="column?._uid"
          class="block-item-sublinks-links-card-scalable__nav-column"
        >
          <UtilRouteLink
            v-for="columnLink in column?.links"
            :key="columnLink?._uid"
            :link="columnLink.link"
            class="block-item-sublinks-links-card-scalable__navigation-item"
          >
            {{ columnLink?.linkLabel }}
          </UtilRouteLink>
        </div>
      </div>
      <div v-if="Array.isArray(items) && items.length > 0" class="block-item-sublinks-links-card-scalable__nav-simple-card">
        <UtilRouteLink
          v-for="item in items"
          :key="item?._uid"
          :link="item.link"
          class="block-item-sublinks-links-card-scalable__navigation-item"
        >
          {{ item?.linkLabel }}
        </UtilRouteLink>
      </div>
    </div>
    <div class="block-item-sublinks-links-card-scalable__overflow" />
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    text: {
        type: String,
        default: '',
    },

    color: {
        type: String,
        default: '',
    },

    columns: {
        type: Array,
        default: () => [],
    },

    items: {
        type: Array,
        default: () => [],
    },

    sizeProportion: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.color ? `var(--c-${props.color}-7)` : `var(--c-${colorTopLevel.value}-7)`));

</script>
  <style lang="scss">
.block-item-sublinks-links-card-scalable {
    position: relative;
    background: v-bind(colorBackground);
    color: var(--c-white);
    grid-column-end: span 2;
    &.is-columns-amount-3  {
        grid-column-end: span 3;
    }
    &.is-columns-amount-4 {
        grid-column-end: span 4;
    }
    &.is-columns-amount-6 {
        grid-column-end: span 6;
    }
    @include above-desktop {
        &:hover .block-item-sublinks-links-card-scalable__overflow {
            opacity: 1;
            transform: scale(1.07);
        }
        &:hover .block-item-sublinks-links-card-scalable__content {
            transform: scale(1.07);
        }
    }

    @include mobile {
        grid-column-end: span 6;
        &.is-columns-amount-3,
        &.is-columns-amount-4,
        &.is-columns-amount-6  {
            grid-column-end: span 6;
        }
    }
}
.block-item-sublinks-links-card-scalable__content {
    @include fluid('gap', 10px, 20px);
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 40px;
    color: v-bind(colorText);
    @include mobile {
        padding: 20px;
    }
}
.block-item-sublinks-links-card-scalable__heading {
    display: flex;
    align-items: center;
    gap: 10px;
    @include mobile {
        margin-bottom: -6px
    }
}
.block-item-sublinks-links-card-scalable__title {
    overflow: hidden;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);
    @include word-break;
}
.block-item-sublinks-links-card-scalable__icon {
    flex-shrink: 0;
    path {
        stroke: var(--c-white);
    }
}
.block-item-sublinks-links-card-scalable__nav {
    @include fluid('gap', 16px, 20px);

    .is-columns-amount-3 & ,
    .is-columns-amount-4 & ,
    .is-columns-amount-6 & {
        display: flex;
        flex-direction: row;
        @include mobile {
            flex-direction: column;
        }
    }
}
.block-item-sublinks-links-card-scalable__nav-simple-card {
    @include fluid('gap', 16px, 20px);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-left: 40px;
    @include tablet {
        padding-left: 20px;
    }
}
.block-item-sublinks-links-card-scalable__nav-column {

    display: flex;
    width: 100%;
    flex-direction: column;
    @include fluid('gap', 16px, 20px);
    padding-left: 20px;

}
.block-item-sublinks-links-card-scalable__navigation-item {
    position: relative;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    line-height: var(--l-height--richtext);
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;

    button {
        line-height: 26px;
    }
    &.is-active {
        color: var(--c-white);
    }
    &::before {
        position: absolute;
        bottom: 5px;
        width: 32px;
        height: 1px;
        border-radius: 10px;
        content: '';
        transform: translateX(0px);
        transition: background-color 100ms $EASE_IN--QUAD,transform 100ms $EASE_IN--QUAD;
    }
    &:hover, &:active {
        &::before {
            position: absolute;
            bottom: 0;
            width: 32px;
            height: 1px;
            border-radius: 10px;
            background: var(--c-white);
            content: '';
            transition: all 100ms ease-in-out;
        }
    }

}
.block-item-sublinks-links-card-scalable__overflow {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: v-bind(colorBackground);
    opacity: 0;
}

  </style>
