<template>
  <div
    class="block-overlay-person"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
  >
    <div class="block-overlay-person__card-wrapper">
      <div class="block-overlay-person__card">
        <button
          class="block-overlay-person__person-close top"
          type="button"
          @click="closeOverlay"
          @keypress="closeOverlay"
        >
          <IonIcon icon-name="close" />
        </button>
        <AtomImage
          v-if="person.content?.image"
          :image="person.content?.image"
          class="block-overlay-person__card-image"
          height="360px"
          width="360px"
          provider="storyblok"
        />
        <div class="block-overlay-person__card-content">
          <div
            v-if="person.content?.departments?.length > 0"
            class="block-overlay-person__person-tags"
          >
            <div
              v-for="department in person.content?.departments"
              :key="department._uid"
              class="block-overlay-person__person-tag"
              :style="`background-color: ${department.content.color_11}; color: ${department.content?.color_6}`"
            >
              {{ department.translated_slugs?.find(
                (slug) => slug.lang === currentLocale,
              )?.name || department.name }}
            </div>
          </div>
          <div class="block-overlay-person__person-department">
            {{ person.content?.department }}
          </div>
          <div class="block-overlay-person__person-name">
            {{ person.content?.name }}
          </div>
          <div class="block-overlay-person__person-position">
            {{ person.content?.position }}
          </div>
          <div v-if="person?.content?.additional_info" class="block-overlay-person__additional-info">
            {{ person.content?.additional_info }}
          </div>
          <div class="block-overlay-person__person-contact-info">
            <a
              v-if="person.content?.email"
              class="block-overlay-person__person-email"
              :href="`mailto:${person.content?.email}`"
            >
              {{ person.content?.email }}
            </a>
            <a
              v-if="person.content?.phone"
              class="block-overlay-person__person-phone"
              :href="`Tel:${person.content?.phone}`"
            >
              {{ person.content?.phone }}
            </a>
          </div>
          <button
            class="block-overlay-person__person-close"
            type="button"
            @click="closeOverlay"
            @keypress="closeOverlay"
          >
            <IonIcon icon-name="close" />
            <p>{{ getStoryblokTranslation('general', 'close') }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    person: {
        type: Object,
        required: true,
        default: () => {},
    },
});

const { locale: currentLocale } = useI18n();

const emit = defineEmits(['close']);

const closeOverlay = () => {
    emit('close', true);
};

</script>

<style lang="scss" scoped>
.block-overlay-person {
    @include z-index('lightbox');

    position: fixed;
    top: 0;
    left: 0;
    display: table;
    display: table-cell;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.25);
    transition: opacity 0.3s ease;
    vertical-align: middle;
}

.block-overlay-person__card-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.block-overlay-person__card {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 270px;
    background-color: var(--c-petrol-7);
    transform: translate(-50%, -50%);
}

.block-overlay-person__card-image {
    height: 270px;
}

.block-overlay-person__card-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: var(--c-white);
}

.block-overlay-person__person-department {
    margin-bottom: 5px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.block-overlay-person__person-name {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-overlay-person__person-position {
    margin-bottom: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
.block-overlay-person__additional-info {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-overlay-person__person-contact-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    a {
        color: var(--c-petrol-4);
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext)
    }
}

.block-overlay-person__person-close {
    display: flex;
    align-items: center;
    align-self: flex-end;
    color: var(--c-petrol-1);
    column-gap: 5px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);

    &.top {
        position: absolute;
        top: 13px;
        right: 13px;
    }

    path {
        fill: var(--c-petrol-1);
    }
}

.block-overlay-person__person-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    column-gap: 5px;
    row-gap: 5px;
}

.block-overlay-person__person-tag {
    padding: 6px 9px 4px 9px;
    border-radius: 4px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
}
</style>
