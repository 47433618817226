<template>
  <div class="block-navigation-side-item">
    <component
      :is="useComponent(item.component)"
      v-for="item in block.content"
      :key="item._uid"
      v-bind="item"
      v-editable="item"
    />
  </div>
</template>

<script setup>

defineProps({
    block: {
        type: Object,
        required: true,
        default: () => {},
    },
});
const availableComponents = {
    StoryblokTextHeading: resolveComponent('StoryblokTextHeading'),
    StoryblokImage: resolveComponent('StoryblokImage'),
    StoryblokTextRichtext: resolveComponent('StoryblokTextRichtext'),
    StoryblokIntroText: resolveComponent('StoryblokIntroText'),
    StoryblokVideoYoutube: resolveComponent('StoryblokVideoYoutube'),
    StoryblokAccordion: resolveComponent('StoryblokAccordion'),
    StoryblokInfoBox: resolveComponent('StoryblokInfoBox'),
    StoryblokLink: resolveComponent('StoryblokLink'),
    StoryblokIframe: resolveComponent('StoryblokIframe'),
    StoryblokQuote: resolveComponent('StoryblokQuote'),
    StoryblokTeaserCard: resolveComponent('StoryblokTeaserCard'),
    StoryblokOverlay: resolveComponent('StoryblokOverlay'),
    StoryblokKeyFigures: resolveComponent('StoryblokKeyFigures'),
    StoryblokKeyFiguresInternational: resolveComponent('StoryblokKeyFiguresInternational'),
    StoryblokProfile: resolveComponent('StoryblokProfile'),
    StoryblokImageGallery: resolveComponent('StoryblokImageGallery'),
    StoryblokTeaserPdf: resolveComponent('StoryblokTeaserPdf'),
    StoryblokSliderEnumeration: resolveComponent('StoryblokSliderEnumeration'),
    StoryblokTeaserSublinks: resolveComponent('StoryblokTeaserSublinks'),
    StoryblokTeaserSublinksScalable: resolveComponent('StoryblokTeaserSublinks'),
    StoryblokTeaserBox: resolveComponent('StoryblokTeaserBox'),
    StoryblokTeaserNewsRegional: resolveComponent('StoryblokTeaserNewsRegional'),
    StoryblokTeaserNewsInternational: resolveComponent('StoryblokTeaserNewsInternational'),
    StoryblokFormContact: resolveComponent('StoryblokFormContact'),
    StoryblokSliderParticipation: resolveComponent('StoryblokSliderParticipation'),
    StoryblokProjectsBlock: resolveComponent('StoryblokProjectsBlock'),
    StoryblokDownloadHubMini: resolveComponent('StoryblokDownloadHubMini'),
    StoryblokTeaserProjects: resolveComponent('StoryblokTeaserProjects'),
    StoryblokContributionCalculator: resolveComponent('StoryblokContributionCalculator'),
};
const useComponent = (key) => availableComponents[key] || null;
</script>

<style lang="scss">
.block-navigation-side-item {
    &.BlockRichtext {
        margin-bottom: 0;
    }
}
.block-navigation-side-item__content {
    &.BlockRichtext {
        margin-bottom: 0;
    }
}
</style>
