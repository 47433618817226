<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-key-figures">
        <BlockKeyFigures
          :title="title"
          :items="mappedItems"
          :image="image"
          image-provider="storyblok"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        required: true,
        validator: (value) => value.isArray && value.length > 0,
    },

    image: {
        type: Array,
        default: () => [],
    },
});

const storyblokApi = useStoryblokApi();

// map data from storyblok to the format we need
const mappedItems = computed(() => props.items.map((item) => ({
    title: item.title,
    text: storyblokApi?.richTextResolver.render(item.text),
    is_light_font: item?.is_light_font,
})));

</script>

<style lang="scss" scoped>
.storyblok-key-figures {
    @include grid-columns(1, 18);
    @include fluid('margin-bottom', 60px, 90px);
}

</style>
