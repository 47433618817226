<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-teaser">
        <BlockTeaser
          :items="allItems"
          :pinned-items="mappedItems"
          :show-load-more="showLoadMore"
          :color-schema="program === 'national' ? 'coral' : 'petrol'"
          @load-more="loadMore"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    pinned: {
        type: Array,
        default: () => [],
    },

    items: {
        type: Array,
        default: () => [],
    },

    program: {
        type: String,
        required: true,
    },
});

const storyblokApi = useStoryblokApi();
const filterStore = useFilterStore();

const program = computed(() => props.program);
const allItems = ref([]);
const pageSize = ref(7);
const page = ref(1);
const showLoadMore = ref(true);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const mappedItems = computed(() => props.pinned
    .filter((item) => item && item.article)
    .map((item) => ({
        article: {
            title: item.article.name,
            lead: item.article?.content?.lead,
            url: item.article.default_full_slug,
            date: item.article?.content?.manualPublishedDate || item.article.first_published_at,
            type: item.article.content.type.name,
            department: {
                name:
            item.article.content.department.translated_slugs?.find(
                (slug) => slug.lang === currentLocale.value,
            )?.name || item.article.content.department.name,
                color_3: item.article.content.department.content.color_3,
            },
            image: {
                ...item.article.content.image_video,
                provider: 'storyblok',
            },
        },
        image_position: item.image_position,
        size: item.size,
    })));

const setFilter = () => {
    let filterQuery = null;

    switch (program.value) {
    case 'national':
        filterQuery = {
            country: {
                is: 'not_empty',
            },
            isStiftung: {
                is: 'false',
            },
        };
        break;
    case 'international':
        filterQuery = {
            country: {
                is: 'empty',
            },
            isStiftung: {
                is: 'false',
            },
        };
        break;
    case 'stiftung':
        filterQuery = {
            isStiftung: {
                is: 'true',
            },
        };
        break;
    default:
        break;
    }

    if (isArrayNotEmpty(mappedItems.value)) {
        filterQuery = {
            ...filterQuery,
            title: {
                not_in: mappedItems.value.map((item) => item.article.title).toString(),
            },
        };
    }

    return filterQuery;
};

const route = useRoute();
const currentPath = computed(() => route.path);
const localePath = useLocalePath();
const isNewsOverview = computed(() => currentPath.value === localePath('/news/') || currentPath.value === localePath('/news'));

const {
    execute: getArticlesTeaser,
    error: getArticlesTeaserError,
} = useLazyAsyncData(
    `get-article-files-teaser-${getUid()}`,
    async () => storyblokApi.get(
        'cdn/stories/',
        {
            starts_with: 'news/news-article',
            version: 'published',
            resolve_relations: 'News.department,News.type,News.tags,News.country',
            per_page: pageSize.value,
            page: page.value,
            filter_query: setFilter(),
            sort_by: 'first_published_at:desc',
            language,
        },
    ),
    {
        transform: (res) => {
            const allTags = [];

            res.data.stories.forEach((article) => {
                article.content.tags.forEach((tag) => {
                    if (!allTags.find((allTag) => allTag.name === tag.name)) {
                        allTags.push(tag);
                    }
                });
            });

            filterStore.setCloudTags(allTags);

            if (page.value === 1) {
                allItems.value = res.data.stories.map((article) => {
                    // Compute tag for each article
                    let teaserTag = '';
                    let textColor = '';
                    let dateColor = '';
                    const tagColors = {
                        backgroundColor: '',
                        color: '',
                    };

                    // Compute tag logic for individual articles
                    if (isNewsOverview.value) {
                        if (article.content.isStiftung) {
                            tagColors.backgroundColor = 'var(--c-petrol-8)';
                            tagColors.color = 'var(--c-white)';
                            textColor = 'var(--c-petrol-1)';
                            dateColor = 'var(--c-petrol-4)';
                            teaserTag = getStoryblokTranslation('general', 'stiftung');
                        } else if (article.content.country && article.content.country?.name) {
                            tagColors.backgroundColor = article.content.department
                                ?.content?.color_11;
                            tagColors.color = article.content.department?.content?.color_4;
                            textColor = article.content.department?.content?.color_1;
                            dateColor = article.content.department?.content?.color_4;
                            teaserTag = getStoryblokTranslation('general', 'international');
                        } else if (article.content.department && article.content.department?.name) {
                            tagColors.backgroundColor = 'var(--c-petrol-11)';
                            tagColors.color = 'var(--c-petrol-4)';
                            textColor = 'var(--c-petrol-1)';
                            dateColor = 'var(--c-petrol-4)';
                            teaserTag = getStoryblokTranslation('general', 'switzerland');
                        }
                    } else if (article.content.isStiftung) {
                        tagColors.backgroundColor = 'var(--c-petrol-8)';
                        tagColors.color = 'var(--c-white)';
                        textColor = 'var(--c-petrol-1)';
                        dateColor = 'var(--c-petrol-4)';
                        teaserTag = getStoryblokTranslation('general', 'stiftung');
                    } else if (article.content.country && article.content.country?.name) {
                        tagColors.backgroundColor = article.content.department?.content?.color_11;
                        tagColors.color = article.content.department?.content?.color_4;
                        textColor = article.content.department?.content?.color_1;
                        dateColor = article.content.department?.content?.color_4;
                        teaserTag = article.content.country?.name;
                    } else if (article.content.department && article.content.department?.name) {
                        tagColors.backgroundColor = article.content.department?.content?.color_11;
                        tagColors.color = article.content.department?.content?.color_4;
                        textColor = article.content.department?.content?.color_1;
                        dateColor = article.content.department?.content?.color_4;
                        teaserTag = article.content.department.translated_slugs?.find(
                            (slug) => slug.lang === currentLocale.value,
                        )?.name || article.content.department?.name;
                    }

                    // Return the mapped article with computed tag and tag colors
                    return {
                        article: {
                            title: article.content.title,
                            lead: article.content.lead,
                            cached_url: article.default_full_slug,
                            story: {
                                alternates: article.translated_slugs,
                            },
                            date: article.content.manualPublishedDate || article.first_published_at,
                            type: article.content.type.name,
                            tags: article.content.tags,
                            department: {
                                name: article.content.department.translated_slugs?.find(
                                    (slug) => slug.lang === currentLocale.value,
                                )?.name || article.content.department?.name,
                                color_1: article.content.department?.content?.color_1,
                                color_3: article.content.department?.content?.color_3,
                                color_4: article.content.department?.content?.color_4,
                                color_11: article.content.department?.content?.color_11,
                            },
                            teaserTag,
                            tagColors,
                            textColor,
                            dateColor,
                            isStiftung: article.content.isStiftung,
                            image: {
                                ...article.content.image_video,
                                provider: 'storyblok',
                            },
                            hasVideoTeaser: article.content.activate_video_teaser,
                            textVideoTeaser: article.content.teaser_video,
                        },
                    };
                });

                if (allItems.value.length < parseInt(res.headers?.total, 10)) {
                    showLoadMore.value = true;
                } else {
                    showLoadMore.value = false;
                }
                return;
            }

            // Handle pagination logic for page > 1
            allItems.value = [
                ...allItems.value,
                ...res.data.stories.map((article) => {
                    // Compute tag for each article
                    let teaserTag = '';
                    let dateColor = '';
                    let textColor = '';
                    const tagColors = {
                        backgroundColor: '',
                        color: '',
                    };

                    // Compute tag logic for individual articles
                    if (isNewsOverview.value) {
                        if (article.content.isStiftung) {
                            tagColors.backgroundColor = 'var(--c-petrol-8)';
                            tagColors.color = 'var(--c-white)';
                            textColor = 'var(--c-petrol-1)';
                            dateColor = 'var(--c-petrol-4)';
                            teaserTag = getStoryblokTranslation('general', 'stiftung');
                        } else if (article.content.country && article.content.country?.name) {
                            tagColors.backgroundColor = article.content.department
                                ?.content?.color_11;
                            tagColors.color = article.content.department?.content?.color_4;
                            textColor = article.content.department?.content?.color_1;
                            dateColor = article.content.department?.content?.color_4;
                            teaserTag = getStoryblokTranslation('general', 'international');
                        } else if (article.content.department && article.content.department?.name) {
                            tagColors.backgroundColor = 'var(--c-petrol-11)';
                            tagColors.color = 'var(--c-petrol-4)';
                            textColor = 'var(--c-petrol-1)';
                            dateColor = 'var(--c-petrol-4)';
                            teaserTag = getStoryblokTranslation('general', 'switzerland');
                        }
                    } else if (article.content.isStiftung) {
                        tagColors.backgroundColor = 'var(--c-petrol-8)';
                        tagColors.color = 'var(--c-white)';
                        textColor = 'var(--c-petrol-1)';
                        dateColor = 'var(--c-petrol-4)';
                        teaserTag = getStoryblokTranslation('general', 'stiftung');
                    } else if (article.content.country && article.content.country?.name) {
                        tagColors.backgroundColor = article.content.department?.content?.color_11;
                        tagColors.color = article.content.department?.content?.color_4;
                        textColor = article.content.department?.content?.color_1;
                        dateColor = article.content.department?.content?.color_4;
                        teaserTag = article.content.country?.name;
                    } else if (article.content.department && article.content.department?.name) {
                        tagColors.backgroundColor = article.content.department?.content?.color_11;
                        tagColors.color = article.content.department?.content?.color_4;
                        textColor = article.content.department?.content?.color_1;
                        dateColor = article.content.department?.content?.color_4;
                        teaserTag = article.content.department.translated_slugs?.find(
                            (slug) => slug.lang === currentLocale.value,
                        )?.name || article.content.department?.name;
                    }

                    // Return the mapped article with computed tag and tag colors
                    return {
                        article: {
                            title: article.content.title,
                            lead: article.content.lead,
                            cached_url: article.default_full_slug,
                            story: {
                                alternates: article.translated_slugs,
                            },
                            date: article.content.manualPublishedDate || article.first_published_at,
                            type: article.content.type.name,
                            tags: article.content.tags,
                            department: {
                                name: article.content.department.translated_slugs?.find(
                                    (slug) => slug.lang === currentLocale.value,
                                )?.name || article.content.department?.name,
                                color_1: article.content.department?.content?.color_1,
                                color_3: article.content.department?.content?.color_3,
                                color_4: article.content.department?.content?.color_4,
                                color_11: article.content.department?.content?.color_11,
                            },
                            teaserTag,
                            tagColors,
                            textColor,
                            dateColor,
                            isStiftung: article.content.isStiftung,
                            image: {
                                ...article.content.image_video,
                                provider: 'storyblok',
                            },
                            hasVideoTeaser: article.content.activate_video_teaser,
                            textVideoTeaser: article.content.teaser_video,
                        },
                    };
                }),
            ];

            if (allItems.value.length === parseInt(res.headers?.total, 10)) {
                showLoadMore.value = false;
            }
        },
    },
);

/*
    Error handling
*/
watch(getArticlesTeaserError, (error) => {
    if (error) {
        useSentryError(error, {
            starts_with: 'news/news-article',
            version: 'published',
            resolve_relations: 'News.department,News.type,News.tags',
            per_page: pageSize.value,
            page: page.value,
            filter_query: setFilter(),
            sort_by: 'first_published_at:desc',
            language,
        });
    }
});

const loadMore = () => {
    page.value += 1;
    getArticlesTeaser();
};
</script>

<style lang="scss" scoped>
.storyblok-teaser {
    @include grid-columns(4, 12);
    @include fluid('margin-bottom', 50px, 80px);

    @include tablet {
        @include grid-columns(1, 17);
    }
}
</style>
