<template>
  <div class="block-slider-hero">
    <div class="block-slider-hero__inner">
      <swiper
        v-if="items && items.length > 0"
        :direction="'horizontal'"
        :pagination="true"
        :navigation="{
          nextEl: '.block-slider-hero__controls--next',
          prevEl: '.block-slider-hero__controls--prev',
        }"
        :grab-cursor="true"
        :modules="modules"
        :loop="true"
        :resistance-ratio="0"
        :autoplay="{ delay: 5000 }"
        class="block-slider-hero__swiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in items"
          :key="index"
        >
          <AtomItemSliderHero :item="item" />
        </swiper-slide>
      </swiper>
      <div class="block-slider-hero__control block-slider-hero__controls--next">
        <div class="block-slider-hero__controls">
          <IonIcon
            icon-name="chevron-right"
            class="block-slider-hero__button-icon"
          />
        </div>
      </div>
      <div
        class="block-slider-hero__control block-slider-hero__controls--prev"
      >
        <div class="block-slider-hero__controls">
          <IonIcon
            icon-name="chevron-left"
            class="block-slider-hero__button-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
});

// import Swiper core and required modules
const {
    Navigation,
    Virtual,
    Pagination,
    Autoplay,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Navigation, Pagination, Autoplay];

// set backgroundcolor for bullets dependinf on active index
const bulletColor = ref(null);
watchEffect(() => {
    bulletColor.value = props.items[0]?.article?.department?.color_1;
});

const onSlideChange = (swiper) => {
    const { activeIndex } = swiper;
    bulletColor.value = props.items[activeIndex].article.department?.color_1;
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">
.block-slider-hero {
    position: relative;
    height: 100%;

    .swiper-pagination-bullets {
        position: absolute;
        bottom: 35px !important;
        height: 15px;

        .swiper-pagination-bullet {
            position: static;
            display: inline-block !important;
            width: 12px;
            height: 12px;
            margin: 0 10px !important;

            background: var(--c-background) !important;
            opacity: 1;
            transform: scale(1);

            @include mobile {
                width: 8px;
                height: 8px;
                margin: 0 6px !important;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background: v-bind(bulletColor) !important;
        }

        @include mobile {
            bottom: 25px !important;
        }
    }

}

.block-slider-hero__control {
    @include z-index('main');

    position: absolute;
    bottom: 50%;
    padding-top: 23px;
    padding-bottom: 23px;
    background: var(--c-background);
    color: v-bind(color1);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;
    transform: translateY(50%);

    &.block-slider-hero__controls--next {
        right: 0;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;

        .block-slider-hero__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 25px;
                margin-left: 25px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &.block-slider-hero__controls--prev {
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;

        .block-slider-hero__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 25px;
                margin-left: 25px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    @include tablet {
        display: none;
    }
}
</style>
