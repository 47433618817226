<template>
  <div class="block-detail-subsidies">
    <div class="block-detail-subsidies__title">
      {{ data.title }}
    </div>
    <div class="block-details-subsidies__wrapper">
      <div
        v-for="subsidie in subsidies"
        :key="subsidie._uid"
        class="block-detail-subsidies__subsidie"
      >
        <div class="block-detail-subsidies__subsidie-year">
          {{ subsidie[data.key_year] }}
        </div>
        <div class="block-detail-subsidies__subsidie-result">
          {{ subsidie[data.key_result] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const {
    data,
} = toRefs(props);

const calculatorStore = useCalculatorStore();
const calculatedData = computed(() => calculatorStore.getCalculatedData);
const subsidies = computed(() => calculatedData.value[data.value.key_object]);
const colorText = computed(() => calculatorStore.getColorSchemeText);
</script>

<style lang="scss" scoped>
.block-detail-subsidies {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    row-gap: 20px;

    @include mobile {
        row-gap: 3px;
    }
}

.block-detail-subsidies__title {
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-details-subsidies__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;

    @include mobile {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

.block-detail-subsidies__subsidie {
    display: flex;
    width: 160px;
    height: 87px;
    flex-direction: column;
    align-items: center;
    padding-top: 8.5px;
    background: var(--c-white);
    color: v-bind(colorText);

    @include mobile {
        width: 100%;
        height: fit-content;
        padding: 5px 0;
    }
}

.block-detail-subsidies__subsidie-year {
    margin-bottom: 6px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-detail-subsidies__subsidie-result {
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    @include mobile {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }
}
</style>
