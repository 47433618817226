<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-download-hub-mini">
        <div class="storyblok-download-hub-mini__title">
          {{ title }}
        </div>
        <div v-if="downloadFiles" class="storyblok-download-hub-mini__files">
          <AtomItemDownloadCardMini
            v-for="(file, index) in downloadFiles"
            :key="file._uid"
            v-bind="{ ...file?.content }"
            :date="file?.first_published_at"
            :file-data="file?.fileData"
          >
            <template #select>
              <AtomSelect
                v-if="file.content?.files"
                :options="convertLangDataOptions(file.content?.files)"
                :card-index="index"
                class="storyblok-download-hub-mini__select"
                :color-bg="file.content?.department?.content?.color_11"
                :color-text="file.content?.department?.content?.color_1"
                :color-icon="file.content?.department?.content?.color_4"
                :has-initial-emit="true"
                @set-data="getLangData"
              />
            </template>
          </AtomItemDownloadCardMini>
        </div>
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    files: {
        type: Array,
        default: () => [],
    },
});

const downloadFiles = ref(props.files);

const getLangData = (data) => {
    downloadFiles.value[data.index].fileData = {
        url: data.url,
        extention: getFileExtension(data.url),
    };

    return data;
};

const convertLangDataOptions = (data) => {
    const options = [];
    data.forEach((item) => {
        options.push({
            language: item.language,
            value: item.file?.filename,
        });
    });

    return options;
};

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const colorIcon = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const colorBackground = computed(() => `var(--c-${colorTopLevel.value}-13)`);
</script>

<style lang="scss">
.storyblok-download-hub-mini {
    @include default-content-columns;
    @include fluid('margin-bottom', 20px, 80px);

    display: flex;
    flex-direction: column;
    gap: 3px;

    .swiper {
        @include tablet {
            display: block;
            overflow: visible;
            width: calc(100% - var(--page-padding));
            margin-left: 0;
        }
    }
}

.storyblok-download-hub-mini__title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    color: v-bind(colorText);
    column-gap: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h3);
    line-height: var(--f-line-height--h3);

    @include tablet {
        padding-bottom: 10px;
    }
}

.storyblok-download-hub-mini__swiper {
    .swiper-wrapper {
        flex-direction: column;
    }

    @include tablet {
        display: block;
        overflow: hidden;
        width: calc(100% + var(--page-padding));
        margin-left: 0;
    }
}

.storyblok-download-hub-mini__file {
    display: flex;
    max-width: 451px;
    height: 401px !important;
    flex-direction: column;

    @include tablet {
        height: 387px;
    }
}

.storyblok-download-hub-mini__files {
    display: flex;
    flex-direction: column;
    background: var(--c-background);
    row-gap: 3px;
}

.storyblok-download-hub-mini__select {
    &.atom-input__wrapper  {
        width: fit-content;
    }
}

.storyblok-download-hub-mini__swiper-controlls {
    position: relative;
    height: 73px;
    margin-top: 3px;
    background: var(--c-white);

    .swiper-pagination-bullets {
        position: absolute;
        bottom: 28.5px !important;
        height: 15px;
        @include tablet {
            bottom: 18px !important;
        }

        .swiper-pagination-bullet {
            position: static;
            width: 12px;
            height: 12px;
            margin: 0 10px !important;
            background: v-bind(colorBackground);
            opacity: 1;
            transform: scale(1);

            @include tablet {
                width: 8px;
                height: 8px;
                margin: 0 6px !important;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background: v-bind(colorText);
        }

    }

    @include tablet {
        height: 46px;
    }

    @include mobile {
        max-width: 451px;
    }
}

.storyblok-download-hub-mini__control {
    @include z-index('header');

    position: absolute;
    height: 100%;
    padding-top: 21px;
    padding-bottom: 22px;
    background: var(--c-background);
    color: v-bind(colorText);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;
    pointer-events: all;

    @include tablet {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    @include mobile {
        max-width: 451px;
    }

    &.storyblok-download-hub-mini__controls--next {
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        padding-left: 28px;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;

        .storyblok-download-hub-mini__controls {
            cursor: pointer;
            gap: 10px;
        }

        &.is-hidden {
            display: none;
        }
    }

    &.storyblok-download-hub-mini__controls--prev {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 28px;
        padding-left: 10px;
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;

        .storyblok-download-hub-mini__controls {
            display: flex;
            align-items: center;

        }

        &.is-hidden {
            display: none;
        }
    }

}

.storyblok-download-hub-mini__button-icon {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;

    path {
        stroke: v-bind(colorIcon);
    }
}

.storyblok-download-hub-mini__controls-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
}

.storyblok-download-hub-mini__controls-counter {
    color: v-bind(colorText);
    font-size: 17px;

    vertical-align: middle;
}
</style>
