<template>
  <button
    class="block-next-steps-navigation-card-redirect"
    type="button"
    @click="redirectToSection()"
  >
    <div class="block-next-steps-navigation-card-redirect__text">
      {{ data.pretitle }}  <IonIcon icon-name="chevron-right" />
    </div>
  </button>
</template>

<script setup>

const props = defineProps({
    content: {
        type: Array,
        default: () => [],
    },
    data: {
        type: Object,
        default: () => {},
    },
    colorDark: {
        type: String,
        default: '',
    },
    colorLight: {
        type: String,
        default: '',
    },
    redirectTo: {
        type: String,
        default: '',
    },
});
const calculatorStore = useCalculatorStore();

const redirectToSection = () => {
    calculatorStore.setIsNextStepsSection(false);
    switch (props.redirectTo) {
    case 'calc':
        calculatorStore.setIsCalculationSection(true);
        calculatorStore.resetCaclData();

        break;
    case 'result':
        calculatorStore.setIsDetailsSection(true);
        break;
    default:
        calculatorStore.setIsCalculationSection(true);
        calculatorStore.resetCaclData();
    }
};
</script>
<style lang="scss">
.block-next-steps-navigation-card-redirect {
    padding: 20px 40px;
    background: v-bind(colorLight);
    color: v-bind(colorDark);
    cursor: pointer;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h3);
    grid-column-end: span 1;
    line-height: var(--l-height--h3);
    @include mobile {
        padding: 10px 20px;
        font-family: var(--f-family--bold);
        grid-column-end: span 2;
    }
}
.block-next-steps-navigation-card-redirect__text {
    display: flex;
    align-items: center;
    gap: 20px;
    .ion-icon {
        transition: all 0.3s ease;

        path {
            stroke: v-bind(colorDark);
        }
    }
    @include mobile {
        justify-content: space-between;
        gap: 20px;
    }
}
</style>
