<template>
  <div class="storyblok-image-gallery">
    <UtilWrapperLayout>
      <UtilGrid>
        <BlockImageGallery :preview-images="mappedPreviewImages" :items="mappedItems" />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    previewImages: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

// map previewimages data from storyblok to the format we need
const mappedPreviewImages = computed(() => props.previewImages.map((item) => ({
    ...item,
    provider: 'storyblok',
})));

// map items data from storyblok to the format we need
const mappedItems = computed(() => props.items.map((item) => ({
    ...item,
    image: {
        ...item.image,
        provider: 'storyblok',
    },
})));
</script>

<style lang="scss" scoped>
.storyblok-image-gallery {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 50px);
}

</style>
