<template>
  <div class="block-profile">
    <div class="block-profile__content">
      <AtomHeading
        tag="h3"
        font-size="h3"
        :text="title"
        class="block-profile__title"
      />
      <div v-for="item in items" :key="item._uid" class="block-profile__item">
        <div class="block-profile__item-title">
          {{ item.title }}
        </div>
        <AtomRichtext
          class="block-profile__item-text"
          :html="item.text"
        />
      </div>
    </div>
    <AtomImage
      v-if="image?.filename"
      class="block-profile__image"
      :image="image"
      :provider="image.provider"
    />
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.isArray && value.length > 0,
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
});

const colorTopLevel = inject('colorSchema');
const color7 = computed(() => `var(--c-${colorTopLevel.value}-7)`);
</script>

<style lang="scss" scoped>
.block-profile {
    @include grid-layout();

    position: relative;
    color: var(--c-white);

    @include mobile {
        height: auto;
        max-height: none;
    }
}

.block-profile__content {
    @include grid-columns(2, 5);

    padding: 40px;
    background: v-bind(color7);

    @include tablet {
        @include grid-columns(1, 6);
    }

    @include mobile {
        @include grid-columns(1, 18);
    }
}

.block-profile__title {
    margin-bottom: 29px;

    &.atom-heading {
        color: var(--c-white);
        font-family: var(--f-family--regular);
    }
}

.block-profile__item {
    margin-bottom: 29px;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:last-child {
        margin-bottom: 0;
    }
}

.block-profile__item-title {
    font-family: var(--f-family--regular);
}

.block-profile__item-text {
    font-family: var(--f-family--bold);
}

.block-profile__image {
    @include grid-columns(7, 9);
    max-height: 626px;

    @include tablet {
        @include grid-columns(7, 12);
    }

    @include mobile {
        @include grid-columns(1, 18);
    }
}
</style>
