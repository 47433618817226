<template>
  <div ref="mapRef" class="atom-map" />
</template>

<script setup>
const props = defineProps({
    /* Props for manual calling */
    latitude: {
        type: [Number, Boolean],
        default: false,
    },
    longitude: {
        type: [Number, Boolean],
        default: false,
    },
    countries: {
        type: Array,
        default: () => [],
    },
});

// props toRefs
const {
    latitude,
    longitude,
    countries,
} = toRefs(props);

const mapRef = ref(null);

const mapSettings = {
    latitude: latitude.value || 41.35920998695867,
    longitude: longitude.value || 8.550142744369095,
    zoom: 1.5,
    minZoom: 1,
    maxZoom: 2,
};

if (process.client) {
    setTimeout(() => {
        if (!mapRef.value) return;
        useMapBox(mapRef, mapSettings, countries.value);
    }, 300);
}

</script>

<style lang="scss" scoped>
.atom-map {
    width: 100%;
    height: 100%;

    &.is-pinch-active {
        @include mobile {
            pointer-events: none;
        }
    }
}
</style>
