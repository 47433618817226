<template>
  <div class="block-hero">
    <div class="block-hero__inner">
      <UtilWrapperLayout>
        <div class="block-hero__grid">
          <div class="block-hero__wrapper">
            <div class="block-hero__pretitle">
              <p>{{ pretitle }}</p>
            </div>
            <div class="block-hero__title">
              <AtomHeading
                v-if="titleDark"
                :text="titleDark"
                class="block-hero__title-dark"
                html-tag="h1"
                font-size="h1"
                :color-schema="colorSchema"
              />
              <AtomHeading
                v-if="titleWhite"
                :text="titleWhite"
                class="block-hero__title-white"
                html-tag="h2"
                font-size="h1"
              />
            </div>
          </div>
          <AtomLead
            v-if="lead"
            :text="addElipsis(lead)"
            class="block-hero__lead"
            color-schema="white"
          />
          <UtilRouteLink
            v-if="link.id && !showLinkAsButton"
            :link="link"
            class="block-hero__link"
          >
            <p v-if="linkLabel">
              {{ linkLabel }}
            </p>
            <IonIcon
              icon-name="chevron-right"
              class="block-header__icon"
            />
          </UtilRouteLink>
          <UtilRouteLink
            v-if="link.id && showLinkAsButton"
            :link="link"
            class="block-hero__link"
          >
            <AtomButton :text="linkLabel" variant="inverted" />
          </UtilRouteLink>
        </div>
      </UtilWrapperLayout>
    </div>
    <AtomImage
      v-if="image?.filename"
      class="block-hero__image"
      :image="image"
      :lazy="false"
      :provider="provider"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    pretitle: {
        type: String,
        default: '',
    },

    titleDark: {
        type: String,
        default: '',
    },

    titleWhite: {
        type: String,
        default: '',
    },

    lead: {
        type: String,
        default: '',
    },

    linkLabel: {
        type: String,
        default: '',
    },

    link: {
        type: [Object, String],
        default: () => {},
    },

    showLinkAsButton: {
        type: Boolean,
        default: false,
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    provider: {
        type: String,
        default: 'ipx',
        validator: (value) => value.length > 0,
    },

    colorSchema: {
        type: String,
        default: 'petrol',
        validator: (value) => ['', 'petrol', 'red', 'blue', 'orange', 'green', 'coral', 'mustard'].includes(value),
    },
});

/*
  Add elipsis to lead text
*/
const clientDeviceStore = useClientDeviceStore();
const addElipsis = (text) => {
    if (props.lead.length > 220 && clientDeviceStore.isMobile) {
        return `${text.slice(0, 220)}...`;
    }

    return text;
};

const colorTopLevel = inject('colorSchema');

const colorPretitle = computed(() => (
    colorTopLevel.value === 'petrol'
        ? `var(--c-${props.colorSchema}-1)`
        : `var(--c-${colorTopLevel.value}-1)`
));

const colorTitleDark = computed(() => (
    colorTopLevel.value === 'petrol'
        ? `var(--c-${props.colorSchema}-2)`
        : `var(--c-${colorTopLevel.value}-2)`
));

const colorBackground = computed(() => (
    colorTopLevel.value === 'petrol'
        ? `var(--c-${props.colorSchema}-6)`
        : `var(--c-${colorTopLevel.value}-6)`
));
</script>

<style lang="scss">
.block-hero {
    position: relative;
    height: 100%;
    background: v-bind(colorBackground);
}

.block-hero__grid {
    @include grid-layout();
}

.block-hero__pretitle {
    margin-bottom: 10px;
    color: v-bind(colorPretitle);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h5);
    line-height: var(--l-height--h5);

    @include mobile {
        margin-bottom: 0;
    }
}

.block-hero__title {
    margin-bottom: 20px;
    hyphens: auto;

    .block-hero__title-dark {
        color: v-bind(colorTitleDark);
        font-family: var(--f-family--black);
    }

    .block-hero__title-white {
        color: var(--c-white);
        font-family: var(--f-family--black);
    }
}

.block-hero__inner {
    @include fluid('padding-top', 50px, 70px);
    @include fluid('padding-bottom', 52px, 66px);
    display: flex;
}

.block-hero__wrapper {
    @include grid-columns(3,10);

    @include tablet {
        @include grid-columns(1,12);
    }
}

.block-hero__lead {
    @include grid-columns(3,9);

    margin-bottom: 20px;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include tablet {
        @include grid-columns(1,12);
    }
}

.block-hero__link {
    @include grid-columns(3,9);

    display: flex;
    align-items: center;
    color: var(--c-white);
    column-gap: 10px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: transform 0.2s ease-out;
    }

    .atom-button {
        margin-top: 20px;

        @include mobile {
            margin-top: 0;
        }
    }

    &:hover {
        .ion-icon {
            transform: translateX(10px);
        }
    }

    @include tablet {
        @include grid-columns(1,12);
    }
}

.block-hero__image {
    @include fluid('width', -100px, 480px);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    .is-rtl-section  &{
        right: auto;
        left: 0;
    }

    @include tablet{
        display: none;
    }
}
</style>
