<template>
  <div class="block-hero-links">
    <div class="block-hero-links__inner">
      <UtilWrapperLayout>
        <div class="block-hero-links__grid">
          <div class="block-hero-links__wrapper">
            <div class="block-hero-links__title">
              <AtomHeading
                v-if="title"
                :text="title"
                html-tag="h1"
                font-size="h1"
                color-schema="white"
              />
            </div>
          </div>
          <AtomLead
            v-if="text"
            :text="text"
            class="block-hero-links__lead"
            color-schema="white"
          />
        </div>
      </UtilWrapperLayout>
    </div>
    <Transition name="fade-in">
      <div
        v-if="hoverIndex !== null"
        class="block-hero-links__background"
        :style="`background: linear-gradient(270deg, var(--c-${links[hoverIndex].colorBackground}) 0%, rgba(107, 145, 156, 0) 100%);`"
      >
        <div class="block-hero-links__background-image">
          <AtomImage
            v-if="isArrayNotEmpty(links) && links[hoverIndex]"
            :image="links[hoverIndex].image"
            provider="storyblok"
            :lazy="false"
          />
        </div>
      </div>
    </Transition>
    <div
      class="block-hero-links__links"
      @mouseleave="hoverIndex = null"
      @focusout="hoverIndex = null"
    >
      <UtilRouteLink
        v-for="link, index in links"
        :key="link._uid"
        :link="link.link"
        class="block-hero-links__link"
        :class="{
          'is-active': hoverIndex === index,
          'is-inactive': hoverIndex !== null && hoverIndex !== index,
        }"
        @mouseover="hoverIndex = index"
        @focusin="hoverIndex = index"
      >
        <span>{{ link.linkLabel }}</span>
        <IonIcon icon-name="chevron-right" />
      </UtilRouteLink>
    </div>
    <div
      class="block-hero-links__links-swiper-wrapper"
      :style="isMobile
        ? `background: linear-gradient(0deg, var(--c-${links[indexRef].colorBackground}) 0%, rgba(107, 145, 156, 0) 100%);`
        : `background: linear-gradient(270deg, var(--c-${links[indexRef].colorBackground}) 0%, rgba(107, 145, 156, 0) 100%);`"
    >
      <swiper
        :direction="'horizontal'"
        :pagination="true"
        :grab-cursor="true"
        :modules="modules"
        :effect="'fade'"
        :fade-effect="{ crossFade: true }"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :loop="true"
        :resistance-ratio="0"
        class="block-hero-links__swiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(link) in links"
          :key="link._uid"
          class="block-hero-links__swiper-slide"
        >
          <UtilRouteLink
            :link="link.link"
            class="block-hero-links__link"
          >
            <span>{{ link.linkLabel }}</span>
            <IonIcon icon-name="chevron-right" />
          </UtilRouteLink>
          <div class="block-hero-links__background-image">
            <AtomImage
              v-if="link.image"
              :image="link.image"
              provider="storyblok"
              :lazy="false"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    links: {
        type: Array,
        default: () => [],
    },
});

const {
    Virtual,
    Pagination,
    Autoplay,
    EffectFade,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Pagination, Autoplay, EffectFade];

const hoverIndex = ref(null);
const indexRef = ref(0);
const { isMobile } = storeToRefs(useClientDeviceStore());

const onSlideChange = (swiper) => {
    const { realIndex } = swiper;
    indexRef.value = realIndex;
};
</script>

<style lang="scss" scoped>
.block-hero-links {
    position: relative;
    height: 100%;
    background: var(--c-petrol-2);
}

.block-hero-links__grid {
    @include grid-layout();
}

.block-hero-links__title {
    margin-bottom: 20px;
    color: var(--c-white);
    hyphens: auto;

    .atom-heading {
        font-family: var(--f-family--black);
    }

    @include mobile {
        margin-bottom: 10px;
    }
}

.block-hero-links__inner {
    display: flex;
    padding-top: 145px;
    padding-bottom: 145px;

    @include tablet {
        padding-top: 122px;
        padding-bottom: 122px;
    }

    @include mobile {
        padding-top: 50px;
        padding-bottom: 30px;
    }
}

.block-hero-links__wrapper {
    @include grid-columns(3,10);

    @include tablet {
        @include grid-columns(1,12);
    }
}

.block-hero-links__lead {
    @include grid-columns(3,9);

    z-index: 1;
    margin-bottom: 20px;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include tablet {
        @include grid-columns(1,11);
    }
}

.block-hero-links__link {
    display: flex;
    align-items: center;
    color: var(--c-white);
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
    transition: color 0.2s ease-out;

    &.is-inactive {
        color: var(--c-petrol-1);

        ::v-deep(.ion-icon) {
            path {
                stroke: var(--c-petrol-1);
                transition: stroke 0.2s ease-out;
            }
        }
    }

    .ion-icon {
        transition: transform 0.2s ease-out;
    }

    &:hover {
        .ion-icon {
            transform: translateX(10px);
        }
    }

    @include tablet {
        @include grid-columns(1,12);

        margin-top: 69px;
    }

    @include mobile {
        margin-top: 0;
        margin-left: var(--page-padding);
    }
}

.block-hero-links__links {
    @include fluid('padding-right', 50px, 153px);
    @include fluid('padding-top', 50px, 80px);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 20px;

    @include tablet {
        display: none;
    }
}

.block-hero-links__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 60%;
    justify-content: flex-end;

    .atom-image__wrapper {
        width: fit-content;
        height: 50%;
    }

    @include tablet {
        display: none;
    }
}

.block-hero-links__background-image {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;

    ::v-deep(.atom-image__wrapper) {
        .atom-image__background {
            justify-content: flex-end;
        }

        img {
            width: fit-content;
            object-fit: contain;
        }

        @include tablet {
            height: 100%;
        }
    }

    @include tablet {
        height: 50%;
    }

    @include mobile {
        width: 30%;
        height: 117px;
        margin-top: 20px;
    }
}

.block-hero-links__links-swiper-wrapper {
    display: none;

    ::v-deep(.swiper-pagination-bullet) {
        width: 12px;
        height: 12px;
        margin: 0 10px;
        background: var(--c-background);
        opacity: 1;

        &.swiper-pagination-bullet-active {
            background: var(--c-petrol-1);
        }

        @include mobile {
            width: 6px;
            height: 6px;
            margin: 0 5px;
        }
    }

    @include tablet {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        width: 70%;
        align-items: flex-end;
        justify-content: flex-end;
    }

    @include mobile {
        position: relative;
        width: 100%;
    }
}

.block-hero-links__swiper {
    width: 50%;
    height: 100%;
    margin: 0;

    @include mobile {
        width: 100%;
    }
}

.block-hero-links__swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
        height: 100%;
        flex-direction: row;
        align-items: flex-start;
    }
}

</style>
