<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-filter is-narrow">
        <BlockFilter
          v-if="getFiltersStatus === 'success' && isArrayNotEmpty(filters)"
          :title="title"
          :dropdowns="filters"
          :color="color"
        />
        <BlockSearch />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    dropdowns: {
        type: Array,
        required: true,
    },

    color: {
        type: String,
        default: '',
    },
});

const {
    dropdowns,
    color,
} = toRefs(props);

const storyblokApi = useStoryblokApi();
const { dayjs } = useDayJs();
const now = dayjs().format('YYYY');

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const {
    data: filters,
    status: getFiltersStatus,
    error: getFiltersError,
} = useLazyAsyncData('get-filter-data', async () => {
    const res = await Promise.all(dropdowns.value.map((dropdown) => {
        if (dropdown.options === 'date') {
            return storyblokApi.get('cdn/stories/', {
                starts_with: 'news/news-article/',
                version: 'published',
                per_page: 1,
                sort_by: 'first_published_at:asc',
                language,
            });
        }

        return storyblokApi.get('cdn/stories/', {
            starts_with: `data-sources/${dropdown.options}`,
            version: 'published',
            language,
        });
    }));

    return res;
}, {
    transform: (res) => res.map((r, i) => {
        if (isArrayNotEmpty(dropdowns.value[i].optionsManual)) {
            return {
                label: dropdowns.value[i].label,
                name: dropdowns.value[i].options,
                uid: dropdowns.value[i]._uid,
                options: dropdowns.value[i].optionsManual.map((option) => ({
                    name: currentLocale === defaultLocale
                        ? option.name : option.translated_slugs?.find(
                            (slug) => slug.lang === currentLocale.value,
                        )?.name || option.name,
                    value: option.uuid,
                    type: dropdowns.value[i].options,
                })),
            };
        }

        if (dropdowns.value[i].options === 'date') {
            const firstNewsArticle = r.data.stories[0];
            const mappedYears = Array.from(
                { length: now - dayjs(firstNewsArticle.first_published_at).format('YYYY') + 1 },
                (_, y) => ({
                    name: now - y,
                    value: now - y,
                }),
            );

            return {
                label: dropdowns.value[i].label,
                name: dropdowns.value[i].options,
                uid: dropdowns.value[i]._uid,
                options: mappedYears.map((year) => ({
                    name: year.name,
                    value: year.value,
                    type: dropdowns.value[i].options,
                })),
            };
        }

        if (isArrayNotEmpty(dropdowns.value) && dropdowns.value[i]?.options === 'thema') {
            const themas = [
                {
                    name: getStoryblokTranslation('general', 'stiftung'),
                    value: 'stiftung',
                },
                {
                    name: getStoryblokTranslation('general', 'switzerland'),
                    value: 'schweiz',
                },
                {
                    name: getStoryblokTranslation('general', 'international'),
                    value: 'international',
                },
            ];

            return {
                label: dropdowns.value[i].label,
                name: dropdowns.value[i].options,
                uid: dropdowns.value[i]._uid,
                options: themas.map((thema) => ({
                    name: thema.name,
                    value: thema.value,
                    type: dropdowns.value[i].options,
                })),
            };
        }

        return {
            label: dropdowns.value[i].label,
            name: dropdowns.value[i].options,
            uid: dropdowns.value[i]._uid,
            options: r.data.stories.map((story) => ({
                name: story.translated_slugs?.find(
                    (slug) => slug.lang === currentLocale.value,
                )?.name || story.name,
                value: story.uuid,
                type: dropdowns.value[i].options,
            })),
        };
    }),
});

/*
    Error handling
*/
watch(getFiltersError, (error) => {
    if (error) {
        useSentryError(error);
    }
});

if (color.value !== '') {
    provide('colorSchema', color);
}
</script>

<style lang="scss">
.storyblok-filter {
    @include default-content-columns;

    margin-bottom: 5px;
}
</style>
