<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div v-if="options.length > 1" ref="wrapperRef" class="atom-select__wrapper">
      <div
        class="atom-select"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <p class="atom-select__label">
          {{ selectedOptionName }}
        </p>

        <IonIcon
          class="atom-select__icon has-pointer is-right icon-chevron"
          icon-name="chevron-down"
          :color="colorIcon"
        />
      </div>

      <div
        class="atom-select__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-select__options"
        >
          <div
            v-for="option in options"
            :key="`${option.value}`"
            class="atom-select__option"
            :class="{ 'is-selected': selectedOption === option.value }"
          >
            <button
              type="button"
              class="atom-select__option-button"
              @click="singleselectOption(option)"
            >
              <span class="atom-select__option-background" />
              <span class="atom-select__option-label">{{ option.language }}</span>
              <IonIcon
                class="atom-select__icon-close has-pointer is-right"
                icon-name="close"
                :color="colorIcon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="options.length === 1" class="atom-select__single-tag">
      {{ selectedOptionName }}
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a singleselect input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.language - The language of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the singleselect field.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
        General data
    */
    cardIndex: {
        type: Number,
        default: 0,
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    colorBg: {
        type: String,
        default: '',
    },

    colorText: {
        type: String,
        default: '',
    },

    colorIcon: {
        type: String,
        default: '',
    },

    /*
        Hotfix for initial value in StoryblokDownloadHubMini.vue
        where the initial value is not emitted
    */
    hasInitialEmit: {
        type: Boolean,
        default: false,
    },
});

/*
    Intialze formfield
*/
const wrapperRef = ref(null);

/*
    Handle input
*/
const emit = defineEmits(['set-data']);

/*
    Custom Logic
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);
const selectedOption = ref('');
const selectedOptionName = ref('');

const handleData = (type) => {
    emit('set-data', {
        index: props.cardIndex, url: selectedOption.value, type, uuids: selectedOption.value,
    });
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

const singleselectOption = (option) => {
    selectedOption.value = option.value;
    selectedOptionName.value = option.language;
    toggleOptions(true);
    handleData(option.type);
};

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value?.scrollHeight}px` : closedHeight;
    }
});

/*
    Function to set initial option by language
*/
const { locale } = useI18n();
const setInitialOption = () => {
    const initialOption = props.options.find(
        (option) => option.language === locale.value.toUpperCase(),
    );
    if (initialOption) {
        selectedOption.value = initialOption.value;
        selectedOptionName.value = initialOption.language;
    } else {
        selectedOption.value = props.options[0]?.value;
        selectedOptionName.value = props.options[0]?.language;
    }
};

onMounted(() => {
    setInitialOption();

    /*
        Hotfix for initial value in StoryblokDownloadHubMini.vue
        where the initial value is not emitted
    */
    if (props.hasInitialEmit) {
        handleData();
    }
});

const colorTopLevel = inject('colorSchema');
const colorBg = computed(() => (props.colorBg ? props.colorBg : `var(--c-${colorTopLevel.value}-11)`));
const colorText = computed(() => (props.colorText ? props.colorText : `var(--c-${colorTopLevel.value}-6)`));
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);

</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    user-select: none;
}
.atom-select__wrapper {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .storyblok-projects-block__filter & {
        width: 100%;
    }
}

.atom-select {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    height: 30px;
    align-items: flex-end;
    justify-content: center;
    background: v-bind(colorBg);

    @include mobile {
        width: 100%;
    }
}

.atom-select__label {
    @include formFieldLabelText;

    display: flex;
    align-items: flex-end;
    padding-top: 2px;
    padding-right: 38px;
    padding-left: 10px;
    margin-bottom: 0;

    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    pointer-events: none;

    .block-download-hub__select & {
        color: v-bind(colorText);
    }

    .storyblok-download-hub-mini__select & {
        color: v-bind(colorText);
    }
}

.atom-select__input {
    @include formFieldMultiselect;

    pointer-events: none;
}

.atom-select__icon {
    @include formFieldInputIcon(false);

    top: 5px;
    right: 10px !important;
    width: 22px;
    height: 22px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;
    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-select__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-select__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;

    &.is-visible {
        display: block;
    }
}

.atom-select__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-select__option {

    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-select__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-select__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-select__option-button {
    display: flex;
    width: 100%;
    min-height: 30px;
    align-items: center;
    justify-content: flex-end;
}

.atom-select__selected-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.atom-select__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBg);
    transition: background-color 0.2s ease-in-out;
}

.atom-select__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBg);
    transition: background-color 0.2s ease-in-out;
}

.atom-select__option-label {
    @include z-index('selectLabel');

    position: relative;
    margin: 6px 38px 6px 10px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--f-line-height--tag);

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-select__selected-option-label {
    @include z-index('selectLabel');
    position: relative;
    width: 66px;
    padding: 6px 36px 6px 10px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--f-line-height--tag);

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-select__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 8px;
    right: 10px !important;
    width: 14px;
    height: 14px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        opacity: 1;
    }

}

.atom-select__selected {
    .atom-select__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}

.atom-select__single-tag {
    @include z-index('selectLabel');

    position: relative;
    padding: 6px 10px;
    background: v-bind(colorBg);
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: 1.5;
}

.icon-chevron {
    justify-content: center;
}
</style>
