import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export const gsapTimeline = async () => gsap.timeline();

export const gsapTo = (element, options) => gsap.to(element.value || element, options);

export const gsapScrollTo = (element, options) => {
    gsap.to(element.value || element, options);
};

export const gsapSet = (element, options) => gsap.set(element.value || element, options);

export const gsapFrom = (element, options) => gsap.from(element.value || element, options);

export const gsapFromTo = (elements, from, options) => {
    gsap.fromTo(elements._rawValue || elements, from, options);
};
