<template>
  <div class="atom-item-slider-enumeration">
    <div v-if="item.pretitle" class="atom-item-slider-enumeration-pretitle">
      {{ item.pretitle }}
    </div>
    <AtomHeading v-if="item.title" class="atom-item-slider-enumeration-title" html-tag="h3" font-size="h3" :text="item.title" />
    <AtomRichtext v-if="item.text" class="atom-item-slider-enumeration-text" :html="item.text" />
  </div>
</template>

<script setup>
defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.atom-item-slider-enumeration {
    @include fluid('padding-top', 20px, 94px);
    @include fluid('padding-bottom', 20px, 94px);
    @include fluid('padding-right', 20px, 130px);
    @include fluid('padding-left', 20px, 130px);

    color: v-bind(colorText);
}

.atom-item-slider-enumeration-pretitle {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.atom-item-slider-enumeration-title {
    @include fluid('margin-bottom', 20px,30px);
}
</style>
