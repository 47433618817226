<template>
  <div class="block-calculation-details">
    <component
      :is="availableComponents[item.component]"
      v-for="item in data.content"
      :key="item._uid"
      :data="item"
      :html="storyblokApi.richTextResolver.render(item.text)"
      :color-scheme="colorScheme"
      :label="item.label"
      :link="item.link"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    colorScheme: {
        type: String,
        default: '',
    },
});

const {
    data,
} = toRefs(props);

const availableComponents = {
    BlockItemDetailSubsidies: resolveComponent('BlockDetailSubsidies'),
    BlockItemDetailNumbers: resolveComponent('BlockDetailNumbers'),
    StoryblokTextRichtext: resolveComponent('AtomRichtext'),
    StoryblokLink: resolveComponent('StoryblokLink'),
};

const calculatorStore = useCalculatorStore();
calculatorStore.setHeaderInfo(data.value.info);
const colorLink = computed(() => calculatorStore.getColorScheme);
const colorHover = `var(--c-${colorLink.value}-4)`;

// watch data for changes
watch(data, () => {
    calculatorStore.setHeaderPretitle(data.value.pretitle);
    calculatorStore.setHeaderTitle(data.value.title);
    calculatorStore.setHeaderInfo(data.value.info);
});

const storyblokApi = useStoryblokApi();
</script>

<style lang="scss">
.block-calculation-details {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 60px 100px 0 100px;
    row-gap: 3px;

    .storyblok-link__wrapper {
        transition: color 0.2s ease-in-out;

        path {
            stroke: var(--c-white);
        }

        &:hover {
            color: v-bind(colorHover);

            path {
                stroke: v-bind(colorHover);
            }
        }
    }

    @include mobile {
        padding: 20px;
    }
}
</style>
