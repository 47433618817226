<template>
  <div class="atom-item-slider-hero">
    <UtilWrapperLayout>
      <div class="atom-item-slider-hero__grid">
        <div class="atom-item-slider-hero__wrapper">
          <div class="atom-item-slider-hero__pretitle">
            <span>{{ dayjs(item.article.date).locale(locale).format('DD. MMMM YYYY') }}</span>
            |
            <span>{{ item.article.department?.name }}</span>
          </div>
          <div class="atom-item-slider-hero__title">
            <AtomHeading
              :text="item.title_dark"
              class="atom-item-slider-hero__title-dark"
              html-tag="h1"
              font-size="h1"
            />
            <AtomHeading
              :text="item.title_white"
              class="atom-item-slider-hero__title-white"
              html-tag="h2"
              font-size="h1"
            />
          </div>
        </div>
        <div class="atom-item-slider-hero__lead">
          <span class="atom-item-slider-hero__lead-type">{{ item.article.type }} | </span>
          <span>{{ item.lead }}</span>
        </div>

        <UtilRouteLink
          v-if="item.article?.cached_url"
          :link="item.article"
          class="atom-item-slider-hero__link"
        >
          <IonIcon
            icon-name="chevron-right"
            class="atom-item-slider-hero__icon"
          />
        </UtilRouteLink>
      </div>
    </UtilWrapperLayout>

    <AtomImage
      v-if="item.image?.filename || item.article?.image?.filename"
      class="atom-item-slider-hero__image"
      :lazy="false"
      :image="item.image?.filename ? item.image : item.article.image"
      :provider="item.article.image.provider"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

const colorText = computed(() => props.item.article.department?.color_1);
const colorBackground = computed(() => props.item.article.department?.color_8);

const { dayjs } = useDayJs();
const { locale } = useI18n();
</script>

<style lang="scss" scoped>
.atom-item-slider-hero {
    @include fluid('padding-top', 50px, 70px);
    @include fluid('padding-bottom', 52px, 66px);

    position: relative;
    height: 520px;
    background: v-bind(colorBackground);

    @include mobile {
        height: 387px;
    }
}

.atom-item-slider-hero__grid {
    @include grid-layout();
}

.atom-item-slider-hero__pretitle {
    display: flex;
    color: v-bind(colorText);
    column-gap: 7px;
    font-family: var(--f-family--bold);

    @include tablet {
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--tichtext);
    }
}

.atom-item-slider-hero__title {
    margin-bottom: 30px;
    color: v-bind(colorText);
    hyphens: auto;

    .atom-item-slider-hero__title-dark {
        color: v-bind(colorTitleDark);
        font-family: var(--f-family--black);

        @include mobile {
            font-family: var(--f-family--bold);
        }
    }

    .atom-item-slider-hero__title-white {
        color: var(--c-white);
        font-family: var(--f-family--black);

        @include mobile {
            font-family: var(--f-family--bold);
        }
    }

}

.atom-item-slider-hero__wrapper {
    @include grid-columns(3,10);

    @include tablet {
        @include grid-columns(1,12);
    }
}

.atom-item-slider-hero__lead {
    @include grid-columns(3,9);

    margin-bottom: 30px;
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include tablet {
        display: none;
    }
}

.atom-item-slider-hero__lead-type {
    font-family: var(--f-family--bold);
}

.atom-item-slider-hero__link {
    @include grid-columns(3,9);
    @include z-index('main');

    display: flex;
    align-items: center;
    color: var(--c-white);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: transform 0.2s ease-out;
    }

    &:hover {
        .ion-icon {
            transform: translateX(10px);
        }
    }

    @include tablet {
        @include grid-columns(1,12);
    }
}

.atom-item-slider-hero__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 28vw;
    height: 100%;

    @include tablet{
        display: none;
    }
}
</style>
