<template>
  <div class="storyblok-teaser-sublinks">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-teaser-sublinks__wrapper is-wide">
          <component
            :is="useComponent(item.component)"
            v-for="item in mappedItems"
            :key="item._uid"
            v-bind="item"
            v-editable="item"
            :color="color"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },

    color: {
        type: String,
        default: 'petrol',
    },
});

const { items } = toRefs(props);
const mappedItems = computed(() => items?.value?.map((item) => {
    const { _uid, ...rest } = item;
    return rest;
}) || []);

const availableComponents = {
    BlockItemSublinksCard: resolveComponent('BlockItemSublinksCard'),
    BlockItemSublinksLinksCard: resolveComponent('BlockItemSublinksLinksCardScalable'),
    BlockItemSublinksLinksCardScalable: resolveComponent('BlockItemSublinksLinksCardScalable'),
};

const useComponent = (key) => availableComponents[key] || null;
</script>

<style lang="scss" scoped>
.storyblok-teaser-sublinks__wrapper {
    @include default-content-columns;
    @include grid-layout(6);
    @include fluid('margin-bottom', 50px, 80px);

    @include mobile {
        row-gap: var(--grid-row--gap);
    }

}
</style>
