<template>
  <div class="block-calculation-items">
    <div v-if="data.title" class="block-calculation-items__title">
      {{ data.title }}
      <div
        v-if="data.info?.content[0]?.content?.length > 0"
        class="block-calculation-item__info"
        @click="showInfo"
        @keypress="showInfo"
      >
        <IonIcon icon-name="info" />
        <Transition name="fade-in">
          <div
            v-if="infoOpen"
            class="block-calculation-item__info-box"
          >
            <AtomRichtext :html="richTextResolver.render(data.info)" />
          </div>
        </Transition>
      </div>
      <div
        v-if="data.hint?.content[0]?.content?.length > 0"
        class="block-calculation-item__info"
        @click="showInfo"
        @keypress="showInfo"
      >
        <IonIcon icon-name="hint" />
        <Transition name="fade-in">
          <div
            v-if="infoOpen"
            class="block-calculation-item__hint-box"
          >
            <AtomRichtext :html="richTextResolver.render(data.hint)" />
          </div>
        </Transition>
      </div>
    </div>
    <div class="block-calculation-items__grid">
      <component
        :is="availableComponents[item.component]"
        v-for="item in data.content"
        :key="item._uid"
        :data="item"
        :label="item.label"
        :name="item.name"
        :is-required-val="item.isRequired"
        :title="item.title"
        :options="item.options"
        :years="item.years"
        :parent-object="item.parent_object"
        :execution-type="item.type"
        :info="item.info"
        :hint="item.hint"
        :category="item.category"
        :send-if-zero="item.sendIfZero"
        @set-input="setInput"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const { richTextResolver } = useStoryblokApi();
const calculatorStore = useCalculatorStore();
const infoOpen = ref(false);

const availableComponents = {
    StoryblokInputText: resolveComponent('AtomInputNumberCalc'),
    StoryblokInputCheckbox: resolveComponent('AtomInputCheckboxCalc'),
    StoryblokInputSingleselect: resolveComponent('AtomSingleselectCalc'),
    StoryblokInputMultiselect: resolveComponent('AtomMultiselectCalc'),
    StoryblokInputRadioCalc: resolveComponent('AtomInputRadioCalc'),
    BlockItemCalculationYear: resolveComponent('AtomInputYearsCalc'),
};

const setInput = (data) => {
    calculatorStore.setRequestBody(data);
};

const showInfo = () => {
    infoOpen.value = !infoOpen.value;
};

const colorScheme = computed(() => calculatorStore.getColorScheme);
const colorText = `var(--c-${colorScheme.value}-1)`;
const colorBackgroundHint = `var(--c-${colorScheme.value}-2)`;
const colorBackgroundInfo = `var(--c-${colorScheme.value}-11)`;
</script>

<style lang="scss" scoped>
.block-calculation-items {
    display: flex;
    flex-direction: column;

}

.block-calculation-items__grid {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
}

.block-calculation-items__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    column-gap: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
}

.block-calculation-item__info {
    position: relative;
    cursor: pointer;
}

.block-calculation-item__info-box {
    @include z-index('header');

    position: absolute;
    top: 50px;
    left: -46px;
    width: 589px;
    padding: 20px;
    background: v-bind(colorBackgroundInfo);
    color: v-bind(colorText);

    &:before {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: 26px;
        display: block;
        width: 58px;
        height: 64px;
        background: v-bind(colorBackgroundInfo);
        content: '';
        transform: rotate(45deg);
    }
}

.block-calculation-item__hint-box {
    @include z-index('header');

    position: absolute;
    top: 50px;
    left: -46px;
    width: 589px;
    padding: 20px;
    background: v-bind(colorBackgroundHint);
    color: var(--c-white);

    &:before {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: 26px;
        display: block;
        width: 58px;
        height: 64px;
        background: v-bind(colorBackgroundHint);
        content: '';
        transform: rotate(45deg);
    }
}
</style>
