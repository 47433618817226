<template>
  <div class="block-project">
    <div class="block-project__content">
      <div v-for="item in items" :key="item._uid" class="block-project__item">
        <div v-if="item.title" class="block-project__item-title">
          {{ item.title }}
        </div>
        <div v-if="typeof item.text === 'string'" class="block-project__item-text">
          {{ item.text }}
        </div>
        <AtomRichtext
          v-else
          class="block-project__item-text"
          :html="richTextResolver.render(item.text)"
        />
      </div>
    </div>
    <div class="block-project__image-section">
      <div v-if="image?.length === 1" class="block-project__single-image">
        <AtomImage
          :image="image[0].img"
          class="block-project__image"
          :provider="imageProvider"
          :lazy="false"
          width="830px"
          height="530px"
        />
        <div class="block-project__image-title">
          <span> {{ image[0].title }}</span>
        </div>
      </div>
      <swiper
        v-if="image && image.length > 1"
        :direction="'horizontal'"
        :pagination="true"
        :grab-cursor="true"
        :modules="modules"
        :resistance-ratio="0"
        class="block-project__image__image-section-swiper"
      >
        <swiper-slide
          v-for="(item, index) in image"
          :key="index"
        >
          <AtomImage
            v-if="item.img?.filename"
            :image="item.img"
            :provider="imageProvider"
            class="block-project__image"
            :lazy="false"
            width="830px"
            height="530px"
          />
          <div v-if="item.title" class="block-project__image-title">
            <span> {{ item.title }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
// import Swiper core and required modules
const {
    Virtual,
    Pagination,
    Swiper,
    SwiperSlide,
} = useSwiperjs();
const modules = [Virtual, Pagination];
const { richTextResolver } = useStoryblokApi();
defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
    },

    image: {
        type: Array,
        default: () => [],
    },

    imageProvider: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss">
.block-project {
    position: relative;
    display: flex;
    gap: 39px;
    @include grid-layout(11);

    @include tablet {
        gap: 0px;
    }
}

.block-project__content {
    align-self: flex-start;
    padding: 40px;
    background:  var(--c-white);
    grid-column-end: span 4;
    @include tablet {
        padding: 30px;
        grid-column-end: span 11;
    }
}

.block-project__title {
    margin-bottom: 20px;
    word-break: break-word;
    &.atom-heading {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
    }
}

.block-project__item {
    margin-bottom: 30px;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:last-child {
        margin-bottom: 0;
    }
}

.block-project__item-title {
    font-family: var(--f-family--thin);
}

.block-project__item-text {
    font-family: var(--f-family--bold);
    a {
        color: v-bind(color4);
        font-family: var(--f-family--thin);
    }
}
.block-project__single-image {
    position: relative;
    width: 100%;
    height: 100%;
}
.block-project__image-section {
    grid-column-end: span 7;
    @include tablet {
        grid-column-end: span 11;
    }
}
.block-project__image {
    height: 100%;
}
.block-project__image__image-section-swiper {
    width: 100%;
    height: 100%;

    .swiper {
        width: 100%;
        height: 100%;
    }
    .swiper-pagination-bullets {
        position: absolute;
        bottom: 43.6px !important;
        height: 15px;

        .swiper-pagination-bullet {
            position: static;
            left: -20px !important;
            width: 13px;
            height: 13px;
            margin: 0 11px !important;
            background: var(--c-white);
            opacity: 1;
            transform: scale(1);

            @include tablet {
                width: 4px;
                height: 4px;
                margin: 0 4px !important;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background: v-bind(color1);
        }

        @include tablet {
            bottom: 10px !important;
        }
    }
}
.block-project__image-title {
    @include z-index('main');

    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 40px 40px 96px 40px;
    color: var(--c-white);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:after {
        @include z-index('minus');

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: v-bind(color4);
        content: '';
        opacity: 0.75;
    }

    @include tablet {
        padding: 10px 20px 20px 20px;
        line-height: 22px;
    }
}
</style>
