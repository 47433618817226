<template>
  <div class="storyblok-teaser-news-regional">
    <BlockTeaserNews
      v-bind="props"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    subtitle: {
        type: String,
        default: '',
    },

    departments: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },

    ctaTitle: {
        type: String,
        default: '',
    },

    ctaLink: {
        type: Object,
        default: () => {},
    },
});
</script>

<style lang="scss">
.storyblok-teaser-news-regional {
    width: 100%;

}
</style>
