<template>
  <UtilWrapperLayout class="storyblok-link__wrapper">
    <UtilGrid>
      <div class="storyblok-link">
        <AtomLink
          :link="linkdata"
          :text="linkdata.text"
          :icon="linkdata.icon"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    link: {
        type: Object,
        required: true,
    },

});

const linkdata = computed(() => useStoryblokLink(props.link, props.label));
</script>

<style lang="scss" scoped>
.storyblok-link {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 60px);

    .block-contribution-calculator & {
        @include grid-columns(1);
        margin-bottom: 0;
    }
}

.storyblok-link__wrapper {
    .block-contribution-calculator & {
        padding: 0;
    }
}
</style>
