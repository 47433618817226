<template>
  <div class="atom-video__wrapper">
    <video
      v-if="url"
      ref="videoRef"
      v-editable="url"
      :alt="alt"
      :title="title"
      allowfullscreen
      :controls="isPlaying"
      preload="metadata"

      class="atom-video"
      :poster="posterUrl"
      @play="isPlaying = true"
      @pause="isPlaying = false"
    >
      <source
        :src="url"
        :type="`video/${fileExtension}`"
      />
      <track
        v-if="captionUrl"
        kind="captions"
        :srcLang="locale"
        :src="captionUrl"
        :label="captionLabel"
      >
    </video>

    <transition name="fade-button">
      <div
        v-if="!isPlaying"
        class="atom-video__button"
        @click="play()"
        @keypress="play()"
      >
        <IonIcon
          class="atom-video__button-play"
          icon-name="video"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const props = defineProps({
    url: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
    alt: {
        type: String,
        default: '',
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
    captionUrl: {
        type: String,
        default: '',
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
    captionLabel: {
        type: String,
        default: '',
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
    posterUrl: {
        type: String,
        default: '',
        validator: (value) => value.length > 0 && typeof value === 'string',
    },
});
const fileExtension = computed(() => {
    const splitted = props.url?.split('.');
    return splitted ? splitted.pop() : false;
});

const isPlaying = ref(false);
const videoRef = ref(null);

const play = () => {
    videoRef.value.play();
};

</script>

<style lang="scss">
.atom-video__wrapper {
    position: relative;
}
.atom-video {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.atom-video__button-play {
    @include fluid('width', 30px, 50px);
    @include fluid('height', 30px, 50px);

    cursor: pointer;
}

.atom-video__button {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    display: flex;
    width: 84px;
    height: 84px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.75);
    transform: translate(-50%, -50%);
}
</style>
