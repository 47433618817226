<template>
  <div class="block-filtered-employees">
    <div v-for="(item, index) in people" :key="item.uuid" class="block-filtered-employees__card">
      <div class="block-filtered-employees__content">
        <div class="block-filtered-employees__img-wrapper">
          <AtomImage
            v-if="item.content?.image?.filename"
            class="block-filtered-employees__img"
            :image="item.content.image"
            provider="storyblok"
            width="360px"
            height="480px"
          />
        </div>
        <div class="block-filtered-employees__info">
          <div class="block-filtered-employees__tags">
            <AtomTag
              v-for="department in item.content?.departments"
              :key="department._uid"
              :text="department.translated_slugs?.find(
                (slug) => slug.lang === currentLocale,
              )?.name || department.name"
              :color-text="department.content?.color_6"
              :color-bg="department.content?.color_11"
            />
          </div>

          <div class="block-filtered-employees__text-section">
            <div
              v-if="item.content?.division?.name"
              class="block-filtered-employees__division"
            >
              {{ item.content.division.translated_slugs?.find(
                (slug) => slug.lang === currentLocale,
              )?.name || item.content.division.name }}
            </div>
            <div v-if="item.name" class="block-filtered-employees__name">
              {{ item.name }}
            </div>
            <div v-if="item.content?.position" class="block-filtered-employees__position">
              {{ item.content.position }}
            </div>
            <div v-if="item.content?.additional_info" class="block-filtered-employees__additional-info">
              {{ item.content.additional_info }}
            </div>
            <a
              v-if="item.content?.email"
              class="block-filtered-employees__email"
              :href="`mailto:${item.content.email}`"
            >
              {{ item.content.email }}
            </a>
            <a
              v-if="item.content?.phone"
              class="block-filtered-employees__phone"
              :href="`Tel:${item.content.phone}`"
            >
              <div>
                {{ item.content?.phone }}
              </div>
            </a>
            <button
              class="block-filtered-employees__more-info"
              type="button"
              @click="() => { openOverlay(index) }"
            >
              {{ getStoryblokTranslation('general', 'more_info') }}
            </button>
            <Transition name="fade-in">
              <BlockOverlayPerson
                v-if="isOpen"
                ref="wrapperRef"
                :person="people[selectedPersonIndex]"
                @close="() => { isOpen = false; }"
              />
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

defineProps({
    people: {
        type: Array,
        default: () => [],
    },
});

const isOpen = ref(false);
const selectedPersonIndex = ref(0);
const wrapperRef = ref(null);

const { locale: currentLocale } = useI18n();

/* Close on click outside */
onClickOutside(wrapperRef, () => {
    isOpen.value = false;
});

const openOverlay = (index) => {
    selectedPersonIndex.value = index;
    isOpen.value = true;
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const color7 = computed(() => `var(--c-${colorTopLevel.value}-7)`);
</script>

  <style lang="scss">
.block-filtered-employees {
    @include grid-layout(6);

    font-family: var(--f-family--thin);
    row-gap: var(--grid-row--gap);

    @include mobile {
        gap: 10px;
    }
}

.block-filtered-employees__img {
    display: block;
    height: 100%;
    background-position: center center;
    object-fit: cover;

    img {
        min-height: 480px;

        @include mobile {
            min-height: 204px;
        }
    }
}

.block-filtered-employees__info {
    @include fluid('padding', 10px, 30px);

    min-height: 186px;

    @include tablet {
        min-height: unset;
    }
}

.block-filtered-employees__tags {
    display: flex;
    flex-wrap: wrap ;
    padding-bottom: 20px;
    gap: 10px;

    @include mobile {
        display: none;
    }
}

.block-filtered-employees__content {
    position: relative;
    height: 100%;
}

.block-filtered-employees__text-section {
    color: v-bind(color1);
    font-family: var(--f-family--thin);

    @include mobile {
        word-break: break-word;
    }
}

.block-filtered-employees__img-wrapper {
    position: relative;
    overflow: hidden;
}

.block-filtered-employees__division {
    @include fluid('font-size', 11px, 14px);

    padding-bottom: 5px;

    @include mobile {
        padding-bottom: 0px;
    }
}

.block-filtered-employees__name {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include mobile {
        padding-bottom: 5px;
    }
}

.block-filtered-employees__position {
    font-size: 17px;
    line-height: 27px;

    @include mobile {
        font-size: 11px;
        line-height: 22px;
    }
}

.block-filtered-employees__additional-info {
    padding-bottom: 20px;
}

.block-filtered-employees__email,
.block-filtered-employees__phone {
    color: v-bind(color4);
}

.block-filtered-employees__additional-info,
.block-filtered-employees__email,
.block-filtered-employees__phone {
    display: none;

    @include mobile {
        display: none;
    }
}

.block-filtered-employees__card {
    background-color: var(--c-white);
    grid-column-end: span 2;
    transition: height  0.7s $EASE_IN--QUAD;

    @include tablet {
        grid-column-start: span 3;
    }

    @include above-mobile {
        &:hover {
            background-color: v-bind(color7);

            .block-filtered-employees__img {
                /* stylelint-disable-next-line max-nesting-depth */
                img {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    min-height: 360px;
                    aspect-ratio: 1/1;
                    object-position: top;
                    transform: scale(1.1);
                }
            }

            .block-filtered-employees__text-section {
                color: var(--c-white);
            }

            .block-filtered-employees__additional-info,
            .block-filtered-employees__email,
            .block-filtered-employees__phone {
                display: block;
            }

            .block-filtered-employees__position {
                padding-bottom: 20px;
            }
        }
    }
}

.block-filtered-employees__more-info {
    display: none;

    @include mobile {
        display: block;
        font-family: var(--f-family--bold);
        font-size: 11px;
        line-height: 22px;
    }
}
  </style>
