<template>
  <div
    class="atom-item-accordion"
    :class="{ 'is-open': isOpen }"
  >
    <button
      class="atom-item-accordion__header"
      type="button"
      @click="toggleAccordion"
    >
      <p v-if="title" class="atom-item-accordion__header-title">
        {{ title }}
      </p>
      <p v-if="titleProject" class="atom-item-accordion__header-title-project">
        <span class="atom-item-accordion__title"><div :class="{ 'bold-title': isOpen }">{{ titleProject }}</div>
          <span class="atom-item-accordion__separator" /> {{ canton }}
          <span class="atom-item-accordion__separator" />{{ convertNumber(tCO2e) }} tCO₂e </span>
      </p>

      <div class="atom-item-accordion__close-icon">
        <IonIcon icon-name="chevron-down" />
      </div>
    </button>
    <div
      ref="accordionItemRef"
      class="atom-item-accordion__flyout"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();
defineProps({
    title: {
        type: String,
        default: '',
    },

    titleProject: {
        type: String,
        default: '',
    },

    canton: {
        type: String,
        default: '',
    },

    tCO2e: {
        type: [String, Number],
        default: 0,
    },
});

const isOpen = ref(false);
const currentItemHeight = ref('0px');
const accordionItemRef = ref(null);

const toggleAccordion = () => {
    isOpen.value = !isOpen.value;
    currentItemHeight.value = isOpen.value ? `${accordionItemRef.value.scrollHeight}px` : '0px';
};
const convertNumber = (num) => Number(num).toLocaleString().replace(',', "'");

watch(() => width.value, (val) => {
    if (isOpen.value && val) {
        currentItemHeight.value = `${accordionItemRef.value.scrollHeight}px`;
    }
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.atom-item-accordion {
    margin-bottom: 3px;
    background: var(--c-white);
}

.atom-item-accordion__header {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 26px 30px 26px 20px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    @include mobile {
        padding: 20px;
    }

    .storyblok-teaser-pdf__accordion-content & {
        padding: 28px 30px;

        @include mobile {
            padding: 20px;
        }
    }
}

.atom-item-accordion__header-title {
    @include fluid('padding-right', 20px, 30px);
}

.atom-item-accordion__flyout {
    overflow: hidden;
    max-height: v-bind(currentItemHeight);
    transition: max-height 0.5s $EASE_OUT--QUART;
}

.atom-item-accordion__close-icon {
    display: flex;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s $EASE_IN_OUT--BACK;

    .is-open & {
        transform: rotate(180deg);
    }
    .block-filtered-projects__accordion-content & {

        width: 22px;
        height: 22px;
        align-self: center;
        justify-self: center;

    }
}

.atom-item-accordion__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    strong {
        font-family: var(--f-family--black);
    }

    @include mobile {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.atom-item-accordion__header-title-project {
    padding-right: 20px
}

.atom-item-accordion__separator {
    display: inline-block;
    width: 1px;
    height: 29px;
    border-radius: 20px;
    margin: 0 10px;
    background: v-bind(color1);
}

.bold-title {
    font-family: var(--f-family--bold);
}
</style>
