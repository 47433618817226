<template>
  <div class="atom-input-years-calc">
    <AtomInputNumberCalc
      v-for="year in years"
      :key="year._uid"
      :initial-value="setInitialValue(year)"
      :data="year"
      :label="year.label"
      :name="year.name"
      :is-required-val="year.isRequired"
      :options="year.options"
      @set-input="setInput"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    years: {
        type: Object,
        default: () => {},
    },

    name: {
        type: String,
        default: '',
    },
});

const yearData = ref([]);
const emit = defineEmits(['set-input']);

const setInput = (data) => {
    if (data.value === '' || data.value === null || data.value === undefined) {
        return;
    }

    // check if year already exists
    const yearExists = yearData.value.find((year) => year.year === parseInt(data.name, 10));

    if (yearExists) {
        yearExists.energyMWh = data.value;
        return;
    }

    yearData.value.push({
        energyMWh: data.value,
        year: parseInt(data.name, 10),
    });

    emit('set-input', {
        name: props.name,
        value: yearData.value,
    });
};

/* set initial input */
const calculatorStore = useCalculatorStore();
const requestBody = computed(() => calculatorStore.getRequestBody);
const setInitialValue = (year) => {
    // if year exists in requestBody, set it as initial value
    if (requestBody.value[props.name]) {
        const yearExists = requestBody.value[props.name].find(
            (yearDetail) => yearDetail.year === parseInt(year.name, 10),
        );

        if (yearExists) {
            return yearExists.energyMWh;
        }
    }

    return 0;
};
</script>

<style lang="scss">
.atom-input-years-calc {
    display: grid;
    grid-column: span 2;
    grid-gap: 36px;
    grid-template-columns: repeat(6, 1fr);

    @include mobile {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
