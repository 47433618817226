export default defineStore('SliderImageStore', {
    state: () => ({
        isOpenValue: false,
    }),

    actions: {
        openSliderImage() {
            this.isOpenValue = true;
        },

        closeSliderImage() {
            this.isOpenValue = false;
        },
    },

    getters: {
        isOpen(state) {
            return state.isOpenValue;
        },
    },
});
