<template>
  <div class="block-tag-cloud">
    <div
      v-if="!tagSelected"
      class="block-tag-cloud__wrapper"
    >
      <div
        v-if="listIsOpen === false"
        class="block-tag-cloud__inner"
      >
        <h2 class="block-tag-cloud__title">
          {{ title }}
        </h2>
        <div
          class="block-tag-cloud__arrow"
          @click="listIsOpen = true"
          @keypress="listIsOpen = true"
        >
          <IonIcon icon-name="chevron-down" />
        </div>
      </div>
      <div v-else class="block-tag-cloud__inner">
        <div v-if="isArrayNotEmpty(tags)" class="block-tag-cloud__cloud">
          <button
            v-for="tag in tags"
            :key="tag"
            type="button"
            class="block-tag-cloud__tag"
            :class="tag.size"
            :style="`--tag-color: ${tag.color}; --tag-hover: ${tag.color_hover};`"
            @click="toggleTag(tag), (tagSelected = true)"
          >
            {{ tag?.name }}
          </button>
        </div>
        <button
          class="block-tag-cloud__arrow is-open"
          type="button"
          @click="listIsOpen = false"
        >
          <IonIcon icon-name="chevron-up" />
        </button>
      </div>
    </div>

    <div v-if="!tagSelected && isArrayNotEmpty(tags)" class="block-tag-cloud__mobile">
      <button
        v-for="tag in tags"
        :key="tag.uuid"
        type="button"
        class="block-tag-cloud__tag"
        :class="tag.size"
        :style="`color: ${tag.color}`"
        @click="toggleTag(tag), (tagSelected = true)"
      >
        {{ tag?.name }}
      </button>
    </div>
  </div>

  <div
    class="block-tag-cloud__selected"
    :class="{ 'is-hidden': !tagSelected }"
  >
    <button
      class="block-tag-cloud__arrow"
      type="button"
      @click="toggleTag(selectedTag), (tagSelected = false)"
    >
      <IonIcon icon-name="chevron-left" />
      <div class="block-tag-cloud__arrow-back">
        {{ getStoryblokTranslation('general', 'all_tags') }}
      </div>
      <div>{{ selectedTag?.name }}</div>
    </button>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    tags: {
        type: Array,
        default: () => [],
    },
});

const listIsOpen = ref(false);
const tagSelected = ref(false);
const selectedTag = ref(null);
const filterStore = useFilterStore();

const toggleTag = (tag) => {
    if (tag.uuid === selectedTag.value?.uuid) {
        selectedTag.value = null;
        filterStore.setTags([]);
    } else {
        selectedTag.value = tag;
        filterStore.setTags(selectedTag.value.uuid);
    }
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss">
.block-tag-cloud {
    display: flex;
    justify-content: space-between;
    grid-column: 3 / span 14;

    @include tablet {
        grid-column: 2 / span 16;
    }

    @include mobile {
        margin-bottom: 30px;
    }
}

.block-tag-cloud__title {
    color: v-bind(color1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    @include mobile {
        font-size: 20px;
    }
}

.block-tag-cloud__wrapper {
    position: relative;
    min-width: 100%;
    padding: 20px 29px;
    background-color: var(--c-white);

    .block-tag-cloud__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.block-tag-cloud__mobile {
    display: none;
    padding: 20px 29px;
    background-color: var(--c-white);
}

.block-tag-cloud__cloud {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.block-tag-cloud__selected {
    margin-top: 20px;
    margin-bottom: 0;

    div {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--h2);
    }

    .block-tag-cloud__arrow-back {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--richtext);
    }

    &.is-hidden {
        display: none;
    }
}

.block-tag-cloud__tag {
    margin-right: 40px;
    color: var(--tag-color);

    &:hover {
        color: var(--tag-hover);
    }

    &.s {
        font-family: var(--f-family--thin);
        font-size: 14px;
        line-height: 18px;
    }
    &.m {
        font-family: var(--f-family--regular);
        font-size: 28px;
        line-height: 38px;
    }
    &.l {
        font-family: var(--f-family--bold);
        font-size: 46px;
        line-height: 56px;

        @include mobile {
            font-size: 36px;
        }
    }

    @include mobile {
        margin-bottom: 12px;
    }
}

.block-tag-cloud__arrow {
    display: flex;
    align-items: center;
    color: v-bind(color4);
    cursor: pointer;

    &.is-open {
        align-self: flex-start;
    }

    div {
        margin-left: 25px;
        text-align: center;
    }

    .block-tag-cloud__arrow-back  {
        margin-left: 15px;
        line-height: 0;
    }

    @include mobile {
        position: absolute;
        right: 20px;
    }
}
</style>
