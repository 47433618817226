const title = ref('');

export const getSEOTitle = () => title.value;

export const setSEOData = (seoToRender) => {
    title.value = seoToRender?.title || '';

    const i18nHead = useLocaleHead({});

    useHead({
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs?.lang,
        },
        link: [
            ...(i18nHead.value.link || []),
        ],
        title: `${seoToRender?.title || ''}`,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: seoToRender?.description || '',
            },
            {
                hid: 'og-title',
                property: 'og:title',
                content: seoToRender?.og_title || '',
            },
            {
                hid: 'og-description',
                property: 'og:description',
                content: seoToRender?.og_description || '',
            },
            {
                hid: 'og-image',
                property: 'og:image',
                content: seoToRender?.og_image || '',
            },
            {
                hid: 'twitter-card',
                property: 'twitter:card',
                content: 'summary',
            },
            {
                hid: 'twitter-card',
                property: 'twitter:image',
                content: seoToRender?.og_image || '',
            },
            {
                hid: 'twitter-title',
                property: 'twitter:title',
                content: seoToRender?.twitter_title || '',
            },
            {
                hid: 'twitter-description',
                property: 'twitter:description',
                content: seoToRender?.twitter_description || '',
            },
            {
                hid: 'twitter-image',
                property: 'twitter:image',
                content: seoToRender?.twitter_image || '',
            },
            ...(i18nHead.value.meta || []),
        ],
    });
};
