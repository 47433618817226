<template>
  <div class="atom-input-radio-calc">
    <div class="atom-input-radio-calc__title">
      {{ title }}
      <div
        v-if="info?.content[0]?.content?.length > 0"
        class="atom-input-radio-calc__info"
        @click="showInfo"
        @keypress="showInfo"
      >
        <IonIcon icon-name="info" />
        <Transition name="fade-in">
          <div
            v-if="infoOpen"
            class="atom-input-radio-calc__info-box"
          >
            <AtomRichtext :html="richTextResolver.render(info)" />
          </div>
        </Transition>
      </div>
      <div
        v-if="hint?.content[0]?.content?.length > 0"
        class="atom-input-radio-calc__info"
        @click="showInfo"
        @keypress="showInfo"
      >
        <IonIcon icon-name="hint" />
        <Transition name="fade-in">
          <div
            v-if="infoOpen"
            class="atom-input-radio-calc__hint-box"
          >
            <AtomRichtext :html="richTextResolver.render(hint)" />
          </div>
        </Transition>
      </div>
    </div>
    <div class="atom-input-radio-calc__wrapper">
      <AtomInputRadio
        :options="options"
        :is-disabled="false"
        @set-input="setInput"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    options: {
        type: Object,
        default: () => {},
    },

    name: {
        type: String,
        default: '',
    },

    title: {
        type: String,
        default: '',
    },

    info: {
        type: Object,
        default: () => {},
    },

    hint: {
        type: Object,
        default: () => {},
    },

    category: {
        type: String,
        default: '',
    },
});

const {
    category,
    options,
} = toRefs(props);

const { richTextResolver } = useStoryblokApi();
const optionData = ref([]);
const emit = defineEmits(['set-input']);
const infoOpen = ref(false);
const calculatorStore = useCalculatorStore();

const setInput = (data) => {
    if (data.value === '' || data.value === null || data.value === undefined) {
        return;
    }

    const optionExists = optionData.value.find((option) => option.option === data.name);

    if (optionExists) {
        optionExists.energyMWh = data.value;
        return;
    }

    emit('set-input', {
        name: props.name,
        value: data.value === 'true',
    });

    calculatorStore.setRefrigerationSystem({
        name: category.value,
        value: data.value,
    });
};

/*
  To disable options that are not available for the selected category
*/

options.value.forEach((option) => {
    if (option.modul && option.modul?.length > 0) {
        // eslint-disable-next-line no-param-reassign
        option.isDisabled = true;
    }
});

watch(calculatorStore.getRefrigerationSystem, () => {
    options.value.forEach((option) => {
        if (option.modul && option.modul?.length > 0) {
        // eslint-disable-next-line no-param-reassign
            option.isDisabled = true;
        }
    });

    options.value.forEach((option) => {
        // for eacj option.modul check if value === category.value, if so set isDisabled to false
        if (option.modul && option.modul?.length > 0) {
            option.modul.forEach((modul) => {
                if (modul === calculatorStore.getRefrigerationSystem.modul) {
                // eslint-disable-next-line no-param-reassign
                    option.isDisabled = false;
                }
            });
        }
    });
});

const showInfo = () => {
    infoOpen.value = !infoOpen.value;
};

const colorScheme = computed(() => calculatorStore.getColorScheme);
const colorText = `var(--c-${colorScheme.value}-1)`;
const colorBackgroundHint = `var(--c-${colorScheme.value}-2)`;
const colorBackgroundInfo = `var(--c-${colorScheme.value}-11)`;
</script>

<style lang="scss">
.atom-input-radio-calc {
    column-gap: 60px;
    grid-column: span 2;
}

.atom-input-radio-calc__title {
    display: flex;
    margin-bottom: 20px;
    column-gap: 15px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.atom-input-radio-calc__wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 20px;
}

.atom-input-radio-calc__info {
    position: relative;
    cursor: pointer;
}

.atom-input-radio-calc__info-box {
    @include z-index('header');

    position: absolute;
    top: 50px;
    left: -46px;
    width: 589px;
    padding: 20px;
    background: v-bind(colorBackgroundInfo);
    color: v-bind(colorText);

    &:before {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: 26px;
        display: block;
        width: 58px;
        height: 64px;
        background: v-bind(colorBackgroundInfo);
        content: '';
        transform: rotate(45deg);
    }
}

.atom-input-radio-calc__hint-box {
    @include z-index('header');

    position: absolute;
    top: 50px;
    left: -46px;
    width: 589px;
    padding: 20px;
    background: v-bind(colorBackgroundHint);
    color: var(--c-white);

    &:before {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: 26px;
        display: block;
        width: 58px;
        height: 64px;
        background: v-bind(colorBackgroundHint);
        content: '';
        transform: rotate(45deg);

        @include mobile {
            right: 16px;
            left: unset;
        }
    }

    @include mobile {
        right: -32px;
        left: unset;
        width: 250px;
    }
}
</style>
