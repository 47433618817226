<template>
  <div class="block-item-teaser-programm-mobile is-medium-wide">
    <div
      class="block-item-teaser-programm-mobile__card"
    >
      <AtomImage
        v-if="mobileImage?.filename"
        class="block-item-teaser-programm-mobile__image"
        :image="mobileImage"
        provider="storyblok"
      />
      <div class="block-item-teaser-programm-mobile__content">
        <h4 v-if="title" class="block-item-teaser-programm-mobile__title">
          {{ title }}
        </h4>
        <p v-if="text" class="block-item-teaser-programm-mobile__text">
          {{ text }}
        </p>
        <UtilRouteLink
          v-if="link?.id || link?.url"
          class="block-item-teaser-programm-mobile__link"
          :link="link"
        >
          <AtomButton
            class="block-item-teaser-programm-mobile__button"
            :text="linkLabel"
            :color-schema="color"
            html-tag="div"
          />
        </UtilRouteLink>
      </div>
      <div
        class="block-item-teaser-programm-mobile__accordion"
        :class="{ 'is-open': indexProp }"
      >
        <button
          class="block-item-teaser-programm-mobile__accordion-header"
          type="button"
          @click="toggleAccordion"
        >
          <p class="block-item-teaser-programm-mobile__accordion-header-title">
            {{ subtitle }}
          </p>

          <div class="block-item-teaser-programm-mobile__accordion-close-icon">
            <IonIcon icon-name="chevron-down" />
          </div>
        </button>
        <div
          ref="accordionItemRef"
          class="block-item-teaser-programm-mobile__accordion-flyout"
        >
          <div class="block-item-teaser-programm-mobile__nav">
            <UtilRouteLink
              v-for="navLink in navLinks"
              :key="navLink._uid"
              :link="navLink.link"
              class="block-item-teaser-programm-mobile__navigation-item"
            >
              {{ link.linkLabel }}
            </UtilRouteLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    link: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    linkLabel: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: 'petrol',
    },

    navLinks: {
        type: Array,
        default: () => [],
    },
    mobileImage: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    indexProp: {
        type: Boolean,
        default: false,
    },
});

const colorText = computed(() => `var(--c-${props.color}-1)`);
const colorIcon = computed(() => `var(--c-${props.color}-4)`);

const colorBackground = computed(() => `var(--c-${props.color}-11)`);

const isOpen = ref(false);
const currentItemHeight = ref('0px');
const accordionItemRef = ref(null);

const toggleAccordion = () => {
    isOpen.value = !isOpen.value;
    currentItemHeight.value = isOpen.value ? `${accordionItemRef.value.scrollHeight}px` : '0px';
};

watch(() => width.value, (val) => {
    if (isOpen.value && val) {
        currentItemHeight.value = `${accordionItemRef.value.scrollHeight}px`;
    }
});

watch(() => props.indexProp, (val) => {
    if (val === true) {
        currentItemHeight.value = `${accordionItemRef.value.scrollHeight}px`;
    } else {
        currentItemHeight.value = '0px';
    }
});
</script>

<style lang="scss">
.block-item-teaser-programm-mobile {
    position: relative;
    background: var(--c-background);
}
.block-item-teaser-programm-mobile__image {
    aspect-ratio: 1/1;
    @include mobile {
        height: 270px;
    }
}
.block-item-teaser-programm-mobile__content {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    background: v-bind(colorBackground);
    color: v-bind(colorText);
    gap: 20px;
}
.block-item-teaser-programm-mobile__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}
.block-item-teaser-programm-mobile__text {
    max-width: 345px;
    padding-bottom: 20px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
.block-item-teaser-programm-mobile__nav {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    background: v-bind(colorBackground);
}
.block-item-teaser-programm-mobile__navigation-item {
    position: relative;

    margin-bottom: 20px;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--side-nav);
    line-height: var(--l-height--side-nav);

    button {
        line-height: 26px;
    }
    &.is-active {
        color: var(--c-orange-1);
    }
    &::before {
        position: absolute;
        bottom: 0;
        width: 32px;
        height: 1px;
        border-radius: 10px;
        content: '';
        transform: translateX(0px);
        transition: background-color 100ms $EASE_IN--QUAD,transform 100ms $EASE_IN--QUAD;
    }
    &:hover, &:active {
        &::before {
            position: absolute;
            bottom: 0;
            width: 32px;
            height: 1px;
            border-radius: 10px;
            background: var(--c-orange-1);
            content: '';
            transition: all 100ms ease-in-out;
        }
    }

}

.block-item-teaser-programm-mobile__accordion {
    margin-top: 3px;
    margin-bottom: 3px;
    background: v-bind(colorBackground);
}

.block-item-teaser-programm-mobile__accordion-header {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 26px 30px 26px 20px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    @include mobile {
        padding: 20px;
    }
}
.block-item-teaser-programm-mobile__accordion-header-title {
    @include fluid('padding-right', 20px, 30px);
}

.block-item-teaser-programm-mobile__accordion-flyout {
    overflow: hidden;
    max-height: v-bind(currentItemHeight);
    transition: max-height 0.5s $EASE_OUT--QUART;
}

.block-item-teaser-programm-mobile__accordion-close-icon {
    display: flex;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    transition: transform 0.6s $EASE_IN_OUT--BACK;
    path {
        stroke: v-bind(colorIcon);
    }
    .is-open & {
        transform: rotate(180deg);
    }
}
  </style>
