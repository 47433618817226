<template>
  <div class="block-detail-numbers">
    <div class="block-detail-numbers__title">
      {{ data.title }}
    </div>
    <div class="block-detail-numbers__number">
      {{ result }}
      <p v-if="data.is_percentage">
        %
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const {
    data,
} = toRefs(props);

const calculatorStore = useCalculatorStore();
const colorText = computed(() => calculatorStore.getColorSchemeText);
const calculatedData = computed(() => calculatorStore.getCalculatedData);
const result = ref(0);

if (data.value.key_object) {
    result.value = calculatedData.value[data.value.key_object][data.value.key_result];
} else {
    result.value = calculatedData.value[data.value.key_result];
}
</script>

<style lang="scss" scoped>
.block-detail-numbers {
    display: flex;
    justify-content: space-between;
    padding: 22px 20px;
    background: var(--c-white);
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    @include mobile {
        flex-direction: column;
        padding: 5px 10px;
    }
}

.block-detail-numbers__title {
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--rc--f-size--richtext);
}

.block-detail-numbers__number {
    display: flex;

    @include mobile {
        align-self: flex-end;
    }
}
</style>
