<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div
        class="storyblok-quote is-narrow"
        :class="{
          'has-image': image?.filename,
        }"
      >
        <div
          v-if="image?.filename"
          class="storyblok-quote__image"
        >
          <AtomImage
            provider="storyblok"
            :image="image"
          />
        </div>

        <div class="storyblok-quote__content">
          <AtomLead
            v-if="text"
            class="storyblok-quote__text"
            :text="text"
          />

          <p v-if="author" class="storyblok-quote__author">
            {{ author }}
          </p>
        </div>
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
defineProps({
    text: {
        type: String,
        default: '',
    },

    author: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => {},
    },
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color6 = computed(() => `var(--c-${colorTopLevel.value}-6)`);
</script>

<style lang="scss" scoped>
.storyblok-quote {
    @include fluid('margin-bottom', 30px, 50px);

    display: flex;
    flex-wrap: nowrap;
    column-gap: var(--grid-gutter);
    grid-column: 6 / span 8;

    &.has-image {
        display: grid;
        grid-column: 6 / span 9;
        grid-template-columns: repeat(9, 1fr);

        @include tablet {
            @include grid-columns(1, calc(var(--grid-columns) - 1));
        }

        @include mobile {
            @include grid-columns(1, var(--grid-columns));
            grid-template-columns: repeat(3, 1fr);
        }

        ::v-deep(.atom-lead) {
            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    @include tablet {
        @include grid-columns(1, calc(var(--grid-columns) - 1));
    }

    @include mobile {
        @include grid-columns(1, var(--grid-columns));
    }
}

.storyblok-quote__author {
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .has-image & {
        @include mobile {
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.storyblok-quote__text {
    @include fluid('margin-bottom', 5px, 10px);

    color: v-bind(color6);
    font-family: var(--f-family--thin);
}

.storyblok-quote__image {
    grid-column: span 2;

    @include mobile {
        grid-column: span 1;
    }
}

.storyblok-quote__content {
    .has-image & {
        grid-column: span 7;

        @include mobile {
            grid-column: span 2;
        }
    }
}
</style>
