<template>
  <div class="block-calculation-next-steps">
    <component
      :is="useComponent(item.component)"
      v-for="item of data?.content"
      :key="item._uid"
      :data="item"
      :btns="data?.btns"
      :color-dark="colorDark"
      :color-light="colorLight"
      :btn-color="btnColor"
      :btn-hover-color="btnHoverColor"
      :template-type="item?.templateType"
      :color-inversion="item?.colorInversion"
      :redirect-to="item?.redirectTo"
      @click="changeState(item)"
    />
    <div class="block-calculation-next-steps__wrap-button">
      <UtilRouteLink
        v-for="btn of data?.btns"
        :key="btn._uid"
        :link="btn.link"
      >
        <AtomButton
          class="block-calculation-next-steps__button"
          :text="btn?.linkLabel"
          :color-code="btnColor"
          :color-code-hover="btnHoverColor"
          html-tag="div"
        />
      </UtilRouteLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    colorScheme: {
        type: String,
        default: '',
    },
});

const {
    data,
} = toRefs(props);

const calculatorStore = useCalculatorStore();
const colorDark = computed(() => (props.colorScheme ? `var(--c-${props.colorScheme}-1)` : 'var(--c-petrol-1)'));
const colorLight = computed(() => (props.colorScheme ? `var(--c-${props.colorScheme}-11)` : 'var(--c-petrol-11)'));
const btnColor = computed(() => (props.colorScheme ? `var(--c-${props.colorScheme}-4)` : 'var(--c-petrol-4)'));
const btnHoverColor = computed(() => (props.colorScheme ? `var(--c-${props.colorScheme}-3)` : 'var(--c-petrol-3)'));
const colorInversion = computed(() => (props.data.colorInversion));
const pretitle = computed(() => (props.data.pretitle));
const title = computed(() => (props.data.title));

const changeState = (item) => {
    if (item.component === 'BlockNavigationCalculationCard') {
        calculatorStore.setNextSteps(item);
        calculatorStore.setIsNextStepsSectionDetailsSection(true);
    }
};

watchEffect(() => {
    if (colorInversion.value) {
        calculatorStore.setColorInversion(true);
    } else {
        calculatorStore.setColorInversion(false);
    }
    if (pretitle.value) {
        calculatorStore.setHeaderPretitle(pretitle.value);
    }
    if (title.value) {
        calculatorStore.setHeaderTitle(title.value);
    }
});

const availableComponents = {
    BlockNavigationCalculationCard: resolveComponent('BlockNavigationCalculationCard'),
    BlockNavigationCalculationCardLink: resolveComponent('BlockNavigationCalculationCardLink'),
    BlockItemCalculationPersonBlock: resolveComponent('BlockItemCalculationPersonBlock'),
    FormItem: resolveComponent('BlockItemFormCalculator'),
    BlockNavigationCalculationCardRedirect: resolveComponent('BlockNavigationCalculationCardRedirect'),
};
const useComponent = (key) => availableComponents[key] || null;
</script>

<style lang="scss" scoped>
.block-calculation-next-steps {
    @include fluid('gap', 10px, 20px);

    position: relative;
    display: grid;
    padding: 60px 100px 0 100px;
    grid-template-columns: repeat(2, 1fr);

    @include mobile {
        padding: 20px;
    }

    &:has(.block-item-calculation-person-block)  {
        @include fluid('gap', 30px, 40px);
    }
}

.block-calculation-next-steps__wrap-button {
    grid-column-end: span 2;

    @include mobile {
        display: flex;
        justify-content: flex-end;
    }
}

.block-calculation-next-steps__button {
    justify-content: flex-start;
}
</style>
