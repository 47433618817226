<template>
  <div
    class="block-item-color"
    :class="{
      'is-active': isActive,
      'is-dark': index >= 7 || isBasecolor && index >= 1,
      'is-basecolor': isBasecolor,
    }"
  >
    <Transition name="fade-in-color">
      <div v-if="!isActive" class="block-item-color__title-closed">
        <p>{{ text }}</p>
        <IonIcon
          icon-name="chevron-down-thin"
        />
      </div>
    </Transition>
    <Transition name="fade-in-color">
      <div v-if="isActive" class="block-item-color__content">
        <div class="block-item-color__top-wrapper">
          <div class="block-item-color__title">
            <p>{{ text }}</p>
            <IonIcon
              icon-name="chevron-up-thin"
              :color-schema="index >= 7 || isBasecolor && index >= 1 ? colorScheme : 'white'"
              @click.stop="colorControl('close')"
              @keypress.stop="colorControl('close')"
            />
          </div>
          <div class="block-item-color__content-index">
            <p v-if="index < 9">
              0{{ index + 1 }}
            </p>
            <p v-else>
              {{ index + 1 }}
            </p>
          </div>
        </div>
        <div class="block-item-color__content-details">
          <div class="block-item-color__content-details-left">
            <div class="block-item-color__content-details-title">
              {{ getStoryblokTranslation('general', 'color_values') }}
            </div>
            <div class="block-item-color__content-details-left-wrapper">
              <div
                v-for="detail, detailIndex in color.details"
                :key="detail._uid"
                class="block-item-color__content-details-left-wrapper-item"
              >
                <p>{{ detail.title }}</p>
                <div class="block-item-color__content-details-left-wrapper-item-wrapper">
                  <p class="block-item-color__content-details-left-wrapper-item-value">
                    {{ detail.value }}
                  </p>
                  <div
                    class="block-item-color__content-details-left-wrapper-item-icon"
                  >
                    <IonIcon
                      icon-name="clipboard"
                      :color-schema="index >= 7 || isBasecolor && index >= 1 ? colorScheme : 'white'"
                      @click="pasteToClipboard(detail.value, detailIndex)"
                      @keypress="pasteToClipboard(detail.value, detailIndex)"
                    />
                    <Transition name="fade-in">
                      <div
                        v-if="showToast && clickedDetailIndex === detailIndex"
                        class="block-item-color__success-toast"
                      >
                        {{ getStoryblokTranslation('general', 'success_clipboard') }}
                      </div>
                    </Transition>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="block-item-color__content-details-right-wrapper">
            <div class="block-item-color__content-details-right">
              <div class="block-item-color__content-details-title">
                {{ getStoryblokTranslation('general', 'usage') }}
              </div>
              <p class="block-item-color__content-details-right-text">
                {{ color.text }}
              </p>
              <div class="block-item-color__content-details-right-tags">
                <p
                  v-for="tag in color.tags"
                  :key="tag._uid"
                  class="block-item-color__content-details-right-tag"
                >
                  {{ tag }}
                </p>
              </div>
            </div>
            <div class="block-item-color__controls">
              <IonIcon
                v-if="index > 0"
                icon-name="chevron-left-thin"
                @click.stop="colorControl('down')"
                @keypress.stop="colorControl('down')"
              />
              <div>{{ index + 1 }}/{{ totalColors }}</div>
              <IonIcon
                v-if="index < totalColors - 1"
                icon-name="chevron-right-thin"
                @click.stop="colorControl('up')"
                @keypress.stop="colorControl('up')"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    color: {
        type: Object,
        required: true,
    },

    text: {
        type: String,
        required: true,
    },

    isActive: {
        type: Boolean,
        default: false,
    },

    index: {
        type: Number,
        required: true,
    },

    totalColors: {
        type: Number,
        required: true,
    },

    colorScheme: {
        type: String,
        required: true,
    },

    isBasecolor: {
        type: Boolean,
        default: false,
    },
});

const {
    color,
    colorScheme,
} = toRefs(props);

const backgroundColor = computed(() => `#${color.value.hex}`);
const tagTextColor = computed(() => `var(--c-${colorScheme.value}-6)`);
const tagBackgroundColor = computed(() => `var(--c-${colorScheme.value}-11)`);
const textColorDark = computed(() => `var(--c-${colorScheme.value}-1)`);

const emit = defineEmits([
    'color-control',
]);

const colorControl = (direction) => {
    emit('color-control', direction);
};

const showToast = ref(false);
const clickedDetailIndex = ref(null);

const pasteToClipboard = (value, index) => {
    navigator.clipboard.writeText(value);
    showToast.value = true;
    clickedDetailIndex.value = index;

    setTimeout(() => {
        showToast.value = false;
    }, 2000);
};
</script>

<style lang="scss" scoped>
.block-item-color__title-closed {
    display: none;
    align-items: center;
    column-gap: 20px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h2);
    line-height: var(--l-height--h2);

    @include mobile {
        column-gap: 15px;
    }
}

.block-item-color {
    overflow: hidden;
    width: 100%;
    flex: 1;
    background: v-bind(backgroundColor);
    color: var(--c-white);
    cursor: pointer;
    transition: flex-basis 0.3s ease-in-out;

    &:first-child {
        flex-basis: 360px;

        &.is-basecolor {
            @include mobile {
                flex-basis: 223px;
            }
        }

        .block-item-color__title-closed {
            display: flex;
            padding: 44px 40px 40px 40px;

            .block-color-palette__color.is-active & {
                display: none;
            }

            @include mobile {
                padding: 22px 20px 20px 20px;
            }
        }

        @include mobile {
            flex-basis: 160px;
        }
    }

    .block-color-palette__color.is-active & {
        &:not(.is-active) {
            flex-basis: 25px;

            @media screen and (max-width: 1555px) {
                flex-basis: 10px;
            }

            @include mobile {
                flex-basis: 60px;
            }
        }
    }

    &.is-active {
        flex-basis: 100%;
        cursor: unset;

        @include mobile {
            flex-basis: 223px;
        }
    }

    &.is-dark {
        color: v-bind(textColorDark);
    }

    @include mobile {
        overflow: visible;
        width: 60px;
        flex: none;
    }
}

.block-item-color__title {
    display: flex;
    align-items: center;
    column-gap: 20px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h2);
    line-height: var(--l-height--h2);

    .ion-icon {
        cursor: pointer;
    }

    @include mobile {
        column-gap: 15px;
    }
}

.block-item-color__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;

    @include mobile {
        padding: 20px;
    }
}

.block-item-color__content-index {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h2);
    line-height: var(--l-height--h2);
}

.block-item-color__content-details {
    position: relative;
    display: flex;
    column-gap: 40px;

    @include mobile {
        flex-direction: column;
        row-gap: 20px;
    }
}

.block-item-color__content-details-title {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    @include mobile {
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }
}

.block-item-color__content-details-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 40px;

    @include mobile {
        row-gap: 20px;
    }
}

.block-item-color__content-details-left-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include mobile {
        row-gap: 10px;
    }
}

.block-item-color__content-details-left-wrapper-item {
    position: relative;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    :nth-child(2) {
        font-family: var(--f-family--bold);
    }

    div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .ion-icon {
        cursor: pointer;
    }
}

.block-item-color__content-details-left-wrapper-item-value {
    padding-top: 3px;
}

.block-item-color__content-details-left-wrapper-item-icon {
    position: relative;
}

.block-item-color__success-toast {
    position: absolute;
    top: 50%;
    left: 150%;
    padding: 8px 12px 6px 12px;
    border-radius: 5px;
    background-color: v-bind(tagBackgroundColor);
    color: v-bind(tagTextColor);
    transform: translateY(-50%);

    &::after {
        position: absolute;
        top: 50%;
        right: 100%;
        border: 5px solid transparent;
        border-right-color: v-bind(tagBackgroundColor);
        margin-top: -5px;
        content: '';
    }
}

.block-item-color__content-details-right-wrapper {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
}

.block-item-color__content-details-right {
    display: flex;
    flex: 2;
    flex-direction: column;
    row-gap: 40px;

    @include mobile {
        row-gap: 20px;
    }
}

.block-item-color__content-details-right-text {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-item-color__content-details-right-tags {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
}

.block-item-color__content-details-right-tag {
    padding: 8px 12px 6px 12px;
    border-radius: 5px;
    background: v-bind(tagBackgroundColor);
    color: v-bind(tagTextColor);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);

    .is-dark & {
        background: v-bind(tagTextColor);
        color: var(--c-white);
    }
}

.block-item-color__controls {
    // position: absolute;
    // right:0;
    // bottom: 0;
    display: flex;
    align-items: center;
    align-self: flex-end;
    column-gap: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    justify-self: flex-end;

    .ion-icon {
        cursor: pointer;
    }
    @include mobile {
        position: relative;
        align-self: flex-end;
        row-gap: 10px;
    }
}
</style>
