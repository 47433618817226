<template>
  <div class="storyblok-info-box">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-info-box__wrapper is-narrow">
          <AtomRichtext
            v-if="text"
            :html="storyblokApi.richTextResolver.render(text)"
            class="storyblok-info-box__paragraph"
          />

          <AtomLink
            v-if="link?.id || link?.url"
            class="storyblok-info-box__link storyblok-link"
            :text="linkLabel"
            :link="link"
            icon="chevron-right"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
defineProps({
    text: {
        type: Object,
        required: true,
    },

    link: {
        type: Object,
        required: true,
    },

    linkLabel: {
        type: String,
        default: '',
    },
});

const storyblokApi = useStoryblokApi();

const colorTopLevel = inject('colorSchema');
const color7 = computed(() => `var(--c-${colorTopLevel.value}-7)`);
</script>
<style lang="scss">

.storyblok-info-box__wrapper {
    @include grid-columns(5, 10);

    @include mobile {
        @include default-content-columns;
        padding: 40px;
    }

    padding: 60px 78px;
    margin-bottom: 60px;
    background-color: v-bind(color7);
    color: var(--c-white);

    .storyblok-info-box__link {
        margin-top: 50px;
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    .atom-richtext {
        font-size: var(--f-size--description);
        line-height: var(--l-height--description);

        h3 {
            margin: 0;
            font-family: var(--f-family--regular);
            font-size: var(--f-size--h3);
            line-height: var(--l-height--h3);
        }

        h4 {
            margin: 0;
            font-family: var(--f-family--regular);
            font-size: var(--f-size--h4);
            line-height: var(--l-height--h4);
        }
    }
}

.storyblok-info-box__paragraph {
    color: var(--c-white);
}
</style>
