<template>
  <div class="block-filter">
    <h2 class="block-filter__title">
      {{ title }}
    </h2>
    <div class="block-filter__select-wrapper">
      <template v-for="dropdown, index in dropdowns" :key="dropdown.uid">
        <AtomSingleselect
          v-if="dropdown.field_type === 'single_option'
            || dropdown.label === 'Datum'
            || dropdown.label === 'Date'
            || dropdown.label === 'Data'"
          :key="dropdown.name"
          :name="dropdown.label"
          :label="dropdown.label"
          :type="dropdown.type"
          :options="[...dropdown.options.map((option) => ({
            ...option,
            label: option.name,
            value: option.value,
            language: option.name,
          }))]"
          :card-index="index"
          class="block-filter__select"
          @set-data="setFilter"
        />
        <AtomMultiselect
          v-else
          :key="dropdown.label"
          :options="dropdown.options"
          :label="dropdown.label"
          class="block-filter__select"
          @set-data="setFilter"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },

    dropdowns: {
        type: Array,
        default: () => [],
    },

    color: {
        type: String,
        default: '',
    },
});

const filterStore = useFilterStore();
const emit = defineEmits(['on-set-filter']);

const setFilter = (data) => {
    emit('on-set-filter', data.uuids);

    switch (data.type) {
    case 'countries':
        filterStore.setCountries(data.uuids);
        break;
    case 'date':
        filterStore.setYears(data.uuids);
        break;
    case 'type-of-article':
        filterStore.setTypesOfArticle(data.uuids);
        break;
    case 'type-of-activity':
        filterStore.setTypesOfActivity(data.uuids);
        break;
    case 'type-of-project':
        filterStore.setTypeOfProject(data.uuids);
        break;
    case 'canton':
        filterStore.setCanton(data.uuids);
        break;
    case 'sort-title':
        filterStore.setSortByName(data.uuids);
        break;
    case 'sort-tCO2e':
        filterStore.setSortBytCO2e(data.uuids);
        break;
    case 'department':
        filterStore.setDepartments(data.uuids);
        break;
    case 'division':
        filterStore.setDivision(data.uuids);
        break;
    case 'thema':
        filterStore.setThemas(data.uuids);
        break;
    default:
        break;
    }
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color11 = computed(() => `var(--c-${colorTopLevel.value}-11)`);
</script>

<style lang="scss">
.block-filter {
    display: flex;
    justify-content: space-between;
    padding: 20px 29px;
    padding-bottom: 40px;
    margin-bottom: 5px;
    background-color: var(--c-white);
    grid-column: 3 / span 14;

    @include tablet {
        grid-column: 2 / span 16;
    }

    @include mobile {
        flex-direction: column;
        align-items: unset;
        padding: 10px;
        .storyblok-projects-block & {
            padding: 20px;
        }
    }
    .storyblok-employees-block & {
        padding: 20px 31px;
    }
}
.block-filter__select-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;

    .storyblok-projects-block__filter & {
        flex-wrap: wrap;
        justify-content: flex-end;
        row-gap: 5px;
    }

    @include mobile {
        flex-direction: column;
        row-gap: 5px;
    }
}
.block-filter__title {
    color: v-bind(color1);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--l-height--h3);

    @include mobile {
        margin-bottom: 10px;
    }
}
.block-filter__select {
    margin-left: 10px;

    @include mobile {
        margin-left: 0;
    }

    > button {
        width: 167px;
        padding-top: 8px;
        padding-right: 10px;
        padding-bottom: 5px;
        background: v-bind(color11);
        font-size: 14px;
        line-height: 18px;
        text-align: right;

        @include mobile {
            width: 100%;
            margin-top: 5px;
        }
    }

    svg {
        margin-left: 5px;
        font-size: 10px;
        transform: translateY(-1px);
    }
    .storyblok-projects-block__filter & {
        width: 167px;
    }
}
.block-filter__select-category {
    display: none;
}
</style>
