<template>
  <div
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div class="atom-multiselect-calc__title">
      {{ title }}
    </div>

    <div ref="wrapperRef" class="atom-multiselect-calc__wrapper">
      <div
        class="atom-multiselect-calc"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <p class="atom-multiselect-calc__label">
          {{ label }}
        </p>

        <IonIcon
          class="atom-multiselect-calc__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>

      <div
        class="atom-multiselect-calc__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-multiselect-calc__options"
        >
          <div
            v-for="option in options"
            :key="option.value"
            class="atom-multiselect-calc__option"
            :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
          >
            <button
              type="button"
              class="atom-multiselect-calc__option-button"
              @click="multiselectOption(option)"
            >
              <span class="atom-multiselect-calc__option-background" />
              <span class="atom-multiselect-calc__option-label">{{ option.name }}</span>
              <IonIcon
                class="atom-multiselect-calc__icon-close has-pointer is-right"
                icon-name="close"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!showOptions"
        class="atom-multiselect-calc__selected"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="atom-multiselect-calc__selected-option"
          :class="{ 'is-selected': multiselectedOptions.includes(option.value) }"
        >
          <button
            type="button"
            class="atom-multiselect-calc__selected-option-button"
            @click="multiselectOption(option)"
          >
            <span class="atom-multiselect-calc__selected-option-background" />
            <span class="atom-multiselect-calc__selected-option-label">{{ option.name }}</span>
            <IonIcon
              class="atom-multiselect-calc__icon-close has-pointer is-right"
              icon-name="close"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
   * This component provides a multiselect input field with label, icon, and validation support.
   *
   * @param {string} props.label - The label for the input field.
   * @param {string} props.name - The name of the input field.
   * @param {array} [props.options=[]] - An array of options to display in the multiselect field.
   *
   * Author: Luca Margadant (luca.margadant@nueva.ch)
   * Copyright: Nueva AG
   */

import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
          General data
      */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
});

const {
    options,
} = toRefs(props);

/*
      Intialze formfield
  */
const wrapperRef = ref(null);

/*
      Handle input
  */
const emit = defineEmits(['set-input']);

/*
      Custom Logic
  */
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);

/* handle multiselected options */
const multiselectedOptions = ref([]);
const objValue = ref({});
const isCalc = ref(false);

const handleData = (type) => {
    emit(
        'set-input',
        // string of all selected options with props.name as key
        {
            name: props.name,
            value: !isCalc.value
                ? multiselectedOptions.value.join(',')
                : objValue.value,
            type,
        },
    );
};

const multiselectOption = (option) => {
    // add option to multiselectedOptions

    // for beitragsrechner
    if (option.from && option.to) {
        objValue.value = {
            from: option.from,
            to: option.to,
        };

        isCalc.value = true;

        handleData(option.type);
    }

    if (!multiselectedOptions.value.includes(option.value)) {
        // add option.value + option.type to multiselectedOptions
        // there should only be one option because its for calculator
        multiselectedOptions.value = [];
        multiselectedOptions.value.push(option.value);

        handleData();
    } else {
        multiselectedOptions.value = multiselectedOptions.value.filter(
            (item) => item !== option.value,
        );

        handleData();
    }
};

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const calculatorStore = useCalculatorStore();
const colorScheme = computed(() => calculatorStore.getColorScheme);
const colorText = `var(--c-${colorScheme.value}-1)`;
const colorBackground = `var(--c-${colorScheme.value}-11)`;

/* set initial input */
const requestBody = computed(() => calculatorStore.getRequestBody);
const setInitialValue = () => {
    if (requestBody.value[props.name] === undefined) return;

    multiselectOption(
        options.value.find((option) => option.value === requestBody.value[props.name]),
    );
};

setInitialValue();
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    display: flex;
    flex-direction: column;
    user-select: none;
}

.atom-multiselect-calc__title {
    margin-bottom: 15px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
}
.atom-multiselect-calc__wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    cursor: pointer;
}

.atom-multiselect-calc {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    width: 170px;
    max-width: 100%;
    align-items: flex-end;
    background: v-bind(colorBackground);

    @include mobile {
        width: 100%;
    }
}

.atom-multiselect-calc__label {
    @include formFieldLabelText;

    display: flex;
    align-items: flex-end;
    padding-top: 2px;
    padding-right: 38px;
    margin-bottom: 0;
    color:v-bind(colorText);
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    pointer-events: none;
}

.atom-multiselect-calc__input {
    @include formFieldMultiselect;

    pointer-events: none;
}

.atom-multiselect-calc__icon {
    @include formFieldInputIcon(false);

    top: 6px;
    right: 10px !important;
    width: 18px;
    height: 18px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-multiselect-calc__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-multiselect-calc__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    top: 63px;
    width: 100%;

    &.is-visible {
        display: block;
    }
}

.atom-multiselect-calc__options {
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        display: flex;
        height: 250px;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.atom-multiselect-calc__option {

    position: relative;
    display: flex;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-multiselect-calc__selected-option {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-multiselect-calc__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-multiselect-calc__option-button {
    @include word-break;

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.atom-multiselect-calc__selected-option-button {
    @include word-break;

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.atom-multiselect-calc__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-multiselect-calc__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: v-bind(colorBackground);
    transition: background-color 0.2s ease-in-out;
}

.atom-multiselect-calc__option-label {
    @include z-index('selectLabel');

    position: relative;
    margin: 6px 36px 6px 10px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: v-bind(colorText);
    }
}

.atom-multiselect-calc__selected-option-label {
    @include z-index('selectLabel');

    position: relative;
    width: 170px;
    padding: 6px 36px 6px 10px;
    color: v-bind(colorText);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
    white-space: nowrap;

    .is-selected & {
        color: v-bind(color1);
    }
}

.atom-multiselect-calc__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 6px;
    right: 10px !important;
    width: 15px;
    height: 15px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        opacity: 1;
    }

}

.atom-multiselect-calc__selected {
    display: flex;
    gap: 3px;
    .atom-multiselect-calc__selected-option {
        display: none;
        width: 100%;
        text-align: end;

        &.is-selected {
            display: flex;
        }
    }
}
  </style>
