export default () => {
    const createBlob = async (filename) => {
        const res = await fetch(filename).then((t) => t.blob()).catch(console.error);
        return res;
    };

    const downloadImages = async (blob, index, name, isIterableName) => {
        const a = document.createElement('a');
        const imageName = isIterableName ? `${name}${index + 1}` : name;
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', imageName);
        a.click();
    };

    const getFileFormat = (url) => url.split('.').pop();

    const getImageName = (url) => url.split('/').pop();

    return {
        downloadImages, createBlob, getFileFormat, getImageName,
    };
};
