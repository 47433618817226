<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-tag-cloud is-narrow">
        <BlockTagCloud
          v-if="isArrayNotEmpty(tags)"
          :title="title"
          :tags="tags"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
    },
});

const { locale: currentLocale } = useI18n();

const filterStore = useFilterStore();
const tags = computed(() => filterStore.getCloudTags.map((tag) => ({
    name: tag.translated_slugs?.find(
        (slug) => slug.lang === currentLocale.value,
    )?.name || tag?.name,
    uuid: tag.uuid,
    color: tag.content.color,
    color_hover: tag.content.color_hover,
    size: tag.content.size,
})));
</script>

<style lang="scss">
.storyblok-tag-cloud {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 45px);
}
</style>
