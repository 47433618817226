<template>
  <div
    class="block-modalbox-overlay"
  >
    <div ref="wrapperRef" class="block-modalbox-overlay__container is-extra-wide">
      <button class="block-modalbox-overlay__close" type="button" @click="modalboxStore.closeModalbox">
        <IonIcon
          class="block-modalbox-overlay__close-icon"
          icon-name="close"
        />
      </button>
      <div class="block-modalbox-overlay__container-inner">
        <div v-for="item in items" :key="item._uid" class="block-modalbox-overlay__container-item">
          <iframe
            v-if="item.iframeUrl"
            :src="item.iframeUrl"
            class="block-modalbox-overlay__iframe"
            title="iframe"
          />

          <AtomRichtext v-if="item.text" :html="item.text" />

          <div v-if="item.ctas && item.ctas.length > 0" class="block-modalbox-overlay__container-ctas">
            <UtilRouteLink
              v-for="cta in item.ctas"
              :key="cta._uid"
              :link="cta"
              @click="modalboxStore.closeModalbox"
            >
              <AtomButton :text="cta.label" />
            </UtilRouteLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

defineProps({
    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const modalboxStore = useModalboxStore();
const wrapperRef = ref(null);

/* Close on click outside */
onClickOutside(wrapperRef, () => {
    modalboxStore.closeModalbox();
});

</script>

<style lang="scss" scoped>
.block-modalbox-overlay {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 0 var(--page-padding);
    background: hsla(0,0%,100%,.3);
    overflow-y: auto;

    @include mobile {
        align-items: flex-start;
        padding: 0;
    }
}

.block-modalbox-overlay__container {
    @include grid-layout();

    position: relative;
    max-width: 1560px;
    height: auto;
    max-height: calc(100% - 2 * var(--grid-gutter));
    margin-top: var(--grid-gutter);
    margin-bottom: var(--grid-gutter);
    background: var(--c-white);
    box-shadow: 0 0 28px 0 rgba(49, 49, 49, 0.36);
    overflow-y: auto;

    @include mobile {
        max-height: none;
        flex: 1;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
    }
}

.block-modalbox-overlay__close {
    @include z-index('header');

    position: absolute;
    top: 36px;
    right: 40px;

    @include mobile {
        top: 20px;
        right: var(--page-padding);
    }
}

.block-modalbox-overlay__container-item {
    @include fluid('margin-bottom', 30px, 50px);

    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.block-modalbox-overlay__container-inner {
    @include default-content-columns;

    position: relative;
    padding: 80px 0;

    @include tablet {
        @include grid-columns(2, 16);
    }

    @include mobile {
        padding: 60px var(--page-padding);
    }
}

.block-modalbox-overlay__iframe {
    @include fluid('height', 350px, 800px);

    width: 600px;

    @include mobile {
        width: 100%;
    }
}
</style>
