<template>
  <div v-if="isFiltered || isPrefiltered" class="block-news-filtered">
    <div v-if="news?.length === 0 && newsStatus === 'success'" class="block-news-filtered__noArticles">
      <span>{{ getStoryblokTranslation('general', 'no_articles') }}</span>
    </div>
    <div class="block-news-filtered-wrapper">
      <UtilRouteLink
        v-for="item in news"
        :key="item.uuid"
        :link="item"
        class="block-news-filtered__teaser block-news-filtered--mini"
      >
        <div class="block-news-filtered__imgWrapper">
          <AtomImage
            v-if="item.content?.image_video?.filename"
            class="block-news-filtered__img"
            :image="item.content.image_video"
            provider="storyblok"
            height="410px"
            width="410px"
          />
          <div
            v-if="item.tagColors.backgroundColor"
            class="block-news-filtered__tag"
            :style="`background-color: ${item.tagColors.backgroundColor}; color: ${item.tagColors.color}`"
          >
            <span>{{ item.teaserTag }}</span>
          </div>
          <div
            v-if="item.content.activate_video_teaser"
            class="block-news-filtered__video-tag"
          >
            <IonIcon class="block_teaser_video__tag-icon" icon-name="video" />
            <span>{{ item.content.teaser_video }}</span>
          </div>
        </div>
        <div class="block-news-filtered__wrapper">
          <div
            v-if="item && item.content.department"
            class="block-news-filtered__date"
            :style="`color: ${item.dateColor}`"
          >
            {{ dayjs(item.first_published_at).locale(currentLocale).format('DD. MMMM YYYY') }}
            <span> | </span>
            {{ item.content.department.translated_slugs?.find(
              (slug) => slug.lang === currentLocale,
            )?.name || item.content.department.name }}
          </div>
          <div class="block-news-filtered__title">
            {{ trimString(item.content.title, 35) }}
          </div>
          <div class="block-news-filtered__title-large">
            {{ trimString(item.content.title, 400) }}
          </div>
          <div class="block-news-filtered__teaser-large">
            <div class="block-news-filtered__lead">
              <span
                v-if="item.content.type"
                class="block-news-filtered_article"
              >{{ item.content.type.name }} |</span>
              {{ trimString(item.content.lead, 200) }}
            </div>
          </div>
          <ProviderColorSchema :color-schema="item.colorSchema">
            <IonIcon
              class="block-news-filtered__icon"
              icon-name="chevron-right"
            />
          </ProviderColorSchema>
        </div>
      </UtilRouteLink>
    </div>
    <AtomButton
      v-if="showLoadMore"
      class="block-news-filtered__more-button"
      :text="getStoryblokTranslation('general', 'more_articles')"
      :color-schema="isInternational ? 'coral' : 'petrol'"
      @click="loadMore()"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    department: {
        type: String,
        default: '',
    },

    region: {
        type: String,
        default: '',
    },

    isInternational: {
        type: Boolean,
        default: false,
    },

    isNational: {
        type: Boolean,
        default: false,
    },

    isStiftung: {
        type: Boolean,
        default: false,
    },
});

const {
    department,
    region,
    isInternational,
    isNational,
    isStiftung,
} = toRefs(props);

const { dayjs } = useDayJs();
const filterStore = useFilterStore();
const countries = computed(() => filterStore.getCountries);
const departments = computed(() => filterStore.getDepartments);
const themas = computed(() => filterStore.getThemas);
const regions = computed(() => filterStore.getRegions);
const allTags = computed(() => filterStore.getCloudTags);
const textSearch = computed(() => filterStore.getTextSearch);
const typesOfArticle = computed(() => filterStore.getTypesOfArticle);
const dates = computed(() => filterStore.getYears);
const tags = computed(() => filterStore.getTags);
const isFiltered = computed(() => filterStore.getIsFilter);
const isPrefiltered = ref(false);
const currentPage = ref(1);
const pageSize = ref(7);
const storyblokApi = useStoryblokApi();
const showLoadMore = ref(true);
const newsData = ref([]);

// prefilled filter department
if (department.value.length > 0) {
    filterStore.setDepartments(department.value);
    isPrefiltered.value = true;
}

// prefilled filter region
if (region.value.length > 0) {
    filterStore.setRegions(region.value);
    isPrefiltered.value = true;
}

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale ? '' : currentLocale.value;

const internationalDepartmentUUID = '6e8e4951-ab05-4981-80f4-736c9b4c7646';

const setFilters = () => {
    let tagIds = [];
    const suffix = currentLocale.value === defaultLocale || currentLocale.value === 'en' ? '' : `__i18n__${currentLocale.value}`;

    if (textSearch.value.length > 0) {
        allTags.value.forEach((tag) => {
            if (tag.name.toLowerCase().includes(textSearch.value.toLowerCase())) {
                tagIds = [...tagIds, tag.uuid];
            }
        });
    }
    const filterQuery = isInternational.value ? {
        department: {
            in: internationalDepartmentUUID,
        },

        ...(region.value.length === 0 ? {} : {
            region: {
                in: regions.value.toString(),
            },
        }),
        ...(countries.value.length === 0 ? {} : {
            country: {
                in: countries.value.toString(),
            },
        }),
        ...(typesOfArticle.value.length === 0 ? {} : {
            type: {
                in: typesOfArticle.value.toString(),
            },
        }),
        ...(tags.value.length === 0 ? {} : {
            tags: {
                any_in_array: tags.value.toString(),
            },
        }),
        ...(textSearch.value.length === 0 ? {} : {
            __or: [
                {
                    [`title${suffix}`]: {
                        like: `*${textSearch.value}*`,
                    },
                },
                {
                    [`lead${suffix}`]: {
                        like: `*${textSearch.value}*`,
                    },
                },
                {
                    tags: {
                        in_array: tagIds,
                    },
                },
            ],
        }),
        ...(dates.value.length === 0 ? {} : {
            manualPublishedDate: {
                gt_date: `${dates.value.toString()}-01-01`,
                lt_date: `${dates.value.toString()}-12-31`,
            },
        }),
    } : {
        __or: [
            department.value.length === 0 ? {} : {
                department: {
                    in: departments.value.toString(),
                },
            },
            region.value.length === 0 ? {} : {
                region: {
                    in: regions.value.toString(),
                },
            },
            countries.value.length === 0 ? {} : {
                country: {
                    in: countries.value.toString(),
                },
            },
            typesOfArticle.value.length === 0 ? {} : {
                type: {
                    in: typesOfArticle.value.toString(),
                },
            },
            textSearch.value.length === 0 ? {} : {
                [`title${suffix}`]: {
                    like: `*${textSearch.value}*`,
                },
                [`lead${suffix}`]: {
                    like: `*${textSearch.value}*`,
                },
                tags: {
                    in_array: tagIds,
                },
            },

            isArrayNotEmpty(themas.value) && themas.value.some((thema) => thema === 'stiftung') ? {
                isStiftung: {
                    is: 'true',
                },
            } : {},

            isArrayNotEmpty(themas.value) && themas.value.some((thema) => thema === 'schweiz') ? {
                program: {
                    not_like: '',
                },
            } : {},

            isArrayNotEmpty(themas.value) && themas.value.some((thema) => thema === 'international') ? {
                country: {
                    not_like: '',
                },
            } : {},
        ],

        ...isNational.value && departments.value.length !== 0 ? {
            department: {
                not_in: internationalDepartmentUUID,
                in: departments.value.toString(),
            },
        } : {},

        ...isNational.value && departments.value.length === 0 ? {
            department: {
                not_in: internationalDepartmentUUID,
            },
        } : {},

        ...isStiftung.value ? {
            isStiftung: {
                is: 'true',
            },
        } : {},

        ...(dates.value.length === 0 ? {} : {
            manualPublishedDate: {
                gt_date: `${dates.value.toString()}-01-01`,
                lt_date: `${dates.value.toString()}-12-31`,
            },
        }),
    };

    return filterQuery;
};

const route = useRoute();
const currentPath = computed(() => route.path);
const localePath = useLocalePath();
const isNewsOverview = computed(() => currentPath.value === localePath('/news/') || currentPath.value === localePath('/news'));

const {
    data: news,
    error: newsError,
    status: newsStatus,
} = useLazyAsyncData(
    `get-filtered-news-article-files-${getUid()}`,
    async () => storyblokApi.get('cdn/stories/', {
        starts_with: 'news/news-article/',
        version: 'published',
        resolve_links: 'link',
        page: currentPage.value,
        per_page: pageSize.value,
        filter_query: setFilters(),
        sort_by: 'first_published_at:desc',
        resolve_relations: 'News.department,News.type,News.country',
        language,
    }),
    {
        transform: (res) => {
            const { data, headers } = res;
            const newStories = data.stories.map((story) => {
                // Compute tag for each article
                let teaserTag = '';
                let textColor = '';
                let dateColor = '';
                let colorSchema = '';
                const tagColors = {
                    backgroundColor: '',
                    color: '',
                };

                // Compute tag logic for individual articles
                if (isNewsOverview.value) {
                    if (story.content.isStiftung) {
                        tagColors.backgroundColor = 'var(--c-petrol-8)';
                        tagColors.color = 'var(--c-white)';
                        textColor = 'var(--c-petrol-1)';
                        dateColor = 'var(--c-petrol-4)';
                        teaserTag = getStoryblokTranslation('general', 'stiftung');
                    } else if (story.content.country && story.content.country.name) {
                        tagColors.backgroundColor = story.content.department.content.color_11;
                        tagColors.color = story.content.department.content.color_4;
                        textColor = story.content.department.content.color_1;
                        dateColor = story.content.department.content.color_4;
                        colorSchema = story.content.department.content.color_schema;
                        teaserTag = getStoryblokTranslation('general', 'international');
                    } else if (story.content.department && story.content.department.name) {
                        tagColors.backgroundColor = 'var(--c-petrol-11)';
                        tagColors.color = 'var(--c-petrol-4)';
                        textColor = 'var(--c-petrol-1)';
                        dateColor = 'var(--c-petrol-4)';
                        teaserTag = getStoryblokTranslation('general', 'switzerland');
                    }
                } else if (story.content.isStiftung) {
                    tagColors.backgroundColor = 'var(--c-petrol-8)';
                    tagColors.color = 'var(--c-white)';
                    textColor = 'var(--c-petrol-1)';
                    dateColor = 'var(--c-petrol-4)';
                    teaserTag = getStoryblokTranslation('general', 'stiftung');
                } else if (story.content.country && story.content.country.name) {
                    tagColors.backgroundColor = story.content.department.content.color_11;
                    tagColors.color = story.content.department.content.color_4;
                    textColor = story.content.department.content.color_1;
                    dateColor = story.content.department.content.color_4;
                    colorSchema = story.content.department.content.color_schema;
                    teaserTag = story.content.country.name;
                } else if (story.content.department && story.content.department.name) {
                    tagColors.backgroundColor = story.content.department.content.color_11;
                    tagColors.color = story.content.department.content.color_4;
                    textColor = story.content.department.content.color_1;
                    dateColor = story.content.department.content.color_4;
                    colorSchema = story.content.department.content.color_schema;
                    teaserTag = story.content.department.translated_slugs?.find(
                        (slug) => slug.lang === currentLocale.value,
                    )?.name || story.content.department?.name;
                }

                return {
                    ...story,
                    cached_url: story.default_full_slug,
                    story: {
                        alternates: story.translated_slugs,
                    },
                    teaserTag,
                    tagColors,
                    textColor,
                    dateColor,
                    colorSchema,
                };
            });

            newsData.value = [...newsData.value, ...newStories];

            if (currentPage.value === 1) {
                if (newsData.value.length < parseInt(headers?.total, 10)) {
                    showLoadMore.value = true;
                } else {
                    showLoadMore.value = false;
                }
            }

            if (newsData.value.length >= parseInt(headers?.total, 10)) {
                showLoadMore.value = false;
            }

            return newsData.value;
        },
        watch: [filterStore, currentPage],
    },
);

/*
    Error handling
*/
watch(
    () => newsError.value,
    (error) => {
        if (error) {
            useSentryError(error, {
                starts_with: 'news/news-article/',
                version: 'published',
                page: currentPage.value,
                per_page: pageSize.value,
                filter_query: setFilters(),
                sort_by: 'first_published_at:desc',
                first_published_at_gt: !dates.value || dates.value.length === 0 ? null : `${dates.value.toString()}-01-01 00:01`,
                first_published_at_lt: !dates.value || dates.value.length === 0 ? null : `${dates.value.toString()}-12-31 23:58`,
                resolve_relations: 'News.department,News.type,News.country',
                language,
            });
        }
    },
);
const trimString = (str, maxlength) => {
    if (str?.length > maxlength) {
        const trimmedstr = `${str.substring(0, maxlength)}...`;
        return trimmedstr;
    }

    return str;
};

const loadMore = () => {
    currentPage.value += 1;
    pageSize.value = 6;
};

watch(filterStore, () => {
    currentPage.value = 1;
    newsData.value = [];
});

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.block-news-filtered {
    @include fluid('margin-bottom', 30px, 50px);
    @include fluid('margin-top', 30px, 50px);

    display: flex;
    flex-direction: column;
}

.block-news-filtered__img {
    display: block;
    height: 300px;
    background-position: center center;
    object-fit: cover;
    transition: all 0.2s ease-out;
}

.block-news-filtered-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobile {
        word-break: break-word;
    }
}

.block-news-filtered-wrapper::after {
    width: 30.4%;
    content: '';
}

.block-news-filtered__noArticles {
    margin-bottom: 40px;
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-news-filtered__teaser:first-of-type {
    position: relative;
    margin-bottom: 45px;

    &:hover {
        .block-news-filtered__img {
            transform: scale(1.05);
            transition: all 0.2s ease-out;
        }
        .block_teaser_video__tag-icon {
            transform: none;
        }
    }

    &.block-news-filtered--mini {
        display: flex;
        width: 100%;
        flex-direction: row;

        .block-news-filtered__img {
            width: 400px;
            height: 100%;

            @include mobile {
                width: 100%;
            }
        }

        .block-news-filtered__wrapper {
            display: flex;
            flex-basis: 55%;
            margin-left: 52px;

            .block-news-filtered__title-large {
                display: block;
                margin-top: 15px;
                color: v-bind(colorText);
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h2);
                line-height: var(--l-height--h2);
            }

            .block-news-filtered__title {
                display: none;
            }

            .block-news-filtered__teaser-large {
                display: block;
                margin-bottom: 20px;
            }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        @include tablet-portrait {
            flex-direction: column;
        }
    }

    @include tablet {
        grid-column: 3 / span 14;
    }

    @include mobile {
        grid-column: 1 / span 3;
    }
}

.block-news-filtered__teaser {
    position: relative;
    display: flex;
    margin-bottom: 45px;

    &:hover {
        .block-news-filtered__img {
            transform: scale(1.05);
        }
        .block_teaser_video__tag-icon {
            transform: none;
        }
        .block-news-filtered__icon {
            transform: translateX(10px);
        }
    }

    &.block-news-filtered--mini {
        width: 30.4%;
        flex-direction: column;

        .block-news-filtered__img {
            @include mobile {
                width: 100%;
            }
        }

        .block-news-filtered__wrapper {
            display: flex;
            flex-direction: column;

            .block-news-filtered__date {
                margin-top: 10.87px;
            }

            .block-news-filtered__title {
                @include line-clamp(2);

                display: -webkit-box;
                overflow: hidden;
                margin-top: 10px;
                margin-bottom: 20px;
                -webkit-box-orient: vertical;
                color: v-bind(colorText);
                font-family: var(--f-family--bold);
                font-size: var(--f-size--h4);
                line-height: var(--l-height--h4);
                text-overflow: ellipsis;
            }

            .block-news-filtered__title-large {
                display: none;
            }

            .block-news-filtered__teaser-large {
                display: none;
            }
        }

        @include tablet-portrait {
            width: 47%;
        }

        @include mobile {
            width: 100%;
        }
    }

    @include tablet {
        grid-column: 3 / span 14;
    }

    @include mobile {
        grid-column: 1 / span 3;
    }
}

.block-news-filtered__imgWrapper {
    position: relative;
    overflow: hidden;
    width: fit-content;

    .block-news-filtered__tag {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        color: var(--c-white);
        font-family: var(--f-family--bold);
        font-size: var(--f-size--tag);
        line-height: var(--l-height--tag);
    }

    .block-news-filtered__video-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 92px;
        height: 48px;
        align-items: center;
        justify-content: center;
        background: rgb(182,182,182, 0.9);
        color: var(--c-white);
        font-family: var(--f-family--bold);
        font-size: var(--f-size--tag);
        gap: 5px;
        line-height: var(--l-height--tag);

        span {
            padding-top: 2px;
        }
    }
}

.block-news-filtered__date {
    margin-top: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    word-wrap: unset;

}

.block-news-filtered_article {
    color: v-bind(colorText);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
}

.block-news-filtered__icon {
    color: var(--c-primary);
    font-size: 14px;
    transition: all 0.2s ease-out;

    @include tablet-portrait {
        position: relative;
    }
}

.block-news-filtered__more-button {
    max-width: 240px;
    align-self: start;
    margin-bottom: 50px;

    &.isHidden {
        display: none;
    }
}

.block-news-filtered__lead {
    color: v-bind(colorText);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
