<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-slider-enumeration">
        <BlockSliderEnumeration
          :items="mappedItems"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const storyblokApi = useStoryblokApi();

// map data from storyblok to the format we need
const mappedItems = computed(() => props.items.map((item) => ({
    pretitle: item.pretitle,
    title: item.title,
    text: storyblokApi?.richTextResolver.render(item.text),
})));

</script>

<style lang="scss" scoped>
.storyblok-slider-enumeration {
    @include grid-columns(5,10);
    @include fluid('margin-bottom', 30px, 50px);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include tablet {
        @include grid-columns(1,18);
    }
}
</style>
