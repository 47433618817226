<template>
  <div class="storyblok-form-contact">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-form-contact">
          <BlockFormContact
            :title="title"
            :text="text"
            :button-text="buttonText"
            :fieldsets="fieldsets"
            :people="computedPeople"
            :defaultmail="defaultmail"
            :send-to-people="sendToPeople"
            :is-contact-media="isContactMedia"
            :is-logo="isLogo"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    buttonText: {
        type: String,
        default: '',
    },

    fieldsets: {
        type: Array,
        default: () => [],
    },

    people: {
        type: Array,
        default: () => [],
    },

    defaultmail: {
        type: String,
        default: '',
    },

    sendToPeople: {
        type: Boolean,
        default: false,
    },

    isContactMedia: {
        type: Boolean,
        default: false,
    },

    isLogo: {
        type: Boolean,
        default: false,
    },
});

const computedPeople = computed(() => props.people);
</script>

<style lang="scss" scoped>
.storyblok-form-contact {
    @include grid-columns(1,18);
    @include fluid('margin-bottom', 30px, 50px);
}
</style>
