<template>
  <div
    :style="`
    --color-text: ${colorText};
    --color-text-hover: ${colorTextHover};
    --color-icon: ${colorIcon};
  `"
    class="block-breadcrumbs"
  >
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="isArrayNotEmpty(breadcrumbs)" class="block-breadcrumbs__wrapper is-medium-wide">
          <div class="block-breadcrumbs__content">
            <nav class="block-breadcrumbs__crumbs">
              <ul class="block-breadcrumbs__items">
                <li
                  v-for="(crumb, i)
                    in breadcrumbs"
                  :key="i"
                  class="block-breadcrumbs__crumb-item"
                >
                  <nuxt-link :to="crumb.slugs[locale] || crum.slugs.de">
                    <span
                      class="block-breadcrumbs__link"
                      :class="{
                        active: i === breadcrumbs.length - 1,
                        first: i === 0,
                      }"
                    >
                      {{ crumb.names[locale] || crumb.names.de }}
                    </span>
                  </nuxt-link>
                  <span
                    class="block-breadcrumbs__separator"
                    :class="{
                      hide: i === breadcrumbs.length - 1,
                    }"
                  />
                </li>
              </ul>
            </nav>
          </div>
          <nuxt-link
            v-if="isArrayNotEmpty(breadcrumbs) && breadcrumbs.length > 1"
            :to="breadcrumbs[breadcrumbs.length - 2].slugs[locale]
              || breadcrumbs[breadcrumbs.length - 2].slugs.de"
          >
            <div class="block-breadcrumbs__back desktop">
              <IonIcon icon-name="chevron-left" class="block-breadcrumbs__icon" />
              <span
                class="block-breadcrumbs__back-title"
              > {{ getStoryblokTranslation('general', 'back_to') }}</span>
            </div>
          </nuxt-link>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
    <nuxt-link
      v-if="isArrayNotEmpty(breadcrumbs) && breadcrumbs.length > 1"
      :to="breadcrumbs[breadcrumbs.length - 2].slugs[locale]
        || breadcrumbs[breadcrumbs.length - 2].slugs.de"
    >
      <div class="block-breadcrumbs__back mobile">
        <IonIcon icon-name="chevron-left" class="block-breadcrumbs__icon" />
        <span
          class="block-breadcrumbs__back-title"
        > {{ getStoryblokTranslation('general', 'back_to') }}</span>
      </div>
    </nuxt-link>
  </div>
</template>

<script setup>
const { locale } = useI18n();
const storyblokApi = useStoryblokApi();

const colorTopLevel = inject('colorSchema');
const colorText = computed(() => (colorTopLevel.value
    ? `var(--c-${colorTopLevel.value}-1)`
    : 'var(--c-transparent)'));

const colorTextHover = computed(() => (colorTopLevel.value
    ? `var(--c-${colorTopLevel.value}-3)`
    : 'var(--c-transparent)'));

const colorIcon = computed(() => (colorTopLevel.value
    ? `var(--c-${colorTopLevel.value}-4)`
    : 'var(--c-transparent)'));

const storyblokContentStore = useStoryblokContentStore();
const defaultSlug = computed(() => storyblokContentStore.getDefaultSlug);

const trimmedDefaultSlug = computed(() => defaultSlug.value?.replace(/^\//, ''));
const splittedDefaultPath = trimmedDefaultSlug.value?.split('/').filter(Boolean);
const defaultSlugStartsWith = isArrayNotEmpty(splittedDefaultPath) ? splittedDefaultPath[0] : null;

const { data: breadcrumbs, error } = useLazyAsyncData(
    `breadcrumbs-${getUid()}`,
    () => storyblokApi.get(
        'cdn/links',
        {
            starts_with: defaultSlugStartsWith,
            per_page: 200,
        },
    ),
    {
        transform: (bcResponse) => {
            const breadCrumbs = [];
            const { links } = bcResponse.data;

            if (!splittedDefaultPath) {
                return breadCrumbs;
            }

            splittedDefaultPath.forEach((_, index) => {
                const currentPath = splittedDefaultPath.slice(0, index + 1).join('/');
                const foundLink = Object.values(links).find(
                    (link) => link.slug === currentPath,
                );

                if (foundLink) {
                    const names = foundLink.alternates.reduce((acc, item) => {
                        acc[item.lang] = item.name;
                        return acc;
                    }, { de: foundLink.name });

                    const slugs = foundLink.alternates.reduce((acc, item) => {
                        acc[item.lang] = `/${item.lang}/${item.translated_slug}`;
                        return acc;
                    }, { de: `/${foundLink.slug}` });

                    breadCrumbs.push({
                        names,
                        slugs,
                    });
                }
            });
            return breadCrumbs;
        },
    },
);

watch(() => error.value, (newValue) => {
    if (newValue) {
        useSentryError(
            newValue,
            {
                starts_with: defaultSlugStartsWith,
                per_page: 200,
            },
        );
    }
}, { immediate: true });
</script>

<style lang="scss" scoped>
.block-breadcrumbs {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 66px;

    @media screen and (max-width: 1440px) {
        height: 126px;
    }
}
.block-breadcrumbs__wrapper {
    @include default-content-columns;

    width: 100%;
    padding: 40px 0;

    @media screen and (max-width: 1440px) {
        position: absolute;
        left: 0;
        display: inline-flex;
        display: flex;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        @include remove-scrollbars;
    }
}

.block-breadcrumbs__content {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    padding-left: 0;
    color: var(--color-text);

    @media screen and (max-width: 1440px) {
        padding-left: var(--page-padding);
    }
}

.block-breadcrumbs__items {
    display: flex;
}

.block-breadcrumbs__crumb-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text);
    font-family: var(--f-family--regular);
    font-size: 28px;
    line-height: 38px;

    .active  {
        font-family: var(--f-family--black);

        @include mobile {
            padding-right: 30px;
        }
    }

    &:hover {
        color: var(--color-text-hover);
    }

    @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 22px;
    }
}

.block-breadcrumbs__link {
    padding: 6px 12px;
    white-space: nowrap;

    &.first {
        padding-left: 0px;
    }
}

.block-breadcrumbs__separator {
    width: 2px;
    height: 38px;
    margin: 0 20px;
    background: var(--color-text);

    &.hide {
        display: none;
    }

    @media screen and (max-width: 1440px) {
        width: 1px;
        height: 29px;
        margin: 0 10px;
    }
}

.block-breadcrumbs__back {
    display: flex;
    align-items: center;
    color: var(--color-icon);
    font-family: var(--f-family--thin);

    &.mobile {
        display: none;
    }

    @media screen and (max-width: 1440px) {
        position: absolute;
        z-index: 5;
        top: 92px;
        bottom: 30px;
        padding-left: var(--page-padding);

        &.desktop {
            display: none;
        }
        &.mobile {
            display: flex;
        }
    }
}

.block-breadcrumbs__icon {
    path {
        stroke: var(--color-icon);
    }
}

.block-breadcrumbs__back-title {
    padding-left: 10px;
    font-size: 17px;
    line-height: 27px;

    @media screen and (max-width: 1440px) {
        font-size: 14px;
        line-height: 22px;
    }
}
</style>
