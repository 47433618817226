<template>
  <div class="block-filtered-projects">
    <div
      v-if="isArrayEmpty(projects)"
      class="block-filter-activities__noArticles"
    >
      <AtomRichtext :html="parsedHtml" />
    </div>
    <AtomItemAccordion
      v-for="project in projects"
      :key="project.uuid"
      :title-project="project.name"
      :canton="project.content?.canton?.content?.code"
      :t-c-o2e="project.content?.tCO2e"
      class="block-filtered-projects__accordion-content"
    >
      <template #content>
        <div class="block-filtered-projects__accordion-flyout">
          <div v-if="project.content?.text" class="block-filtered-projects__description">
            {{ project.content?.text }}
          </div>
          <BlockProject
            v-if="project.content"
            :items=" project.content?.infoBlock"
            :image="project.content?.slider"
            image-provider="storyblok"
          />
        </div>
      </template>
    </AtomItemAccordion>
  </div>
</template>

<script setup>
defineProps({
    projects: {
        type: Array,
        default: () => [],
    },
});

const { richTextResolver } = useStoryblokApi();
const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const parsedHtml = computed(() => richTextResolver.render(getStoryblokTranslation('general', 'no_projects', true)));
</script>
<style lang="scss" scoped>
.block-filter-activities__noArticles {
    margin-top: 40px;
    margin-bottom: 40px;
    color: v-bind(color1);
    font-family: var(--f-family--thin);
    grid-column-end: span 2;

}
.block-filtered-projects {
    font-family: var(--f-family--thin);
    row-gap: var(--grid-row--gap);
}
.block-filtered-projects__accordion-content {
    background-color: var(--c-background);
}
.block-filtered-projects__accordion-flyout {
    padding: 28px 30px;
    @include tablet {
        padding: 20px;
    }
}
.block-filtered-projects__description {
    padding-bottom: 30px;
    color: v-bind(color1);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-filtered-projects__card {
    grid-column-end: span 1;
    @include mobile {
        grid-column-start: span 2;
    }
}
.block-filtered-projects__img {
    display: block;
    height: 100%;
    background-position: center center;
    object-fit: cover;
    transition: all 0.2s ease-out;
    img {
        min-height: 419px;
        @include mobile {
            min-height: 204px;
        }
    }
}

.block-filtered-projects__icon {
    width: 22px;
    height: 22px;
    color: v-bind(colorText1);
    transition: all 0.2s ease-out;
    path {
        stroke: v-bind(colorText1);
    }

    @include tablet-portrait {
        position: relative;
    }
}
.block-filtered-projects__content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.block-filtered-projects__text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0px 22px 40px;
    gap: 15px;

    @include mobile {
        word-break: break-word;
    }
}

.block-filtered-projects__title {
    color: v-bind(colorText1);
    font-size: var(--f-size--programm-title);
    line-height: var(--l-height--programm-title);
    @include mobile {
        font-family: var(--f-family--regular);
    }

}
</style>
