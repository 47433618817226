<template>
  <div class="storyblok-teaser-programm is-medium-wide">
    <div v-show="!isTablet" class="storyblok-teaser-programm__wrapper is-extra-wide">
      <BlockItemTeaserProgramm
        v-for="(item) in items"
        :key="item._uid"
        class="storyblok-teaser-programm__card"
        v-bind="item"
      />
    </div>
    <div v-show="isTablet" class="storyblok-teaser-programm__wrapper is-extra-wide">
      <ClientOnly>
        <Swiper
          slides-per-view="auto"
          :space-between="20"
          :grab-cursor="true"
          class="storyblok-teaser-programm__swiper"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="(item, index) in items"
            :key="item._uid"
            class="storyblok-teaser-programm__swiper-card"
          >
            <BlockItemTeaserProgrammMobile
              class="storyblok-teaser-programm__card"
              :index-prop="activeIndex === index"
              v-bind="{ ...item }"
            />
          </SwiperSlide>
          <SwiperSlide class="storyblok-teaser-programm__swiper-card" />
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const clientStore = useClientDeviceStore();
const isTablet = computed(() => (clientStore.isTablet));
const { Swiper, SwiperSlide } = useSwiperjs();

const swiperController = ref(null);

const onSwiper = (e) => {
    swiperController.value = e;
};
const activeIndex = computed(() => swiperController.value?.realIndex);
</script>

<style lang="scss" scoped>
.storyblok-teaser-programm {
    position: relative;
    background: var(--c-background);
}

.storyblok-teaser-programm__wrapper {
    @include fluid('margin-bottom', 50px, 80px);
    @include fluid('margin-top', 20px, 90px);
    @include fluid('gap', 20px, 40px);

    display: flex;
}

.storyblok-teaser-programm__swiper {
    .storyblok-teaser-programm__swiper-card {
        max-width: 450px;

        @include mobile {
            max-width: 270px;
        }
    }
}
</style>
