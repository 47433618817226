<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-teaser-card is-narrow" :class="{ 'is-big-grid': isBigGrid }">
        <BlockTeaserCard
          :items="mappedItems"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    isBigGrid: {
        type: Boolean,
        default: false,
    },
});

const mappedItems = computed(() => props.items.map((item) => ({
    pretitle: item.pretitle,
    title: item.title,
    text: item.text,
    link_label: item.link_label,
    link: item.link,
    image: {
        ...item.image,
        provider: 'storyblok',
    },
    country: item.country,
})));

</script>

<style lang="scss" scoped>
.storyblok-teaser-card {
    @include default-content-columns;
    @include fluid('margin-bottom', 20px, 40px);

    &.is-big-grid {
        @include grid-columns(1, 18);
    }
}
</style>
