<template>
  <div class="block-form-newsletter">
    <div class="block-form-newsletter__content">
      <div class="block-form-contact__form">
        <AtomHeading
          :text="title"
          class="block-form-contact__form-title"
          html-tag="h3"
          font-size="h3"
        />
        <AtomRichtext :html="text" class="block-form-contact__form-text" />
        <BlockFormController
          :fieldsets="fields"
          :button-text="btnText"
          @on-submit="onSubmit"
        />
      </div>

      <transition name="fade-in">
        <div
          v-if="statusMessage"
          class="block-form-contant__message"
        >
          <AtomRichtext :html="`<p>${statusMessage}</p>`" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
const { locale: currentLocale } = useI18n();
const language = currentLocale.value;
const localePath = useLocalePath();

const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    btnText: {
        type: String,
        default: '',
    },

    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },
});

const statusMessage = ref(null);
const onSubmit = async (data) => {
    try {
        await useFetch('/api/mailchimp_signup', {
            method: 'POST',
            body: { ...data, language },
        });

        statusMessage.value = props.successMessage;

        return data;
    } catch (e) {
        statusMessage.value = props.errorMessage;
        useSentryError(e);
    } finally {
        window.setTimeout(() => {
            statusMessage.value = null;
            navigateTo(localePath('/newsletter-bestaetigung'));
        }, 1000);
    }

    return false;
};
</script>

<style lang="scss" scoped>
.block-form-newsletter {
    position: relative;
    max-width: 960px;
    height: auto;
    max-height: calc(100% - 2 * var(--grid-gutter));
    padding: 90px;
    margin:  var(--grid-gutter)  var(--page-padding);
    margin-top: var(--grid-gutter);
    margin-bottom: var(--grid-gutter);
    background: var(--c-white);
    overflow-y: auto;

    @include mobile {
        flex: 1;
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
    }
}

.block-form-newsletter__content {
    @include grid-columns(2,16);
    @include fluid('margin-bottom', 30px, 50px);

}

.block-form-contact__form-title,
.block-form-contact__form-text {
    padding-bottom: 30px;
    @include mobile {
        padding-bottom: 20px;
    }
}

.block-form-contant__message {
    margin-top: 40px;
}
</style>
