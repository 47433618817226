<template>
  <div class="block-item-form-calculator">
    <form v-if="data.fields.length > 0" class="block-item-form-calculator__controller" method="post" @set-input="sendData()" @submit.prevent="onSubmit">
      <UtilRendererForm
        :data="data.fields"
        :color="colorDark"
        @set-input="sendData"
      />
      <div v-if="data?.btns" class="block-item-form-calculator__btns">
        <AtomButton
          v-for="btn of data?.btns"
          :key="btn._uid"
          class="block-news-filtered__more-button"
          :text="btn?.linkLabel"
          type="submit"
          :color-code="btnColor"
          :color-code-hover="btnHoverColor"
          @click="sendEmail(submitData)"
        />
      </div>
    </form>
  </div>
</template>

<script setup>

const submitData = ref({});
const props = defineProps({
    pretitle: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: '',
    },
    content: {
        type: Array,
        default: () => [],
    },
    colorDark: {
        type: String,
        default: '',
    },
    data: {
        type: Object,
        default: () => {},
    },
    btns: {
        type: Array,
        default: () => [],
    },

    templateType: {
        type: String,
        default: '',
    },
    btnColor: {
        type: String,
        default: '',
    },
    btnHoverColor: {
        type: String,
        default: '',
    },
});
const calculatorStore = useCalculatorStore();
const templateT = computed(() => props.templateType);
const calcData = computed(() => calculatorStore.getCalculatedData);
const requestBody = computed(() => calculatorStore.getRequestBody);
const nextSteps2Data = computed(() => calculatorStore.getNextSteps2);
const coolingSystemData = computed(() => calculatorStore.getRefrigerationSystem);
const { locale } = useI18n();

const sendData = (data) => {
    if (data.name && data.value) {
        submitData.value[data.name] = data.value;
    }
};

const onSubmit = async () => {
    emit('on-submit', submitData.value);
};
const coolingSystemsTemplateVariant = () => {
    switch (coolingSystemData.value.modul) {
    case 'Vorzeitiger Ersatz':
        return 'KM1';
    case 'Kältemittelwechsel':
        return 'KM2';
    case 'Kleine CO2-Anlagen':
        return 'KM3';
    default:
        return '';
    }
};

const sendEmail = async (data) => {
    if (submitData.value.email) {
        try {
            await useFetch('/api/send_calc_result_mail', {
                method: 'POST',
                body: {
                    coolingSystemsTemplateVersion: coolingSystemsTemplateVariant(),
                    templateType: templateT.value,
                    ...calcData.value,
                    language: locale.value,
                    ...submitData.value,
                    ...requestBody.value,
                },
            });

            return data;
        } catch (e) {
            useSentryError(e);
        } finally {
            calculatorStore.setNextSteps(nextSteps2Data.value);
        }
    }

    return false;
};

</script>

<style lang="scss" scoped>
.block-item-form-calculator {
    grid-column-end: span 2;

}
.block-item-form-calculator__btns {
    padding-top: 18px;
    grid-column-end: span 2;
    @include mobile {
        display: flex;
        justify-content: flex-end;
        padding-top: 43px;
    }

}
.block-item-form-calculator__controller {
    display: grid;
    column-gap: 36px;
    grid-template-columns: repeat(2, 1fr);
}

</style>
