<template>
  <div
    class="navigation-card"
    @click="emitFunction"
    @keypress="emitFunction"
  >
    <AtomImage
      v-if="image?.filename"
      class="navigation-card__image"
      :image="image"
      provider="storyblok"
      height="360px"
      width="360px"
    />
    <div class="navigation-card__content">
      <div v-if="title" class="navigation-card__heading">
        <h4 v-if="title" class="navigation-card__title">
          {{ title }}
        </h4>
        <IonIcon class="navigation-card__icon" icon-name="chevron-right" />
      </div>
      <p v-if="text" class="navigation-card__text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
        default: '',
    },
    color: {
        type: String,
        default: '',
    },
    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
    icon: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
    iconSmall: {
        type: String,
        default: 'house',
    },
});

const emit = defineEmits(['passItemsData']);
const emitFunction = () => {
    emit('passItemsData', {
        title: props.title,
        items: props.items,
        icon: props.icon,
        color: props.color,
        iconSmall: props.iconSmall,

    });
};

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.color
    ? `var(--c-${props.color}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));
const colorText = computed(() => (props.color
    ? `var(--c-${props.color}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));
const colorIcon = computed(() => (props.color
    ? `var(--c-${props.color}-4)`
    : `var(--c-${colorTopLevel.value}-4)`));

</script>
  <style lang="scss">
.navigation-card {
    background: v-bind(colorBackground);
    cursor: pointer;
    grid-column-end: span 1;
}
.navigation-card__heading {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    gap: 10px;
}
.navigation-card__icon  {

    width: 22px;
    height: 22px;
    path {
        stroke: v-bind(colorIcon);
    }

}
.navigation-card__image {
    aspect-ratio: 1/1;
}
.navigation-card__content {
    display: flex;
    flex-direction: column;
    padding: 20px 40px 40px 40px;
    color: v-bind(colorText);

    @include tablet {
        padding: 20px;
    }
}
.navigation-card__text {
    @include line-clamp(3);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    @include tablet {
        margin-bottom: 20px;
    }
}

.navigation-card__title {
    font-family: var(--f-family--black);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
    @include word-break;
}

  </style>
