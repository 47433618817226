<template>
  <div class="atom-teaser-card">
    <AtomImage
      v-if="item.image?.filename"
      :image="item.image"
      class="atom-teaser-card__image"
      :provider="item.image.provider"
      height="420px"
      width="560px"
    />
    <div class="atom-teaser-card__inner">
      <div v-if="item.pretitle" class="atom-teaser-card__pretitle">
        {{ item.pretitle }}
      </div>
      <AtomHeading
        :text="item.title"
        class="atom-teaser-card__title"
        html-tag="h3"
        font-size="h3"
      />
      <AtomRichtext
        :html="item.text"
        class="atom-teaser-card__lead"
      />

      <AtomLink
        :link="item.link"
        :text="item.link_label"
        class="atom-teaser-card__link"
        icon="chevron-right"
        @click="item.country ? setFilter(item.country) : null"
        @keypress="item.country ? setFilter(item.country) : null"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    item: {
        type: Object,
        default: () => {},
    },
});

/*
  Set filter if country is selected
*/
const filterStore = useFilterStore();

const setFilter = (country) => {
    filterStore.setPrefilteredCountry(country);
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => (
    colorTopLevel.value === 'petrol'
        ? 'var(--c-petrol-1)'
        : `var(--c-${colorTopLevel.value}-1)`
));
</script>

<style lang="scss" scoped>
.atom-teaser-card {
    grid-column: auto/span 9;
}

.atom-teaser-card__image {
    width: 100%;
    margin-bottom: 15px;
    aspect-ratio: 4/3;
}

.atom-teaser-card__inner {
    padding: 0 40px;
}

.atom-teaser-card__pretitle {
    margin-bottom: 10px;
    color: v-bind(color1);
    font-family: var(--f-family--thin);
}

.atom-teaser-card__title {
    margin-bottom: 30px;
}

.atom-teaser-card__lead {
    margin-bottom: 20px;
}
</style>
