<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-teaser-curated">
        <BlockTeaserCurated
          v-if="isArrayNotEmpty(mappedItems)"
          :items="mappedItems"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const { locale: currentLocale } = useI18n();

/*
    Mapping of items
*/
const mappedItems = props.items.map((item) => ({
    article: {
        title: item.article?.content?.title,
        lead: item.article?.content?.lead,
        cached_url: item.article.default_full_slug,
        story: {
            alternates: item.article.translated_slugs,
        },
        date: item.article?.content?.manualPublishedDate || item.article.first_published_at,
        type: item.article?.content?.type?.name,
        department: {
            name: item.article.content.department?.translated_slugs?.find(
                (slug) => slug.lang === currentLocale.value,
            )?.name || item.article?.content?.department?.name,
            color_3: item.article?.content?.department?.content.color_3,
        },
        image: {
            ...item.article?.content?.image_video,
            provider: 'storyblok',
        },
    },
    image_position: item.image_position,
    size: item.size,
}));
</script>

<style lang="scss" scoped>
.storyblok-teaser-curated {
    @include grid-columns(4, 12);
    @include fluid('margin-bottom', 50px, 80px);
}
</style>
