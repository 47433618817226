import mapboxgl from 'mapbox-gl';

export default (mapRef, settings, markers) => {
    useHead({
        link: [
            { rel: 'preconnect', content: 'https:///api.tiles.mapbox.com' },
            { rel: 'stylesheet', type: 'text/css', href: 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.css' },
        ],
        script: [
            { src: 'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js', async: true },
        ],
    });

    const mapStore = useMapStore();
    const activeCountryIndex = computed(() => mapStore.getActiveCountryIndex);
    const isOpen = computed(() => mapStore.getIsOpen);

    const token = getStoryblokSetting(
        'map',
        'mapbox_token',
    );

    const style = getStoryblokSetting(
        'map',
        'mapbox_style_url',
    );

    mapboxgl.accessToken = token.value;

    const alwaysSettings = {
        container: mapRef.value,
        attributionControl: false,
        performanceMetricsCollection: false,
        antialias: true,
        logoPosition: 'bottom-right',
    };

    const customSettings = {
        style: style.value,
        center: [
            parseFloat(settings.longitude),
            parseFloat(settings.latitude),
        ],
        zoom: parseFloat(settings.zoom, 10),
        minZoom: parseInt(settings.minZoom, 10),
        maxZoom: parseInt(settings.maxZoom, 10),
    };

    const mapSettings = { ...alwaysSettings, ...customSettings };
    const map = new mapboxgl.Map(mapSettings);

    const places = {
        type: 'FeatureCollection',
        features: [],
    };

    markers.forEach((marker) => {
        places.features.push({
            type: 'Feature',
            properties: {
                description: marker.name,
                color: '#FFFFFF',
                icon: 'circle',
            },
            geometry: {
                type: 'Point',
                coordinates: [
                    marker.content.longitude,
                    marker.content.latitude,
                ],
            },
        });
    });

    // watch active country index
    watch(activeCountryIndex, (index) => {
        if (index === -1) {
            // set layout property text-size to 17
            map.setLayoutProperty('poi-labels', 'text-size', 17);

            // fly to default location
            map.flyTo({
                center: [
                    parseFloat(settings.longitude),
                    parseFloat(settings.latitude),
                ],
                zoom: parseFloat(settings.zoom, 10),
                speed: 0.5,
            });

            return;
        }

        // set layout property text-size
        map.setLayoutProperty('poi-labels', 'text-size', [
            'case',
            ['==', ['get', 'description'], markers[index].name],
            24,
            17,
        ]);

        // fly to marker if drag is not active
        if (map.dragPan.isActive()) {
            return;
        }

        map.flyTo({
            center: [
                parseFloat(markers[index].content.longitude),
                parseFloat(markers[index].content.latitude),
            ],
            zoom: 1,
            speed: 0.5,
        });
    });

    // watch is open to set zoom to 1.5
    watch(isOpen, (value) => {
        if (!value) {
            map.flyTo({
                zoom: 1.5,
                speed: 0.5,
            });
        }
    });

    map.on('load', () => {
        // Add a GeoJSON source containing place coordinates and information.
        map.addSource('places', {
            type: 'geojson',
            data: places,
        });

        map.addLayer({
            id: 'poi-labels',
            type: 'symbol',
            source: 'places',
            layout: {
                'text-field': ['get', 'description'],
                'text-variable-anchor': ['bottom-left'],
                'text-radial-offset': 0.5,
                'icon-image': ['get', 'icon'],
                'text-size': 17,
                'text-allow-overlap': true,
                'icon-allow-overlap': true,
            },
            paint: {
                'text-color': ['get', 'color'],
            },
        });
    });

    // on click fly to marker
    map.on('click', 'poi-labels', (e) => {
        map.flyTo({
            center: e.features[0].geometry.coordinates,
            zoom: 1,
            speed: 0.5,
        });

        // return index of marker
        const index = markers.findIndex(
            (marker) => marker.name === e.features[0].properties.description,
        );

        // set active country index
        mapStore.setActiveCountryIndex(index);

        // set open to true
        mapStore.setIsOpen(true);
    });

    // add cursor pointer to markers on hover
    map.on('mouseenter', 'poi-labels', () => {
        map.getCanvas().style.cursor = 'pointer';
    });

    // remove cursor pointer on mouse leave
    map.on('mouseleave', 'poi-labels', () => {
        map.getCanvas().style.cursor = '';
    });

    // on drag get markers in bounds and sort by distance to center
    map.on('drag', () => {
        if (isOpen.value) {
            // get center of map
            const center = map.getCenter();

            // set a square around the center without turf
            const bounds = [
                center.lng - 10,
                center.lat - 10,
                center.lng + 10,
                center.lat + 10,
            ];

            // get all markers in bounds
            const markersInBounds = markers.filter((marker) => {
                const lng = parseFloat(marker.content.longitude);
                const lat = parseFloat(marker.content.latitude);

                return lng >= bounds[0]
                    && lat >= bounds[1]
                    && lng <= bounds[2]
                    && lat <= bounds[3];
            });

            // sort markers by distance to center
            markersInBounds.sort((a, b) => {
                const aLng = parseFloat(a.content.longitude);
                const aLat = parseFloat(a.content.latitude);
                const bLng = parseFloat(b.content.longitude);
                const bLat = parseFloat(b.content.latitude);

                const aDistance = Math.sqrt(
                    (aLng - center.lng) ** 2 + (aLat - center.lat) ** 2,
                );
                const bDistance = Math.sqrt(
                    (bLng - center.lng) ** 2 + (bLat - center.lat) ** 2,
                );

                return aDistance - bDistance;
            });

            if (!markersInBounds.length) {
                return;
            }

            // get index of first marker in bounds
            const index = markers.findIndex(
                (marker) => marker.name === markersInBounds[0].name,
            );

            // set active country index
            mapStore.setActiveCountryIndex(index);
        }
    });

    // custom zoom controls
    const zoomIn = document.querySelector('.block-map-international__zoom-in');
    if (!zoomIn) return;
    zoomIn.addEventListener('click', () => {
        map.zoomIn();
    });

    const zoomOut = document.querySelector('.block-map-international__zoom-out');
    if (!zoomOut) return;
    zoomOut.addEventListener('click', () => {
        map.zoomOut();
    });

    // disable map rotation using right click + drag (pitch/tilt)
    map.dragRotate.disable();
};
