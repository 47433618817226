<template>
  <section class="block-participation-process-slider">
    <Swiper
      :modules="modules"
      slides-per-view="auto"
      :pagination="true"
      :grab-cursor="false"
      :navigation="{
        nextEl: '.block-participation-process-slider__controls--next',
        prevEl: '.block-participation-process-slider__controls--prev',
      }"
      @slide-change="onSlideChange"
      @swiper="onSwiper($event)"
    >
      <SwiperSlide
        v-for="(slide) in block.slides"
        :key="slide.stageId"
        ref="slideRef"
        class="block-participation-process-slider__slide"
      >
        <BlockParticipationProcessSliderElement
          :stage="slide.stageId"
          :title="slide.title"
          :actors="slide.actors"
          :text="slide.text"
          :extra-box="slide.extraBox"
        />
      </SwiperSlide>
      <div
        v-show="block.slides[activeSlide + 1]"
        class="block-participation-process-slider__control block-participation-process-slider__controls--next"
      >
        <div class="block-participation-process-slider__controls">
          <span v-if="block.slides[activeSlide + 1]">{{
            block.slides[activeSlide + 1].stageId.replace('-', '.')
          }}</span>
          <IonIcon icon-name="chevron-right" class="block-participation-process-slider__button-icon" />
        </div>
      </div>
      <div
        v-show="block.slides[activeSlide - 1]"
        class="block-participation-process-slider__control block-participation-process-slider__controls--prev"
      >
        <div class="block-participation-process-slider__controls">
          <IonIcon icon-name="chevron-left" class="block-participation-process-slider__button-icon" />
          <span v-if="block.slides[activeSlide - 1]">{{
            block.slides[activeSlide - 1].stageId.replace('-', '.')
          }}</span>
        </div>
      </div>
    </Swiper>
  </section>
</template>

<script setup>
const props = defineProps({
    block: {
        type: Object,
        default() {
            return {};
        },
    },
    activeStage: {
        type: String,
        default: '1-0',
    },
});

const { block, activeStage } = toRefs(props);

const {
    Pagination,
    Navigation,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Navigation, Pagination];

const slideRef = ref(null);
const swiper = ref(null);
const activeSlide = ref(0);
const activeSliderStage = ref(block.value.slides[0].stageId);

const emit = defineEmits(['new-target']);

const onSlideChange = (slide) => {
    activeSlide.value = slide.activeIndex + 1;
    activeSliderStage.value = block.value.slides[activeSlide.value - 1].stageId;
    emit('new-target', activeSliderStage.value);
};

const onSwiper = (e) => {
    swiper.value = e;
};

const setActiveSlide = (target) => {
    const id = target.value.substr(target.value.length - 3);
    const activeBlock = block.value.slides.findIndex((slide) => slide.stageId === id);
    swiper.value.slideTo(activeBlock);
    activeSlide.value = activeBlock;
    activeSliderStage.value = block.value.slides[activeSlide.value].stageId;
};

watch(() => activeStage.value, (newValue) => {
    setActiveSlide({ value: newValue });
});

onMounted(() => {
    setActiveSlide(activeStage);
});
</script>

<style lang="scss">
.block-participation-process-slider {
    position: relative;

    .swiper-pagination {
        position: relative;
        margin: 0 auto;

        @include mobile {
            display: none;
        }

        .swiper-pagination-bullet {
            left: -20px !important;
            width: 12px;
            height: 12px;
            margin: 0 10px;
            background: #f3f6f8 0% 0% no-repeat padding-box;
            opacity: 1;
            transform: scale(1);

            &[aria-label='Go to slide 3'],
            &[aria-label='Go to slide 4'],
            &[aria-label='Go to slide 7'],
            &[aria-label='Go to slide 8'],
            &[aria-label='Go to slide 9'],
            &[aria-label='Go to slide 11'],
            &[aria-label='Go to slide 12'],
            &[aria-label='Go to slide 14'],
            &[aria-label='Go to slide 15'],
            &[aria-label='Go to slide 17'],
            &[aria-label='Go to slide 18'],
            &[aria-label='Go to slide 19'] {
                display: none;
            }

            &.swiper-pagination-bullet-active {
                background: var(--c-coral-4) 0% 0% no-repeat padding-box;
            }
        }

        @include mobile-portrait {
            display: none;
        }
    }

    // max-width: calc(100% - 30px);
    margin: 0 auto;
    background: var(--c-white);
    grid-column: 3 / span 14 !important;

    @media (hover: hover) {
        max-width: 100%;
    }

    @include tablet {
        grid-column: 2 / span 16 !important;
    }

    @include mobile {
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        grid-column: 1 / span 3 !important;

    }
}

.block-participation-process-slider__slide {
    padding-top: 70px;
    padding-bottom: 50px;
}

.block-participation-process-slider__control {
    position: absolute;
    z-index: 100;
    bottom: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    background: var(--c-background);
    color: var(--c-coral-4);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;

    &.block-participation-process-slider__controls--next {
        right: 0;
        padding-left: 25px;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;
        transform: translateX(0) translateY(33px);

        @include mobile {
            position: absolute;
            right: 0px;
            bottom: 50px;
        }

    }

    &.block-participation-process-slider__controls--prev {
        padding-right: 15px;
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;
        transform: translateX(0) translateY(33px);

        @include mobile {
            position: absolute;
            bottom: 50px;
            left: 0px;
        }

        @media (hover: hover) {
            transform: translateX(0px) translateY(33px);
        }
    }

    @include tablet {
        top: auto;
        bottom: -40px;
    }

    @include tablet-portrait {
        top: auto;
    }

    @include mobile {
        bottom: -32px;
    }
}

.block-participation-process-slider__controls {
    display: flex;
    align-items: center;

    svg {
        margin-bottom: 4px;

        @include mobile-portrait {
            display: none;
        }
    }
}

.block-participation-process-slider__controls {
    &::after {
        content: none;
    }
}

.swiper-container-horizontal {
    >.swiper-pagination-bullets {
        .swiper-pagination-bullet {
            margin: 0 8px;
        }
    }
}

.block-participation-process-slider__swiper {
    z-index: -1;
}

.block-participation-process-slider__control {
    @include z-index('header');

    position: absolute;
    bottom: 133px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: var(--c-background);
    color: var(--c-coral-1);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;

    &.block-participation-process-slider__controls--next {
        right: 0;
        padding-left: 25px;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;

        .block-participation-process-slider__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &.block-participation-process-slider__controls--prev {
        padding-right: 25px;
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;

        .block-participation-process-slider__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    @include mobile {
        bottom: 20px;
        transform: translateY(0);
    }
}

.block-participation-process-slider__close {
    @include z-index('main');

    position: absolute;
    top: 40px;
    right: 36px;
    cursor: pointer;

    @include mobile {
        top: 20px;
        right: 20px;
    }
}

.block-participation-process-slider__button-icon {
    path {
        stroke: var(--c-coral-4);
    }
}
</style>
