<template>
  <div class="block-calculation">
    <div
      v-if="errorMessage"
      ref="wrapperRef"
      class="block-calculation__error"
      @click="closeError"
      @keypress="closeError"
    >
      <IonIcon
        class="block-calculation__error-close"
        icon-name="close"
      />
      <p class="block-calculation__error-message">
        {{ errorMessage }}
      </p>
    </div>
    <component
      :is="availableComponents[item.component]"
      v-for="item in items"
      :key="item._uid"
      :data="item"
      :html="item.text ? storyblokApi.richTextResolver.render(item?.text) : ''"
      :color-scheme="colorScheme"
      :label="item.label"
      :link="item.link"
    />
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    colorScheme: {
        type: String,
        default: '',
    },
});

const wrapperRef = ref(null);

const {
    data,
    colorScheme,
} = toRefs(props);

const items = ref([]);
const calculatorStore = useCalculatorStore();

const errorMessage = computed(() => calculatorStore.getErrorMessage);

items.value = data.value.content;
calculatorStore.setHeaderInfo(data.value.info);

onMounted(() => {
    calculatorStore.setIsRefrigeratorSection(data.value.is_refrigerator);
});

watch(() => data.value, () => {
    items.value = data.value.content;
    calculatorStore.setIsRefrigeratorSection(data.value.is_refrigerator);
    calculatorStore.setHeaderInfo(data.value.info);
    calculatorStore.setCalculationDetails(data.value.details);
});

const availableComponents = {
    StoryblokTextRichtext: resolveComponent('AtomRichtext'),
    BlockItemCalculationinput: resolveComponent('BlockCalculationItems'),
    BlockItemCalculationResult: resolveComponent('BlockCalculationResults'),
    StoryblokLink: resolveComponent('StoryblokLink'),
};

const storyblokApi = useStoryblokApi();

const closeError = () => {
    calculatorStore.setErrorMessage('');
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    calculatorStore.setErrorMessage('');
});
</script>

<style lang="scss">
.block-calculation {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: 60px 100px 0 100px;
    row-gap: 60px;

    .storyblok-link__wrapper {
        path {
            stroke: v-bind(colorScheme);
        }
    }

    @include mobile {
        padding: 20px 20px 20px 20px;
        row-gap: 10px;
    }
}

.block-calculation__error {
    position: absolute;
    z-index: 100;
    right: 100px;
    bottom: 135px;
    left: 100px;
    padding: 20px;
    background: var(--c-background);
    box-shadow: 5px 5px 15px rgba(60, 47, 35, 0.25);
    color: var(--c-error);

    @include mobile {
        top: -85px;
        right: 20px;
        bottom: unset;
        left: 20px;
        padding-right: 42px;
    }
}

.block-calculation__error-title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    column-gap: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);

    p {
        padding-top: 2px;
    }
}

.block-calculation__error-message {
    padding-left: 32px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);

    &:before {
        padding: 0 10px;
        content: '•';
    }

    @include mobile {
        padding-left: 0;
    }
}

.block-calculation__error-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
</style>
