<template>
  <div class="storyblok-teaser-box">
    <UtilWrapperLayout>
      <UtilGrid>
        <div
          class="storyblok-teaser-box__wrapper"
          :class="image?.filename ? 'has-image' : 'no-image'"
        >
          <AtomImage
            v-if="image?.filename"
            class="storyblok-teaser-box__image"
            :image="image"
            height="450px"
            width="450px"
            provider="storyblok"
          />
          <div class="storyblok-teaser-box__content">
            <div class="storyblok-teaser-box__heading">
              <p v-if="pretitle" class="storyblok-teaser-box__pretitle">
                {{ pretitle }}
              </p>
              <h4 v-if="title" class="storyblok-teaser-box__title">
                {{ title }}
              </h4>
            </div>
            <AtomRichtext
              v-if="text"
              :html="storyblokApi.richTextResolver.render(text)"
              class="storyblok-teaser-box__paragraph"
            />
            <AtomLink
              v-if="btnType === 'link' && link?.cached_url"
              class="storyblok-teaser-box__link"
              v-bind="{ ...linkdata }"
            />

            <UtilRouteLink
              v-if=" btnType === 'btn' && link?.cached_url && linkLabel"
              class="storyblok-teaser-box__link"
              :link="link"
            >
              <AtomButton
                class="storyblok-teaser-box__button"
                variant="inverted"
                :text="linkLabel"
                :color-schema="color || colorTopLevel || 'petrol'"
                html-tag="div"
              />
            </UtilRouteLink>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    pretitle: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },

    text: {
        type: Object,
        required: true,
    },

    link: {
        type: Object,
        default: () => {},
    },

    linkLabel: {
        type: String,
        default: '',
    },

    btnType: {
        type: String,
        default: 'btn',
    },

    color: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const storyblokApi = useStoryblokApi();
const colorBackground = computed(() => (props.color ? `var(--c-${props.color}-7)` : `var(--c-${colorTopLevel.value}-7)`));

const linkdata = computed(() => useStoryblokLink(props.link, props.linkLabel));
</script>

<style lang="scss">
.storyblok-teaser-box {
    @include fluid('margin-bottom', 32px, 157px);

    .layout-news & {
        margin-bottom: unset;
    }
}

.storyblok-teaser-box__wrapper {
    margin-bottom: 60px;
    color: var(--c-white);

    &.no-image {
        grid-column: 5 / span 10;
    }

    &.has-image {
        @include fluid('gap', 20px, 40px);

        display: flex;
        grid-column: 3 / span 14;

        @include mobile {
            flex-direction: column;
        }
    }

    &.no-image ,
    &.has-image {
        @include tablet {
            @include grid-columns(1, calc(var(--grid-columns) - 1));
        }

        @include mobile {
            @include grid-columns(1, var(--grid-columns));
        }
    }
}

.storyblok-teaser-box__image {
    flex-basis: 411px;
    flex-shrink: 1;

    @include mobile {
        flex-basis: 202px;
        img {
            aspect-ratio: 27 / 20;
        }
    }
}

.storyblok-teaser-box__content {
    @include fluid('padding', 20px, 80px);

    flex-grow: 1;
    background-color: v-bind(colorBackground);
}

.storyblok-teaser-box__pretitle {
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.storyblok-teaser-box__title {
    padding-bottom: 20px;
    font-family: var(--f-family--black);
    font-size: var(--f-size--h2);
    line-height: var(--l-height--h2);
}

.storyblok-teaser-box__paragraph {
    max-width: 499px;
    padding-bottom: 20px;
    color: var(--c-white);
}

.storyblok-teaser-box__link {
    path {
        stroke: var(--c-white);
    }
}
</style>
