<template>
  <div
    v-if="!isDisabledRefrigerator"
    class="atom-input atom-input__wrapper"
    :class="{ 'has-options-open': showOptions }"
  >
    <div ref="wrapperRef" class="atom-singleselect-calc__wrapper">
      <div
        class="atom-singleselect-calc"
        @keypress="toggleOptions(false)"
        @click="toggleOptions(false)"
      >
        <p v-if="singleselectedOptions.length === 0" class="atom-singleselect-calc__label">
          {{ label }}
        </p>
        <p v-else class="atom-singleselect-calc__label">
          {{ selectedOptionLabel }}
        </p>

        <IonIcon
          v-if="options.length > 1"
          class="atom-singleselect-calc__icon has-pointer is-right"
          icon-name="chevron-down"
        />
      </div>

      <div
        class="atom-singleselect-calc__options-wrapper"
        :class="{ 'is-visible': showOptions }"
      >
        <div
          ref="optionsRef"
          class="atom-singleselect-calc__options"
        >
          <div
            v-for="option in options"
            :key="`${option.value}-${option.type}`"
            class="atom-singleselect-calc__option"
            :class="{ 'is-selected': singleselectedOptions.includes(option.value) }"
          >
            <button
              type="button"
              class="atom-singleselect-calc__option-button"
              @click="singleselectOption(option)"
            >
              <span class="atom-singleselect-calc__option-background" />
              <span class="atom-singleselect-calc__option-label">{{ option.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * This component provides a singleselect input field with label, icon, and validation support.
 *
 * @param {string} props.label - The label for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {array} [props.options=[]] - An array of options to display in the singleselect field.
 *
 * Author: Luca Margadant (luca.margadant@nueva.ch)
 * Copyright: Nueva AG
 */

import { useWindowSize, onClickOutside } from '@vueuse/core';

const props = defineProps({
    /*
        General data
    */
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    name: {
        type: String,
        required: true,
        validator: (value) => value.length > 0 && !value.includes(' '),
    },

    options: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },

    executionType: {
        type: Array,
        default: () => [],
        validator: (value) => Array.isArray(value),
    },
});

const {
    executionType,
} = toRefs(props);

/*
    Intialze formfield
*/
const wrapperRef = ref(null);

/*
    Handle input
*/
const emit = defineEmits(['set-input']);

/*
    Custom Logic
*/
const optionsRef = ref(null);
const closedHeight = '4px';
const optionsHeight = ref(closedHeight);
const calculatorStore = useCalculatorStore();

/* handle singleselected options */
const singleselectedOptions = ref([]);
const selectedOptionLabel = ref('');
const selectedOptionObject = ref('');
const selectedOptionError = ref('');

const handleData = () => {
    emit('set-input', {
        name: props.name,
        value: singleselectedOptions.value[0],
        object: selectedOptionObject.value,
    });
};

// if options length is 1, set it as selected
const options = computed(() => {
    if (props.options.length === 1) {
        singleselectedOptions.value.push(props.options[0].value);
        selectedOptionLabel.value = props.options[0].name;
    }

    return props.options;
});

/*
    Watch options to update selectedOption on language change
*/
watch(options, () => {
    if (singleselectedOptions.value.length === 0) {
        selectedOptionLabel.value = '';
    }

    if (Array.isArray(options?.value) && singleselectedOptions.value?.[0] !== undefined) {
        selectedOptionLabel.value = options.value.find(
            (option) => option.value === singleselectedOptions.value[0],
        )?.name || '';
    } else {
        selectedOptionLabel.value = '';
    }
});

/* show and hide options */
const showOptions = ref(false);
const toggleOptions = (forceClose = false) => {
    // dont open if there is only one option
    if (options.value.length === 1) {
        return;
    }

    showOptions.value = forceClose ? false : !showOptions.value;
    optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (showOptions.value) {
        toggleOptions();
    }
});

const singleselectOption = (option) => {
    // add option to singleselectedOptions
    if (!singleselectedOptions.value.includes(option.value)) {
        // adds option.value + option.type to singleselectedOptions
        // there should only be one option selected
        singleselectedOptions.value = [];
        singleselectedOptions.value.push(option.value);
        selectedOptionLabel.value = option.name;
        selectedOptionObject.value = option.object;
        selectedOptionError.value = option.error_message;

        if (selectedOptionError.value) {
            calculatorStore.setErrorMessage(selectedOptionError.value);
            toggleOptions(true);
            return;
        }

        toggleOptions(true);
        handleData(option.type);
    } else {
        singleselectedOptions.value = singleselectedOptions.value.filter(
            (item) => item !== option.value,
        );

        handleData(option.type);
    }
};

/* Watch window width to set height of box accordingly on resize */
const { width } = useWindowSize();
watch(() => width.value, () => {
    if (!props.isFullScreen) {
        optionsHeight.value = showOptions.value ? `${optionsRef.value.scrollHeight}px` : closedHeight;
    }
});

const colorScheme = computed(() => calculatorStore.getColorScheme);
const colorText = `var(--c-${colorScheme.value}-1)`;
const colorHover = `var(--c-${colorScheme.value}-11)`;

/*
    Only show if certain project type is selected
*/
const isDisabledRefrigerator = ref(false);
const projectType = computed(() => calculatorStore.getProjectType);

if (executionType.value && executionType.value?.length > 0) {
    isDisabledRefrigerator.value = true;
}

executionType.value.forEach((type) => {
    if (type === projectType.value) {
        isDisabledRefrigerator.value = false;
    }
});

// set initial value
const requestBody = computed(() => calculatorStore.getRequestBody);

const setInitialValue = () => {
    if (requestBody.value[props.name] === undefined) return;

    singleselectOption(
        options.value.find((option) => {
            const key = Object.keys(requestBody.value[props.name])[0];
            return option.value === requestBody.value[props.name] || option.value === key;
        }),
    );
};

setInitialValue();
</script>

<style lang="scss" scoped>
.atom-input__wrapper {
    @include formFieldWrapper;

    user-select: none;
}
.atom-singleselect-calc__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
}

.atom-singleselect-calc {
    @include formFieldBasicsText;

    position: relative;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 77px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    background: var(--c-background);

    @include mobile {
        width: 100%;
        height: 72px;
    }
}

.atom-singleselect-calc__label {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    color: v-bind(colorText);
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    pointer-events: none;
}

.atom-singleselect-calc__input {
    @include formFieldMultiselect;

    pointer-events: none;
}

.atom-singleselect-calc__icon {
    @include formFieldInputIcon(false);

    position: relative;
    top: 0;
    right: 0 !important;
    width: 18px;
    height: 18px;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .has-options-open & {
        transform: rotate(180deg);
    }
}
.atom-singleselect-calc__trigger {
    @include z-index('selectTrigger');

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
}

.atom-singleselect-calc__options-wrapper {
    @include z-index('selectTrigger');

    position: absolute;
    bottom: -100%;
    display: none;
    width: 100%;
    height: 100%;

    &.is-visible {
        display: block;
    }
}

.atom-singleselect-calc__options {
    width: 100%;
    height: 0;
    transition: height 0.5s $EASE_OUT--QUINT;

    .has-options-open & {
        height: 100%;
    }
}

.atom-singleselect-calc__option {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect-calc__selected-option {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 3px;
    transition: opacity 0.3s $EASE_OUT--SINE;

    .has-options-open & {
        opacity: 1;
        transition: opacity 0.3s $EASE_IN_OUT--SINE;
    }
}

.atom-singleselect-calc__option-divider {
    display: block;
    width: calc(100% - 40px);
    height: 2px;
    margin: 0 auto;
}

.atom-singleselect-calc__option-button {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 30px;
}

.atom-singleselect-calc__selected-option-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.atom-singleselect-calc__option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: var(--c-background);
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: v-bind(colorHover);
    }
}

.atom-singleselect-calc__selected-option-background {
    @include z-index('selectBackground');

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0px;
    left: 0;
    background: var(--c-petrol-11);
    transition: background-color 0.2s ease-in-out;
}

.atom-singleselect-calc__option-label {
    @include z-index('selectLabel');

    position: relative;
    color: var(--c-green-1);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: var(--c-petrol-1)
    }
}

.atom-singleselect-calc__selected-option-label {
    @include z-index('selectLabel');
    position: relative;
    width: 170px;
    padding: 6px 36px 6px 10px;
    color: var(--c-petrol-6);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);

    .is-selected & {
        color: var(--c-petrol-1)
    }
}

.atom-singleselect-calc__icon-close {
    @include formFieldInputIcon(false);
    @include z-index('selectLabel');

    top: 6px;
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: transform 0.5s $EASE_IN_OUT--BACK;

    .is-selected & {
        right: 10px;
        opacity: 1;
    }

}

.atom-singleselect-calc__selected {
    .atom-singleselect-calc__selected-option {
        display: none;

        &.is-selected {
            display: flex;
        }
    }
}
</style>
