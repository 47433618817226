<template>
  <div class="storyblok-slider-participation">
    <div class="storyblok-slider-participation__inner">
      <swiper
        v-if="items && items.length > 0"
        :direction="'horizontal'"
        :pagination="isTablet ? tabletPagination : { clickable: true }"
        :navigation="{
          nextEl: '.storyblok-slider-participation__controls--next',
          prevEl: '.storyblok-slider-participation__controls--prev',
        }"
        :grab-cursor="true"
        :modules="modules"
        :loop="true"
        :resistance-ratio="0"
        class="storyblok-slider-participation__swiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in items"
          :key="index"
        >
          <AtomItemSliderParticipation
            v-bind="item"
            :color="color"
            :counter="setCouner(index)"
          />
        </swiper-slide>
        <div class="storyblok-slider-participation__mobile__controls">
          <div
            class="storyblok-slider-participation__control storyblok-slider-participation__controls--prev"
            :class="{ 'is-hidden': hideNext }"
          >
            <div class="storyblok-slider-participation__controls">
              <IonIcon icon-name="chevron-right" class="storyblok-slider-participation__button-icon left" />
            </div>
          </div>
          <div
            class="swiper-pagination storyblok-slider-participation__swiper__pagination"
          />
          <div
            class="storyblok-slider-participation__control storyblok-slider-participation__controls--next"
            :class="{ 'is-hidden': hidePrev }"
          >
            <div class="storyblok-slider-participation__controls">
              <IonIcon icon-name="chevron-left" class="storyblok-slider-participation__button-icon right" />
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</template>

<script setup>
const clientStore = useClientDeviceStore();
const isTablet = computed(() => (clientStore.isTablet));
const setCouner = (index) => {
    const ordinalNumber = index + 1;
    if (ordinalNumber < 10) {
        return `0${ordinalNumber}`;
    }
    return ordinalNumber;
};
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    color: {
        type: String,
        default: 'petrol',
    },
});

// import Swiper core and required modules
const {
    Navigation,
    Virtual,
    Pagination,
    Autoplay,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Navigation, Pagination, Autoplay];
const colorText = computed(() => (props.color && `var(--c-${props.color}-1)`));
const tabletPagination = {
    type: 'fraction',
    el: '.swiper-pagination',
};

</script>
<style lang="scss">
.storyblok-slider-participation {
    @include fluid('margin-bottom', 50px, 80px);
    position: relative;
    height: 100%;

    .swiper-pagination-bullets {
        position: absolute;
        bottom: 60px !important;
        height: 15px;

        .swiper-pagination-bullet {
            position: static;
            width: 12px;
            height: 12px;
            margin: 0 10px !important;
            background: var(--c-background);

            opacity: 1;
            transform: scale(1);

            @include mobile {
                width: 8px;
                height: 8px;
                margin: 0 6px !important;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background: v-bind(colorText);
        }

        @include mobile {
            bottom: 25px !important;
        }
    }

}
.storyblok-slider-participation__mobile__controls {
    @include z-index('main');

    position: absolute;
    right: 0;
    bottom: 40px !important;
    left: 0;
    display: none;
    margin: auto;

    @include tablet {
        position: absolute !important;
        bottom: 20px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.storyblok-slider-participation__control {

    color: v-bind(colorText);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;

    path {
        stroke: v-bind(colorText);
    }
    @include tablet {
        display: flex;
    }
    .storyblok-slider-participation__controls {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-right: 27px;
            margin-left: 27px;
            transform: rotate(-180deg);
        }

    }

    &.storyblok-slider-participation__controls--next {

        &.is-hidden {
            display: none;
        }
    }

    &.storyblok-slider-participation__controls--prev {
        left: 37%;

        &.is-hidden {
            display: none;
        }
    }

}
.storyblok-slider-participation__swiper__pagination {
    bottom: auto !important;
    left: auto !important;
    max-width: 50px;
    color: var(--c-white);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
}

</style>
