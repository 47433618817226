<template>
  <div class="storyblok-activities-filtered">
    <UtilWrapperLayout>
      <UtilGrid>
        <BlockFilter
          v-if="isArrayNotEmpty(mappedDropdowns)"
          class="storyblok-activities-filtered__filter is-medium-wide"
          :title="getStoryblokTranslation('general', 'filter_by')"
          :dropdowns="sortFilterDropdowns(mappedDropdowns)"
          :color="color"
        />
        <BlockFilteredActivities
          class="storyblok-activities-filtered__block is-narrow"
          :class="{ 'is-pending': activitiesStatus === 'pending' }"
          :articles="activities"
          :color="color"
          :button-label="buttonLabel"
        />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    filterByCountry: {
        type: String,
        default: '',
    },

    buttonLabel: {
        type: String,
        default: '',
    },
});

const filterParamsData = [{
    label: getStoryblokTranslation('general', 'country'),
    slug: 'international/',
    content_type: 'Country',
    position: 1,
    type: 'countries',
    storyblok_component: 'Country',
}, {
    label: getStoryblokTranslation('general', 'activities'),
    slug: 'data-sources/activities',
    content_type: '',
    position: 2,
    type: 'type-of-activity',
    storyblok_component: 'Program',
}];

const filterParams = computed(() => (props.filterByCountry
    ? filterParamsData.slice(1) : filterParamsData));
const mappedDropdowns = ref([]);

const storyblokApi = useStoryblokApi();

const filterStore = useFilterStore();
const countries = computed(() => filterStore.getCountries);
const typesOfActivity = computed(() => filterStore.getTypesOfActivity);

const colorStore = useColorStore();
const color = computed(() => colorStore.getGlobalColor);

const activityStore = useActivityStore();
const savedIndex = computed(() => activityStore.getStoredIndex);

const { locale: currentLocale, defaultLocale } = useI18n();
const language = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;

const sortFilterDropdowns = (data) => {
    const sorted = data.sort((a, b) => (a.position < b.position));
    return sorted;
};

const setFilter = () => {
    const filterQuery = {
        country: countries?.value?.length === 0 ? {} : {
            in: countries.value.toString(),
        },
        type: typesOfActivity?.value?.length === 0 ? {} : {
            any_in_array: typesOfActivity.value.toString(),
        },
    };

    return filterQuery;
};

const {
    data: activities,
    status: activitiesStatus,
    error: activitiesError,
} = useLazyAsyncData(
    `get-activities-${getUid}`,
    async () => storyblokApi.get('cdn/stories/', {
        starts_with: 'international/activities/',
        version: 'published',
        resolve_relations: 'Activity.type,Activity.country',
        resolve_links: 'link',
        per_page: 100,
        is_startpage: false,
        filter_query: setFilter(),
        sort_by: 'first_published_at:desc',
        language,
    }),
    {
        transform: (res) => {
            if (
                typeof countries.value === 'object'
                && typeof typesOfActivity.value === 'object'
                && countries.value.length === 0
                && typesOfActivity.value.length === 0
            ) {
                // random shuffle activities if no filter is set and no index is saved
                if (!savedIndex.value) {
                    res.data.stories.sort(() => Math.random() - 0.5);
                }
            }

            mappedDropdowns.value = [];
            filterParams.value.forEach((option) => {
                mappedDropdowns.value.push({
                    label: option.label,
                    options: res.data.rels.filter(
                        (item) => item.content.component === option.storyblok_component,
                    )
                        .map((rel) => ({
                            name: rel.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale,
                            )?.name || rel.name,
                            value: rel.uuid,
                            type: option.type,
                        })),
                    type: option.type,
                    position: option.position,
                });
            });

            return res.data.stories.map((story) => ({
                ...story,
                cached_url: story.default_full_slug,
                story: {
                    alternates: story.translated_slugs,
                },
            }));
        },
        watch: [filterStore],
        immediate: false,
    },
);

/*
    Error handling
*/
watch(activitiesError, (error) => {
    if (error) {
        useSentryError(error, {

        });
    }
});

onMounted(() => {
    if (props.filterByCountry) {
        filterStore.setCountries(props.filterByCountry);
    }

    filterStore.resetData();
});
</script>

<style lang="scss" scoped>
.storyblok-activities-filtered {
    @include fluid('margin-bottom', 30px, 50px);
}

.storyblok-activities-filtered__filter,
.storyblok-activities-filtered__block {
    @include default-content-columns;

}

.storyblok-activities-filtered__block {
    &.is-pending {
        min-height: 800px;
    }
}
</style>
