export default defineStore('MapStore', {
    state: () => ({
        activeCountryIndex: -1,
        isOpen: false,
    }),

    actions: {
        setActiveCountryIndex(index) {
            this.activeCountryIndex = index;
        },

        setIsOpen(isOpen) {
            this.isOpen = isOpen;

            if (!isOpen) {
                this.setActiveCountryIndex(-1);
            }
        },
    },

    getters: {
        getActiveCountryIndex() {
            return this.activeCountryIndex;
        },

        getIsOpen() {
            return this.isOpen;
        },
    },
});
