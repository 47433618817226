<template>
  <div class="block-overlay">
    <AtomButton
      class="block-overlay__button"
      :text="label"
      @click="modalboxStore.openModalbox"
      @keypress="modalboxStore.openModalbox"
    />
    <Transition name="fade-in">
      <BlockMobalboxOverlay
        v-if="modalboxStore.isOpen"
        :items="mappedItems"
      />
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

const storyblokApi = useStoryblokApi();

const mappedItems = computed(() => props.items.map((item) => ({
    ...item,
    text: storyblokApi?.richTextResolver.render(item.text),
    // map ctas to the format we need
    ctas: item.ctas.map((cta) => ({
        ...cta,
        link: cta.link.cached_url || cta.link,
    })),
})));

const modalboxStore = useModalboxStore();
</script>

<style lang="scss" scoped>
.block-overlay {
    height: auto;
}
</style>
