<template>
  <div ref="wrapperRef" class="block-color-palette">
    <div
      v-for="palette, paletteIndex in palettes"
      :key="palette._uid"
      class="block-color-palette__color"
      :class="{ 'is-active': activePalette === paletteIndex }"
      @click="setActivePalette(paletteIndex)"
      @keypress="setActivePalette(paletteIndex)"
    >
      <BlockItemColor
        v-for="color, colorIndex in palette.colors"
        :key="color._uid"
        :color="color"
        :color-scheme="palette.color"
        :text="palette.title"
        :index="colorIndex"
        :total-colors="palette.colors.length"
        :is-basecolor="palette.is_basecolor"
        :is-active="activePalette === paletteIndex && activeColor === colorIndex"
        @click="setActiveColor(colorIndex)"
        @keypress="setActiveColor(colorIndex)"
        @color-control="colorControl"
      />
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    palettes: {
        type: Object,
        default: () => {},
    },
});

const {
    palettes,
} = toRefs(props);

const activePalette = ref(null);
const activeColor = ref(null);
const wrapperRef = ref(null);

const setActivePalette = (index) => {
    activePalette.value = index;
};

const setActiveColor = (index) => {
    activeColor.value = index;
};

const colorControl = (direction) => {
    if (direction === 'up') {
        activeColor.value += 1;
    } else if (direction === 'down') {
        activeColor.value -= 1;
    } else if (direction === 'close') {
        activePalette.value = null;
        activeColor.value = null;
    }
};

/* Close colorpalette on click outside */
onClickOutside(wrapperRef, () => {
    activePalette.value = null;
    activeColor.value = null;
});
</script>

<style lang="scss" scoped>
.block-color-palette {
    @include default-content-columns;
    @include fluid('margin-bottom', 30px, 50px);

    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.block-color-palette__color {
    display: flex;
    overflow: hidden;
    height: 136px;
    column-gap: 3px;
    transition: height 0.3s ease-in-out;

    &.is-active {
        height: 650px;

        @include mobile {
            height: 700px;
        }
    }

    @include mobile {
        @include remove-scrollbars;

        overflow: scroll;
        height: 78px;
    }
}
</style>
