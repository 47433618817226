<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div class="storyblok-profile">
        <BlockProfile
          :title="title"
          :items="mappedItems"
          :image="mappedImage"
        />
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        required: true,
        validator: (value) => value.isArray && value.length > 0,
    },

    image: {
        type: Object,
        default: () => {},
        validator: (value) => typeof value === 'object',
    },
});

const storyblokApi = useStoryblokApi();

// map data from storyblok to the format we need
const mappedItems = computed(() => props.items.map((item) => ({
    title: item.title,
    text: storyblokApi?.richTextResolver.render(item.text),
})));

// map image data from storyblok to the format we need
const mappedImage = computed(() => ({
    ...props.image,
    focus: props.image.focus || 'center',
    provider: 'storyblok',
}));

</script>

<style lang="scss" scoped>
.storyblok-profile {
    @include grid-columns(1, 18);
    @include fluid('margin-bottom', 50px, 80px);
}
</style>
