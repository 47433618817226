export default (link, label) => {
    const baseData = {
        link: link.cached_url === 'home' ? '/' : link.cached_url,
        text: label,
        isInternal: link?.linktype === 'story',
        rawLink: link,
    };
    return {
        ...link,
        ...baseData,
        icon: baseData.isInternal ? 'chevron-right' : 'arrow-up-right',
    };
};
