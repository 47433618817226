<template>
  <div
    class="atom-tag"
    :class="[
      `is-${variant}`,
    ]"
  >
    <span class="atom-tag__text">{{ text }}</span>
  </div>
</template>

<script setup>
const props = defineProps({
    /*
        Text
    */
    text: {
        type: String,
        default: 'Call to action',
        validator: (value) => value.length > 0,
    },

    /*
        Colors
    */
    variant: {
        type: String,
        default: 'primary',
        validator: (value) => [
            'primary', 'inverted',
        ].includes(value),
    },

    colorSchema: {
        type: String,
        default: 'petrol',
        validator: (value) => ['petrol', 'red', 'blue', 'orange', 'green', 'coral', 'mustard'].includes(value),
    },

    colorText: {
        type: String,
        default: '',
    },

    colorBg: {
        type: String,
        default: '',
    },
});

const colorText = computed(() => (props.colorText ? props.colorText : `var(--c-${props.colorSchema}-6)`));
const colorBg = computed(() => (props.colorBg ? props.colorBg : `var(--c-${props.colorSchema}-11)`));
</script>

<style lang="scss">

.atom-tag {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 6px 12px;
    border-radius: 4px;
    background: v-bind(colorBg);
    color: v-bind(colorText);
    column-gap: 40px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    transition: opacity 0.3s $EASE_IN_OUT--SINE;

    &.is-inverted {
        background: v-bind(colorText);
        color: v-bind(colorBg);
    }

    span {
        padding-top: 1px;
    }

}

.atom-tag__text {
    z-index: 1;
}

</style>
