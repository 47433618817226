<template>
  <div class="block-key-figures">
    <div class="block-key-figures__content">
      <AtomHeading
        tag="h3"
        font-size="h3"
        :text="title"
        class="block-key-figures__title"
      />
      <div v-for="item in items" :key="item._uid" class="block-key-figures__item">
        <div class="block-key-figures__item-title">
          {{ item.title }}
        </div>
        <AtomRichtext
          class="block-key-figures__item-text"
          :class="{ 'is-light-font': item?.is_light_font }"
          :html="item.text"
        />
      </div>
    </div>
    <div class="block-key-figures__image-section">
      <AtomImage
        v-if="image?.length === 1"
        :image="image[0]"
        class="block-key-figures__image"
        :provider="imageProvider"
      />
      <ClientOnly>
        <swiper
          v-if="image && image.length > 1"
          :direction="'horizontal'"
          :pagination="true"
          :grab-cursor="true"
          :modules="modules"
          :resistance-ratio="0"
          class="block-key-figures__image__image-section-swiper"
          @slide-change="onSlideChange"
        >
          <swiper-slide
            v-for="(item, index) in image"
            :key="index"
          >
            <AtomImage
              v-if="item?.filename"
              :image="item"
              :provider="imageProvider"
              class="block-key-figures__image"
            />
          </swiper-slide>
        </swiper>
      </ClientOnly>

      <h3 v-if="image[indexRef].copyright" class="block-key-figures__copyright-desktop">
        {{ image[indexRef].copyright }}
      </h3>
    </div>
    <h3 v-if="image[indexRef].copyright" class="block-key-figures__copyright-mobile">
      {{ image[indexRef].copyright }}
    </h3>
  </div>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },

    items: {
        type: Array,
        required: true,
        default: () => [],
        validator: (value) => value.isArray && value.length > 0,
    },

    image: {
        type: Array,
        default: () => [],
    },

    imageProvider: {
        type: String,
        default: '',
    },
});

const {
    Virtual,
    Pagination,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Pagination];

const indexRef = ref(0);
const onSlideChange = (swiper) => {
    const { activeIndex } = swiper;
    indexRef.value = activeIndex;
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss">
.block-key-figures {
    @include grid-layout();
    position: relative;

    @include mobile {
        height: auto;
        max-height: none;
    }
}

.block-key-figures__content {
    @include grid-columns(2, 5);

    padding: 40px 81px 98px 40px;
    background:  var(--c-white);

    @include tablet {
        @include grid-columns(1, 18);
    }
}

.block-key-figures__title {
    margin-bottom: 29px;
    word-break: break-word;
    &.atom-heading {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
    }
}

.block-key-figures__item {
    margin-bottom: 29px;
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    &:last-child {
        margin-bottom: 0;
    }
}

.block-key-figures__item-title {
    font-family: var(--f-family--thin);
}

.block-key-figures__item-text {
    font-family: var(--f-family--bold);

    &.is-light-font {
        color: v-bind(color4);
        font-family: var(--f-family--thin);
    }
}

.block-key-figures__image-section {
    @include grid-columns(7, 11);

    @include tablet {
        @include grid-columns(7, 12);
    }

    @include tablet {
        @include grid-columns(1, 18);
    }
}

.block-key-figures__copyright-desktop,
.block-key-figures__copyright-mobile {
    padding-top: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-key-figures__copyright-desktop {
    @include mobile {
        display: none;
    }
}

.block-key-figures__copyright-mobile {
    display: none;

    @include mobile {
        display: block;
        grid-column-end: span 3;
    }
}

.block-key-figures__image {
    height: 100%;
}

.block-key-figures__image__image-section-swiper {
    height: 100%;

    .swiper-pagination-bullets {
        position: absolute;
        bottom: 35px !important;
        height: 15px;

        .swiper-pagination-bullet {
            position: static;
            left: -20px !important;
            width: 13px;
            height: 13px;
            margin: 0 11px !important;
            background: var(--c-white);
            opacity: 1;
            transform: scale(1);

            @include mobile {
                width: 8px;
                height: 8px;
                margin: 0 4px !important;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background:var(--c-coral-4);
        }

        @include mobile {
            bottom: 20px !important;
        }
    }
}
</style>
