<template>
  <div class="storyblok-employees-block">
    <UtilWrapperLayout class="delete-padding">
      <UtilGrid>
        <div class="storyblok-employees-block__filter-section is-medium-wide">
          <AtomHeading
            v-if="title"
            class="storyblok-employees-block__title"
            :text="title"
          />
          <BlockFilter
            v-if="isArrayNotEmpty(filterData)"
            class="storyblok-employees-block__filter"
            :title="getStoryblokTranslation('general', 'filter')"
            :dropdowns="filterData"
            :color="color"
            @on-set-filter="filterPeople"
          />
          <div v-else class="storyblok-employees-block__empty-filter" />
        </div>

        <BlockFilteredEmployees
          class="storyblok-employees-block__block is-narrow"
          :people="filteredPeople?.length !== 0 ? filteredPeople : sortedPeople"
        />
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>
<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    people: {
        type: Array,
        default: () => [],
    },
});

const {
    people,
} = toRefs(props);

const filterData = ref([]);
const filteredPeople = ref([]);

const colorStore = useColorStore();
const color = computed(() => colorStore.getGlobalColor);

const sortedPeople = computed(() => people.value.sort((a, b) => a.position - b.position));

const filterParams = [{
    label: getStoryblokTranslation('general', 'platform'),
    content_type: 'Department',
    position: 2,
    type: 'department',
    storyblok_component: 'Department',
}];

const getDropdownData = () => {
    const mappedDropdowns = [];

    const departments = people.value
        .flatMap((person) => person.content?.departments?.map((dep) => ({
            uuid: dep.uuid,
            name: dep.name,
        })) || [])
        .filter((dep, index, self) => index === self.findIndex((t) => t.uuid === dep.uuid));

    // Sort the departments based on the position
    const sortedDepartments = departments.sort((a, b) => a.position - b.position);

    filterParams.forEach((param) => {
        const dropdown = {
            label: param.label,
            type: param.type,
            options: sortedDepartments.map((dep) => ({
                value: dep.uuid,
                name: dep.name,
            })),
        };

        if (dropdown.options.length > 0) {
            mappedDropdowns.push(dropdown);
        }
    });

    filterData.value = mappedDropdowns;
};

const filterPeople = (data) => {
    filteredPeople.value = sortedPeople.value.filter((person) => {
        const departments = person.content?.departments?.map((dep) => dep.uuid) || [];
        return data.every((uuid) => departments.includes(uuid));
    });
};

getDropdownData();
</script>
<style lang="scss" scoped>
.delete-padding {
    @include mobile {
        padding: 0 ;
    }
}
.storyblok-employees-block__title {
    padding-bottom: 32px;
    @include mobile {
        padding-bottom: 10px;
        padding-left: var(--page-padding);
    }
}
.storyblok-employees-block__filter-section {
    @include grid-columns(3, calc(var(--grid-columns) - 4));
}
.storyblok-employees-block__block {
    @include grid-columns(4, calc(var(--grid-columns) - 6));
}
.storyblok-employees-block__block,
.storyblok-employees-block__filter-section,
.storyblok-employees-block__empty-filter {
    @include tablet {
        @include grid-columns(1, calc(var(--grid-columns) - 1));
    }

    @include mobile {
        @include grid-columns(1, var(--grid-columns));
    }
}
.storyblok-employees-block {
    @include fluid('padding-bottom', 30px, 60px);
}
.storyblok-employees-block__empty-filter {
    padding: 40px 29px;
    background-color: var(--c-white);
}
  </style>
