<template>
  <div class="block-slider-enumeration">
    <div class="block-slider-enumeration__slider">
      <swiper
        v-if="items && items.length > 0"
        :direction="'horizontal'"
        :pagination="true"
        :navigation="{
          nextEl: '.block-slider-enumeration__controls--next',
          prevEl: '.block-slider-enumeration__controls--prev',
        }"
        :grab-cursor="true"
        :modules="modules"
        :resistance-ratio="0"
        class="block-slider-enumeration__swiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide
          v-for="(item, index) in items"
          :key="index"
        >
          <AtomItemSliderEnumeration :item="item" />
        </swiper-slide>
      </swiper>
      <div
        class="block-slider-enumeration__control block-slider-enumeration__controls--next"
        :class="{ 'is-hidden': hideNext }"
      >
        <div class="block-slider-enumeration__controls">
          <span v-if="items">{{
            nextSlideIndex(items.length, activeSlide)
          }}</span>
          <IonIcon icon-name="chevron-right" class="block-slider-enumeration-gallery__button-icon" />
        </div>
      </div>
      <div
        class="block-slider-enumeration__control block-slider-enumeration__controls--prev"
        :class="{ 'is-hidden': hidePrev }"
      >
        <div class="block-slider-enumeration__controls">
          <IonIcon icon-name="chevron-left" class="block-slider-enumeration-gallery__button-icon" />
          <span v-if="items">{{
            prevSlideIndex(activeSlide)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    items: {
        type: Array,
        default: () => [],
        validator: (value) => value.length > 0,
    },
});

// import Swiper core and required modules
const {
    Navigation,
    Virtual,
    Pagination,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const modules = [Virtual, Navigation, Pagination];

const activeSlide = ref(null);
const hidePrev = ref(false);
const hideNext = ref(false);

const onSlideChange = (swiper) => {
    activeSlide.value = swiper.realIndex + 1;
};

const prevSlideIndex = (slideNumber) => {
    if (slideNumber > 1) {
        hidePrev.value = false;
        return slideNumber - 1;
    }
    hidePrev.value = true;
    return '';
};

const nextSlideIndex = (sliderArrayLength, slideNumber) => {
    if (sliderArrayLength === slideNumber) {
        hideNext.value = true;
        return '';
    }
    hideNext.value = false;
    return slideNumber + 1;
};

const colorTopLevel = inject('colorSchema');

const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color6 = computed(() => `var(--c-${colorTopLevel.value}-6)`);
</script>

<style lang="scss">
.block-slider-enumeration {
    height: 100%;
    min-height: 503px;
    max-height: 100vh;
    background: var(--c-white);
    transition: opacity 0.35s ease-in-out;

    .swiper-pagination-bullets {
        position: absolute;
        bottom: 35px !important;
        height: 15px;

        .swiper-pagination-bullet {
            position: static;
            left: -20px !important;
            width: 12px;
            height: 12px;
            background: var(--c-background);
            opacity: 1;
            transform: scale(1);

            @include mobile {
                width: 8px;
                height: 8px;
            }
        }

        &.swiper-pagination-bullets-dynamic {
            overflow: hidden;
        }

        .swiper-pagination-bullet-active {
            background: v-bind(color6) !important;
        }

        @include mobile {
            bottom: 20px !important;
        }
    }
}

.block-slider-enumeration__close-icon {
    padding-top: 2px;
    transition: transform 0.2s $EASE_IN_OUT--SINE;
}

.block-slider-enumeration__close {
    @include z-index('lightbox');

    position: absolute;
    top: 30px;
    left: 60px;
    display: flex;
    cursor: pointer;

    p {
        margin-left: 10px;
        font-family: var(--f-family--regular);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }

    &:hover {
        .block-slider-enumeration__close-icon {
            transform: translateX(-10px);
        }
    }

    @include tablet-portrait {
        left: 20px;
    }

    @include mobile {
        top: 20px;
    }
}

.block-slider-enumeration-gallery {
    position: relative;
    max-width: 100%;
}

.block-slider-enumeration__control {
    @include z-index('main');

    position: absolute;
    bottom: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    background: var(--c-background);
    color: v-bind(color1);
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    outline: none;
    transform: translateY(50%);

    &.block-slider-enumeration__controls--next {
        right: 0;
        padding-left: 25px;
        border-bottom-left-radius: 38px;
        border-top-left-radius: 38px;

        .block-slider-enumeration__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    &.block-slider-enumeration__controls--prev {
        padding-right: 25px;
        border-bottom-right-radius: 38px;
        border-top-right-radius: 38px;

        .block-slider-enumeration__controls {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &.is-hidden {
            display: none;
        }
    }

    @include tablet {
        bottom: 10px;
        transform: translateY(0);
    }
}

.block-slider-enumeration__swiper {
    height: 100%;
}

.block-slider-enumeration__slider {
    position: relative;
    height: 100%;
}
</style>
