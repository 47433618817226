export default defineStore('ColorStore', {
    state: () => ({
        globalColor: 'red',
    }),

    actions: {
        setGlobalColor(color) {
            this.globalColor = color;
        },
    },

    getters: {
        getGlobalColor(state) {
            return state.globalColor;
        },
    },
});
