<template>
  <div
    class="block-item-project"
  >
    <AtomImage
      v-if="image?.filename"
      class="block-item-project__image"
      :image="image"
      provider="storyblok"
      lazy="false"
    />
    <div class="block-item-project__content">
      <div class="block-item-project__heading">
        <AtomTag
          class="block-item-project__tag"
          :text="tag"
          color-text="var(--c-white)"
          :color-bg="colorBackgroundTag"
        />
        <h3 class="block-item-project__title">
          {{ title }}
        </h3>
      </div>

      <AtomLink
        v-if="link.cached_url"
        class="block-item-project__link"
        :text="linkLabel"
        :link="link"
        icon="chevron-right"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    image: {
        type: Object,
        default: () => {},
    },

    tag: {
        type: String,
        default: '',
    },

    linkLabel: {
        type: String,
        default: '',
    },

    link: {
        type: Object,
        default: () => {},
    },

    color: {
        type: String,
        default: '',
    },
});

const colorTopLevel = inject('colorSchema');
const colorBackground = computed(() => (props.color
    ? `var(--c-${props.color}-11)`
    : `var(--c-${colorTopLevel.value}-11)`));

const colorBackgroundTag = computed(() => (props.color
    ? `var(--c-${props.color}-6)`
    : `var(--c-${colorTopLevel.value}-6)`));

const colorText = computed(() => (props.color
    ? `var(--c-${props.color}-4)`
    : `var(--c-${colorTopLevel.value}-4)`));

const colorText2 = computed(() => (props.color
    ? `var(--c-${props.color}-1)`
    : `var(--c-${colorTopLevel.value}-1)`));

</script>

<style lang="scss">
.block-item-project {
    display: flex;
    flex-direction: column;
    grid-column-end: span 1;
}

.block-item-project__image {
    overflow: hidden;
    height: 154px;
}

.block-item-project__tag {
    align-self: flex-start;
    margin-bottom: 10px;
}

.block-item-project__content {
    @include word-break;
    @include fluid('padding', 20px, 30px);

    display: flex;
    flex-direction: column;
    flex-grow: 5;
    justify-content: space-between;
    background: v-bind(colorBackground);
}

.block-item-project__title {
    color: v-bind(colorText2);
    font-family: var(--f-family--black);
    font-size: 24px;
    line-height: 38px;

    @include mobile {
        font-family: var(--f-family--bold);
        font-size: 14px;
        line-height: 22px;
    }
}

.block-item-project__link {
    @include fluid('padding-top', 20px, 40px);

    color: v-bind(colorText);

    path {
        stroke: v-bind(colorText);
    }
}
</style>
