<template>
  <div>
    <div ref="wrapperRef" class="block-search">
      <div
        class="block-search__title"
        :class="{ 'is-active': searchFilterOpen }"
        @click="toggleOpen()"
        @keypress="toggleOpen()"
      >
        <div class="block-search__title-wrapper">
          <p>{{ getStoryblokTranslation('general', 'search_term') }}</p>
          <IonIcon
            class="block-search__title-icon-magnifying"
            icon-name="search"
          />
        </div>
        <IonIcon
          class="block-search__title-icon"
          icon-name="chevron-down"
        />
      </div>
      <div
        class="block-search__content-wrapper"
        :class="{ 'is-open': searchFilterOpen }"
      >
        <AtomInputText
          class="block-search__input"
          name="search"
          :label="getStoryblokTranslation('general', 'search')"
          @set-input="filterText"
        />
        <IonIcon
          class="block-search__title-icon-magnifying"
          icon-name="search"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const searchFilterOpen = ref(false);
const wrapperRef = ref(null);
const filterData = ref(null);
const filterStore = useFilterStore();
const { throttledExecute } = useThrottledExecute(700);

const filterText = (data) => {
    filterData.value = data;

    throttledExecute(() => {
        filterStore.setTextSearch(filterData.value);
    });
};

const resetData = () => {
    filterData.value = null;
};

const toggleOpen = () => {
    resetData();
    searchFilterOpen.value = !searchFilterOpen.value;

    // focus on input field if search filter is open
    if (searchFilterOpen.value) {
        setTimeout(() => {
            document.querySelector('.atom-input-text__input').focus();
        }, 100);
    }
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (searchFilterOpen.value) {
        toggleOpen();
    }
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
</script>

<style lang="scss" scoped>
.block-search {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: var(--c-white);

    ::v-deep(.block-search__title-icon-magnifying) {
        ellipse {
            stroke: v-bind(color4);
        }

        path {
            stroke: v-bind(color4);
        }
    }
}

.block-search__title-icon-magnifying {
    display: block;
}

.block-search__title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 21px 30px;
    column-gap: 20px;
    cursor: pointer;

    ::v-deep(.block-search__title-icon) {
        path {
            stroke: v-bind(color4);
        }
    }

    p {
        color: v-bind(color1);
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);

        @include mobile {
            font-size: var(--f-size--description);
            line-height: var(--l-height--description);
        }
    }

    &.is-active {
        padding: 40px 30px;

        p{
            font-family: var(--f-family--black);
        }

        .block-search__title-icon {
            transform: rotate(180deg);
        }

        .block-search__title-icon-magnifying {
            display: none;
        }

        @include mobile {
            padding: 10px;
        }
    }

    @include mobile {
        padding: 10px;
    }
}

.block-search__title-wrapper {
    display: flex;
    align-items: center;
    color: v-bind(color1);
    column-gap: 10px;
}

.block-search__content-wrapper {
    display: flex;
    overflow: hidden;
    max-width: 50%;
    height: 0;
    align-items: center;
    column-gap: 20px;

    @include mobile {
        max-width: 100%;
    }

    &.is-open {
        height: 100%;
        padding: 0 30px 40px 30px;

        @include mobile {
            padding: 0 10px 20px 10px;
        }
    }
}

.block-search__input {
    width: 444px;

    @include mobile {
        width: 100%;
    }
}
</style>
