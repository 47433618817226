<!-- eslint-disable no-useless-concat -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="implementation-wheel__wrapper">
    <div
      class="implementation-wheel"
      :class="{ showContent: activeStage !== null }"
    >
      <div class="implementation-wheel__info">
        <div
          v-if="activeStage !== null && showSlider"
          class="implementation-wheel__info--inner"
        >
          <IonIcon
            class="implementation-wheel__info-close"
            icon-name="close"
            @click="activeStage = null"
          />
          <BlockParticipationProcessSlider
            v-if="implementationWheelData && showSlider && implementationWheelDataStatus === 'success'"
            :block="{
              slides: implementationWheelData,
            }"
            :active-stage="activeStage"
            @new-target="setNewActiveStage"
          />
        </div>
      </div>
      <svg
        id="Layer_1"
        class="implementation-wheel__wheel"
        version="1.1"
        width="2467"
        height="2467"
        viewBox="0 0 2467 2467"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g class="svg-elements-wrapper" @mouseover="mouseOnSVG = true" @mouseleave=" mouseOnSVG = false; useTarget('');">
          <!-- Stage 1 -->
          <g
            :class="{
              active:
                activeMainGroup === 1
                || activeStage === 'target-1-0',
            }"
            @mouseover="setIsActive(1), useTarget()"
            @click="handleClick('target-1-0')"
          >
            <path
              class="implementation-wheel-st1 stage"
              d="M1452.7 1107.3L1831.7 929.5C1739 731.9 1582.9 603.8 1370.9 551.4L1270.5 958.1C1351.4 977.8 1418.1 1033.5 1452.7 1107.3Z"
              fill="#F3ECD1"
            />
          </g>

          <!-- Stage 2 -->
          <g
            :class="{
              active:
                activeMainGroup === 2
                || activeStage === 'target-2-0',
            }"
            @mouseover="setIsActive(2), useTarget()"
            @click="handleClick('target-2-0')"
          >
            <path class="implementation-wheel-st2 stage" d="M1831.7 929.6L1452.7 1107.4C1469.2 1142.5 1478.4 1181.7 1478.4 1223.1C1478.4 1267.8 1467.7 1309.9 1448.6 1347.1L1822.4 1536.5C1921.1 1341.7 1924.4 1127.2 1831.7 929.6Z" fill="#E2D396" />
          </g>

          <!-- Stage 3 -->
          <g
            :class="{
              active:
                activeMainGroup === 3
                || activeStage === 'target-3-0',
            }"
            @mouseover="setIsActive(3), useTarget()"
            @click="handleClick('target-3-0')"
          >
            <path class="implementation-wheel-st3 stage" d="M1448.7 1347C1411.8 1419.1 1343.7 1472.6 1262.2 1489.7L1350.4 1900.3C1563.8 1854.4 1723.8 1731.1 1822.4 1536.4L1448.7 1347Z" fill="#D9C161" />
          </g>

          <!-- Stage 4 -->
          <g
            :class="{
              active:
                activeMainGroup === 4
                || activeStage === 'target-4-0',
            }"
            @mouseover="setIsActive(4), useTarget()"
            @click="handleClick('target-4-0')"
          >
            <path class="implementation-wheel-st4 stage" d="M1205.8 1495.6C1139.2 1495.6 1078.2 1471.7 1030.8 1432L761.2 1754.8C928.7 1894.7 1137 1946.2 1350.4 1900.3L1262.2 1489.7C1244 1493.6 1225.2 1495.6 1205.8 1495.6Z" fill="#D1AF23" />
          </g>

          <!-- Stage 5 -->
          <g
            :class="{
              active:
                activeMainGroup === 5
                || activeStage === 'target-5-0',
            }"
            @mouseover="setIsActive(5), useTarget()"
            @click="handleClick('target-5-0')"
          >
            <path class="implementation-wheel-st5 stage" d="M933.3 1223C933.3 1221.8 933.3 1220.6 933.3 1219.4L512.9 1213C509.6 1431.3 593.7 1614.9 761.3 1754.8L1030.9 1432C971.2 1382 933.3 1306.9 933.3 1223Z" fill="#978016" />
          </g>

          <!-- Stage 6 -->
          <g
            :class="{
              active:
                activeMainGroup === 6
                || activeStage === 'target-6-0',
            }"
            @mouseover="setIsActive(6), useTarget()"
            @click="handleClick('target-6-0')"
          >
            <path class="implementation-wheel-st6 stage" d="M1040.5 1006.3L787.2 671.6C613.2 803.4 516.2 994.7 512.9 1213L933.3 1219.4C934.4 1132.5 976.2 1055.4 1040.5 1006.3Z" fill="#745F0B" />
          </g>

          <!-- Stage 7 -->
          <g
            :class="{
              active:
                activeMainGroup === 7
                || activeStage === 'target-7-0',
            }"
            @mouseover="setIsActive(7), useTarget()"
            @click="handleClick('target-7-0')"
          >
            <path class="implementation-wheel-st7 stage" d="M1205.8 950.4C1228.1 950.4 1249.7 953.1 1270.5 958.1L1370.9 551.4C1159 499.1 961.2 539.8 787.1 671.5L1040.5 1006.1C1086.4 971.2 1143.7 950.4 1205.8 950.4Z" fill="#54460E" />
          </g>

          <!-- Inner Decoration  -->
          <path class="immplementation-wheel__inner-decoration" d="M1371.3 1082.7C1341.3 1047.1 1299.8 1021.7 1254.6 1011L1251.4 1024.9C1293.6 1034.8 1332.3 1058.6 1360.4 1091.9C1389.5 1126.2 1405.9 1168.3 1408.1 1213.4C1410.7 1267.7 1392 1319.8 1355.5 1360.1C1319 1400.3 1268.9 1423.9 1214.5 1426.5C1160.2 1429.1 1108.1 1410.4 1067.8 1373.9C1027.6 1337.4 1004 1287.3 1001.4 1232.9C998.8 1178.6 1017.5 1126.5 1054 1086.2C1090.5 1046 1140.6 1022.4 1195 1019.8C1198.8 1019.6 1202.7 1019.5 1206.6 1019.5L1188.7 1034.2L1209.1 1036.2L1235.5 1014.7L1213.9 988.2L1193.6 986.2L1209.3 1005.4C1204.3 1005.3 1199.3 1005.4 1194.3 1005.6C1136.2 1008.4 1082.6 1033.7 1043.5 1076.7C1004.4 1119.7 984.4 1175.5 987.2 1233.6C990 1291.7 1015.3 1345.3 1058.3 1384.4C1101.3 1423.5 1157.1 1443.5 1215.2 1440.7C1273.3 1437.9 1326.9 1412.6 1366 1369.6C1405.1 1326.6 1425.1 1270.8 1422.3 1212.7C1420.1 1164.4 1402.4 1119.5 1371.3 1082.7Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1050 1200.7H1058.3V1230H1050V1200.7Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1088.8 1229.9V1215C1088.8 1213 1087.8 1212 1086 1212C1084.2 1212 1083.3 1212.9 1083.3 1214.9V1229.9H1075.8V1214.9C1075.8 1212.8 1074.9 1212 1073.1 1212C1071.9 1212 1070.9 1212.5 1070.3 1213.3V1229.9H1062.8V1206.2L1068.2 1205.9L1069.4 1207.9C1071.1 1206.4 1073.2 1205.6 1075.4 1205.6C1078.2 1205.6 1080.2 1206.5 1081.6 1208.6C1083.4 1206.5 1085.7 1205.6 1088.3 1205.6C1093.1 1205.6 1096.5 1208.7 1096.5 1214.8V1229.9H1088.8Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1112.6 1230.3C1110.7 1230.3 1109 1229.9 1107.6 1228.7H1107.5L1107.6 1231.9V1236H1100.1V1206.2L1105.3 1205.9L1106.7 1207.8C1108.3 1206.4 1110.5 1205.7 1112.8 1205.7C1118.7 1205.7 1121.9 1208.9 1121.9 1218C1121.8 1226.1 1118.1 1230.3 1112.6 1230.3ZM1110.6 1212C1109.3 1212 1108.1 1212.6 1107.5 1213.5V1222.7C1108.1 1223.6 1109.1 1224.3 1110.5 1224.3C1112.6 1224.3 1114 1223.2 1114 1217.9C1114 1213.2 1113.1 1212 1110.6 1212Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1125 1229.9V1199.8L1132.6 1199.4V1229.9H1125Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1135.9 1217.8C1135.9 1210.2 1139.3 1205.6 1146.8 1205.6C1153.2 1205.6 1156.9 1209.2 1156.9 1216.7C1156.9 1217.8 1156.8 1219.1 1156.7 1220.1H1143.6C1143.9 1223 1145.6 1224.1 1149.1 1224.1C1151.2 1224.1 1153.4 1223.8 1155.4 1223.1L1156.2 1228.9C1154.2 1229.8 1151.3 1230.3 1148.2 1230.3C1139.8 1230.3 1135.9 1225.9 1135.9 1217.8ZM1150.1 1215.4C1150 1212.7 1148.9 1211.6 1146.9 1211.6C1144.6 1211.6 1143.8 1212.5 1143.6 1215.4H1150.1Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1185.9 1229.9V1215C1185.9 1213 1184.9 1212 1183.1 1212C1181.3 1212 1180.4 1212.9 1180.4 1214.9V1229.9H1172.9V1214.9C1172.9 1212.8 1172 1212 1170.2 1212C1169 1212 1168 1212.5 1167.4 1213.3V1229.9H1159.9V1206.2L1165.3 1205.9L1166.5 1207.9C1168.2 1206.4 1170.3 1205.6 1172.5 1205.6C1175.3 1205.6 1177.3 1206.5 1178.7 1208.6C1180.5 1206.5 1182.8 1205.6 1185.4 1205.6C1190.2 1205.6 1193.6 1208.7 1193.6 1214.8V1229.9H1185.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1196.1 1217.8C1196.1 1210.2 1199.5 1205.6 1207 1205.6C1213.4 1205.6 1217.1 1209.2 1217.1 1216.7C1217.1 1217.8 1217 1219.1 1216.9 1220.1H1203.8C1204.1 1223 1205.8 1224.1 1209.3 1224.1C1211.4 1224.1 1213.6 1223.8 1215.6 1223.1L1216.4 1228.9C1214.4 1229.8 1211.5 1230.3 1208.4 1230.3C1200 1230.3 1196.1 1225.9 1196.1 1217.8ZM1210.3 1215.4C1210.2 1212.7 1209.1 1211.6 1207.1 1211.6C1204.8 1211.6 1204 1212.5 1203.8 1215.4H1210.3Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1233.6 1229.9V1215C1233.6 1212.8 1232.6 1212 1230.7 1212C1229.5 1212 1228.2 1212.5 1227.6 1213.4V1230H1220.1V1206.3L1225.5 1206L1226.6 1207.9C1228.4 1206.5 1230.6 1205.8 1232.9 1205.8C1237.9 1205.8 1241.2 1209.2 1241.2 1215.2V1230.1H1233.6V1229.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1253.2 1211.5V1222.5C1253.2 1224 1253.9 1224.7 1255.7 1224.7C1256.3 1224.7 1257.1 1224.6 1257.5 1224.4L1257.9 1229.6C1257 1230 1255.2 1230.3 1253.4 1230.3C1248.2 1230.3 1245.7 1227.3 1245.7 1222.6V1211.4H1243.4V1206H1246.1L1247.3 1200.9L1253.2 1200.4V1206H1258.2V1211.4H1253.2V1211.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1273.4 1228C1271.8 1229.5 1269.7 1230.3 1267.2 1230.3C1262.6 1230.3 1259.8 1227.6 1259.8 1222.3C1259.8 1217.9 1263 1214.8 1268.3 1214.8C1269.9 1214.8 1271.2 1215 1272.3 1215.3V1214.4C1272.3 1212.6 1271.3 1211.7 1268.4 1211.7C1265.7 1211.7 1264.1 1212.1 1261.9 1212.9L1261.3 1207.2C1263.8 1206.1 1266.6 1205.6 1269.8 1205.6C1277.3 1205.6 1279.8 1208.8 1279.8 1215.4V1229.8H1274L1273.4 1228ZM1272.2 1220C1271.7 1219.8 1270.8 1219.6 1269.6 1219.6C1267.6 1219.6 1266.7 1220.4 1266.7 1222.1C1266.7 1223.8 1267.7 1224.5 1269.2 1224.5C1270.8 1224.5 1272.2 1223.6 1272.2 1222.3V1220Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1291.8 1211.5V1222.5C1291.8 1224 1292.5 1224.7 1294.3 1224.7C1294.9 1224.7 1295.7 1224.6 1296.1 1224.4L1296.5 1229.6C1295.6 1230 1293.8 1230.3 1292 1230.3C1286.8 1230.3 1284.3 1227.3 1284.3 1222.6V1211.4H1282V1206H1284.7L1285.9 1200.9L1291.8 1200.4V1206H1296.8V1211.4H1291.8V1211.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1303.7 1204.3C1300.9 1204.3 1299.6 1203.5 1299.6 1201.4C1299.6 1199.5 1300.9 1198.5 1303.7 1198.5C1306.5 1198.5 1307.8 1199.5 1307.8 1201.4C1307.7 1203.3 1306.5 1204.3 1303.7 1204.3ZM1299.8 1229.9V1206.3L1307.5 1205.9V1229.9H1299.8Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1322.3 1230.5C1314.5 1230.5 1311 1226.9 1311 1218.1C1311 1209.3 1314.5 1205.6 1322.3 1205.6C1330.1 1205.6 1333.5 1209.3 1333.5 1218.1C1333.5 1226.9 1330.1 1230.5 1322.3 1230.5ZM1322.3 1224.6C1324.9 1224.6 1325.8 1223.4 1325.8 1218.1C1325.8 1212.8 1324.9 1211.6 1322.3 1211.6C1319.7 1211.6 1318.8 1212.8 1318.8 1218.1C1318.8 1223.4 1319.7 1224.6 1322.3 1224.6Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1350 1229.9V1215C1350 1212.8 1349 1212 1347.1 1212C1345.9 1212 1344.6 1212.5 1344 1213.4V1230H1336.5V1206.3L1341.9 1206L1343 1207.9C1344.8 1206.5 1347 1205.8 1349.3 1205.8C1354.3 1205.8 1357.6 1209.2 1357.6 1215.2V1230.1H1350V1229.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1171.2 1247.5L1174.6 1265.6L1175.1 1268.7H1175.2L1175.6 1265.6L1178.6 1247.5H1186.7L1179.9 1276.8H1170.2L1167.2 1259.2L1166.7 1256H1166.6L1166.1 1259.2L1163.1 1276.8H1153.4L1146.6 1247.5H1155.1L1158 1265.7L1158.5 1268.8H1158.6L1159.1 1265.7L1162.5 1247.5H1171.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1202.7 1276.7V1261.8C1202.7 1259.7 1201.8 1258.8 1199.8 1258.8C1198.4 1258.8 1197.3 1259.4 1196.7 1260.2V1276.8H1189.2V1246.7L1196.7 1246.3V1251L1196.6 1254.1L1196.7 1254.2C1198.5 1252.9 1200.4 1252.5 1202.2 1252.5C1207.2 1252.5 1210.2 1256 1210.2 1261.9V1276.8L1202.7 1276.7Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1213 1264.6C1213 1257 1216.4 1252.4 1223.9 1252.4C1230.3 1252.4 1234 1256 1234 1263.5C1234 1264.6 1233.9 1265.9 1233.8 1266.9H1220.7C1221 1269.8 1222.7 1270.9 1226.2 1270.9C1228.3 1270.9 1230.5 1270.6 1232.5 1269.9L1233.3 1275.7C1231.3 1276.6 1228.4 1277.1 1225.3 1277.1C1216.9 1277.1 1213 1272.7 1213 1264.6ZM1227.2 1262.2C1227.1 1259.5 1226 1258.4 1224 1258.4C1221.7 1258.4 1220.9 1259.3 1220.7 1262.2H1227.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1236.1 1264.6C1236.1 1257 1239.5 1252.4 1247 1252.4C1253.4 1252.4 1257.1 1256 1257.1 1263.5C1257.1 1264.6 1257 1265.9 1256.9 1266.9H1243.8C1244.1 1269.8 1245.8 1270.9 1249.3 1270.9C1251.4 1270.9 1253.6 1270.6 1255.6 1269.9L1256.4 1275.7C1254.4 1276.6 1251.5 1277.1 1248.4 1277.1C1240 1277.1 1236.1 1272.7 1236.1 1264.6ZM1250.3 1262.2C1250.2 1259.5 1249.1 1258.4 1247.1 1258.4C1244.8 1258.4 1244 1259.3 1243.8 1262.2H1250.3Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1260.3 1276.7V1246.6L1267.9 1246.2V1276.7H1260.3Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1574.4 1229.6C1570.4 1229.6 1567.2 1228.5 1564.6 1226.9L1565.2 1224C1567.9 1225.7 1570.8 1226.7 1574.4 1226.7C1579.9 1226.7 1582.7 1224 1582.7 1219.6C1582.7 1210.4 1565 1214.4 1565 1203C1565 1197.8 1568.3 1193.8 1575.7 1193.8C1579.1 1193.8 1582.1 1194.5 1584.5 1195.6L1583.9 1198.3C1581.3 1197.2 1578.7 1196.6 1575.8 1196.6C1570.1 1196.6 1567.8 1199.5 1567.8 1202.8C1567.8 1211.6 1585.6 1207.4 1585.6 1219.4C1585.6 1225.8 1581.7 1229.6 1574.4 1229.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1596.3 1204.1V1222.5C1596.3 1225.8 1598 1227.1 1600.7 1227.1C1601.5 1227.1 1602.4 1227 1603 1226.7L1603.2 1228.9C1602.5 1229.2 1601.1 1229.5 1599.9 1229.5C1595.7 1229.5 1593.4 1227.3 1593.4 1222.6V1204.2H1589.2V1201.9H1593.5L1594.1 1194.8L1596.3 1194.5V1201.9H1603.4V1204.2L1596.3 1204.1Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1624.9 1225.2C1623.3 1227.8 1620.1 1229.5 1616.3 1229.5C1611.4 1229.5 1608 1227.1 1608 1221C1608 1215.8 1611.2 1212.9 1617.8 1212.9C1620.2 1212.9 1622.7 1213.4 1624.6 1214.1V1210.7C1624.6 1205.5 1622.4 1203.9 1617.7 1203.9C1614.9 1203.9 1612.4 1204.4 1610 1205.6L1609.7 1203.2C1612.3 1202 1615 1201.4 1618.2 1201.4C1624.8 1201.4 1627.5 1204.1 1627.5 1211.2V1229.1H1625.4L1624.9 1225.2ZM1624.5 1216.2C1623.8 1215.7 1621.4 1214.9 1618.2 1214.9C1613 1214.9 1610.7 1217.1 1610.7 1220.8C1610.7 1225.4 1613.5 1227 1617.1 1227C1621.1 1227 1624.6 1224.2 1624.6 1221.6L1624.5 1216.2Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1640.2 1216.6C1638.4 1217 1637.4 1217.9 1637.4 1219.1C1637.4 1220.2 1638.2 1221 1640.5 1221.3L1646.8 1221.9C1653.5 1222.6 1655.4 1224.6 1655.4 1228.2C1655.4 1233 1652.6 1236 1644.4 1236C1637.3 1236 1634.3 1233.1 1634.3 1228.8C1634.3 1226.7 1635.4 1224.7 1637.3 1223.2C1635.9 1222.6 1634.8 1221.4 1634.8 1219.6C1634.8 1217.9 1636 1216.5 1638.3 1215.7C1636.4 1214.6 1635 1212.6 1635 1209.3C1635 1204.2 1637.6 1201.3 1644.2 1201.3C1646.5 1201.3 1649 1201.8 1650.8 1203.2L1655.2 1201.2L1655.8 1202.6L1652.3 1204.9C1652.9 1206 1653.3 1207.4 1653.3 1209.1C1653.3 1214.2 1651 1217 1644.2 1217C1642.8 1217.2 1641.4 1217 1640.2 1216.6ZM1639.7 1223.8C1637.8 1225 1636.8 1226.9 1636.8 1228.5C1636.8 1231.7 1639 1233.7 1644.3 1233.7C1651 1233.7 1652.8 1231.6 1652.8 1228.6C1652.8 1226.3 1651.6 1225 1646.4 1224.5L1639.7 1223.8ZM1637.7 1209.4C1637.7 1214 1640.5 1215 1644.1 1215C1649.1 1215 1650.6 1213.1 1650.6 1209.4C1650.6 1205.2 1648.6 1203.7 1644.2 1203.7C1639.4 1203.7 1637.7 1205.6 1637.7 1209.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1659.5 1214.5C1659.5 1206 1663.1 1201.4 1670.3 1201.4C1676.4 1201.4 1680.7 1205.1 1680.7 1213.9C1680.7 1214.3 1680.7 1214.9 1680.6 1215.3H1662.3C1662.4 1224 1665.3 1226.9 1671.8 1226.9C1674.7 1226.9 1677 1226.2 1679.3 1225L1679.7 1227.4C1677.4 1228.7 1674.7 1229.5 1671.6 1229.5C1663 1229.5 1659.5 1225 1659.5 1214.5ZM1678.2 1213.3C1678.1 1206.4 1674.9 1203.9 1670.4 1203.9C1665.1 1203.9 1662.7 1207.1 1662.4 1213.3H1678.2Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1706.4 1226L1724.2 1226.1L1724.1 1228.9H1702.2V1226.8C1711.4 1218.2 1720.9 1210.6 1720.9 1203.5C1720.9 1199.8 1718.5 1196.8 1712.5 1196.8C1709 1196.8 1705.7 1198.3 1703.3 1200.4L1702.4 1197.9C1704.9 1195.7 1708.7 1194 1712.9 1194C1719.9 1194 1723.8 1197.4 1723.8 1203.2C1723.9 1210.8 1716.5 1217.6 1706.4 1226Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1595.2 1249.5L1583.8 1284H1573.1L1561.7 1249.5H1572.3L1577.8 1270.7L1578.7 1274.3H1578.8L1579.6 1270.7L1585.2 1249.5H1595.2Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1593.7 1269.7C1593.7 1260.7 1597.7 1255.3 1606.6 1255.3C1614.1 1255.3 1618.6 1259.5 1618.6 1268.4C1618.6 1269.7 1618.5 1271.2 1618.4 1272.4H1603C1603.4 1275.8 1605.4 1277.1 1609.5 1277.1C1612 1277.1 1614.5 1276.7 1616.9 1276L1617.9 1282.8C1615.6 1283.8 1612.1 1284.5 1608.4 1284.5C1598.3 1284.5 1593.7 1279.2 1593.7 1269.7ZM1610.5 1266.9C1610.4 1263.7 1609.1 1262.4 1606.7 1262.4C1604 1262.4 1603.1 1263.5 1602.8 1266.9H1610.5Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1622 1284V1256.1L1629 1255.8L1629.8 1259.8H1629.9C1631.6 1256.8 1634.2 1255.4 1637.1 1255.4C1638.4 1255.4 1639.5 1255.6 1640.3 1255.9L1639.8 1264.3C1638.9 1264 1637.7 1263.8 1636.5 1263.8C1633.1 1263.8 1630.8 1266 1630.8 1270V1284H1622Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1648.6 1253.8C1645.3 1253.8 1643.8 1252.9 1643.8 1250.3C1643.8 1248 1645.3 1246.8 1648.6 1246.8C1652 1246.8 1653.4 1248 1653.4 1250.3C1653.4 1252.6 1652 1253.8 1648.6 1253.8ZM1644 1284V1256.1L1653.1 1255.7V1284H1644Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1668.8 1284H1660V1262.2H1656.8V1255.9H1659.9V1254.8C1659.9 1250.8 1663 1247.3 1669.8 1247.3C1671.5 1247.3 1673.1 1247.5 1674.1 1247.8L1673.9 1253.4C1673.3 1253.3 1672.6 1253.2 1671.8 1253.2C1669.5 1253.2 1668.8 1253.9 1668.8 1255V1255.9H1686.2V1284.1H1677.2V1262.3H1668.8V1284ZM1681.7 1253.7C1678.4 1253.7 1676.9 1252.8 1676.9 1250.3C1676.9 1248 1678.4 1246.8 1681.7 1246.8C1685.1 1246.8 1686.5 1248 1686.5 1250.3C1686.5 1252.6 1685.1 1253.7 1681.7 1253.7Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1710 1263.7C1708.7 1263.3 1707.1 1263 1705.7 1263C1701 1263 1699.6 1264.8 1699.6 1270.1C1699.6 1275.2 1701.8 1277.3 1705.5 1277.3C1707.2 1277.3 1708.8 1277 1710.1 1276.5L1710.7 1283.1C1709.1 1284 1706.7 1284.6 1703.6 1284.6C1695 1284.6 1690.4 1279.7 1690.4 1270.2C1690.4 1261 1694.4 1255.5 1703.8 1255.5C1706.1 1255.5 1708.8 1256 1710.5 1256.6L1710 1263.7Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1728.8 1281.7C1727 1283.4 1724.5 1284.5 1721.5 1284.5C1716 1284.5 1712.7 1281.4 1712.7 1275.1C1712.7 1269.9 1716.4 1266.2 1722.7 1266.2C1724.6 1266.2 1726.1 1266.4 1727.4 1266.8V1265.8C1727.4 1263.7 1726.2 1262.6 1722.8 1262.6C1719.6 1262.6 1717.8 1263.1 1715.1 1264L1714.4 1257.3C1717.3 1256.1 1720.7 1255.5 1724.4 1255.5C1733.3 1255.5 1736.2 1259.3 1736.2 1267.1V1284.1H1729.4L1728.8 1281.7ZM1727.5 1272.3C1726.9 1272.1 1725.8 1271.9 1724.5 1271.9C1722.2 1271.9 1721 1272.9 1721 1274.9C1721 1276.9 1722.2 1277.8 1723.9 1277.8C1725.8 1277.8 1727.4 1276.7 1727.4 1275.2L1727.5 1272.3Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1750.5 1262.2V1275.1C1750.5 1276.9 1751.4 1277.7 1753.4 1277.7C1754.1 1277.7 1755.1 1277.6 1755.6 1277.4L1756.1 1283.6C1755.1 1284.1 1753 1284.5 1750.8 1284.5C1744.6 1284.5 1741.7 1281 1741.7 1275.4V1262.2H1739V1255.8H1742.1L1743.5 1249.8L1750.5 1249.2V1255.8H1756.3V1262.2H1750.5Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1764.6 1253.8C1761.3 1253.8 1759.8 1252.9 1759.8 1250.3C1759.8 1248 1761.3 1246.8 1764.6 1246.8C1768 1246.8 1769.4 1248 1769.4 1250.3C1769.4 1252.6 1767.9 1253.8 1764.6 1253.8ZM1760 1284V1256.1L1769.1 1255.7V1284H1760Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1786.5 1284.6C1777.3 1284.6 1773.2 1280.3 1773.2 1270C1773.2 1259.7 1777.3 1255.3 1786.5 1255.3C1795.7 1255.3 1799.7 1259.7 1799.7 1270C1799.8 1280.3 1795.8 1284.6 1786.5 1284.6ZM1786.6 1277.7C1789.6 1277.7 1790.7 1276.2 1790.7 1270C1790.7 1263.7 1789.6 1262.3 1786.6 1262.3C1783.5 1262.3 1782.5 1263.7 1782.5 1270C1782.4 1276.3 1783.5 1277.7 1786.6 1277.7Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1819.2 1284V1266.4C1819.2 1263.8 1818.1 1262.8 1815.8 1262.8C1814.3 1262.8 1812.9 1263.4 1812.1 1264.4V1284H1803.3V1256L1809.6 1255.6L1810.8 1257.8C1812.9 1256.1 1815.5 1255.3 1818.3 1255.3C1824.2 1255.3 1828.1 1259.3 1828.1 1266.4V1284H1819.2Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1368.2 1558.9C1364.2 1558.9 1361 1557.8 1358.4 1556.2L1359 1553.3C1361.7 1555 1364.6 1556 1368.2 1556C1373.7 1556 1376.5 1553.3 1376.5 1548.9C1376.5 1539.7 1358.8 1543.7 1358.8 1532.3C1358.8 1527.1 1362.1 1523.1 1369.5 1523.1C1372.9 1523.1 1375.9 1523.8 1378.3 1524.9L1377.7 1527.6C1375.1 1526.5 1372.5 1525.9 1369.6 1525.9C1363.9 1525.9 1361.6 1528.8 1361.6 1532.1C1361.6 1540.9 1379.4 1536.7 1379.4 1548.7C1379.4 1555 1375.6 1558.9 1368.2 1558.9Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1390.2 1533.4V1551.8C1390.2 1555.1 1391.9 1556.4 1394.6 1556.4C1395.4 1556.4 1396.3 1556.3 1396.9 1556L1397.1 1558.2C1396.4 1558.5 1395 1558.8 1393.8 1558.8C1389.6 1558.8 1387.3 1556.6 1387.3 1551.9V1533.5H1383.1V1531.2H1387.4L1388 1524.1L1390.2 1523.8V1531.2H1397.3V1533.5L1390.2 1533.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1418.7 1554.4C1417.1 1557 1413.9 1558.7 1410.1 1558.7C1405.2 1558.7 1401.8 1556.3 1401.8 1550.2C1401.8 1545 1405 1542.1 1411.6 1542.1C1414 1542.1 1416.5 1542.6 1418.4 1543.3V1539.9C1418.4 1534.7 1416.2 1533.1 1411.5 1533.1C1408.7 1533.1 1406.2 1533.6 1403.8 1534.8L1403.5 1532.4C1406.1 1531.2 1408.8 1530.6 1412 1530.6C1418.6 1530.6 1421.3 1533.3 1421.3 1540.4V1558.3H1419.2L1418.7 1554.4ZM1418.4 1545.5C1417.7 1545 1415.3 1544.2 1412.1 1544.2C1406.9 1544.2 1404.6 1546.4 1404.6 1550.1C1404.6 1554.7 1407.4 1556.3 1411 1556.3C1415 1556.3 1418.5 1553.5 1418.5 1550.9L1418.4 1545.5Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1434 1545.9C1432.2 1546.3 1431.2 1547.2 1431.2 1548.4C1431.2 1549.5 1432 1550.3 1434.3 1550.6L1440.6 1551.2C1447.3 1551.9 1449.2 1553.9 1449.2 1557.5C1449.2 1562.3 1446.4 1565.3 1438.2 1565.3C1431.1 1565.3 1428.1 1562.4 1428.1 1558.1C1428.1 1556 1429.2 1554 1431.1 1552.5C1429.7 1551.9 1428.6 1550.7 1428.6 1548.9C1428.6 1547.2 1429.8 1545.8 1432.1 1545C1430.2 1543.9 1428.8 1541.9 1428.8 1538.6C1428.8 1533.5 1431.4 1530.6 1438 1530.6C1440.3 1530.6 1442.8 1531.1 1444.6 1532.5L1449 1530.5L1449.6 1531.9L1446.1 1534.2C1446.7 1535.3 1447.1 1536.7 1447.1 1538.4C1447.1 1543.5 1444.8 1546.3 1438 1546.3C1436.6 1546.4 1435.3 1546.3 1434 1545.9ZM1433.5 1553.1C1431.6 1554.3 1430.6 1556.2 1430.6 1557.8C1430.6 1561 1432.8 1563 1438.1 1563C1444.8 1563 1446.6 1560.9 1446.6 1557.9C1446.6 1555.6 1445.4 1554.3 1440.2 1553.8L1433.5 1553.1ZM1431.5 1538.7C1431.5 1543.3 1434.3 1544.3 1437.9 1544.3C1442.9 1544.3 1444.4 1542.4 1444.4 1538.7C1444.4 1534.5 1442.4 1533 1438 1533C1433.2 1533 1431.5 1534.9 1431.5 1538.7Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1453.4 1543.8C1453.4 1535.3 1457 1530.7 1464.2 1530.7C1470.3 1530.7 1474.6 1534.4 1474.6 1543.2C1474.6 1543.6 1474.6 1544.2 1474.5 1544.6H1456.2C1456.3 1553.3 1459.2 1556.2 1465.7 1556.2C1468.6 1556.2 1470.9 1555.5 1473.2 1554.3L1473.6 1556.7C1471.3 1558 1468.6 1558.8 1465.5 1558.8C1456.8 1558.8 1453.4 1554.3 1453.4 1543.8ZM1472 1542.6C1471.9 1535.7 1468.7 1533.2 1464.2 1533.2C1458.9 1533.2 1456.5 1536.4 1456.2 1542.6H1472Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1506.1 1558.6C1501.4 1558.6 1498.2 1557.3 1495.8 1555.8L1496.5 1553.3C1499.2 1555 1502.2 1556 1506.1 1556C1512.6 1556 1515.5 1552.5 1515.5 1547.8C1515.5 1542.9 1512.1 1540.9 1503.5 1540.9V1538.6C1511.5 1538.6 1514.6 1536.2 1514.6 1531.9C1514.6 1527.9 1511.4 1526.1 1506.4 1526.1C1502.5 1526.1 1499.6 1527.4 1497.1 1529.6L1496.2 1527.3C1498.5 1525.2 1502.1 1523.4 1506.6 1523.4C1513.3 1523.4 1517.4 1526.3 1517.4 1531.8C1517.4 1536.3 1514.6 1538.7 1510.9 1539.7V1539.8C1515.7 1540.8 1518.4 1543.3 1518.4 1548.1C1518.3 1554.4 1514.6 1558.6 1506.1 1558.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1382.8 1605.2L1382.6 1613.3H1358.3V1578.8H1382L1382.1 1586.9H1367.8V1592H1379.6V1599.4H1367.8V1605.3L1382.8 1605.2Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1399.8 1606.8L1398.4 1603.3H1398.3L1396.9 1606.8L1394.2 1613.2H1384.4L1392.5 1599L1384.6 1585H1394.9L1397.5 1591L1398.9 1594.5H1399L1400.4 1591L1403 1585H1412.8L1405.3 1598.8L1412.9 1613.2H1402.7L1399.8 1606.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1430.3 1611C1428.5 1612.7 1426 1613.8 1423 1613.8C1417.5 1613.8 1414.2 1610.7 1414.2 1604.4C1414.2 1599.2 1417.9 1595.5 1424.2 1595.5C1426.1 1595.5 1427.6 1595.7 1428.9 1596.1V1595.1C1428.9 1593 1427.7 1591.9 1424.3 1591.9C1421.1 1591.9 1419.3 1592.4 1416.6 1593.3L1415.9 1586.6C1418.8 1585.4 1422.2 1584.8 1425.9 1584.8C1434.8 1584.8 1437.7 1588.6 1437.7 1596.4V1613.4H1430.9L1430.3 1611ZM1429 1601.6C1428.4 1601.4 1427.3 1601.2 1426 1601.2C1423.7 1601.2 1422.5 1602.2 1422.5 1604.2C1422.5 1606.2 1423.7 1607.1 1425.4 1607.1C1427.3 1607.1 1428.9 1606 1428.9 1604.5L1429 1601.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1472.8 1613.3V1595.7C1472.8 1593.3 1471.6 1592.2 1469.5 1592.2C1467.4 1592.2 1466.3 1593.3 1466.3 1595.6V1613.3H1457.4V1595.6C1457.4 1593.2 1456.3 1592.2 1454.2 1592.2C1452.8 1592.2 1451.6 1592.8 1450.9 1593.8V1613.4H1442.1V1585.5L1448.4 1585.1L1449.8 1587.5C1451.8 1585.8 1454.3 1584.7 1456.8 1584.7C1460.2 1584.7 1462.5 1585.8 1464.1 1588.2C1466.2 1585.7 1468.9 1584.7 1472 1584.7C1477.7 1584.7 1481.6 1588.4 1481.6 1595.6V1613.4L1472.8 1613.3Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1491.1 1583C1487.8 1583 1486.3 1582.1 1486.3 1579.5C1486.3 1577.2 1487.8 1576 1491.1 1576C1494.5 1576 1495.9 1577.2 1495.9 1579.5C1495.9 1581.9 1494.5 1583 1491.1 1583ZM1486.6 1613.3V1585.4L1495.7 1585V1613.3H1486.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1516.8 1613.3V1595.7C1516.8 1593.1 1515.7 1592.1 1513.4 1592.1C1511.9 1592.1 1510.5 1592.7 1509.7 1593.7V1613.3H1500.9V1585.3L1507.2 1584.9L1508.4 1587.1C1510.5 1585.4 1513.1 1584.6 1515.9 1584.6C1521.8 1584.6 1525.7 1588.6 1525.7 1595.7V1613.3H1516.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1545.2 1611C1543.4 1612.7 1540.9 1613.8 1537.9 1613.8C1532.4 1613.8 1529.1 1610.7 1529.1 1604.4C1529.1 1599.2 1532.8 1595.5 1539.1 1595.5C1541 1595.5 1542.5 1595.7 1543.8 1596.1V1595.1C1543.8 1593 1542.6 1591.9 1539.2 1591.9C1536 1591.9 1534.2 1592.4 1531.5 1593.3L1530.8 1586.6C1533.7 1585.4 1537.1 1584.8 1540.8 1584.8C1549.7 1584.8 1552.6 1588.6 1552.6 1596.4V1613.4H1545.8L1545.2 1611ZM1543.9 1601.6C1543.3 1601.4 1542.2 1601.2 1540.9 1601.2C1538.6 1601.2 1537.4 1602.2 1537.4 1604.2C1537.4 1606.2 1538.6 1607.1 1540.3 1607.1C1542.2 1607.1 1543.8 1606 1543.8 1604.5L1543.9 1601.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1566.9 1591.5V1604.4C1566.9 1606.2 1567.8 1607 1569.8 1607C1570.5 1607 1571.5 1606.9 1572 1606.7L1572.5 1612.9C1571.5 1613.4 1569.4 1613.8 1567.2 1613.8C1561 1613.8 1558.1 1610.3 1558.1 1604.7V1591.5H1555.4V1585.1H1558.5L1559.9 1579.1L1566.9 1578.5V1585.1H1572.7V1591.5H1566.9Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1580.9 1583C1577.6 1583 1576.1 1582.1 1576.1 1579.5C1576.1 1577.2 1577.6 1576 1580.9 1576C1584.3 1576 1585.7 1577.2 1585.7 1579.5C1585.7 1581.9 1584.3 1583 1580.9 1583ZM1576.4 1613.3V1585.4L1585.5 1585V1613.3H1576.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1602.9 1613.9C1593.7 1613.9 1589.6 1609.6 1589.6 1599.3C1589.6 1589 1593.7 1584.6 1602.9 1584.6C1612.1 1584.6 1616.1 1589 1616.1 1599.3C1616.2 1609.6 1612.2 1613.9 1602.9 1613.9ZM1602.9 1607C1605.9 1607 1607 1605.5 1607 1599.3C1607 1593 1605.9 1591.6 1602.9 1591.6C1599.8 1591.6 1598.8 1593 1598.8 1599.3C1598.8 1605.6 1599.9 1607 1602.9 1607Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1635.6 1613.3V1595.7C1635.6 1593.1 1634.5 1592.1 1632.2 1592.1C1630.7 1592.1 1629.3 1592.7 1628.5 1593.7V1613.3H1619.7V1585.3L1626 1584.9L1627.2 1587.1C1629.3 1585.4 1631.9 1584.6 1634.7 1584.6C1640.6 1584.6 1644.5 1588.6 1644.5 1595.7V1613.3H1635.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1688.2 1613.7C1685.9 1612.8 1683.4 1611.5 1680.9 1609.9C1677.9 1612.3 1674.2 1613.9 1669.6 1613.9C1661.6 1613.9 1658.6 1608.9 1658.6 1603.9C1658.6 1599.4 1661.3 1596.4 1664.4 1594.1C1662.7 1591.4 1661.6 1588.9 1661.6 1586.9C1661.6 1582.2 1664.2 1578.2 1672.4 1578.2C1678.4 1578.2 1683 1581.1 1683 1585.7C1683 1589.9 1679.1 1592.7 1675.2 1595.3C1676.6 1596.7 1678.2 1598.2 1680 1599.5C1680.9 1597.9 1681.6 1596.2 1682 1594.5H1689.6C1689.3 1596.9 1688.2 1600.3 1686.2 1603.6C1687.9 1604.5 1689.6 1605.3 1691.4 1605.9L1688.2 1613.7ZM1674.5 1605.2C1672.6 1603.6 1670.7 1601.8 1669.1 1600C1668.3 1601 1667.8 1602 1667.8 1603.1C1667.8 1605 1669 1606 1671 1606C1672.2 1606 1673.4 1605.7 1674.5 1605.2ZM1671 1590C1672.8 1588.9 1674 1587.7 1674.1 1586.5C1674.1 1585.3 1673.2 1584.8 1672.3 1584.8C1670.9 1584.8 1670 1585.5 1670 1587.1C1669.9 1587.8 1670.3 1588.8 1671 1590Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1380.8 1642.4C1379.1 1641.8 1376.8 1641.4 1374.4 1641.4C1368.7 1641.4 1367.4 1643.6 1367.4 1650.9C1367.4 1658.6 1368.9 1660.8 1374.6 1660.8C1376.9 1660.8 1379.2 1660.3 1381 1659.6L1382 1667.1C1379.6 1668.2 1376.2 1668.9 1373.3 1668.9C1362.7 1668.9 1357.4 1663.1 1357.4 1650.8C1357.4 1639.5 1362.5 1633.1 1374 1633.1C1376.7 1633.1 1379.9 1633.7 1382 1634.5L1380.8 1642.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1397.4 1668.9C1388.2 1668.9 1384.1 1664.6 1384.1 1654.3C1384.1 1644 1388.2 1639.6 1397.4 1639.6C1406.6 1639.6 1410.6 1644 1410.6 1654.3C1410.6 1664.6 1406.6 1668.9 1397.4 1668.9ZM1397.4 1662C1400.4 1662 1401.5 1660.5 1401.5 1654.3C1401.5 1648 1400.4 1646.6 1397.4 1646.6C1394.3 1646.6 1393.3 1648 1393.3 1654.3C1393.3 1660.6 1394.4 1662 1397.4 1662Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1430.1 1668.3V1650.7C1430.1 1648.1 1429 1647.1 1426.7 1647.1C1425.2 1647.1 1423.8 1647.7 1423 1648.7V1668.3H1414.2V1640.3L1420.5 1639.9L1421.7 1642.1C1423.8 1640.4 1426.4 1639.6 1429.2 1639.6C1435.1 1639.6 1439 1643.6 1439 1650.7V1668.3H1430.1Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1453.7 1668.3H1444.9V1646.5H1441.7V1640.2H1444.8V1639.1C1444.8 1635.1 1447.9 1631.6 1454.7 1631.6C1456.4 1631.6 1458 1631.8 1459 1632.1L1458.8 1637.7C1458.2 1637.6 1457.5 1637.5 1456.7 1637.5C1454.4 1637.5 1453.7 1638.2 1453.7 1639.3V1640.2H1471.1V1668.4H1462.1V1646.6H1453.7V1668.3ZM1466.7 1638C1463.4 1638 1461.9 1637.1 1461.9 1634.6C1461.9 1632.3 1463.4 1631.1 1466.7 1631.1C1470.1 1631.1 1471.5 1632.3 1471.5 1634.6C1471.5 1636.8 1470 1638 1466.7 1638Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1476.4 1668.3V1640.4L1483.4 1640.1L1484.2 1644.1H1484.3C1486 1641.1 1488.6 1639.7 1491.5 1639.7C1492.8 1639.7 1493.9 1639.9 1494.7 1640.2L1494.2 1648.6C1493.3 1648.3 1492.1 1648.1 1490.9 1648.1C1487.5 1648.1 1485.2 1650.3 1485.2 1654.3V1668.3H1476.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1528.5 1668.3V1650.7C1528.5 1648.3 1527.3 1647.2 1525.2 1647.2C1523.1 1647.2 1522 1648.3 1522 1650.6V1668.3H1513.1V1650.6C1513.1 1648.2 1512 1647.2 1509.9 1647.2C1508.5 1647.2 1507.3 1647.8 1506.6 1648.8V1668.4H1497.8V1640.5L1504.1 1640.1L1505.5 1642.5C1507.5 1640.8 1510 1639.7 1512.5 1639.7C1515.9 1639.7 1518.2 1640.8 1519.8 1643.2C1521.9 1640.7 1524.6 1639.7 1527.7 1639.7C1533.4 1639.7 1537.3 1643.4 1537.3 1650.6V1668.4L1528.5 1668.3Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1556.9 1666C1555.1 1667.7 1552.6 1668.8 1549.6 1668.8C1544.1 1668.8 1540.8 1665.7 1540.8 1659.4C1540.8 1654.2 1544.5 1650.5 1550.8 1650.5C1552.7 1650.5 1554.2 1650.7 1555.5 1651.1V1650.1C1555.5 1648 1554.3 1646.9 1550.9 1646.9C1547.7 1646.9 1545.9 1647.4 1543.2 1648.3L1542.5 1641.6C1545.4 1640.4 1548.8 1639.8 1552.5 1639.8C1561.4 1639.8 1564.3 1643.6 1564.3 1651.4V1668.4H1557.5L1556.9 1666ZM1555.5 1656.6C1554.9 1656.4 1553.8 1656.2 1552.5 1656.2C1550.2 1656.2 1549 1657.2 1549 1659.2C1549 1661.2 1550.2 1662.1 1551.9 1662.1C1553.8 1662.1 1555.4 1661 1555.4 1659.5L1555.5 1656.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1578.6 1646.5V1659.4C1578.6 1661.2 1579.5 1662 1581.5 1662C1582.2 1662 1583.2 1661.9 1583.7 1661.7L1584.2 1667.9C1583.2 1668.4 1581.1 1668.8 1578.9 1668.8C1572.7 1668.8 1569.8 1665.3 1569.8 1659.7V1646.5H1567.1V1640.1H1570.2L1571.6 1634.1L1578.6 1633.5V1640.1H1584.4V1646.5H1578.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1592.6 1638C1589.3 1638 1587.8 1637.1 1587.8 1634.5C1587.8 1632.2 1589.3 1631 1592.6 1631C1596 1631 1597.4 1632.2 1597.4 1634.5C1597.4 1636.9 1596 1638 1592.6 1638ZM1588.1 1668.3V1640.4L1597.2 1640V1668.3H1588.1Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1614.6 1668.9C1605.4 1668.9 1601.3 1664.6 1601.3 1654.3C1601.3 1644 1605.4 1639.6 1614.6 1639.6C1623.8 1639.6 1627.8 1644 1627.8 1654.3C1627.9 1664.6 1623.9 1668.9 1614.6 1668.9ZM1614.6 1662C1617.6 1662 1618.7 1660.5 1618.7 1654.3C1618.7 1648 1617.6 1646.6 1614.6 1646.6C1611.5 1646.6 1610.5 1648 1610.5 1654.3C1610.5 1660.6 1611.6 1662 1614.6 1662Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1647.3 1668.3V1650.7C1647.3 1648.1 1646.2 1647.1 1643.9 1647.1C1642.4 1647.1 1641 1647.7 1640.2 1648.7V1668.3H1631.4V1640.3L1637.7 1639.9L1638.9 1642.1C1641 1640.4 1643.6 1639.6 1646.4 1639.6C1652.3 1639.6 1656.2 1643.6 1656.2 1650.7V1668.3H1647.3Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1004.2 1641.9C1000.2 1641.9 997 1640.8 994.4 1639.2L995 1636.3C997.7 1638 1000.6 1639 1004.2 1639C1009.7 1639 1012.5 1636.3 1012.5 1631.9C1012.5 1622.7 994.8 1626.7 994.8 1615.3C994.8 1610.1 998.1 1606.1 1005.5 1606.1C1008.9 1606.1 1011.9 1606.8 1014.3 1607.9L1013.7 1610.6C1011.1 1609.5 1008.5 1608.9 1005.6 1608.9C999.9 1608.9 997.6 1611.8 997.6 1615.1C997.6 1623.9 1015.4 1619.7 1015.4 1631.7C1015.4 1638.1 1011.6 1641.9 1004.2 1641.9Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1026.2 1616.4V1634.8C1026.2 1638.1 1027.9 1639.4 1030.6 1639.4C1031.4 1639.4 1032.3 1639.3 1032.9 1639L1033.1 1641.2C1032.4 1641.5 1031 1641.8 1029.8 1641.8C1025.6 1641.8 1023.3 1639.6 1023.3 1634.9V1616.5H1019.1V1614.2H1023.4L1024 1607.1L1026.2 1606.8V1614.2H1033.3V1616.5L1026.2 1616.4Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1054.8 1637.5C1053.2 1640.1 1050 1641.8 1046.2 1641.8C1041.3 1641.8 1037.9 1639.4 1037.9 1633.3C1037.9 1628.1 1041.1 1625.2 1047.7 1625.2C1050.1 1625.2 1052.6 1625.7 1054.5 1626.4V1623C1054.5 1617.8 1052.3 1616.2 1047.6 1616.2C1044.8 1616.2 1042.3 1616.7 1039.9 1617.9L1039.6 1615.5C1042.2 1614.3 1044.9 1613.7 1048.1 1613.7C1054.7 1613.7 1057.4 1616.4 1057.4 1623.5V1641.4H1055.3L1054.8 1637.5ZM1054.4 1628.5C1053.7 1628 1051.3 1627.2 1048.1 1627.2C1042.9 1627.2 1040.6 1629.4 1040.6 1633.1C1040.6 1637.7 1043.4 1639.3 1047 1639.3C1051 1639.3 1054.5 1636.5 1054.5 1633.9L1054.4 1628.5Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1070 1628.9C1068.2 1629.3 1067.2 1630.2 1067.2 1631.4C1067.2 1632.5 1068 1633.3 1070.3 1633.6L1076.6 1634.2C1083.3 1634.9 1085.2 1636.9 1085.2 1640.5C1085.2 1645.3 1082.4 1648.3 1074.2 1648.3C1067.1 1648.3 1064.1 1645.4 1064.1 1641.1C1064.1 1639 1065.2 1637 1067.1 1635.5C1065.7 1634.9 1064.6 1633.7 1064.6 1631.9C1064.6 1630.2 1065.8 1628.8 1068.1 1628C1066.2 1626.9 1064.8 1624.9 1064.8 1621.6C1064.8 1616.5 1067.4 1613.6 1074 1613.6C1076.3 1613.6 1078.8 1614.1 1080.6 1615.5L1085 1613.5L1085.6 1614.9L1082.1 1617.2C1082.7 1618.3 1083.1 1619.7 1083.1 1621.4C1083.1 1626.5 1080.8 1629.3 1074 1629.3C1072.6 1629.5 1071.3 1629.3 1070 1628.9ZM1069.5 1636.1C1067.6 1637.3 1066.6 1639.2 1066.6 1640.8C1066.6 1644 1068.8 1646 1074.1 1646C1080.8 1646 1082.6 1643.9 1082.6 1640.9C1082.6 1638.6 1081.4 1637.3 1076.2 1636.8L1069.5 1636.1ZM1067.5 1621.7C1067.5 1626.3 1070.3 1627.3 1073.9 1627.3C1078.9 1627.3 1080.4 1625.4 1080.4 1621.7C1080.4 1617.5 1078.4 1616 1074 1616C1069.2 1616 1067.5 1617.9 1067.5 1621.7Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1089.4 1626.8C1089.4 1618.3 1093 1613.7 1100.2 1613.7C1106.3 1613.7 1110.6 1617.4 1110.6 1626.2C1110.6 1626.6 1110.6 1627.2 1110.5 1627.6H1092.2C1092.3 1636.3 1095.2 1639.2 1101.7 1639.2C1104.6 1639.2 1106.9 1638.5 1109.2 1637.3L1109.6 1639.7C1107.3 1641 1104.6 1641.8 1101.5 1641.8C1092.8 1641.8 1089.4 1637.3 1089.4 1626.8ZM1108 1625.6C1107.9 1618.7 1104.7 1616.2 1100.2 1616.2C1094.9 1616.2 1092.5 1619.4 1092.2 1625.6H1108Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1150.6 1633.2V1641.3H1147.9V1633.2H1131.6L1131.3 1631.2L1146.9 1606.7H1150.6V1630.5H1155.4L1155.3 1633.2H1150.6ZM1148 1612.9L1148.1 1609.2H1148L1146 1612.4L1136.5 1627.4L1134.5 1630.4V1630.5H1147.7L1148 1612.9Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M994.7 1661.8H1004.5V1696.3H994.7V1661.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1018.2 1696.8C1014.7 1696.8 1011.8 1696.2 1009.3 1695.1L1010.2 1688.1C1012.6 1689.3 1016 1689.9 1018.2 1689.9C1020.4 1689.9 1021.3 1689.1 1021.3 1688C1021.3 1684.5 1009 1686.9 1009 1676.8C1009 1671.6 1012.5 1667.7 1020.2 1667.7C1023.1 1667.7 1026 1668.3 1028.4 1669.3L1027.8 1676.2C1025.5 1675.3 1022.1 1674.9 1020.1 1674.9C1018.4 1674.9 1017.5 1675.5 1017.5 1676.5C1017.5 1679.9 1029.5 1677.7 1029.5 1687.3C1029.4 1692.9 1026.2 1696.8 1018.2 1696.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1040.4 1696.8C1036.9 1696.8 1034 1696.2 1031.5 1695.1L1032.4 1688.1C1034.8 1689.3 1038.2 1689.9 1040.4 1689.9C1042.6 1689.9 1043.5 1689.1 1043.5 1688C1043.5 1684.5 1031.2 1686.9 1031.2 1676.8C1031.2 1671.6 1034.7 1667.7 1042.4 1667.7C1045.3 1667.7 1048.2 1668.3 1050.6 1669.3L1050 1676.2C1047.7 1675.3 1044.3 1674.9 1042.3 1674.9C1040.6 1674.9 1039.7 1675.5 1039.7 1676.5C1039.7 1679.9 1051.7 1677.7 1051.7 1687.3C1051.7 1692.9 1048.5 1696.8 1040.4 1696.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1079.7 1686C1079.7 1693.1 1075.7 1696.8 1067.3 1696.8C1058.9 1696.8 1054.9 1693 1054.9 1686V1668.1H1063.7V1685.9C1063.7 1688.2 1064.8 1689.3 1067.3 1689.3C1069.7 1689.3 1070.8 1688.3 1070.8 1685.9V1668.1H1079.7V1686Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1099.1 1694C1097.3 1695.7 1094.8 1696.8 1091.8 1696.8C1086.3 1696.8 1083 1693.7 1083 1687.4C1083 1682.2 1086.7 1678.5 1093 1678.5C1094.9 1678.5 1096.4 1678.7 1097.7 1679.1V1678.1C1097.7 1676 1096.5 1674.9 1093.1 1674.9C1089.9 1674.9 1088.1 1675.4 1085.4 1676.3L1084.7 1669.6C1087.6 1668.4 1091 1667.8 1094.7 1667.8C1103.6 1667.8 1106.5 1671.6 1106.5 1679.4V1696.4H1099.7L1099.1 1694ZM1097.8 1684.6C1097.2 1684.4 1096.1 1684.2 1094.8 1684.2C1092.5 1684.2 1091.3 1685.2 1091.3 1687.2C1091.3 1689.2 1092.5 1690.1 1094.2 1690.1C1096.1 1690.1 1097.7 1689 1097.7 1687.5L1097.8 1684.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1126.8 1696.3V1678.7C1126.8 1676.1 1125.7 1675.1 1123.4 1675.1C1121.9 1675.1 1120.5 1675.7 1119.7 1676.7V1696.3H1110.9V1668.3L1117.2 1667.9L1118.4 1670.1C1120.5 1668.4 1123.1 1667.6 1125.9 1667.6C1131.8 1667.6 1135.7 1671.6 1135.7 1678.7V1696.3H1126.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1158.6 1676C1157.3 1675.6 1155.7 1675.3 1154.3 1675.3C1149.6 1675.3 1148.2 1677.1 1148.2 1682.4C1148.2 1687.5 1150.4 1689.6 1154.1 1689.6C1155.8 1689.6 1157.4 1689.3 1158.7 1688.8L1159.3 1695.4C1157.7 1696.3 1155.3 1696.9 1152.2 1696.9C1143.6 1696.9 1139 1692 1139 1682.5C1139 1673.3 1143 1667.8 1152.4 1667.8C1154.7 1667.8 1157.4 1668.3 1159.1 1668.9L1158.6 1676Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1160.8 1682C1160.8 1673 1164.8 1667.6 1173.7 1667.6C1181.2 1667.6 1185.7 1671.8 1185.7 1680.7C1185.7 1682 1185.6 1683.5 1185.5 1684.7H1170C1170.4 1688.1 1172.4 1689.4 1176.5 1689.4C1179 1689.4 1181.5 1689 1183.9 1688.2L1184.9 1695C1182.6 1696 1179.1 1696.7 1175.4 1696.7C1165.4 1696.8 1160.8 1691.5 1160.8 1682ZM1177.6 1679.2C1177.5 1676 1176.2 1674.7 1173.8 1674.7C1171.1 1674.7 1170.2 1675.8 1169.9 1679.2H1177.6Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1006.2 1751.9C997 1751.9 992.9 1747.6 992.9 1737.3C992.9 1727 997 1722.6 1006.2 1722.6C1015.4 1722.6 1019.4 1727 1019.4 1737.3C1019.5 1747.6 1015.5 1751.9 1006.2 1751.9ZM1006.3 1745C1009.3 1745 1010.4 1743.5 1010.4 1737.3C1010.4 1731 1009.3 1729.6 1006.3 1729.6C1003.2 1729.6 1002.2 1731 1002.2 1737.3C1002.1 1743.6 1003.2 1745 1006.3 1745Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1024.2 1751.3V1729.5H1021V1723.2H1024.1V1722.1C1024.1 1718.1 1027.2 1714.6 1034 1714.6C1035.7 1714.6 1037.5 1714.9 1038.8 1715.2L1038.3 1720.7C1037.5 1720.5 1036.7 1720.4 1035.9 1720.4C1033.7 1720.4 1033 1721.1 1033 1722.2V1723.1H1038.3V1729.4H1033V1751.2L1024.2 1751.3Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1063.4 1716.8L1070.3 1734.4L1071.6 1737.9H1071.7L1072.9 1734.4L1079.8 1716.7H1090.3V1751.2H1081.5V1736.7L1081.6 1733H1081.5L1080.2 1736.5L1074.5 1751.3H1068.2L1062.5 1736.4L1061.3 1732.9H1061.2V1736.6V1751.2H1052.7V1716.7L1063.4 1716.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1110.2 1751.9C1099.3 1751.9 1094.4 1746.1 1094.4 1734C1094.4 1721.9 1099.1 1716.2 1110.2 1716.2C1121.3 1716.2 1126 1721.9 1126 1734C1126 1746.4 1121.3 1751.9 1110.2 1751.9ZM1110.2 1724.5C1105.9 1724.5 1104.6 1726.2 1104.6 1734C1104.6 1741.8 1106 1743.7 1110.3 1743.7C1114.6 1743.7 1115.9 1741.8 1115.9 1734C1115.9 1726.2 1114.6 1724.5 1110.2 1724.5Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1138.2 1751.8C1134.7 1751.8 1131.8 1751.2 1129.3 1750.1L1130.2 1743.1C1132.6 1744.3 1136 1744.9 1138.2 1744.9C1140.4 1744.9 1141.3 1744.1 1141.3 1743C1141.3 1739.5 1129 1741.9 1129 1731.8C1129 1726.6 1132.5 1722.7 1140.2 1722.7C1143.1 1722.7 1146 1723.3 1148.4 1724.3L1147.8 1731.2C1145.5 1730.3 1142.1 1729.9 1140.1 1729.9C1138.4 1729.9 1137.5 1730.5 1137.5 1731.5C1137.5 1734.9 1149.5 1732.7 1149.5 1742.3C1149.4 1747.9 1146.2 1751.8 1138.2 1751.8Z" fill="#352D0B" />
          <path class="immplementation-wheel__inner-decoration" d="M1403.3 823.4C1399.3 823.4 1396.1 822.3 1393.5 820.7L1394.1 817.8C1396.8 819.5 1399.7 820.5 1403.3 820.5C1408.8 820.5 1411.6 817.8 1411.6 813.4C1411.6 804.2 1393.9 808.2 1393.9 796.8C1393.9 791.6 1397.2 787.6 1404.6 787.6C1408 787.6 1411 788.3 1413.4 789.4L1412.8 792.1C1410.2 791 1407.6 790.4 1404.7 790.4C1399 790.4 1396.7 793.3 1396.7 796.6C1396.7 805.4 1414.5 801.2 1414.5 813.2C1414.5 819.5 1410.6 823.4 1403.3 823.4Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1425.2 797.9V816.3C1425.2 819.6 1426.9 820.9 1429.6 820.9C1430.4 820.9 1431.3 820.8 1431.9 820.5L1432.1 822.7C1431.4 823 1430 823.3 1428.8 823.3C1424.6 823.3 1422.3 821.1 1422.3 816.4V798H1418.1V795.7H1422.4L1423 788.6L1425.2 788.3V795.7H1432.3V798L1425.2 797.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1453.8 818.9C1452.2 821.5 1449 823.2 1445.2 823.2C1440.3 823.2 1436.9 820.8 1436.9 814.7C1436.9 809.5 1440.1 806.6 1446.7 806.6C1449.1 806.6 1451.6 807.1 1453.5 807.8V804.4C1453.5 799.2 1451.3 797.6 1446.6 797.6C1443.8 797.6 1441.3 798.1 1438.9 799.3L1438.6 796.9C1441.2 795.7 1443.9 795.1 1447.1 795.1C1453.7 795.1 1456.4 797.8 1456.4 804.9V822.8H1454.3L1453.8 818.9ZM1453.4 810C1452.7 809.5 1450.3 808.7 1447.1 808.7C1441.9 808.7 1439.6 810.9 1439.6 814.6C1439.6 819.2 1442.4 820.8 1446 820.8C1450 820.8 1453.5 818 1453.5 815.4L1453.4 810Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1469.1 810.4C1467.3 810.8 1466.3 811.7 1466.3 812.9C1466.3 814 1467.1 814.8 1469.4 815.1L1475.7 815.7C1482.4 816.4 1484.3 818.4 1484.3 822C1484.3 826.8 1481.5 829.8 1473.3 829.8C1466.2 829.8 1463.2 826.9 1463.2 822.6C1463.2 820.5 1464.3 818.5 1466.2 817C1464.8 816.4 1463.7 815.2 1463.7 813.4C1463.7 811.7 1464.9 810.3 1467.2 809.5C1465.3 808.4 1463.9 806.4 1463.9 803.1C1463.9 798 1466.5 795.1 1473.1 795.1C1475.4 795.1 1477.9 795.6 1479.7 797L1484.1 795L1484.7 796.4L1481.2 798.7C1481.8 799.8 1482.2 801.2 1482.2 802.9C1482.2 808 1479.9 810.8 1473.1 810.8C1471.7 810.9 1470.3 810.8 1469.1 810.4ZM1468.6 817.6C1466.7 818.8 1465.7 820.7 1465.7 822.3C1465.7 825.5 1467.9 827.5 1473.2 827.5C1479.9 827.5 1481.7 825.4 1481.7 822.4C1481.7 820.1 1480.5 818.8 1475.3 818.3L1468.6 817.6ZM1466.6 803.2C1466.6 807.8 1469.4 808.8 1473 808.8C1478 808.8 1479.5 806.9 1479.5 803.2C1479.5 799 1477.5 797.5 1473.1 797.5C1468.3 797.5 1466.6 799.4 1466.6 803.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1488.5 808.3C1488.5 799.8 1492.1 795.2 1499.3 795.2C1505.4 795.2 1509.7 798.9 1509.7 807.7C1509.7 808.1 1509.7 808.7 1509.6 809.1H1491.3C1491.4 817.8 1494.3 820.7 1500.8 820.7C1503.7 820.7 1506 820 1508.3 818.8L1508.7 821.2C1506.4 822.5 1503.7 823.3 1500.6 823.3C1491.9 823.3 1488.5 818.7 1488.5 808.3ZM1507.1 807.1C1507 800.2 1503.8 797.7 1499.3 797.7C1494 797.7 1491.6 800.9 1491.3 807.1H1507.1Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1535.6 820.4H1542.3V796L1542.4 792.3H1542.3L1534.4 797.7L1534 795L1543.6 788.2H1545.3V820.4H1551.8V822.8H1535.6V820.4Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1403.9 843.2L1410.8 860.8L1412.1 864.3H1412.2L1413.4 860.8L1420.3 843.1H1430.8V877.6H1422V863.1L1422.1 859.4H1422L1420.7 862.9L1415 877.7H1408.7L1403 862.8L1401.8 859.3H1401.7V863V877.6H1393.2V843.1H1403.9V843.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1447.9 878.4C1438.7 878.4 1434.6 874.1 1434.6 863.8C1434.6 853.5 1438.7 849.1 1447.9 849.1C1457.1 849.1 1461.1 853.5 1461.1 863.8C1461.1 874.1 1457.2 878.4 1447.9 878.4ZM1448 871.5C1451 871.5 1452.1 870 1452.1 863.8C1452.1 857.5 1451 856.1 1448 856.1C1444.9 856.1 1443.9 857.5 1443.9 863.8C1443.8 870.1 1444.9 871.5 1448 871.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1480.6 877.8V860.2C1480.6 857.6 1479.5 856.6 1477.2 856.6C1475.7 856.6 1474.3 857.2 1473.5 858.2V877.8H1464.7V849.8L1471 849.4L1472.2 851.6C1474.3 849.9 1476.9 849.1 1479.7 849.1C1485.6 849.1 1489.5 853.1 1489.5 860.2V877.8H1480.6Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1499 847.5C1495.7 847.5 1494.2 846.6 1494.2 844C1494.2 841.7 1495.7 840.5 1499 840.5C1502.4 840.5 1503.8 841.7 1503.8 844C1503.8 846.4 1502.4 847.5 1499 847.5ZM1494.5 877.8V849.9L1503.6 849.5V877.8H1494.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1518.7 856V868.9C1518.7 870.7 1519.6 871.5 1521.6 871.5C1522.3 871.5 1523.3 871.4 1523.8 871.2L1524.3 877.4C1523.3 877.9 1521.2 878.3 1519 878.3C1512.8 878.3 1509.9 874.8 1509.9 869.2V856H1507.2V849.6H1510.3L1511.7 843.6L1518.7 843V849.6H1524.5V856H1518.7Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1539.2 878.4C1530 878.4 1525.9 874.1 1525.9 863.8C1525.9 853.5 1530 849.1 1539.2 849.1C1548.4 849.1 1552.4 853.5 1552.4 863.8C1552.4 874.1 1548.5 878.4 1539.2 878.4ZM1539.3 871.5C1542.3 871.5 1543.4 870 1543.4 863.8C1543.4 857.5 1542.3 856.1 1539.3 856.1C1536.2 856.1 1535.2 857.5 1535.2 863.8C1535.1 870.1 1536.2 871.5 1539.3 871.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1556 877.8V849.9L1563 849.6L1563.8 853.6H1563.9C1565.6 850.6 1568.2 849.2 1571.1 849.2C1572.4 849.2 1573.5 849.4 1574.3 849.7L1573.8 858.1C1572.9 857.8 1571.7 857.6 1570.5 857.6C1567.1 857.6 1564.8 859.8 1564.8 863.8V877.8H1556Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1582.6 847.5C1579.3 847.5 1577.8 846.6 1577.8 844C1577.8 841.7 1579.3 840.5 1582.6 840.5C1586 840.5 1587.4 841.7 1587.4 844C1587.4 846.4 1586 847.5 1582.6 847.5ZM1578.1 877.8V849.9L1587.2 849.5V877.8H1578.1Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1608.3 877.8V860.2C1608.3 857.6 1607.2 856.6 1604.9 856.6C1603.4 856.6 1602 857.2 1601.2 858.2V877.8H1592.4V849.8L1598.7 849.4L1599.9 851.6C1602 849.9 1604.6 849.1 1607.4 849.1C1613.3 849.1 1617.2 853.1 1617.2 860.2V877.8H1608.3Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1629.4 866.5C1629.1 866.7 1628.9 867.1 1628.9 867.4C1628.9 868 1629.4 868.4 1630.4 868.4L1637 868.7C1643.5 869 1646.2 871.6 1646.2 876.1C1646.2 881.9 1642.1 885.4 1632.7 885.4C1624.4 885.4 1620.9 882.2 1620.9 878C1620.9 876.4 1621.5 874.9 1622.8 873.8C1621.6 872.9 1620.7 871.5 1620.7 869.6C1620.7 867.7 1621.7 865.8 1624.2 864.5C1622.3 863.2 1621.2 861 1621.2 857.9C1621.2 852.8 1624.3 849 1632.5 849C1634.9 849 1637.5 849.4 1639.6 850.4L1644.4 849L1646.2 853L1643.4 855.3C1643.6 856 1643.7 856.9 1643.7 857.8C1643.7 863.3 1640.4 866.5 1632.5 866.5C1631.4 866.7 1630.4 866.7 1629.4 866.5ZM1629 875.5C1628.6 876 1628.4 876.6 1628.4 877C1628.4 878.5 1629.6 879.1 1632.6 879.1C1637.3 879.1 1638.4 878.5 1638.4 877.4C1638.4 876.4 1637.8 876.1 1635.5 875.9L1629 875.5ZM1629.9 858.2C1629.9 860.3 1630.8 860.9 1632.3 860.9C1634.5 860.9 1635.1 860.3 1635.1 858.2C1635.1 856.2 1634.4 855.5 1632.5 855.5C1630.5 855.5 1629.9 856.2 1629.9 858.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1421.2 933.2C1418.9 932.3 1416.4 931 1413.9 929.4C1410.9 931.8 1407.2 933.4 1402.6 933.4C1394.6 933.4 1391.6 928.4 1391.6 923.4C1391.6 918.9 1394.3 915.9 1397.4 913.6C1395.7 910.9 1394.6 908.4 1394.6 906.4C1394.6 901.7 1397.2 897.7 1405.4 897.7C1411.4 897.7 1416 900.6 1416 905.2C1416 909.4 1412.1 912.2 1408.2 914.8C1409.6 916.2 1411.2 917.7 1413 919C1413.9 917.4 1414.6 915.7 1415 914H1422.6C1422.3 916.4 1421.2 919.8 1419.2 923.1C1420.9 924 1422.6 924.8 1424.4 925.4L1421.2 933.2ZM1407.5 924.7C1405.6 923.1 1403.7 921.3 1402.1 919.5C1401.3 920.5 1400.8 921.5 1400.8 922.6C1400.8 924.5 1402 925.5 1404 925.5C1405.2 925.5 1406.4 925.2 1407.5 924.7ZM1404 909.5C1405.8 908.4 1407 907.2 1407.1 906C1407.1 904.8 1406.2 904.3 1405.3 904.3C1403.9 904.3 1403 905 1403 906.6C1402.9 907.3 1403.3 908.3 1404 909.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1460.3 918.9L1467.8 932.8H1456.6L1451.5 920.8H1448V932.8H1438.4V898.3H1451.6C1460.8 898.3 1465.5 901.8 1465.5 909.5C1465.5 914 1463.7 917.5 1460.3 918.9ZM1451.7 905.9H1448.1V913.8H1451.6C1454.5 913.8 1455.5 913.1 1455.5 909.8C1455.5 906.8 1454.6 905.9 1451.7 905.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1468 918.5C1468 909.5 1472 904.1 1480.9 904.1C1488.4 904.1 1492.9 908.3 1492.9 917.2C1492.9 918.5 1492.8 920 1492.7 921.2H1477.3C1477.7 924.6 1479.7 925.9 1483.8 925.9C1486.3 925.9 1488.8 925.5 1491.2 924.8L1492.2 931.6C1489.9 932.6 1486.4 933.3 1482.7 933.3C1472.6 933.3 1468 928 1468 918.5ZM1484.8 915.6C1484.7 912.4 1483.4 911.1 1481 911.1C1478.3 911.1 1477.4 912.2 1477.1 915.6H1484.8Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1511.1 933.2C1508.9 933.2 1506.9 932.7 1505.2 931.3H1505.1L1505.2 935V939.9H1496.3V904.8L1502.5 904.4L1504.1 906.6C1506 905 1508.6 904.1 1511.3 904.1C1518.3 904.1 1522 907.9 1522 918.6C1522 928.3 1517.7 933.2 1511.1 933.2ZM1508.8 911.6C1507.2 911.6 1505.9 912.3 1505.2 913.4V924.3C1505.9 925.4 1507.1 926.2 1508.7 926.2C1511.2 926.2 1512.8 925 1512.8 918.6C1512.8 913 1511.8 911.6 1508.8 911.6Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1537.7 933.4C1528.5 933.4 1524.4 929.1 1524.4 918.8C1524.4 908.5 1528.5 904.1 1537.7 904.1C1546.9 904.1 1550.9 908.5 1550.9 918.8C1550.9 929.1 1547 933.4 1537.7 933.4ZM1537.7 926.5C1540.7 926.5 1541.8 925 1541.8 918.8C1541.8 912.5 1540.7 911.1 1537.7 911.1C1534.6 911.1 1533.6 912.5 1533.6 918.8C1533.6 925.1 1534.7 926.5 1537.7 926.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1554.5 932.8V904.9L1561.5 904.6L1562.3 908.6H1562.4C1564.1 905.6 1566.7 904.2 1569.6 904.2C1570.9 904.2 1572 904.4 1572.8 904.7L1572.3 913.1C1571.4 912.8 1570.2 912.6 1569 912.6C1565.6 912.6 1563.3 914.8 1563.3 918.8V932.8H1554.5Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1585.9 911V923.9C1585.9 925.7 1586.8 926.5 1588.8 926.5C1589.5 926.5 1590.5 926.4 1591 926.2L1591.5 932.4C1590.5 932.9 1588.4 933.3 1586.2 933.3C1580 933.3 1577.1 929.8 1577.1 924.2V911H1574.4V904.6H1577.5L1578.9 898.6L1585.9 898V904.6H1591.7V911H1585.9Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1600 902.5C1596.7 902.5 1595.2 901.6 1595.2 899C1595.2 896.7 1596.7 895.5 1600 895.5C1603.4 895.5 1604.8 896.7 1604.8 899C1604.7 901.4 1603.3 902.5 1600 902.5ZM1595.4 932.8V904.9L1604.5 904.5V932.8H1595.4Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1625.6 932.8V915.2C1625.6 912.6 1624.5 911.6 1622.2 911.6C1620.7 911.6 1619.3 912.2 1618.5 913.2V932.8H1609.7V904.8L1616 904.4L1617.2 906.6C1619.3 904.9 1621.9 904.1 1624.7 904.1C1630.6 904.1 1634.5 908.1 1634.5 915.2V932.8H1625.6Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M1646.7 921.5C1646.4 921.7 1646.2 922.1 1646.2 922.4C1646.2 923 1646.7 923.4 1647.7 923.4L1654.3 923.7C1660.8 924 1663.5 926.6 1663.5 931.1C1663.5 936.9 1659.4 940.4 1650 940.4C1641.7 940.4 1638.2 937.2 1638.2 933C1638.2 931.4 1638.8 929.9 1640.1 928.8C1638.9 927.9 1638 926.5 1638 924.6C1638 922.7 1639 920.8 1641.5 919.5C1639.6 918.2 1638.5 916 1638.5 912.9C1638.5 907.8 1641.6 904 1649.8 904C1652.2 904 1654.8 904.4 1656.9 905.4L1661.7 904L1663.5 908L1660.7 910.3C1660.9 911 1661 911.9 1661 912.8C1661 918.3 1657.7 921.5 1649.8 921.5C1648.8 921.7 1647.7 921.7 1646.7 921.5ZM1646.3 930.5C1645.9 931 1645.7 931.6 1645.7 932C1645.7 933.5 1646.9 934.1 1649.9 934.1C1654.6 934.1 1655.7 933.5 1655.7 932.4C1655.7 931.4 1655.1 931.1 1652.8 930.9L1646.3 930.5ZM1647.2 913.2C1647.2 915.3 1648.1 915.9 1649.6 915.9C1651.8 915.9 1652.4 915.3 1652.4 913.2C1652.4 911.2 1651.7 910.5 1649.8 910.5C1647.9 910.5 1647.2 911.2 1647.2 913.2Z" fill="#342D15" />
          <path class="immplementation-wheel__inner-decoration" d="M677.6 1388.5C673.6 1388.5 670.4 1387.4 667.8 1385.8L668.4 1382.9C671.1 1384.6 674 1385.6 677.6 1385.6C683.1 1385.6 685.9 1382.9 685.9 1378.5C685.9 1369.3 668.2 1373.3 668.2 1361.9C668.2 1356.7 671.5 1352.7 678.9 1352.7C682.3 1352.7 685.3 1353.4 687.7 1354.5L687.1 1357.2C684.5 1356.1 681.9 1355.5 679 1355.5C673.3 1355.5 671 1358.4 671 1361.7C671 1370.5 688.8 1366.3 688.8 1378.3C688.8 1384.7 685 1388.5 677.6 1388.5Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M699.6 1363V1381.4C699.6 1384.7 701.3 1386 704 1386C704.8 1386 705.7 1385.9 706.3 1385.6L706.5 1387.8C705.8 1388.1 704.4 1388.4 703.2 1388.4C699 1388.4 696.7 1386.2 696.7 1381.5V1363H692.5V1360.7H696.8L697.4 1353.6L699.6 1353.3V1360.7H706.7V1363H699.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M728.2 1384.1C726.6 1386.7 723.4 1388.4 719.6 1388.4C714.7 1388.4 711.3 1386 711.3 1379.9C711.3 1374.7 714.5 1371.8 721.1 1371.8C723.5 1371.8 726 1372.3 727.9 1373V1369.6C727.9 1364.4 725.7 1362.8 721 1362.8C718.2 1362.8 715.7 1363.3 713.3 1364.5L713 1362.1C715.6 1360.9 718.3 1360.3 721.5 1360.3C728.1 1360.3 730.8 1363 730.8 1370.1V1388H728.7L728.2 1384.1ZM727.8 1375.1C727.1 1374.6 724.7 1373.8 721.5 1373.8C716.3 1373.8 714 1376 714 1379.7C714 1384.3 716.8 1385.9 720.4 1385.9C724.4 1385.9 727.9 1383.1 727.9 1380.5V1375.1H727.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M743.4 1375.6C741.6 1376 740.6 1376.9 740.6 1378.1C740.6 1379.2 741.4 1380 743.7 1380.3L750 1380.9C756.7 1381.6 758.6 1383.6 758.6 1387.2C758.6 1392 755.8 1395 747.6 1395C740.5 1395 737.5 1392.1 737.5 1387.8C737.5 1385.7 738.6 1383.7 740.5 1382.2C739.1 1381.6 738 1380.4 738 1378.6C738 1376.9 739.2 1375.5 741.5 1374.7C739.6 1373.6 738.2 1371.6 738.2 1368.3C738.2 1363.2 740.8 1360.3 747.4 1360.3C749.7 1360.3 752.2 1360.8 754 1362.2L758.4 1360.2L759 1361.6L755.5 1363.9C756.1 1365 756.5 1366.4 756.5 1368.1C756.5 1373.2 754.2 1376 747.4 1376C746.1 1376.1 744.7 1375.9 743.4 1375.6ZM742.9 1382.7C741 1383.9 740 1385.8 740 1387.4C740 1390.6 742.2 1392.6 747.5 1392.6C754.2 1392.6 756 1390.5 756 1387.5C756 1385.2 754.8 1383.9 749.6 1383.4L742.9 1382.7ZM740.9 1368.3C740.9 1372.9 743.7 1373.9 747.3 1373.9C752.3 1373.9 753.8 1372 753.8 1368.3C753.8 1364.1 751.8 1362.6 747.4 1362.6C742.7 1362.6 740.9 1364.5 740.9 1368.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M762.8 1373.4C762.8 1364.9 766.4 1360.3 773.6 1360.3C779.7 1360.3 784 1364 784 1372.8C784 1373.2 784 1373.8 783.9 1374.2H765.6C765.7 1382.9 768.6 1385.8 775.1 1385.8C778 1385.8 780.3 1385.1 782.6 1383.9L783 1386.3C780.7 1387.6 778 1388.4 774.9 1388.4C766.3 1388.4 762.8 1383.9 762.8 1373.4ZM781.4 1372.2C781.3 1365.3 778.1 1362.8 773.6 1362.8C768.3 1362.8 765.9 1366 765.6 1372.2H781.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M815.6 1388.3C811.5 1388.3 808.4 1387.3 805.8 1385.5L806.4 1382.9C809 1384.6 811.6 1385.7 815.3 1385.7C821.4 1385.7 824.3 1382.7 824.3 1376.5C824.3 1371.2 821.5 1368.9 815.7 1368.9C812.3 1368.9 809.2 1369.8 807.1 1371L805.9 1369.9L807.3 1353.5H825.6L825.7 1356.3H809.5L808.5 1368C810.8 1367 813.7 1366.4 816.4 1366.4C823.6 1366.4 827.2 1369.6 827.2 1376.3C827.2 1384.1 823.4 1388.3 815.6 1388.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M683.9 1416.7V1442.9H674.2V1416.7H665.6L665.8 1408.4H692.4L692.6 1416.7H683.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M695 1442.9V1415L702 1414.7L702.8 1418.7H702.9C704.6 1415.7 707.2 1414.3 710.1 1414.3C711.4 1414.3 712.5 1414.5 713.3 1414.8L712.8 1423.2C711.9 1422.9 710.7 1422.7 709.5 1422.7C706.1 1422.7 703.8 1424.9 703.8 1428.9V1442.9H695Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M730.5 1440.6C728.7 1442.3 726.2 1443.4 723.2 1443.4C717.7 1443.4 714.4 1440.3 714.4 1434C714.4 1428.8 718.1 1425.1 724.4 1425.1C726.3 1425.1 727.8 1425.3 729.1 1425.7V1424.7C729.1 1422.6 727.9 1421.5 724.5 1421.5C721.3 1421.5 719.5 1422 716.8 1422.9L716.1 1416.2C719 1415 722.4 1414.4 726.1 1414.4C735 1414.4 737.9 1418.2 737.9 1426V1443H731.1L730.5 1440.6ZM729.1 1431.3C728.5 1431.1 727.4 1430.9 726.1 1430.9C723.8 1430.9 722.6 1431.9 722.6 1433.9C722.6 1435.9 723.8 1436.8 725.5 1436.8C727.4 1436.8 729 1435.7 729 1434.2L729.1 1431.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M758.2 1442.9V1425.3C758.2 1422.7 757.1 1421.7 754.8 1421.7C753.3 1421.7 751.9 1422.3 751.1 1423.3V1442.9H742.3V1414.9L748.6 1414.5L749.8 1416.7C751.9 1415 754.5 1414.2 757.3 1414.2C763.2 1414.2 767.1 1418.2 767.1 1425.3V1442.9H758.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M779.7 1443.4C776.2 1443.4 773.3 1442.8 770.8 1441.7L771.7 1434.7C774.1 1435.9 777.5 1436.5 779.7 1436.5C781.9 1436.5 782.8 1435.7 782.8 1434.6C782.8 1431.1 770.5 1433.4 770.5 1423.4C770.5 1418.2 774 1414.3 781.7 1414.3C784.6 1414.3 787.5 1414.9 789.9 1415.9L789.3 1422.8C787 1421.9 783.6 1421.5 781.6 1421.5C779.9 1421.5 779 1422.1 779 1423.1C779 1426.5 791 1424.3 791 1433.9C791 1439.5 787.7 1443.4 779.7 1443.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M795.5 1442.9V1421.1H792.3V1414.8H795.4V1413.7C795.4 1409.7 798.5 1406.2 805.3 1406.2C807 1406.2 808.8 1406.5 810.1 1406.8L809.6 1412.3C808.8 1412.1 808 1412 807.2 1412C805 1412 804.3 1412.7 804.3 1413.8V1414.7H809.6V1421H804.3V1442.8L795.5 1442.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M810.6 1428.6C810.6 1419.6 814.6 1414.2 823.5 1414.2C831 1414.2 835.5 1418.4 835.5 1427.3C835.5 1428.6 835.4 1430.1 835.3 1431.3H819.9C820.3 1434.7 822.3 1436 826.4 1436C828.9 1436 831.4 1435.6 833.8 1434.9L834.8 1441.7C832.5 1442.7 829 1443.4 825.3 1443.4C815.2 1443.4 810.6 1438.2 810.6 1428.6ZM827.3 1425.8C827.2 1422.6 825.9 1421.3 823.5 1421.3C820.8 1421.3 819.9 1422.4 819.6 1425.8H827.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M838.9 1442.9V1415L845.9 1414.7L846.7 1418.7H846.8C848.5 1415.7 851.1 1414.3 854 1414.3C855.3 1414.3 856.4 1414.5 857.2 1414.8L856.7 1423.2C855.8 1422.9 854.6 1422.7 853.4 1422.7C850 1422.7 847.7 1424.9 847.7 1428.9V1442.9H838.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M679.6 1498.5C670.4 1498.5 666.3 1494.2 666.3 1483.9C666.3 1473.6 670.4 1469.2 679.6 1469.2C688.8 1469.2 692.8 1473.6 692.8 1483.9C692.9 1494.3 688.9 1498.5 679.6 1498.5ZM679.7 1491.6C682.7 1491.6 683.8 1490.1 683.8 1483.9C683.8 1477.6 682.7 1476.2 679.7 1476.2C676.6 1476.2 675.6 1477.6 675.6 1483.9C675.5 1490.2 676.6 1491.6 679.7 1491.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M697.6 1497.9V1476.1H694.4V1469.8H697.5V1468.7C697.5 1464.7 700.6 1461.2 707.4 1461.2C709.1 1461.2 710.9 1461.5 712.2 1461.8L711.7 1467.3C710.9 1467.1 710.1 1467 709.3 1467C707.1 1467 706.4 1467.7 706.4 1468.8V1469.7H711.7V1476H706.4V1497.8L697.6 1497.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M736.8 1463.4L743.7 1481L745 1484.5H745.1L746.3 1481L753.2 1463.3H763.7V1497.8H755V1483.3L755.1 1479.6H755L753.7 1483.1L748 1497.9H741.7L736 1483L734.8 1479.5H734.7V1483.2V1497.8H726.2V1463.3H736.8V1463.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M783.6 1498.5C772.7 1498.5 767.8 1492.7 767.8 1480.6C767.8 1468.5 772.5 1462.8 783.6 1462.8C794.7 1462.8 799.4 1468.5 799.4 1480.6C799.4 1493.1 794.7 1498.5 783.6 1498.5ZM783.6 1471.1C779.3 1471.1 778 1472.8 778 1480.6C778 1488.4 779.4 1490.3 783.7 1490.3C788 1490.3 789.3 1488.4 789.3 1480.6C789.3 1472.8 788 1471.1 783.6 1471.1Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M811.6 1498.4C808.1 1498.4 805.2 1497.8 802.7 1496.7L803.6 1489.7C806 1490.9 809.4 1491.5 811.6 1491.5C813.8 1491.5 814.7 1490.7 814.7 1489.6C814.7 1486.1 802.4 1488.4 802.4 1478.4C802.4 1473.2 805.9 1469.3 813.6 1469.3C816.5 1469.3 819.4 1469.9 821.8 1470.9L821.2 1477.8C818.9 1476.9 815.5 1476.5 813.5 1476.5C811.8 1476.5 810.9 1477.1 810.9 1478.1C810.9 1481.5 822.9 1479.3 822.9 1488.9C822.8 1494.5 819.6 1498.4 811.6 1498.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M677.6 946.9C673.6 946.9 670.4 945.8 667.8 944.2L668.4 941.3C671.1 943 674 944 677.6 944C683.1 944 685.9 941.3 685.9 936.9C685.9 927.7 668.2 931.7 668.2 920.3C668.2 915.1 671.5 911.1 678.9 911.1C682.3 911.1 685.3 911.8 687.7 912.9L687.1 915.6C684.5 914.5 681.9 913.9 679 913.9C673.3 913.9 671 916.8 671 920.1C671 928.9 688.8 924.7 688.8 936.7C688.8 943 685 946.9 677.6 946.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M699.6 921.3V939.7C699.6 943 701.3 944.3 704 944.3C704.8 944.3 705.7 944.2 706.3 943.9L706.5 946.1C705.8 946.4 704.4 946.7 703.2 946.7C699 946.7 696.7 944.5 696.7 939.8V921.4H692.5V919.1H696.8L697.4 912L699.6 911.7V919.1H706.7V921.4L699.6 921.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M728.2 942.4C726.6 945 723.4 946.7 719.6 946.7C714.7 946.7 711.3 944.3 711.3 938.2C711.3 933 714.5 930.1 721.1 930.1C723.5 930.1 726 930.6 727.9 931.3V927.9C727.9 922.7 725.7 921.1 721 921.1C718.2 921.1 715.7 921.6 713.3 922.8L713 920.4C715.6 919.2 718.3 918.6 721.5 918.6C728.1 918.6 730.8 921.3 730.8 928.4V946.3H728.7L728.2 942.4ZM727.8 933.4C727.1 932.9 724.7 932.1 721.5 932.1C716.3 932.1 714 934.3 714 938C714 942.6 716.8 944.2 720.4 944.2C724.4 944.2 727.9 941.4 727.9 938.8V933.4H727.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M743.4 933.9C741.6 934.3 740.6 935.2 740.6 936.4C740.6 937.5 741.4 938.3 743.7 938.6L750 939.2C756.7 939.9 758.6 941.9 758.6 945.5C758.6 950.3 755.8 953.3 747.6 953.3C740.5 953.3 737.5 950.4 737.5 946.1C737.5 944 738.6 942 740.5 940.5C739.1 939.9 738 938.7 738 936.9C738 935.2 739.2 933.8 741.5 933C739.6 931.9 738.2 929.9 738.2 926.6C738.2 921.5 740.8 918.6 747.4 918.6C749.7 918.6 752.2 919.1 754 920.5L758.4 918.5L759 919.9L755.5 922.2C756.1 923.3 756.5 924.7 756.5 926.4C756.5 931.5 754.2 934.3 747.4 934.3C746.1 934.4 744.7 934.2 743.4 933.9ZM742.9 941.1C741 942.3 740 944.2 740 945.8C740 949 742.2 951 747.5 951C754.2 951 756 948.9 756 945.9C756 943.6 754.8 942.3 749.6 941.8L742.9 941.1ZM740.9 926.6C740.9 931.2 743.7 932.2 747.3 932.2C752.3 932.2 753.8 930.3 753.8 926.6C753.8 922.4 751.8 920.9 747.4 920.9C742.7 921 740.9 922.8 740.9 926.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M762.8 931.7C762.8 923.2 766.4 918.6 773.6 918.6C779.7 918.6 784 922.3 784 931.1C784 931.5 784 932.1 783.9 932.5H765.6C765.7 941.2 768.6 944.1 775.1 944.1C778 944.1 780.3 943.4 782.6 942.2L783 944.6C780.7 945.9 778 946.7 774.9 946.7C766.2 946.8 762.8 942.2 762.8 931.7ZM781.4 930.5C781.3 923.6 778.1 921.1 773.6 921.1C768.3 921.1 765.9 924.3 765.6 930.5H781.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M816.4 946.6C808.7 946.6 804.6 942.5 804.6 930.2C804.6 917.2 809.5 911.4 818.5 911.4C821.2 911.4 823.4 911.8 825.9 912.8L825.6 915.5C823.4 914.6 821.4 914.2 818.8 914.2C811.5 914.2 807.7 918.5 807.3 928.2L807.4 928.3C809.9 926 813.5 924.6 817 924.6C824.4 924.6 827.7 927.8 827.7 934.6C827.8 942.4 823.9 946.6 816.4 946.6ZM816.6 926.9C813.5 926.9 809.7 928.5 807.6 930.9C807.7 940.4 810.4 944 816.4 944C822 944 824.9 940.8 824.9 934.5C824.9 929.2 822.3 926.9 816.6 926.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M668.1 966.7H677.9V1001.2H668.1V966.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M691.6 1001.8C688.1 1001.8 685.2 1001.2 682.7 1000.1L683.6 993.1C686 994.3 689.4 994.9 691.6 994.9C693.8 994.9 694.7 994.1 694.7 993C694.7 989.5 682.4 991.9 682.4 981.8C682.4 976.6 685.9 972.7 693.6 972.7C696.5 972.7 699.4 973.3 701.8 974.3L701.2 981.2C698.9 980.3 695.5 979.9 693.5 979.9C691.8 979.9 690.9 980.5 690.9 981.5C690.9 984.9 702.9 982.6 702.9 992.3C702.8 997.9 699.6 1001.8 691.6 1001.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M713.9 1001.8C710.4 1001.8 707.5 1001.2 705 1000.1L705.9 993.1C708.3 994.3 711.7 994.9 713.9 994.9C716.1 994.9 717 994.1 717 993C717 989.5 704.7 991.9 704.7 981.8C704.7 976.6 708.2 972.7 715.9 972.7C718.8 972.7 721.7 973.3 724.1 974.3L723.5 981.2C721.2 980.3 717.8 979.9 715.8 979.9C714.1 979.9 713.2 980.5 713.2 981.5C713.2 984.9 725.2 982.6 725.2 992.3C725.1 997.9 721.9 1001.8 713.9 1001.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M753.1 991C753.1 998.1 749.1 1001.8 740.7 1001.8C732.3 1001.8 728.3 998 728.3 991V973.1H737.1V990.9C737.1 993.2 738.2 994.3 740.7 994.3C743.1 994.3 744.2 993.3 744.2 990.9V973.1H753.1V991Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M772.6 998.9C770.8 1000.6 768.3 1001.7 765.3 1001.7C759.8 1001.7 756.5 998.6 756.5 992.3C756.5 987.1 760.2 983.4 766.5 983.4C768.4 983.4 769.9 983.6 771.2 984V983C771.2 980.9 770 979.8 766.6 979.8C763.4 979.8 761.6 980.3 758.9 981.2L758.2 974.5C761.1 973.3 764.5 972.7 768.2 972.7C777.1 972.7 780 976.5 780 984.3V1001.3H773.2L772.6 998.9ZM771.2 989.6C770.6 989.4 769.5 989.2 768.2 989.2C765.9 989.2 764.7 990.2 764.7 992.2C764.7 994.2 765.9 995.1 767.6 995.1C769.5 995.1 771.1 994 771.1 992.5L771.2 989.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M800.2 1001.2V983.6C800.2 981 799.1 980 796.8 980C795.3 980 793.9 980.6 793.1 981.6V1001.2H784.3V973.2L790.6 972.8L791.8 975C793.9 973.3 796.5 972.5 799.3 972.5C805.2 972.5 809.1 976.5 809.1 983.6V1001.2H800.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M832 980.9C830.7 980.5 829.1 980.2 827.7 980.2C823 980.2 821.6 982 821.6 987.3C821.6 992.4 823.8 994.5 827.5 994.5C829.2 994.5 830.8 994.2 832.1 993.7L832.7 1000.3C831.1 1001.2 828.7 1001.8 825.6 1001.8C817 1001.8 812.4 996.9 812.4 987.4C812.4 978.2 816.4 972.7 825.8 972.7C828.1 972.7 830.8 973.2 832.5 973.8L832 980.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M834.2 986.9C834.2 977.9 838.2 972.5 847.1 972.5C854.6 972.5 859.1 976.7 859.1 985.6C859.1 986.9 859 988.4 858.9 989.6H843.5C843.9 993 845.9 994.3 850 994.3C852.5 994.3 855 993.9 857.4 993.2L858.4 1000C856.1 1001 852.6 1001.7 848.9 1001.7C838.8 1001.7 834.2 996.5 834.2 986.9ZM851 984.1C850.9 980.9 849.6 979.6 847.2 979.6C844.5 979.6 843.6 980.7 843.3 984.1H851Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M886 1001.9C876.8 1001.9 872.7 997.6 872.7 987.3C872.7 977 876.8 972.6 886 972.6C895.2 972.6 899.2 977 899.2 987.3C899.2 997.6 895.3 1001.9 886 1001.9ZM886 995C889 995 890.1 993.5 890.1 987.3C890.1 981 889 979.6 886 979.6C882.9 979.6 881.9 981 881.9 987.3C881.9 993.5 883 995 886 995Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M904 1001.2V979.4H900.8V973.1H903.9V972C903.9 968 907 964.5 913.8 964.5C915.5 964.5 917.3 964.8 918.6 965.1L918.1 970.6C917.3 970.4 916.5 970.3 915.7 970.3C913.5 970.3 912.8 971 912.8 972.1V973H918.1V979.3H912.8V1001.1H904V1001.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M668.1 1021.7H677.9V1056.2H668.1V1021.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M699.7 1030V1056.2H690V1030H681.4L681.6 1021.7H708.2L708.4 1030H699.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M722 1021.7L728.9 1039.3L730.2 1042.8H730.3L731.5 1039.3L738.4 1021.6H748.9V1056.1H740.1V1041.6L740.2 1037.9H740.1L738.8 1041.4L733.1 1056.2H726.8L721.1 1041.3L719.9 1037.8H719.8V1041.5V1056.1H711.3V1021.6L722 1021.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M768.8 1056.9C757.9 1056.9 753 1051.1 753 1039C753 1026.9 757.7 1021.2 768.8 1021.2C779.9 1021.2 784.6 1026.9 784.6 1039C784.6 1051.4 779.9 1056.9 768.8 1056.9ZM768.7 1029.4C764.4 1029.4 763.1 1031.1 763.1 1038.9C763.1 1046.7 764.5 1048.6 768.8 1048.6C773.1 1048.6 774.4 1046.7 774.4 1038.9C774.4 1031.1 773.2 1029.4 768.7 1029.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M796.7 1056.8C793.2 1056.8 790.3 1056.2 787.8 1055.1L788.7 1048.1C791.1 1049.3 794.5 1049.9 796.7 1049.9C798.9 1049.9 799.8 1049.1 799.8 1048C799.8 1044.5 787.5 1046.9 787.5 1036.8C787.5 1031.6 791 1027.7 798.7 1027.7C801.6 1027.7 804.5 1028.3 806.9 1029.3L806.3 1036.2C804 1035.3 800.6 1034.9 798.6 1034.9C796.9 1034.9 796 1035.5 796 1036.5C796 1039.9 808 1037.6 808 1047.3C807.9 1052.9 804.7 1056.8 796.7 1056.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M850.7 1056.6C848.4 1055.7 845.9 1054.4 843.4 1052.8C840.4 1055.2 836.7 1056.8 832.1 1056.8C824.1 1056.8 821.1 1051.8 821.1 1046.8C821.1 1042.3 823.8 1039.3 826.9 1037C825.2 1034.3 824.1 1031.8 824.1 1029.8C824.1 1025.1 826.7 1021.1 834.9 1021.1C840.9 1021.1 845.5 1024 845.5 1028.6C845.5 1032.8 841.6 1035.6 837.7 1038.2C839.1 1039.6 840.7 1041.1 842.5 1042.4C843.4 1040.8 844.1 1039.1 844.5 1037.4H852.1C851.8 1039.8 850.7 1043.2 848.7 1046.5C850.4 1047.4 852.1 1048.2 853.9 1048.8L850.7 1056.6ZM837.1 1048.1C835.2 1046.5 833.3 1044.7 831.7 1042.9C830.9 1043.9 830.4 1044.9 830.4 1046C830.4 1047.9 831.6 1048.9 833.6 1048.9C834.8 1048.9 836 1048.7 837.1 1048.1ZM833.5 1032.9C835.3 1031.8 836.5 1030.6 836.6 1029.4C836.6 1028.2 835.7 1027.7 834.8 1027.7C833.4 1027.7 832.5 1028.4 832.5 1030C832.4 1030.7 832.8 1031.7 833.5 1032.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M680.6 1100.9H677.3V1111.3H667.7V1076.8H680.6C690.8 1076.8 695 1080.8 695 1089.1C695 1096.4 690.9 1100.9 680.6 1100.9ZM680.6 1084.4H677.3V1093.5H680.6C683.8 1093.5 685 1092.8 685 1089C685 1085.3 684 1084.4 680.6 1084.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M712.4 1108.9C710.6 1110.6 708.1 1111.7 705.1 1111.7C699.6 1111.7 696.3 1108.6 696.3 1102.3C696.3 1097.1 700 1093.4 706.3 1093.4C708.2 1093.4 709.7 1093.6 711 1094V1093C711 1090.9 709.8 1089.8 706.4 1089.8C703.2 1089.8 701.4 1090.3 698.7 1091.2L698 1084.5C700.9 1083.3 704.3 1082.7 708 1082.7C716.9 1082.7 719.8 1086.5 719.8 1094.3V1111.3H713L712.4 1108.9ZM711.1 1099.6C710.5 1099.4 709.4 1099.2 708.1 1099.2C705.8 1099.2 704.6 1100.2 704.6 1102.2C704.6 1104.2 705.8 1105.1 707.5 1105.1C709.4 1105.1 711 1104 711 1102.5L711.1 1099.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M734.1 1110.6L734.2 1110.3L722.2 1083.1H732.2L737.2 1098.1L738.3 1101.6H738.4L739.2 1098L742.7 1083.1H752.1L742.8 1110.6C740.6 1116.6 737 1119.2 731.6 1119.2C729.8 1119.2 728.1 1118.9 726.5 1118.3L727.1 1112.3C728.1 1112.7 728.9 1112.9 729.9 1112.9C731.9 1112.8 733.3 1112.4 734.1 1110.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M785.4 1111.2V1093.6C785.4 1091.2 784.2 1090.1 782.1 1090.1C780 1090.1 778.9 1091.2 778.9 1093.5V1111.2H770V1093.5C770 1091.1 768.9 1090.1 766.8 1090.1C765.4 1090.1 764.2 1090.7 763.5 1091.7V1111.3H754.7V1083.4L761 1083L762.4 1085.4C764.4 1083.7 766.9 1082.6 769.4 1082.6C772.8 1082.6 775.1 1083.7 776.7 1086.1C778.8 1083.6 781.5 1082.6 784.6 1082.6C790.3 1082.6 794.2 1086.3 794.2 1093.5V1111.3L785.4 1111.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M797.5 1096.9C797.5 1087.9 801.5 1082.5 810.4 1082.5C817.9 1082.5 822.4 1086.7 822.4 1095.6C822.4 1096.9 822.3 1098.4 822.2 1099.6H806.8C807.2 1103 809.2 1104.3 813.3 1104.3C815.8 1104.3 818.3 1103.9 820.7 1103.2L821.7 1110C819.4 1111 815.9 1111.7 812.2 1111.7C802.1 1111.7 797.5 1106.5 797.5 1096.9ZM814.2 1094.1C814.1 1090.9 812.8 1089.6 810.4 1089.6C807.7 1089.6 806.8 1090.7 806.5 1094.1H814.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M841.7 1111.2V1093.6C841.7 1091 840.6 1090 838.3 1090C836.8 1090 835.4 1090.6 834.6 1091.6V1111.2H825.8V1083.2L832.1 1082.8L833.3 1085C835.4 1083.3 838 1082.5 840.8 1082.5C846.7 1082.5 850.6 1086.5 850.6 1093.6V1111.2H841.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M864.9 1089.4V1102.3C864.9 1104.1 865.8 1104.9 867.8 1104.9C868.5 1104.9 869.5 1104.8 870 1104.6L870.5 1110.8C869.5 1111.3 867.4 1111.7 865.2 1111.7C859 1111.7 856.1 1108.2 856.1 1102.6V1089.4H853.4V1083H856.5L857.9 1077L864.9 1076.4V1083H870.7V1089.4H864.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M882 1111.8C878.5 1111.8 875.6 1111.2 873.1 1110.1L874 1103.1C876.4 1104.3 879.8 1104.9 882 1104.9C884.2 1104.9 885.1 1104.1 885.1 1103C885.1 1099.5 872.8 1101.9 872.8 1091.8C872.8 1086.6 876.3 1082.7 884 1082.7C886.9 1082.7 889.8 1083.3 892.2 1084.3L891.6 1091.2C889.3 1090.3 885.9 1089.9 883.9 1089.9C882.2 1089.9 881.3 1090.5 881.3 1091.5C881.3 1094.9 893.3 1092.6 893.3 1102.3C893.2 1107.9 890 1111.8 882 1111.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M960.2 727.1C956.2 727.1 953 726 950.4 724.4L951 721.5C953.7 723.2 956.6 724.2 960.2 724.2C965.7 724.2 968.5 721.5 968.5 717.1C968.5 707.9 950.8 711.9 950.8 700.5C950.8 695.3 954.1 691.3 961.5 691.3C964.9 691.3 967.9 692 970.3 693.1L969.7 695.8C967.1 694.7 964.5 694.1 961.6 694.1C955.9 694.1 953.6 697 953.6 700.3C953.6 709.1 971.4 704.9 971.4 716.9C971.5 723.2 967.6 727.1 960.2 727.1Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M982.2 701.5V719.9C982.2 723.2 983.9 724.5 986.6 724.5C987.4 724.5 988.3 724.4 988.9 724.1L989.1 726.3C988.4 726.6 987 726.9 985.8 726.9C981.6 726.9 979.3 724.7 979.3 720V701.6H975.1V699.3H979.4L980 692.2L982.2 691.9V699.3H989.3V701.6H982.2V701.5Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1010.8 722.6H1010.7C1009.2 725.2 1006 726.9 1002.2 726.9C997.3 726.9 993.9 724.5 993.9 718.4C993.9 713.2 997.1 710.3 1003.7 710.3C1006.1 710.3 1008.6 710.8 1010.5 711.5V708.1C1010.5 702.9 1008.3 701.3 1003.6 701.3C1000.8 701.3 998.3 701.8 995.9 703L995.6 700.6C998.2 699.4 1000.9 698.8 1004.1 698.8C1010.7 698.8 1013.4 701.5 1013.4 708.6V726.5H1011.3L1010.8 722.6ZM1010.4 713.6C1009.7 713.1 1007.3 712.3 1004.1 712.3C998.9 712.3 996.6 714.5 996.6 718.2C996.6 722.8 999.4 724.4 1003 724.4C1007 724.4 1010.5 721.6 1010.5 719V713.6H1010.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1026.1 714.1C1024.3 714.5 1023.3 715.4 1023.3 716.6C1023.3 717.7 1024.1 718.5 1026.4 718.8L1032.7 719.4C1039.4 720.1 1041.3 722.1 1041.3 725.7C1041.3 730.5 1038.5 733.5 1030.3 733.5C1023.2 733.5 1020.2 730.6 1020.2 726.3C1020.2 724.2 1021.3 722.2 1023.2 720.7C1021.8 720.1 1020.7 718.9 1020.7 717.1C1020.7 715.4 1021.9 714 1024.2 713.2C1022.3 712.1 1020.9 710.1 1020.9 706.8C1020.9 701.7 1023.5 698.8 1030.1 698.8C1032.4 698.8 1034.9 699.3 1036.7 700.7L1041.1 698.7L1041.7 700.1L1038.2 702.4C1038.8 703.5 1039.2 704.9 1039.2 706.6C1039.2 711.7 1036.9 714.5 1030.1 714.5C1028.7 714.6 1027.3 714.5 1026.1 714.1ZM1025.5 721.3C1023.6 722.5 1022.6 724.4 1022.6 726C1022.6 729.2 1024.8 731.2 1030.1 731.2C1036.8 731.2 1038.6 729.1 1038.6 726.1C1038.6 723.8 1037.4 722.5 1032.2 722L1025.5 721.3ZM1023.5 706.8C1023.5 711.4 1026.3 712.4 1029.9 712.4C1034.9 712.4 1036.4 710.5 1036.4 706.8C1036.4 702.6 1034.4 701.1 1030 701.1C1025.3 701.2 1023.5 703 1023.5 706.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1045.4 711.9C1045.4 703.4 1049 698.8 1056.2 698.8C1062.3 698.8 1066.6 702.5 1066.6 711.3C1066.6 711.7 1066.6 712.3 1066.5 712.7H1048.2C1048.3 721.4 1051.2 724.3 1057.7 724.3C1060.6 724.3 1062.9 723.6 1065.2 722.4L1065.6 724.8C1063.3 726.1 1060.6 726.9 1057.5 726.9C1048.9 727 1045.4 722.4 1045.4 711.9ZM1064 710.7C1063.9 703.8 1060.7 701.3 1056.2 701.3C1050.9 701.3 1048.5 704.5 1048.2 710.7H1064Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1110.9 694.5L1095.4 726.4H1092L1107.9 694.8H1087.3L1087.4 691.9H1110.8V694.5H1110.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M972.9 755.6C971.2 755 968.9 754.6 966.5 754.6C960.8 754.6 959.5 756.8 959.5 764.1C959.5 771.8 961 774 966.7 774C969 774 971.3 773.5 973.1 772.8L974.1 780.3C971.7 781.4 968.3 782.1 965.4 782.1C954.8 782.1 949.5 776.3 949.5 764C949.5 752.7 954.6 746.3 966.1 746.3C968.8 746.3 972 746.9 974.1 747.7L972.9 755.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M989.4 782.1C980.2 782.1 976.1 777.8 976.1 767.5C976.1 757.2 980.2 752.8 989.4 752.8C998.6 752.8 1002.6 757.2 1002.6 767.5C1002.6 777.8 998.7 782.1 989.4 782.1ZM989.4 775.2C992.4 775.2 993.5 773.7 993.5 767.5C993.5 761.2 992.4 759.8 989.4 759.8C986.3 759.8 985.3 761.2 985.3 767.5C985.3 773.7 986.4 775.2 989.4 775.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1006.2 781.4V753.5L1013.2 753.2L1014 757.2H1014.1C1015.8 754.2 1018.4 752.8 1021.3 752.8C1022.6 752.8 1023.7 753 1024.5 753.3L1024 761.7C1023.1 761.4 1021.9 761.2 1020.7 761.2C1017.3 761.2 1015 763.4 1015 767.4V781.4H1006.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1027.6 781.4V753.5L1034.6 753.2L1035.4 757.2H1035.5C1037.2 754.2 1039.8 752.8 1042.7 752.8C1044 752.8 1045.1 753 1045.9 753.3L1045.4 761.7C1044.5 761.4 1043.3 761.2 1042.1 761.2C1038.7 761.2 1036.4 763.4 1036.4 767.4V781.4H1027.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1047.1 767.2C1047.1 758.2 1051.1 752.8 1060 752.8C1067.5 752.8 1072 757 1072 765.9C1072 767.2 1071.9 768.7 1071.8 769.9H1056.4C1056.8 773.3 1058.8 774.6 1062.9 774.6C1065.4 774.6 1067.9 774.2 1070.3 773.5L1071.3 780.3C1069 781.3 1065.5 782 1061.8 782C1051.7 781.9 1047.1 776.7 1047.1 767.2ZM1063.9 764.3C1063.8 761.1 1062.5 759.8 1060.1 759.8C1057.4 759.8 1056.5 760.9 1056.2 764.3H1063.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1083.5 782C1080 782 1077.1 781.4 1074.6 780.3L1075.5 773.3C1077.9 774.5 1081.3 775.1 1083.5 775.1C1085.7 775.1 1086.6 774.3 1086.6 773.2C1086.6 769.7 1074.3 772.1 1074.3 762C1074.3 756.8 1077.8 752.9 1085.5 752.9C1088.4 752.9 1091.3 753.5 1093.7 754.5L1093.1 761.4C1090.8 760.5 1087.4 760.1 1085.4 760.1C1083.7 760.1 1082.8 760.7 1082.8 761.7C1082.8 765.1 1094.8 762.9 1094.8 772.5C1094.7 778.1 1091.5 782 1083.5 782Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1113 781.9C1110.8 781.9 1108.8 781.4 1107.1 780H1107L1107.1 783.7V788.6H1098.2V753.5L1104.4 753.1L1106 755.3C1107.9 753.7 1110.5 752.8 1113.2 752.8C1120.2 752.8 1123.9 756.6 1123.9 767.3C1123.8 777 1119.5 781.9 1113 781.9ZM1110.7 760.3C1109.1 760.3 1107.8 761 1107.1 762.1V773C1107.8 774.1 1109 774.9 1110.6 774.9C1113.1 774.9 1114.7 773.7 1114.7 767.3C1114.7 761.7 1113.6 760.3 1110.7 760.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1139.5 782.1C1130.3 782.1 1126.2 777.8 1126.2 767.5C1126.2 757.2 1130.3 752.8 1139.5 752.8C1148.7 752.8 1152.7 757.2 1152.7 767.5C1152.7 777.8 1148.8 782.1 1139.5 782.1ZM1139.6 775.2C1142.6 775.2 1143.7 773.7 1143.7 767.5C1143.7 761.2 1142.6 759.8 1139.6 759.8C1136.5 759.8 1135.5 761.2 1135.5 767.5C1135.4 773.7 1136.5 775.2 1139.6 775.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1172.2 781.4V763.8C1172.2 761.2 1171.1 760.2 1168.8 760.2C1167.3 760.2 1165.9 760.8 1165.1 761.8V781.4H1156.3V753.4L1162.6 753L1163.8 755.2C1165.9 753.5 1168.5 752.7 1171.3 752.7C1177.2 752.7 1181.1 756.7 1181.1 763.8V781.4H1172.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1203.2 781.4L1202.4 779.2C1200.6 780.9 1198.4 782 1195.2 782C1188.2 782 1184.4 777.3 1184.4 767.2C1184.4 758 1188.1 752.8 1195 752.8C1197.3 752.8 1199.5 753.5 1201.3 755L1201.4 754.9L1201.3 751.2V745.9L1210.1 745.5V781.5H1203.2V781.4ZM1201.2 761.9C1200.5 761 1199.2 760.3 1197.6 760.3C1194.6 760.3 1193.5 761.9 1193.5 767.5C1193.5 773.5 1194.4 775 1197.6 775C1199.4 775 1200.6 774.1 1201.1 773.2V761.9H1201.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1219.9 751.2C1216.6 751.2 1215.1 750.3 1215.1 747.7C1215.1 745.4 1216.6 744.2 1219.9 744.2C1223.3 744.2 1224.7 745.4 1224.7 747.7C1224.7 750 1223.3 751.2 1219.9 751.2ZM1215.4 781.4V753.5L1224.5 753.1V781.4H1215.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1245.6 781.4V763.8C1245.6 761.2 1244.5 760.2 1242.2 760.2C1240.7 760.2 1239.3 760.8 1238.5 761.8V781.4H1229.7V753.4L1236 753L1237.2 755.2C1239.3 753.5 1241.9 752.7 1244.7 752.7C1250.6 752.7 1254.5 756.7 1254.5 763.8V781.4H1245.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1266.7 770.2C1266.4 770.4 1266.2 770.8 1266.2 771.1C1266.2 771.7 1266.7 772.1 1267.7 772.1L1274.3 772.4C1280.8 772.7 1283.5 775.3 1283.5 779.8C1283.5 785.6 1279.4 789.1 1270 789.1C1261.7 789.1 1258.2 785.9 1258.2 781.7C1258.2 780.1 1258.8 778.6 1260.1 777.5C1258.9 776.6 1258 775.2 1258 773.3C1258 771.4 1259 769.5 1261.5 768.2C1259.6 766.9 1258.5 764.7 1258.5 761.6C1258.5 756.5 1261.6 752.7 1269.8 752.7C1272.2 752.7 1274.8 753.1 1276.9 754.1L1281.7 752.7L1283.5 756.7L1280.7 759C1280.9 759.7 1281 760.6 1281 761.5C1281 767 1277.7 770.2 1269.8 770.2C1268.7 770.4 1267.7 770.3 1266.7 770.2ZM1266.3 779.2C1265.9 779.7 1265.7 780.3 1265.7 780.7C1265.7 782.2 1266.9 782.8 1269.9 782.8C1274.6 782.8 1275.7 782.2 1275.7 781.1C1275.7 780.1 1275.1 779.8 1272.8 779.6L1266.3 779.2ZM1267.2 761.9C1267.2 764 1268.1 764.6 1269.6 764.6C1271.8 764.6 1272.4 764 1272.4 761.9C1272.4 759.9 1271.7 759.2 1269.8 759.2C1267.9 759.1 1267.2 759.9 1267.2 761.9Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M970 830.5H959.3L957.8 836.4H947.6L959.1 801.9H970.6L982.2 836.4H971.5L970 830.5ZM963.8 813.1L961.3 822.8H968.1L965.7 813.2L964.9 809.5H964.8L963.8 813.1Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1001.3 836.4L1000.5 834.2C998.7 835.9 996.5 837 993.3 837C986.3 837 982.5 832.3 982.5 822.2C982.5 813 986.2 807.8 993.1 807.8C995.4 807.8 997.6 808.5 999.4 810L999.5 809.9L999.4 806.2V800.9L1008.2 800.5V836.5H1001.3V836.4ZM999.3 816.9C998.6 816 997.3 815.3 995.7 815.3C992.7 815.3 991.6 816.9 991.6 822.5C991.6 828.5 992.5 830 995.7 830C997.5 830 998.7 829.1 999.2 828.2V816.9H999.3Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1022.6 835.8C1022.6 842.2 1018.5 844.1 1013.7 844.1C1012.2 844.1 1010.7 843.9 1009.5 843.5L1009.9 837.6C1010.4 837.7 1011 837.8 1011.5 837.8C1012.8 837.8 1013.7 837.2 1013.7 835.6V808.5L1022.6 808.1V835.8ZM1018 806.1C1014.7 806.1 1013.2 805.2 1013.2 802.7C1013.2 800.4 1014.7 799.2 1018 799.2C1021.4 799.2 1022.8 800.4 1022.8 802.7C1022.8 805 1021.5 806.1 1018 806.1Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1052.4 826.2C1052.4 833.3 1048.4 837 1040 837C1031.6 837 1027.6 833.2 1027.6 826.2V808.3H1036.4V826C1036.4 828.3 1037.5 829.4 1040 829.4C1042.4 829.4 1043.5 828.4 1043.5 826V808.2H1052.4V826.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1065 837C1061.5 837 1058.6 836.4 1056.1 835.3L1057 828.3C1059.4 829.5 1062.8 830.1 1065 830.1C1067.2 830.1 1068.1 829.3 1068.1 828.2C1068.1 824.7 1055.8 827.1 1055.8 817C1055.8 811.8 1059.3 807.9 1067 807.9C1069.9 807.9 1072.8 808.5 1075.2 809.5L1074.6 816.4C1072.3 815.5 1068.9 815.1 1066.9 815.1C1065.2 815.1 1064.3 815.7 1064.3 816.7C1064.3 820.1 1076.3 817.9 1076.3 827.5C1076.2 833.1 1073 837 1065 837Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1088.7 814.7V827.6C1088.7 829.4 1089.6 830.2 1091.6 830.2C1092.3 830.2 1093.3 830.1 1093.8 829.9L1094.3 836.1C1093.3 836.6 1091.2 837 1089 837C1082.8 837 1079.9 833.5 1079.9 827.9V814.7H1077.2V808.3H1080.3L1081.7 802.3L1088.7 801.7V808.3H1094.5V814.7H1088.7Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1128.3 836.4V818.8C1128.3 816.4 1127.1 815.3 1125 815.3C1122.9 815.3 1121.8 816.4 1121.8 818.7V836.4H1112.9V818.7C1112.9 816.3 1111.8 815.3 1109.7 815.3C1108.3 815.3 1107.1 815.9 1106.4 816.9V836.5H1097.6V808.6L1103.9 808.2L1105.3 810.6C1107.3 808.9 1109.8 807.8 1112.3 807.8C1115.7 807.8 1118 808.9 1119.6 811.3C1121.7 808.8 1124.4 807.8 1127.5 807.8C1133.2 807.8 1137.1 811.5 1137.1 818.7V836.5H1128.3V836.4Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1140.4 822.2C1140.4 813.2 1144.4 807.8 1153.3 807.8C1160.8 807.8 1165.3 812 1165.3 820.9C1165.3 822.2 1165.2 823.7 1165.1 824.9H1149.7C1150.1 828.3 1152.1 829.6 1156.2 829.6C1158.7 829.6 1161.2 829.2 1163.6 828.5L1164.6 835.3C1162.3 836.3 1158.8 837 1155.1 837C1145 836.9 1140.4 831.7 1140.4 822.2ZM1157.2 819.3C1157.1 816.1 1155.8 814.8 1153.4 814.8C1150.7 814.8 1149.8 815.9 1149.5 819.3H1157.2Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1184.6 836.4V818.8C1184.6 816.2 1183.5 815.2 1181.2 815.2C1179.7 815.2 1178.3 815.8 1177.5 816.8V836.4H1168.7V808.4L1175 808L1176.2 810.2C1178.3 808.5 1180.9 807.7 1183.7 807.7C1189.6 807.7 1193.5 811.7 1193.5 818.8V836.4H1184.6Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1207.8 814.7V827.6C1207.8 829.4 1208.7 830.2 1210.7 830.2C1211.4 830.2 1212.4 830.1 1212.9 829.9L1213.4 836.1C1212.4 836.6 1210.3 837 1208.1 837C1201.9 837 1199 833.5 1199 827.9V814.7H1196.3V808.3H1199.4L1200.8 802.3L1207.8 801.7V808.3H1213.6V814.7H1207.8Z" fill="white" />
          <path class="immplementation-wheel__inner-decoration" d="M1224.9 837C1221.4 837 1218.5 836.4 1216 835.3L1216.9 828.3C1219.3 829.5 1222.7 830.1 1224.9 830.1C1227.1 830.1 1228 829.3 1228 828.2C1228 824.7 1215.7 827.1 1215.7 817C1215.7 811.8 1219.2 807.9 1226.9 807.9C1229.8 807.9 1232.7 808.5 1235.1 809.5L1234.5 816.4C1232.2 815.5 1228.8 815.1 1226.8 815.1C1225.1 815.1 1224.2 815.7 1224.2 816.7C1224.2 820.1 1236.2 817.9 1236.2 827.5C1236.1 833.1 1232.9 837 1224.9 837Z" fill="white" />
          <use
            ref="fakeIndex"
            class="fake-z-index"
            :xlink:href="'#' + hoveringTarget"
            @click="handleClick(hoveringTarget)"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script setup>
const activeStage = ref(null);
const hoveringTarget = ref('');
const activeMainGroup = ref(0);
const clickedMainGroup = ref(0);
const mouseOnSVG = ref(false);
const showSlider = ref(false);

watch(
    () => activeStage.value,
    (newValue) => {
        if (newValue === null) {
            showSlider.value = false;
        }
        if (newValue !== null) {
            setTimeout(() => {
                showSlider.value = true;
            }, 300);
        }
    },
);

watch(
    () => mouseOnSVG.value,
    (newValue) => {
        if (newValue === false) {
            activeMainGroup.value = parseInt(clickedMainGroup.value, 10);
            hoveringTarget.value = activeStage.value;
        }
    },
);

const { locale } = useI18n();
const storyblokApi = useStoryblokApi();

const {
    data: implementationWheelData,
    status: implementationWheelDataStatus,
    error: implementationWheelDataError,
} = useLazyAsyncData(
    'get-data-implementation-wheel',
    async () => storyblokApi.get('cdn/stories/implementationwheel/implementationwheel', {
        version: 'published',
        resolve_links: 'link',
        language: locale.value,
    }),
    {
        transform: (res) => {
            const sliderContentMounted = [];
            res.data.story.content.stages.forEach((stage) => {
                const sliderObject = {};
                sliderObject.stage = stage.id.slice(0, -2);
                sliderObject.stageId = stage.id;
                sliderObject.title = stage.name;
                sliderObject.actors = stage.card[0]?.actors;
                sliderObject.text = stage.card[0]?.text;
                sliderObject.extraBox = stage.extra_title
                    ? `<b>${stage.extra_title}</b> <br />${stage.extra_text}`
                    : '';
                sliderContentMounted.push(sliderObject);

                stage.phases.forEach((phase) => {
                    const sliderObjectPhase = {};
                    sliderObjectPhase.stageId = phase.id;
                    sliderObjectPhase.title = phase.name;
                    sliderObjectPhase.actors = phase.card[0]?.actors;
                    sliderObjectPhase.text = phase.card[0]?.text;
                    sliderContentMounted.push(sliderObjectPhase);
                });
            });

            return sliderContentMounted;
        },
    },
);

/*
    Error handling
*/
watch(
    () => implementationWheelDataError.value,
    (error) => {
        if (error) {
            useSentryError(error, {
                version: 'published',
                language: locale.value,
            });
        }
    },
);

const handleClick = (value) => {
    activeStage.value = value;
    clickedMainGroup.value = activeMainGroup.value;
};

const fakeIndex = ref(null);

const useTarget = (id) => {
    fakeIndex.value.style.transition = 'transform 0s ease-in-out, opacity 0s ease-in-out;';
    fakeIndex.value.style.transform = 'scale(1)';
    window.setTimeout(() => {
        hoveringTarget.value = id;
        fakeIndex.value.style.transition = 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out;';
        fakeIndex.value.style.transform = 'scale(1.175)';
    }, 200);
};

const setIsActive = (index) => {
    activeMainGroup.value = parseInt(index, 10);
};

const setNewActiveStage = (event) => {
    activeStage.value = `target-${event}`;
    hoveringTarget.value = `target-${event}`;
    clickedMainGroup.value = event.substring(0, 1);
    activeMainGroup.value = parseInt(event.substring(0, 1), 10);
};
</script>

<style lang="scss">
.fake-z-index {
    @media (hover: hover) {
        cursor: pointer;
        opacity: 0;
        transform-box: fill-box;
        transform-origin: center;
        transition: transform 0.25s ease-in-out, opacity 0.2s ease-in-out;

        .svg-elements-wrapper:hover & {
            opacity: 1;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &:hover {
            transform: scale(1.2);
        }
    }
}
.implementation-wheel__wrapper {
    display: grid;
    max-width: 1404px;
    padding-right: 25px;
    padding-left: 25px;
    margin: 0 auto;
    @include fluid('margin-bottom', 50px, 100px);
    @include fluid('margin-top', 50px, 100px);
    column-gap: 36px;
    grid-template-columns: repeat(18, 1fr);

    @include mobile {
        grid-template-columns: repeat(3, 1fr);
    }
}

.implementation-wheel-st1 {
    fill: #F3ECD1;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #E6DBAF, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #E6DBAF, $amount: 5);
    }
}

.implementation-wheel-st2 {
    fill: #E2D396;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #E2CF82, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #E2CF82, $amount: 5);
    }
}

.implementation-wheel-st3 {
    fill: #D9C161;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #D1B646, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #D1B646, $amount: 5);
    }
}

.implementation-wheel-st4 {
    fill: #D1AF23;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #AD9221, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #AD9221, $amount: 5);
    }
}

.implementation-wheel-st5 {
    fill: #978016;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #776519, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #776519, $amount: 5);
    }
}

.implementation-wheel-st6 {
    fill: #745F0B;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #54460E, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #54460E, $amount: 5);
    }
}

.implementation-wheel-st7 {
    fill: #54460E;

    .active & {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #352D0B, $amount: 5);
    }

    &:hover {
        /* stylelint-disable-next-line scss/no-global-function-names */
        fill: darken($color: #352D0B, $amount: 5);
    }
}

.target-item {
    cursor: pointer;
    transform-box: fill-box;
    transform-origin: center;

    g {
        pointer-events: none;
    }

    &.active {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.34);
        path {
            transition: fill 0.25s ease-in-out;
            // fill: white;
        }

        g {
            path {
                transition: fill 0.25s ease-in-out;
                // fill: black;
            }
        }
    }
}

.implementation-wheel {
    display: flex;
    max-width: 100%;
    margin-top: -173px;
    margin-bottom: -184px;
    grid-column: 1 / span 18;

    @include tablet {
        margin-top: -147px;
        margin-bottom: -156px;
    }

    @include tablet-portrait {
        margin-top: -121px;
        margin-bottom: -127px;
    }

    @include mobile {
        overflow: hidden;
        padding-top: 81px;
        margin-top: -20px;
        margin-bottom: 54px;
        grid-column: 1 / span 3;
    }
}

.implementation-wheel__info {
    width: 0;
    flex-basis: 0%;
    flex-grow: 0;
    transition: flex-basis 0.3s $EASE_OUT--CIRC, width 0.3s $EASE_OUT--CIRC;

    .showContent & {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 150px;
        margin-bottom: 220px;

        @include mobile {
            margin-top: 0;
            margin-bottom: 0;
        }

        @media (hover: hover) {
            width: 50%;
            max-width: 680px;
            flex-basis: 50%;
        }
    }

    &--inner {
        position: relative;
        background: var(--c-white);
        box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        transition: opacity 0.6s $EASE_OUT--CIRC;

        .showContent & {
            width: 100%;
            opacity: 1;
            transition-delay: 0.3s;
        }
    }
}

.implementation-wheel__wheel {
    height: 100%;
    flex-basis: 100%;
    transition: flex-basis 0.6s $EASE_OUT--CIRC;

    .stage {
        cursor: pointer;
    }

    @include mobile {
        transform: translateX(0) scale(1.6);
    }

    .showContent & {
        display: none;
        @media (hover: hover) {
            display: block;
            flex-basis: 50%;
            transform: translateX(100px) scale(1.5);
        }

        @media screen and (max-width: 1900px) {
            transform: translateX(0) scale(1.3);
        }
        @media screen and (max-width: 1554px) {
            transform: translateX(0) scale(1);
        }
    }
}

.implementation-wheel__info-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color:  var(--c-coral-1);
    @include z-index('close');

    @media (hover: hover) {
        top: 40px;
        right: 40px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    path {
        fill: var(--c-coral-4);
        stroke: var(--c-coral-4);
    }
}

.st0 {
    fill: #e8e9d5;
}

#target-2-1,
#target-2-2 {
    &.active {
        .st10 {
            fill: #3c2c34;
        }
    }
}
#target-4-1,
#target-4-2,
#target-4-3 {
    &.active {
        .st10 {
            fill: #3c2f23;
        }
    }
}
#target-5-1,
#target-5-2 {
    &.active {
        .st10 {
            fill: #383625;
        }
    }
}
#target-6-1,
#target-6-2 {
    &.active {
        .st10 {
            fill: #383625;
        }
    }
}
#target-7-1,
#target-7-2,
#target-7-3 {
    &.active {
        .st10 {
            fill: #1e373e;
        }
    }
}

.immplementation-wheel__inner-decoration {
    pointer-events: none;
}
</style>
