<template>
  <div
    class="block-contribution-calculator"
  >
    <div class="block-contribution-calculator__header">
      <div class="block-contribution-calculator__header-title-wrapper">
        <div class="block-contribution-calculator__header-pretitle">
          {{ headerPretitle }}
        </div>
        <div class="block-contribution-calculator__header-title">
          {{ headerTitle }}
          <div
            v-if="headerInfo !== '' && headerInfo?.content[0]?.content?.length > 0"
            class="block-contribution-calculator__info"
            @click="showInfo"
            @keypress="showInfo"
          >
            <IonIcon icon-name="info" />
            <Transition name="fade-in">
              <div
                v-if="infoOpen"
                class="block-contribution-calculator__info-box"
              >
                <AtomRichtext :html="richTextResolver.render(headerInfo)" />
              </div>
            </Transition>
          </div>
        </div>
      </div>
      <div v-if="!isInitial" class="block-contribution-calculator__header-controls">
        <div
          v-if="navigationIndex > 0 && amountPages > 2"
          class="block-contribution-calculator__header-control"
          @click="handleIndex('first')"
          @keypress="handleIndex('first')"
        >
          <IonIcon icon-name="chevron-left-skip" />
        </div>
        <div
          v-if="navigationIndex > 0"
          class="block-contribution-calculator__header-control"
          @click="handleIndex('prev')"
          @keypress="handleIndex('prev')"
        >
          <IonIcon icon-name="chevron-left" />
        </div>
        <div
          v-if="amountPages > 1"
          :class="{
            'is-hidden': isRefrigeratorSection
              && !refrigeratorData.type,
          }"
          class="block-contribution-calculator__header-controls-numbers"
        >
          <p>{{ navigationIndex + 1 }}</p>/{{ amountPages }}
        </div>
        <div
          v-if="navigationIndex < amountPages - 1"
          class="block-contribution-calculator__header-control"
          :class="{
            'is-hidden': isRefrigeratorSection
              && !refrigeratorData.type,
          }"
          @click="handleIndex('next')"
          @keypress="handleIndex('next')"
        >
          <IonIcon icon-name="chevron-right" />
        </div>
        <div
          v-if="navigationIndex < amountPages - 1
            && amountPages > 2"
          class="block-contribution-calculator__header-control"
          :class="{
            'is-hidden': isRefrigeratorSection
              && !refrigeratorData.type
              || navigationIndex === 0,
          }"
          @click="handleIndex('last')"
          @keypress="handleIndex('last')"
        >
          <IonIcon icon-name="chevron-right-skip" />
        </div>
      </div>
    </div>
    <div class="block-contribution-calculator__content" :class="{ 'is-initial': isInitial }">
      <div
        v-if="isInitial"
        class="block-contribution-calculator__calculators"
        :class="{ 'is-end': endOfSwiper }"
      >
        <swiper
          :slides-per-view="clientDeviceStore.isMobile ? 1 : 2.5"
          :space-between="clientDeviceStore.isMobile ? 10 : 40"
          :navigation="{
            nextEl: '.block-contribution-calculator__controls--next',
            prevEl: '.block-contribution-calculator__controls--prev',
          }"
          :grab-cursor="true"
          :modules="modules"
          :grid="{
            rows: 2,
            fill: 'row',
          }"
          @slide-change="onSlideChange"
          @swiper="onSwiper($event)"
        >
          <swiper-slide
            v-for="calculator in calculators"
            :key="calculator._uid"
            class="block-contribution-calculator__calculator"
            @click="setCalcData(calculator)"
          >
            <BlockItemCalculator :data="calculator" />
          </swiper-slide>
          <div class="block-contribution-calculator__controls">
            <div
              class="block-contribution-calculator__controls--prev"
              :class="{ 'is-hidden': startOfSwiper }"
            >
              <IonIcon icon-name="chevron-left" class="block-contribution-calculator__button-icon" />
            </div>
            <div>
              {{ activePage }} / {{ totalPages }}
            </div>
            <div
              class="block-contribution-calculator__controls--next"
              :class="{ 'is-hidden': endOfSwiper }"
            >
              <IonIcon icon-name="chevron-right" class="block-contribution-calculator__button-icon" />
            </div>
          </div>
        </swiper>
      </div>

      <BlockRefrigeratorLandingPage
        v-if="isRefrigeratorSection"
        :data="refrigeratorLandingPage[0]"
        :color-scheme="colorSchemeHeader"
      />

      <BlockCalculation
        v-if="isCalculation && !isRefrigeratorSection"
        :data="calculationData[navigationIndex]"
        :color-scheme="colorSchemeHeader"
      />

      <BlockCalculationDetails
        v-if="isDetails"
        :data="calculationDetails[navigationIndex]"
        :color-scheme="colorSchemeHeader"
      />

      <BlockCalculationNextSteps
        v-if="isNextSteps"
        :data="nextStepsData"
        :color-scheme="simpleColorScheme"
      />

      <div v-if="!isInitial" class="block-contribution-calculator__navigation">
        <div class="block-contribution-calculator__navigation-left">
          <div class="block-contribution-calculator__navigation-breadcrumbs">
            <div
              v-for="breadcrumb, index in breadcrumbs"
              :key="breadcrumb._uid"
              class="block-contribution-calculator__navigation-breadcrumb"
              :class="{ 'is-bold': index === breadcrumbs.length - 1 }"
            >
              <p v-if="index != 0">
                |
              </p>{{ breadcrumb }}
            </div>
          </div>
          <div
            v-if="isCalculation"
            class="block-contribution-calculator__navigation-back"
            @click="resetData"
            @keypress="resetData"
          >
            <IonIcon icon-name="chevron-left" />
            <p>{{ getStoryblokTranslation('general', 'back_to_selection') }}</p>
          </div>
          <div
            v-if="isDetails"
            class="block-contribution-calculator__navigation-back"
            @click="resetToCalc(initialData)"
            @keypress="resetToCalc(initialData)"
          >
            <IonIcon icon-name="chevron-left" />
            <p>{{ getStoryblokTranslation('general', 'back_to_calculation') }}</p>
          </div>
          <div
            v-if="isNextSteps && !isNextStepDetailsSection"
            class="block-contribution-calculator__navigation-back"
            @click="resetToDetails()"
            @keypress="resetToDetails()"
          >
            <IonIcon icon-name="chevron-left" />
            <p>{{ getStoryblokTranslation('general', 'back_to_details') }}</p>
          </div>
          <div
            v-if="isNextStepDetailsSection"
            class="block-contribution-calculator__navigation-back"
            @click="resetToNextSteps()"
            @keypress="resetToNextSteps()"
          >
            <IonIcon icon-name="chevron-left" />
            <p>{{ getStoryblokTranslation('general', 'back_to_next_steps') }}</p>
          </div>
        </div>

        <AtomButton
          v-if="navigationIndex !== amountPages - 1 && !isRefrigeratorSection"
          class="block-contribution-calculator__navigation-button hidden-on-mobile"
          :text="getStoryblokTranslation('general', 'next')"
          variant="outline"
          :color-schema="colorScheme"
          :class="{
            'is-white': colorScheme === 'white',
            'is-hidden': isRefrigeratorSection && navigationIndex > 0,
          }"
          @click="handleIndex('next')"
          @keypress="handleIndex('next')"
        />
        <AtomButton
          v-if="isRefrigeratorSection
            && refrigeratorData.modul
            && refrigeratorData.type"
          class="block-contribution-calculator__navigation-button hidden-on-mobile"
          :text="getStoryblokTranslation('general', 'next')"
          variant="outline"
          :color-schema="colorScheme"
          :class="{ 'is-white': colorScheme === 'white' }"
          @click="setCalcData(initialData)"
          @keypress="setCalcData(initialData)"
        />
        <AtomButton
          v-if="navigationIndex === amountPages - 1 && isCalculation && hasResult"
          class="block-contribution-calculator__navigation-button"
          :text="getStoryblokTranslation('general', 'details_calculation')"
          :color-schema="colorScheme"
          @click="showDetails"
          @keypress="showDetails"
        />
        <AtomButton
          v-if="navigationIndex === amountPages - 1 && isDetails"
          class="block-contribution-calculator__navigation-button is-next-steps"
          :text="getStoryblokTranslation('general', 'next_steps')"
          :color-schema="initialColorScheme"
          :color-code="colorSchemeNextStep"
          @click="showNextSteps"
          @keypress="showNextSteps"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    pretitle: {
        type: String,
        default: '',
    },

    title: {
        type: String,
        default: '',
    },

    calculators: {
        type: Array,
        default: () => [],
    },

    initialCalculator: {
        type: Object,
        default: () => {},
    },
});

const {
    pretitle,
    title,
    calculators,
    initialCalculator,
} = toRefs(props);

/*
  Swiper
*/
const {
    Navigation,
    Pagination,
    Grid,
    Swiper,
    SwiperSlide,
} = useSwiperjs();

const { locale } = useI18n();

const { richTextResolver } = useStoryblokApi();
const initialData = ref({});
const clientDeviceStore = useClientDeviceStore();

const modules = [Navigation, Pagination, Grid];
const startOfSwiper = ref(true);
const endOfSwiper = ref(false);
const activePage = ref(1);
const totalPages = ref(0);

const onSlideChange = (slide) => {
    startOfSwiper.value = slide.isBeginning;
    endOfSwiper.value = slide.isEnd;
    activePage.value = slide.activeIndex + 1;
};

const onSwiper = (swiper) => {
    activePage.value = swiper.realIndex + 1;
    totalPages.value = Math.ceil(swiper.slides.length / 2);
};

const isInitial = ref(true);
const isRefrigeratorCalc = ref(false);
const isCalculation = ref(false);
const isDetails = ref(false);
const isNextSteps = ref(false);
const infoOpen = ref(false);
const hasResult = ref(false);

const colorScheme = ref('var(--c-petrol-6)');
const initialColorScheme = ref('');
const colorSchemeHeader = ref('var(--c-petrol-6)');
const colorSchemeContent = ref('var(--c-petrol-6)');
const colorSchemeText = ref('var(--c-petrol-1)');
const colorSchemeInfo = ref('var(--c-petrol-1)');
const colorSchemeNextStep = ref('var(--c-petrol-4)');
const colorBackgroundSchemeInfo = ref('var(--c-petrol-11)');
const simpleColorScheme = ref('petrol');

const calculatorStore = useCalculatorStore();
const urlApi = computed(() => calculatorStore.getUrlApi);
const calculationData = computed(() => calculatorStore.getCalculation);
const nextStepsData = computed(() => calculatorStore.getNextSteps);
const nextStepsColorInversion = computed(() => calculatorStore.getColorInversion);
const isCalculationSection = computed(() => calculatorStore.getIsCalculationSection);
const isDetailsSection = computed(() => calculatorStore.getIsDetailsSection);
const isNextStepsSection = computed(() => calculatorStore.getIsNextStepsSection);
const isNextStepDetailsSection = computed(() => calculatorStore.getIsNextStepsDetailsSection);
const isRefrigeratorSection = computed(() => calculatorStore.getIsRefrigeratorSection);
const refrigeratorData = computed(() => calculatorStore.getRefrigerationSystem);
const refrigeratorLandingPage = computed(() => calculatorStore.getRefrigeratorLandingPage);

const headerTitle = ref(title.value);
const headerPretitle = ref(pretitle.value);
const headerInfo = computed(() => calculatorStore.getHeaderInfo);

const calculationDetails = ref({});
const calculationNextSteps = ref({});
const requestbody = computed(() => calculatorStore.getRequestBody);
const navigationIndex = ref(0);

const breadcrumbs = ref([]);
const amountPages = ref(0);

const setCalcData = (data, saved) => {
    initialData.value = data;

    // set initial state
    isInitial.value = false;
    isCalculation.value = true;

    // set color schema
    colorScheme.value = data.color ? data.color : 'petrol';
    simpleColorScheme.value = data.color ? data.color : 'petrol';
    initialColorScheme.value = colorScheme.value;
    colorSchemeHeader.value = data.color ? `var(--c-${data.color}-6)` : 'var(--c-petrol-6)';
    colorSchemeContent.value = isCalculation.value ? 'var(--c-white)' : 'var(--c-petrol-6)';
    colorBackgroundSchemeInfo.value = `var(--c-${data.color}-11)`;
    colorSchemeText.value = data.color ? `var(--c-${data.color}-1)` : 'var(--c-petrol-1)';
    calculatorStore.setColorScheme(colorScheme.value);
    calculatorStore.setColorText(colorSchemeText.value);

    // set calculation data
    if (data.is_refrigerator && !refrigeratorData.value.modul && !refrigeratorData.value.type) {
        calculatorStore.setCalculation(data.calculation, true);
        calculatorStore.setIsRefrigeratorSection(true);
    } else if (
        data.is_refrigerator
        && refrigeratorData.value.modul
        && refrigeratorData.value.type
    ) {
        const index = data.calculation_refrigerator[0].content.findIndex(
            (item) => item.modul === refrigeratorData.value.modul
            && item.type === refrigeratorData.value.type,
        );
        calculatorStore.setCalculation(
            data.calculation_refrigerator[0].content[index].calculation,
        );
        amountPages.value = data.calculation_refrigerator[0].content[index].calculation.length;

        // set api url
        calculatorStore.setUrlApi(data.calculation_refrigerator[0].content[index].url_api);

        calculatorStore.setIsRefrigeratorSection(false);

        // reset breadcrumb
        breadcrumbs.value = [];

        // set request body for each data in data.requestbody
        if (!saved) {
            data.calculation_refrigerator[0].content[index].requestbody.forEach((element) => {
                calculatorStore.setRequestBody(element);
            });
        }

        isRefrigeratorCalc.value = true;
    } else {
        calculatorStore.setCalculation(data.calculation);
        amountPages.value = data.calculation.length;
        calculatorStore.setIsRefrigeratorSection(false);

        // set api url
        calculatorStore.setUrlApi(data.url_api);

        // set request body for each data in data.requestbody
        if (!saved) {
            data.requestbody.forEach((element) => {
                calculatorStore.setRequestBody(element);
            });
        }

        // set calculation details
        calculationDetails.value = data.details;
    }

    breadcrumbs.value.push(props.pretitle);
    breadcrumbs.value.push(data.title);

    // set initial title and pretitle
    headerTitle.value = calculatorStore.getHeaderTitle;
    headerPretitle.value = calculatorStore.getHeaderPretitle;

    // set calculation next steps
    calculationNextSteps.value = data.nextSteps;
    if (data.nextSteps?.length > 0) {
        calculatorStore.setNextSteps(data.nextSteps[0]);
    }

    if (data.nextSteps2?.length > 0) {
        calculatorStore.setNextSteps2(data.nextSteps2[0]);
    }
};

// if only one calculator is available, set it as initial data
if (initialCalculator.value.length === 1) {
    setCalcData(initialCalculator.value[0]);
}

const handleIndex = (direction) => {
    infoOpen.value = false;

    switch (direction) {
    case 'next':
        navigationIndex.value += 1;
        break;
    case 'prev':
        navigationIndex.value -= 1;
        break;
    case 'first':
        navigationIndex.value = 0;
        break;
    case 'last':
        navigationIndex.value = amountPages.value - 1;
        break;
    default:
        break;
    }
};

const calculate = async (body) => {
    // if any of the bodys is 0, return
    if (Object.values(body).some((value) => value === '0')) {
        return;
    }

    try {
        const { data, error } = await useFetch(urlApi.value, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            query: {
                lang: locale.value,
            },
            body,
        });

        calculatorStore.setCalculatedData(data.value);

        // to show details button
        if (data.value) {
            hasResult.value = true;
        }

        if (error.value) {
            calculatorStore.setErrorMessage(error.value.data.message);
        } else {
            calculatorStore.setErrorMessage('');
        }
    } catch (e) {
        useSentryError(e);
    }
};

const { throttledExecute } = useThrottledExecute(500);
watch(requestbody.value, () => {
    const body = { ...toRaw(requestbody.value) };

    if (!isRefrigeratorSection.value) {
        throttledExecute(() => {
            calculate(body);
        });
    }
});
watch(
    () => ({ ...refrigeratorData.value }),
    (newValue, oldValue) => {
        if (
            newValue?.type !== undefined
          && newValue?.type !== null && newValue?.type?.length > 0
          && newValue?.type !== oldValue?.type) {
            setCalcData(initialData.value);
        }
    },
    { deep: true, immediate: true },
);
watch(() => calculatorStore.getCalculatedData, () => {
    if (calculatorStore.getCalculatedData === null) {
        hasResult.value = false;

        if (isInitial.value && initialData.value.requestbody) {
            initialData.value.requestbody.forEach((element) => {
                calculatorStore.setRequestBody(element);
            });
        }
    }
});

const showInfo = () => {
    infoOpen.value = !infoOpen.value;
};

const showDetails = () => {
    isCalculation.value = false;
    isNextSteps.value = false;
    isDetails.value = true;
    calculatorStore.setIsCalculationSection(false);

    navigationIndex.value = 0;
    amountPages.value = calculationDetails.value.length;

    // set new color scheme
    colorSchemeContent.value = colorSchemeHeader.value;
    colorSchemeText.value = 'var(--c-white)';
    colorScheme.value = 'white';
    colorSchemeInfo.value = `var(--c-${calculatorStore.getColorScheme}-1)`;
    colorSchemeNextStep.value = `var(--c-${calculatorStore.getColorScheme}-4)`;
    colorBackgroundSchemeInfo.value = `var(--c-${calculatorStore.getColorScheme}-11)`;

    // set inital title and pretitle
    calculatorStore.setHeaderPretitle(calculationDetails.value[0].pretitle);
    calculatorStore.setHeaderTitle(calculationDetails.value[0].title);
    headerInfo.value = calculationDetails.value[0].info;

    // update breadcrumbs
    breadcrumbs.value.push(calculationDetails.value[0].pretitle);
    calculatorStore.setColorInversion(false);
};

const showNextSteps = () => {
    isDetails.value = false;
    isNextSteps.value = true;
    calculatorStore.setHeaderInfo('');
    calculatorStore.setIsNextStepsSection(true);

    navigationIndex.value = 0;
    amountPages.value = calculationNextSteps.value.length;

    // set new color scheme
    colorSchemeContent.value = colorSchemeHeader.value;
    colorSchemeText.value = 'var(--c-white)';
    colorScheme.value = 'white';

    // set inital title and pretitle
    headerTitle.value = calculationNextSteps.value[0].title;
    headerPretitle.value = calculationNextSteps.value[0].pretitle;

    // update breadcrumbs
    breadcrumbs.value.push(headerPretitle.value);
    calculatorStore.setNextSteps(calculationNextSteps.value[navigationIndex.value]);
};

const resetData = () => {
    isInitial.value = true;
    isCalculation.value = false;
    isDetails.value = false;

    calculatorStore.setIsDetailsSection(false);
    calculatorStore.setIsCalculationSection(false);
    calculatorStore.setIsNextStepsSection(false);
    calculatorStore.setColorInversion(false);

    navigationIndex.value = 0;
    breadcrumbs.value = [];

    calculatorStore.setHeaderTitle(title.value);
    calculatorStore.setHeaderPretitle(pretitle.value);

    endOfSwiper.value = false;
    hasResult.value = false;

    refrigeratorData.value.modul = '';
    refrigeratorData.value.type = '';

    // set color schema
    colorScheme.value = 'petrol';
    colorSchemeHeader.value = 'var(--c-petrol-6)';
    colorSchemeContent.value = 'var(--c-petrol-6)';

    // reset store
    calculatorStore.resetData();

    if (isRefrigeratorCalc.value) {
        isRefrigeratorCalc.value = false;
        setCalcData(initialData.value);
    }
};

const resetToCalc = (data) => {
    breadcrumbs.value = [];
    isDetails.value = false;
    navigationIndex.value = 0;
    calculatorStore.setIsNextStepsSection(false);
    calculatorStore.setColorInversion(false);

    setCalcData(data, true);
};

const resetToDetails = () => {
    breadcrumbs.value.splice(-2, 2);
    isDetails.value = true;
    isNextSteps.value = false;
    calculatorStore.setIsNextStepsSection(false);
    calculatorStore.setColorInversion(false);
    navigationIndex.value = 0;

    showDetails();
};

const resetToNextSteps = () => {
    breadcrumbs.value.splice(-1, 1);
    isDetails.value = false;
    isNextSteps.value = true;
    calculatorStore.setIsNextStepsSection(true);
    calculatorStore.setIsNextStepsSectionDetailsSection(false);
    calculatorStore.setColorInversion(false);
    navigationIndex.value = 0;

    showNextSteps();
};

watchEffect(() => {
    if (calculatorStore.getHeaderPretitle) {
        headerPretitle.value = calculatorStore.getHeaderPretitle;
    }

    if (calculatorStore.getHeaderTitle) {
        headerTitle.value = calculatorStore.getHeaderTitle;
    }

    if (calculatorStore.getHeaderInfo) {
        headerInfo.value = calculatorStore.getHeaderInfo;
    }

    if (calculatorStore.getDetails) {
        if (refrigeratorData.value.modul && refrigeratorData.value.type) {
            calculationDetails.value = calculatorStore.getDetails;
        }
    }

    if (isNextStepsSection.value && nextStepsColorInversion.value) {
        colorSchemeContent.value = 'var(--c-white)';
        colorSchemeText.value = colorSchemeInfo.value;
    }

    if (isNextStepsSection.value && !nextStepsColorInversion.value) {
        colorSchemeContent.value = colorSchemeHeader.value;
        colorSchemeText.value = 'var(--c-white)';
        colorScheme.value = 'white';
    }

    if (isCalculationSection.value) {
        isCalculation.value = true;
        isNextSteps.value = false;
        colorSchemeContent.value = 'var(--c-white)';
        colorSchemeText.value = colorSchemeInfo.value;
        breadcrumbs.value = [];
        navigationIndex.value = 0;
        setCalcData(initialData.value, true);
    }

    if (isDetailsSection.value) {
        resetToDetails();
    }
});

watch(() => props.title, () => {
    resetData();

    if (initialCalculator.value.length === 1) {
        breadcrumbs.value = [];
        setCalcData(initialCalculator.value[0]);
    }
});
</script>

<style lang="scss">
.block-contribution-calculator {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 3px;
}

.block-contribution-calculator__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 40px 100px;
    background: v-bind(colorSchemeHeader);
    color: var(--c-white);

    @include mobile {
        flex-direction: column;
        align-items: unset;
        padding: 10px;
    }
}

.block-contribution-calculator__header-pretitle {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.block-contribution-calculator__header-title {
    display: flex;
    column-gap: 20px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    line-height: var(--f-line-height--h3);
}

.block-contribution-calculator__info {
    position: relative;
    cursor: pointer;

    @include mobile {
        position: unset
    }
}

.block-contribution-calculator__info-box {
    @include z-index('header');

    position: absolute;
    top: 50px;
    left: -70px;
    width: 598px;
    padding: 20px;
    background: v-bind(colorBackgroundSchemeInfo);
    color: v-bind(colorSchemeInfo);

    &:before {
        position: absolute;
        z-index: -1;
        top: -10px;
        left: 50px;
        display: block;
        width: 58px;
        height: 64px;
        background: v-bind(colorBackgroundSchemeInfo);
        content: '';
        transform: rotate(45deg);

        @include mobile {
            display: none;
        }
    }

    @include mobile {
        top: 40px;
        right: 40px;
        left: 40px;
        width: unset;
    }
}

.block-contribution-calculator__header-controls {
    display: flex;
    align-items: center;
    column-gap: 30px;

    @include mobile {
        align-self: flex-end;
        column-gap: 10px;
    }
}

.block-contribution-calculator__header-control {
    cursor: pointer;

    &.is-hidden {
        display: none;
    }
}

.block-contribution-calculator__header-controls-numbers {
    display: flex;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        font-family: var(--f-family--bold);
    }

    &.is-hidden {
        display: none;
    }
}

.block-contribution-calculator__content {
    display: flex;
    width: 100%;
    height: 724px;
    flex-direction: column;
    justify-content: space-between;
    background: v-bind(colorSchemeContent);
    color: v-bind(colorSchemeText);

    &.is-initial{
        justify-content: center;

        @include mobile {
            justify-content: flex-start;
            padding: 20px 0;
        }
    }

    @include mobile {
        height: 100%;
    }
}

.block-contribution-calculator__calculators {
    position: relative;
    width: 100%;
    padding: 0 100px;

    &::before {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 100px;
        display: block;
        width: 160px;
        height: 100%;
        background: linear-gradient(270deg, #6B919C 54.06%, rgba(107, 145, 156, 0) 104.37%, rgba(107, 145, 156, 0) 154.06%);
        content: '';
        opacity: 1;
        pointer-events: none;
        transition: opacity 0.1s ease;

        @include mobile {
            display: none;
        }
    }

    &::after {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 100px;
        display: block;
        width: 160px;
        height: 100%;
        background: linear-gradient(90deg, #6B919C 54.06%, rgba(107, 145, 156, 0) 104.37%, rgba(107, 145, 156, 0) 154.06%);
        content: '';
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s ease;

        @include mobile {
            display: none;
        }
    }

    &.is-end {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    @include mobile {
        padding: 0 20px;
    }

    .swiper {
        display: flex;
        flex-direction: column;
    }
}

.block-contribution-calculator__controls {
    display:none;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    color: var(--c-white);
    column-gap: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);

    @include mobile {
        display: flex;
    }
}

.block-contribution-calculator__controls--prev {
    cursor: pointer;

    &.is-hidden {
        display: none;
    }
}

.block-contribution-calculator__controls--next {
    cursor: pointer;

    &.is-hidden {
        display: none;
    }
}

.block-contribution-calculator__navigation {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 100px 60px 100px;

    @include mobile {
        flex-direction: column;
        flex-direction: column-reverse;
        align-items: unset;
        padding: 0 20px 20px 20px;
        row-gap: 13px;
    }
}

.block-contribution-calculator__navigation-left {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.block-contribution-calculator__navigation-breadcrumbs {
    display: flex;
    align-items: center;
    column-gap: 22px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);

    @include tablet {
        display: none;
    }
}

.block-contribution-calculator__navigation-breadcrumb {
    display: flex;
    align-items: center;
    column-gap: 22px;

    p {
        font-family: var(--f-family--thin);
        font-size: 29px;
    }

    &.is-bold {
        font-family: var(--f-family--bold);
        font-size: var(--f-size--button);
    }
}

.block-contribution-calculator__navigation-button {
    display: flex;
    width: 200px;
    height: fit-content;
    justify-content: center;
    white-space: nowrap;

    &.is-white {
        border: 2px solid var(--c-white);
        background: transparent;
    }

    &.is-hidden {
        display: none;
    }

    &.hidden-on-mobile {
        @include mobile {
            display: none;
        }
    }

    @include mobile {
        height: 47px;
        align-self: flex-end;
    }
}

.block-contribution-calculator__navigation-back {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);

    .ion-icon {
        width: 22px;
        height: 22px;
        transition: all 0.3s ease;

        path {
            stroke: v-bind(colorSchemeText);
        }
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0);
        }
    }
}
</style>
