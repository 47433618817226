<template id="modal__template">
  <div name="block-viewer-pdf">
    <div class="block-viewer-pdf__wrapper">
      <ClientOnly>
        <Swiper
          v-if="pages !== 0 && pages !== 1"
          :modules="modules"
          :slides-per-view="1"
          :space-between="60"
          :grab-cursor="true"
          :pagination="pagination"
          :breakpoints="{
            1200: {
              slidesPerView: 2,
            },
          }"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="n in parseInt(pages)"
            :key="n"
            class="block-viewer-pdf__slide"
          >
            <div class="block-viewer-pdf__content__pdf-block">
              <VuePDF :pdf="pdf" :page="n" fit-parent />
            </div>
          </SwiperSlide>
        </Swiper>
        <div v-if="pages === 1" class="block-viewer-pdf__single-page">
          <div class="block-viewer-pdf__content__pdf-block">
            <VuePDF :pdf="pdf" :page="pages" fit-parent />
          </div>
        </div>
      </ClientOnly>
    </div>
    <div v-if="pages !== 0 && pages !== 1" class="block-viewer-pdf__swiper__nav">
      <div
        class="swiper-pagination ui-accordion-pdf-item__swiper-pagination"
      />
      <div class="block-viewer-pdf__arrow-block">
        <div
          class="block-viewer-pdf__arrow-right"
          @click="changeSlide('prev')"
          @keypress="changeSlide('prev')"
        >
          <IonIcon icon-name="chevron-left" />
        </div>
        <div
          class="block-viewer-pdf__arrow-left"
          @click="activeIndex === parseInt(pages) ? null : changeSlide('next')"
          @keypress="changeSlide('next')"
        >
          <IonIcon icon-name="chevron-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const {
    Navigation, Pagination, Swiper, SwiperSlide,
} = useSwiperjs();
const { VuePDF, usePDF } = useVuePdf();

const props = defineProps({
    urlPdf: {
        type: String,
        default: '',
        validator: (value) => value.length > 0,
    },
});

const modules = [Navigation, Pagination];

const url = computed(() => (props.urlPdf));
const { pdf, pages } = usePDF(url.value);

/*
    Init Swiper
*/
const swiperController = ref(null);

const onSwiper = (e) => {
    swiperController.value = e;
};

/*
  Slide Index
*/
const activeIndex = computed(() => swiperController.value?.realIndex);

/*
    Controlls
*/
const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        swiperController.value.slidePrev();
    }
};

const pagination = {
    el: '.swiper-pagination',
    dynamicBullets: true,
    clickable: true,
};

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss">

.block-viewer-pdf__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 30px 46px 30px 20px;
    @include mobile {
        padding: 30px 20px;
    }
}
.block-viewer-pdf__single-page {
    width: 412px;
    height: 585px;
    border: 0.1px solid var(--c-grey-border);
    @include tablet {
        width: 100%;
        height: 100%;
    }
}
.block-viewer-pdf__image {
    flex-basis: 70%;
    img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
    }
}
.block-viewer-pdf__slide {
    position: relative;
    border: 0.1px solid var(--c-grey-border);
    transition: max-width 0.25s ease-in-out, padding 0.25s ease-in-out;
}
.block-viewer-pdf__arrow-block {
    z-index: 10;
    display: flex;
    gap: 40px;
}
.atom-card__arrow-link__icon {
    width: 24px;
    height: 24px;
    &.left {
        transform: rotate(180deg);
    }
}
.block-viewer-pdf__arrow-right,
.block-viewer-pdf__arrow-left {
    display: flex;
    width: 22px;
    height: 22px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.block-viewer-pdf__arrow-right {
    margin-bottom: 30px;
}

.block-viewer-pdf__swiper__nav {
    display: flex;
    width: 100%;
    padding-left: 15px;
    gap: 30px;
    @include mobile {
        flex-wrap: wrap;
    }

    .swiper-pagination-bullet-active-main {
        background: v-bind(color1) 0% 0% no-repeat padding-box !important;
    }

    .swiper-pagination {
        position: static !important;
        width: auto !important;
        margin-top: 5px;
        transform: translateX(50%);

        .swiper-pagination-bullet {
            left: 0px !important;
            width: 12px;
            height: 12px;
            margin: 0 10px!important;
            transform: scale(1);
        }
    }

    .swiper-pagination-bullets-dynamic {
        font-size: 10px !important;
        transform: translateX(0) !important;
    }
}

</style>
